var ip = window.location.hostname;
var protocol = window.location.protocol == "https:" ? 'https' : 'http';
export const priceunit = 'Rs. '

export const API_URL = `${protocol}://${ip}/api/`;
export const BASE_URL = `${protocol}://${ip}/images/`;

// export const API_URL = 'https://dominoscrmstg.simplexts.com.au/api/'
// export const BASE_URL = 'https://dominoscrmstg.simplexts.com.au/images/'

export const EncryptionKey = '6c.k6cGghiexfi/pDTt!WK01{y?DY#-98LJRuWZvR0'
export const AUTHORIZATION_KEY = "dominos-6fd51480-6f22-11ed-835a-cb4f720618dc"

export const DEFUALT_PAGE_SIZE=25;