import {
  GET_CART,
  CLEAR_CART,
  SAVE_CART,
  COUPON_DATA,
  GET_TAX,
  GET_INST,
} from './cartType';
import Api from '../../components/Api';
import { removeCoupon, resetCoupon } from './menuAction';
var CryptoJS = require("crypto-js");

export const getCart = () => {
  return async (dispatch: any) => {
    let cart: any = await localStorage.getItem('cart');
    if (cart == null || cart.length <= 0) {
      cart = [];
    }
    dispatch({
      type: GET_CART,
      payload: cart
    });
  };
};
export const clearCart = () => {
  return async (dispatch: any) => {
    localStorage.removeItem('cart');
   dispatch (resetCoupon());
      dispatch(removeCoupon());
    dispatch(getCart());

  };
};
export const saveCart = (cart: any) => {
  //type of cart is array
  return async (dispatch: any) => {
    //convert cart to string
    let strCart = JSON.stringify(cart);
    //save cart
    if(cart && cart.length>0){
      localStorage.setItem('cart', strCart);
    }
    dispatch(getCart());
  };
};
export const getTaxValue = (state_id: any) => {
  return async (dispatch: any) => {
    Api.get(`menu/tax_value/${state_id}`)
      .then((response) => {
        if (response.data.success) {
          dispatch({
            type: GET_TAX,
            payload: response.data.successResponse,
          });
        }
      })
      .catch((err) => {
        if (err.response) {
          let error;
          dispatch({
            type: GET_TAX,
            payload: "",
          });
          console.log(error);
        } else {
          alert(err.message)
        }
      });
  };
};
export const getInst = (text: any) => {
  return async (dispatch: any) => {
    dispatch({
      type: GET_INST,
      instructions: text
    });
  };
};
