import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import Topbar from "../../components/topbar";
import Sidebar from "../../components/sidebar";
import Footer from "../../components/footer/main";
import jwt from "jsonwebtoken";
import _ from "lodash";
import AnchorLink from "react-anchor-link-smooth-scroll";
import { secretKey } from "../../secret";
import { connect } from "react-redux";
import {
  addCustomer,
  customerDetail,
  getCart,
  getStores,
  getTaxValue,
  handleAddressInput,
  handleCustomerInput,
  handleMenuInput,
  logoutUser,
  orderDetail,
  saveCart,
  validateEmail,
} from "../../redux";
import { CustomerProps, CustomerState } from "../../interfaces/customer";
import Complaint from "./complaint";

import Autocomplete from "react-google-autocomplete";
import { withGoogleMap, withScriptjs } from "react-google-maps";
import { Tabs, TabLink, TabContent } from "react-tabs-redux";
import Select from 'react-select';
import {
  findLocalStores,
  submitComplaint,
  TimerStart,
  trackOrder,
  stopCount,
  addAddressUser,
  addressesListk,
  BlockCustomer,
  updateStateCustomer,
  ActivateAccount,
} from "../../redux/actions/customerAction";
import { StartClockTimer, stopTimer } from "../../redux/actions/headerAction";
import moment from "moment";
import { toast } from "react-toastify";
import { complaintReasonsList } from "../../redux/actions/customerComplaintAction";
import { getCitiesWeb, getTradeZonesWeb, getTradeAreas, setTradeZoneName, setCityName, setTradeAreaName } from "../../redux/actions/trade_zones_areas_Action";
import { handleAddInfo, handleHouseNo, handleStreetName, handleStreetNo } from "../../redux";
import './customerDetail.css'
import Invoice from "../order/FbrInvoice/fbrInvoice";
import { showorderItems, updateSelectedRow } from "../../redux/actions/orderAction";
class ActionFormatter extends Component<{ row: any }, {}> {
  constructor(readonly props: any) {
    super(props);
  }
  showorderItems = (id: any) => {
    let storeId: any = "";
    let stateId: any = "";
    this.props.data.orderDetailForRepeat(id);
    storeId = this.props.data.orders.find((obj: any) => {
      return obj.order_id == id;
    }).store_id;
    if (storeId && storeId !== "") {
      stateId = this.props.data.stores.find((obj: any) => {
        return obj.store_id == storeId;
      }).state_id;
    }
    this.props.data.getTaxValue(stateId);
  };
  render() {
    const { row, data } = this.props;
    return (
      <div>
        <button
          title="Repeat Order"
          data-toggle="modal"
          data-target={`#repeatorder${row.order_id}`}
          className="btn btn-outline-info ml-2"
          onClick={() => this.showorderItems(row.order_id)}
        >
          <i className="fa fa-repeat"></i>
        </button>
        {/*Invoice Modal */}
        <div
          id={`repeatorder${row.order_id}`}
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
          className="modal fade text-left"
        >
          <div role="document" className="modal-dialog modal-lg">
            <div className="modal-content">
              <div className="modal-header">
                <h4 id="exampleModalLabel" className="modal-title">
                  Order
                </h4>
                <button
                  type="button"
                  data-dismiss="modal"
                  aria-label="Close"
                  className="close"
                >
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body">
                {/* <OrderDetail
                  data={data}
                  tax={
                    !_.isEmpty(this.props.data.taxdata) &&
                    this.props.data.taxdata.tax_percent
                  }
                  orderId={row.order_id}
                /> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
class InvoiceFormator extends Component<{ row: any }, { selectedOrderId: any }> {
  constructor(readonly props: any) {
    super(props);
    this.state = {
      selectedOrderId: "",
    }
  }
  showorderItems = (id: any) => {
    this.props.data.showorderItems(id, this.props.data.orders);
    this.setState({ selectedOrderId: id });
  }
  render() {
    const { row, data } = this.props;
    return (
      <div>
        {/* <button title="View Order Items" data-toggle="modal" data-target={`#viewitems${row.order_id}`} className="btn btn-outline-info" onClick={() => this.showorderItems(row.order_id)}><i className="fa fa-list"></i></button> */}
        <button title="View Invoice" data-toggle="modal" data-target={`#viewinvoice${row.order_id}`} className="btn btn-outline-info ml-2" onClick={() => this.showorderItems(row.order_id)}><i className="fa fa-file-pdf-o"></i></button>
        {/* <!-- Modal--> */}
        {/*Invoice Modal */}
        <div id={`viewinvoice${row.order_id}`} data-backdrop='static' role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" className="modal fade text-left">
          <div role="document" className="modal-dialog modal-lg" style={{ width: '500px' }}>
            <div className="modal-content">
              <div className="modal-header">
                <h4 id="exampleModalLabel" className="modal-title">Invoice</h4>
                <button type="button" data-dismiss="modal" aria-label="Close" className="close"><span aria-hidden="true">×</span></button>
              </div>
              <div className="modal-body">
                <Invoice data={data} orderId={this.state.selectedOrderId} />
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}


function priceFormatter(cell: any, row: any) {
  if (row.aggregator_orderId) {
    return `Rs. ${cell}`;
  } else {
    return `Rs. ${Math.round(cell)}`;
  }
}
function actionFormatter(cell: any, row: any, props: any) {
  return <ActionFormatter row={row} data={props} />;
}
function invoiceFormatter(cell: any, row: any, props: any) {
  return <InvoiceFormator row={row} data={props} />;
}

function complaintFormatter(cell: any, row: any, props: any) {
  return (
    <button
      title="Feedback"
      className="btn btn-outline-primary"
      data-toggle="modal"
      data-target={`#RegFeedback`}
      onClick={() => {
        props.updateSelectedRow(row)
      }}
    >
      <i className="fa fa-comment"></i>
    </button>
  );
}
class Map extends Component<
  {
    handleAddressInput: (address: any) => {};
    findLocalStores: (lat: any, lng: any) => {};
  },
  { lat: any; lng: any }
> {
  constructor(readonly props: any) {
    super(props);
    this.state = {
      lat: 0,
      lng: 0,
    };
    // this.handleSubmit = this.handleSubmit.bind(this);
  }
  /**
   * Component should only update ( meaning re-render ), when the user selects the address, or drags the pin
   *
   * @param nextProps
   * @param nextState
   * @return {boolean}
   */
  shouldComponentUpdate(nextProps: any, nextState: any): boolean | any {
    return false;
  }
  onPlaceSelected = (place: any) => {
    if (place.formatted_address) {
      const address = place.formatted_address,
        latValue = place.geometry.location.lat(),
        lngValue = place.geometry.location.lng();
      this.setState({ lat: latValue, lng: lngValue });
      this.props.handleAddressInput(address);
      this.props.findLocalStores(latValue, lngValue);
    }
  };
  auto = () => {
    const AsyncMap: any = withScriptjs(
      withGoogleMap((props: any) => (
        <Autocomplete
          style={{
            width: "100%",
            height: "40px",
            marginBottom: "15px",
            paddingLeft: "16px",
            marginTop: "2px",
          }}
          onPlaceSelected={this.onPlaceSelected}
          types={["address"]}
          componentRestrictions={{ country: "pk" }}
        />
      ))
    );
    let map;
    map = (
      <AsyncMap
        googleMapURL={`https://maps.googleapis.com/maps/api/js?key=AIzaSyDGq7xWqvDvuvqwyTGJZDjfcRy1aCRmvt8&libraries=geometry,drawing,places`}
        loadingElement={<div style={{ height: "0px" }} />}
        containerElement={<div style={{ height: this.props.height }} />}
        mapElement={<div style={{ height: "0px" }} />}
      />
    );
    return map;
  };
  render() {
    return <div>{this.auto()}</div>;
  }
}
function ComplaintComp(props: any) {
  return (
    <div
      id={`RegFeedback`}
      role="dialog"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
      className="modal fade text-left"
    >
      <div role="document" className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h4 id="exampleModalLabel" className="modal-title">
              Customer Feedback
            </h4>
            <button
              type="button"
              data-dismiss="modal"
              aria-label="Close"
              className="close"
            >
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div className="modal-body">
            <Complaint />
          </div>
        </div>
      </div>
    </div>
  );
}
class CustomerDetail extends Component<CustomerProps, CustomerState> {
  customerNumber: any;
  selectRef: any;
  selectRef2: any;
  selectRef3: any;

  constructor(readonly props: any) {
    super(props);

    this.state = {
      cityObj: { label: '', value: '' },
      zoneObj: { label: '', value: '' },
      areaObj: { label: '', value: '' },
      phone: "",
      fullname: "",
      email: "",
      feedbackType: "",
      receiptnumber: "",
      storeId: "",
      dateOfOrder: "",
      feedbackMessage: "",
      isValidPhone: "",
      orderId: "",
      placeClick: "",
      newUserphone: "",
      isValidEmail: true,
      newUser: false,
      traceOrderby: "Phone",
      editable_fullAddress: "",
      editClick: false,
      addClick: false,
      addressIndex: null,
      sizejson: [],
      addressesList: [],
      place: "",
      editAddress_id: "",
      isUpdateBtnDisabled: true
    };
    this.selectRef = null;
    this.selectRef2 = null;
    this.selectRef3 = null;

    const urlPhone = localStorage.getItem("urlPhone");
    if (urlPhone && urlPhone != "") {
      window.location.href = "/customerDetail?number=" + urlPhone;
      this.submitPhone(urlPhone, "");
      localStorage.setItem("urlPhone", "");
    } else {
      let search = window.location.search;
      let params = new URLSearchParams(search);
      this.customerNumber = params.get("number");
      if (this.customerNumber !== "" && this.customerNumber !== null) {
        this.submitPhone(this.customerNumber, "");
      }
    }
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSubmitComplaint = this.handleSubmitComplaint.bind(this);
    this.validatePhone = this.validatePhone.bind(this)
  }
  componentDidMount() {
    this.props.storesList();
    this.props.complaintReasonsList();
    document.title = "DominosCRM | Customer Detail";
    let search = window.location.search;
    let params = new URLSearchParams(search);
    let customerNumber = params.get("number");
    if (customerNumber !== "" && customerNumber !== null) {
      this.setState({ phone: customerNumber, isValidPhone: true });
      let newUserphone = this.TrimNumber(customerNumber);
      this.setState({ newUserphone: newUserphone })
    }
    this.props.getCities()
  }

  UNSAFE_componentWillReceiveProps(nextProps: any) {
    if (this.props.callduration === nextProps.callduration) {
      this.setState({ addressesList: nextProps.addressData })
    }
    let { orders, customer } = this.props
    if (!_.isEmpty(orders) && orders.length > 0) {
      let totalRevenue = 0
      orders.map((item: any) => {
        totalRevenue += item.order_grossprice
      })
      let obj = {
        totalRevenue: Math.round(totalRevenue),
        lastOrderDate: moment(orders[0].date_created).local().format('YYYY-MM-DD HH:mm').split(' ')
      }
      // this.props.updateStateCustomer(obj,customer)
      // console.log(Math.round(totalRevenue),'   total revenue');
      // console.log(moment(orders[0].date_created).local().format('YYYY-MM-DD HH:mm').split(' '),'   last order date created');      
    }
  }


  handleAddClick = async (e: any,) => {
    if (this.state.sizejson.length == 1) {
      !this.state.editClick && toast.error("Only one address can be added at a time")
    }
    else {
      let customer_id = localStorage.getItem('customer');
      const newlist: any = {
        full_address: "",
        place: "Home",
        customer_id: customer_id,
        city: "",
        city_id: "",
        location: "",
        zone_id: "",
        area: "",
        area_id: ""
      }
      const size: any = this.state.sizejson.concat(newlist)
      await this.setState({ sizejson: size, isUpdateBtnDisabled: false, addClick: true });
    }
    e.preventDefault()
  };
  editAddressField = async (addressObj: any, index: any) => {
    let { addressesList } = this.state
    await this.setState({ editable_fullAddress: addressObj, isUpdateBtnDisabled: false })
    addressesList.forEach((address: any) => {
      if (address.address_id == addressObj.address_id) {
        address.enable = true;
      }
    })
    this.setState({
      addressesList: addressesList,
      editAddress_id: addressObj.address_id
    })
  }
  isAddressReady = () => {
    const { tradeAreaName, tradeZoneName, cityName, streetName, streetNo, houseNo } = this.props;
    return (cityName !== "" && tradeZoneName !== "" && tradeAreaName !== "" && streetName !== "" && streetNo !== "" && houseNo !== "");
  }
  handleVariantInputChange = (e: any, index: any) => {
    const { name, value } = e.target;
    console.log('value', value);

    const list: any = this.state.addressesList;
    list[index][name] = value;
    this.setState({ addressesList: list });
    if (name == "pos_code") {
      this.props.checkDuplicatePosCode(value)
    }
    if (name == "erp_id") {
      this.props.checkDuplicateErpId(value)
    }
  };

  handleNewVariantInputChange = (e: any, index: any) => {
    const { name, value } = e.target;
    localStorage.setItem("place", value)
    const list: any = this.state.sizejson;
    list[index][name] = value;
    this.setState({ sizejson: list });
  };

  handleBlockCustomer = () => {
    let obj = {
      is_active: 0
    }
    this.props.BlockCustomer(obj);
  }
  handleActivation = () => {
    this.props.handleActivation();
  }
  handleUnBlockCustomer = () => {
    let obj = {
      is_active: 1
    }
    this.props.BlockCustomer(obj);
  }

  handleAddressSubmit = async (event: any, index: any) => {
    let { houseNo, Block, Area, City, streetName, streetNo, Additional_address_info } = this.props
    let place = localStorage.getItem("place")
    let { addressesList } = this.state;
    let full_address = houseNo + " " + Block + " " + Area + " " + City;
    if (houseNo === '' && Block === '' && Area === '' && City === '') {
      this.props.addAddress(addressesList);
    }
    else {
      let list: any = this.state.sizejson;
      let obj = {
        full_address: event.target.value,
        city: this.state.cityObj.label, city_id: this.state.cityObj.value,
        location: this.state.zoneObj.label,
        zone_id: this.state.zoneObj.value,
        area: this.state.areaObj.label,
        area_id: this.state.areaObj.value,
        street_name: streetName,
        street_number: streetNo,
        extra_details: Additional_address_info
      }
      if (list.length > 0) {
        let addresses = this.state.addressesList;
        if (this.state.editClick) {
          addresses[index].full_address = full_address;
          addresses[index].city = this.state.cityObj.label;
          addresses[index].city_id = this.state.cityObj.value;
          addresses[index].location = this.state.zoneObj.label;
          addresses[index].zone_id = this.state.zoneObj.value;
          addresses[index].area = this.state.areaObj.label;
          addresses[index].area_id = this.state.areaObj.value;
          addresses[index].address_type = "callcenter";
          addresses[index].place = place;
          addresses[index].street_name = streetName;
          addresses[index].street_number = streetNo;
          addresses[index].extra_details = Additional_address_info;
          list = addresses;
        } else {
          list[0].full_address = full_address;
          list[0].city = this.state.cityObj.label;
          list[0].city_id = this.state.cityObj.value;
          list[0].location = this.state.zoneObj.label;
          list[0].zone_id = this.state.zoneObj.value;
          list[0].area = this.state.areaObj.label;
          list[0].area_id = this.state.areaObj.value;
          list[0].address_type = "callcenter";
          list[0].street_name = streetName;
          list[0].street_number = streetNo;
          list[0].extra_details = Additional_address_info;
        }
      } else {
        list.push(obj)
      }
      const dupAddressArr: any = this.state.addressesList.concat(list);
      this.setState({ addressesList: dupAddressArr })
      this.props.addAddress(dupAddressArr);
      this.setState({ sizejson: [] })
    }
    this.setState({ isUpdateBtnDisabled: true })
    this.props.setHouseNo(houseNo)
    this.props.setStreetName(streetName)
    this.props.setStreetNo(streetNo)
    this.props.handleAddInfo(Additional_address_info)
    this.props.setCityName(this.state.cityObj.label, this.state.cityObj.value)
    this.props.setTradeAreaName(this.state.areaObj.label, this.state.areaObj.value)
    this.props.setTradeZoneName(this.state.zoneObj.label, this.state.zoneObj.value)
    this.props.handleAddressInput(full_address)
    await this.setState({ placeClick: "" })


  };

  validatePhone(event: { target: { name: any; value: any; }; }) {
    // let reg = /^[0-9\b]+$/;
    // if (event.target.value === '' || reg.test(event.target.value)) {
    //   this.setState({ [event.target.name]: event.target.value, isValidPhone: true })
    // } else {
    //   event.target.value = event.target.value.slice(0, event.target.value.length-1);
    //   this.setState({ [event.target.name]: event.target.value, isValidPhone: false });
    // }
    if (event.target.value.length <= 11) {
      this.setState({ [event.target.name]: event.target.value, isValidPhone: true })
    }
  };
  TrimNumber = (phone_number: any) => {
    let str_phone_number = phone_number.toString();
    if (str_phone_number.charAt(0) === '+') {
      str_phone_number = str_phone_number.substring(3);
      str_phone_number = "0" + str_phone_number
    }
    if (str_phone_number.charAt(0) === '9') {
      str_phone_number = str_phone_number.substring(2);
      str_phone_number = "0" + str_phone_number;
    }
    if (str_phone_number.charAt(0) === ' ') {
      str_phone_number = str_phone_number.substring(3);
      str_phone_number = "0" + str_phone_number;
    }
    if (str_phone_number.length == 8) {
      str_phone_number = "0" + str_phone_number;
    }
    return str_phone_number;
  }
  submitPhone = (phone_number: any, order_id: any) => {
    let { time } = this.props;
    let refined_phone_number = phone_number.toString();
    refined_phone_number = this.TrimNumber(phone_number);
    if (phone_number != "") {
      let obj: any = {
        phone_number: refined_phone_number,
      };
      this.setState({ phone: phone_number, isValidPhone: true, newUserphone: refined_phone_number, sizejson: [], addClick: false, editClick: false });
      this.setState({ newUser: false });
      if (refined_phone_number.length < 11) {
        toast.error("Phone Length must be minimum 11")
      } else {
        this.props.customerDetail(obj);
      }
    } else if (order_id != "") {
      let obj: any = {
        order_id: order_id,
      };
      this.props.trackOrder(obj);
      this.setState({ sizejson: [], addClick: false, editClick: false })
    }
    if (time == 0) {
      this.props.StartClockTimer(time);
    }
  };

  submitPhoneTrace = (phone_number: any, order_id: any) => {
    let { time } = this.props;
    let refined_phone_number = phone_number.toString();
    let { traceOrderby } = this.state;
    refined_phone_number = this.TrimNumber(phone_number);
    if (traceOrderby === 'Phone') {
      let obj: any = {
        phone_number: refined_phone_number,
      };
      this.setState({ phone: phone_number, isValidPhone: true, sizejson: [], addClick: false, editClick: false });
      this.setState({ newUser: false });
      this.props.customerDetail(obj);
    } else if (traceOrderby === "OrderId") {
      let obj: any = {
        order_id: order_id,
      };
      this.props.trackOrder(obj);
      this.setState({ sizejson: [], addClick: false, editClick: false });
    }
    if (time == 0) {
      this.props.StartClockTimer(time);
    }
    this.props.stopCount();
  };

  isCustomerReady = () => {
    let {
      firstname,
      lastname,
      email,
      isValidEmail,
      delivery_address,
      houseNo
      // Area,
      // City,
    } = this.props;
    let { newUserphone } = this.state;
    if (email !== "") {
      return (
        firstname !== "" &&
        lastname !== "" &&
        isValidEmail &&
        newUserphone !== "" &&
        houseNo !== ""
        // Area !== "" &&
        // City !== ""
      );
    } else {
      return (
        firstname !== "" &&
        lastname !== "" &&
        newUserphone !== "" &&
        houseNo !== ""
        // Area !== "" &&
        // City !== ""
      );
    }
  };
  handleInputChange(event: { target: { name: any; value: any } }) {
    this.setState({
      [event.target.name]: event.target.value,
    });
  }

  handleSubmit = (event: any) => {
    let { firstname, lastname, email } = this.props;
    let { newUserphone } = this.state;
    let data: any = {
      first_name: firstname,
      last_name: lastname,
      login_name: lastname === "" ? firstname : firstname + " " + lastname,
      phone_number: newUserphone,
      geo_address:
        this.props.houseNo +
        " ",
      // this.props.Block +
      // " " +
      // this.props.Area +
      // " " +
      // this.props.City,
      is_active: 1,
      is_guest: 1,
    };
    if (email !== '') {
      data.email_address = email;
    }
    this.props.addCustomer(data);
    // this.props.handleAddressInput(this.props.houseNo +  " " + this.props.tradeZoneName +" " + this.props.tradeAreaName + " " + this.props.cityName,)
    // this.setState({ newUser: false });
  };
  handleSubmitComplaint = (event: any) => {
    let { callduration } = this.props;
    const {
      fullname,
      email,
      phone,
      feedbackMessage,
      feedbackType,
      storeId,
      dateOfOrder,
      receiptnumber,
    } = this.state;
    const data: any = {
      name: fullname,
      email_address: email,
      phone_number: phone,
      receipt_number: receiptnumber,
      message: feedbackMessage,
      type: feedbackType,
      store_id: storeId,
      order_date: dateOfOrder,
    };
    callduration = callduration / 60;
    let rounded_time = Math.round(callduration * 10) / 10;
    let callStatsObj = {
      call_duration: rounded_time,
      call_status: 2,
      customer_number: localStorage.getItem("phone"),
      total_sale: 0,
      order_id: receiptnumber,
    };
    this.props.submitComplaint(data, callStatsObj);
    this.props.stopTimer();
  };
  isFormReady = () => {
    let { isValidPhone, phone, orderId } = this.state;
    return !isValidPhone || phone !== "" || orderId !== "";
  };
  handleCityInput = async (e: any) => {
    if (!_.isEmpty(e)) {
      if (this.props.tradeAreaName !== '') {
        this.selectRef3.select.clearValue();
      }
      this.props.setCityName(e.label, e.value)
      await this.setState({
        cityObj: {
          label: e.label,
          value: e.value
        }
      })
      this.props.setTradeZoneName('', null)
      this.props.setTradeAreaName('', null)
      this.props.getTradeZones(e.value)
    }

  }
  handleArea = async (e: any) => {

    if (!_.isEmpty(e)) {
      await this.setState({
        areaObj: {
          label: e.label,
          value: e.value
        }
      })
      this.props.setTradeAreaName(e.label, e.value)
    }
  }

  handleZone = async (e: any) => {
    if (!_.isEmpty(e)) {
      // let zone = JSON.parse(e.zone)
      if (this.props.tradeAreaName !== '') {
        // this.selectRef.select.clearValue();
        this.selectRef3.select.clearValue();
      }
      // console.log('zone', zone);
      // this.props.findLocalStoreWithoutMap(zone.store_id)
      await this.setState({
        zoneObj: {
          label: e.label,
          value: e.value
        }
      })
      this.props.setTradeAreaName('', null)
      this.props.setTradeZoneName(e.label, e.value)
      this.props.getTradeAreas(e.value)
    }
  }

  blockInvalidChar = (e: any) => {
    var regex = new RegExp("^[a-zA-Z0-9]");
    let specialkey: any = e.keyCode;

    var key: any = String.fromCharCode(!e.charCode ? e.which : e.charCode);
    if (specialkey == 8 || specialkey == 9) {
      return true;
    } else {
      if (/\S/.test(e.target.value)) {
        // string is not empty and not just whitespace
        if (!regex.test(key)) {
          if (specialkey == 32) {
          } else {
            e.preventDefault()
          }
        }
      } else {
        ((!regex.test(key) || specialkey == 32)) && e.preventDefault()
      }
    }
  }

  render() {
    let { isValidEmail, email } = this.props;
    if (localStorage.token) {
      jwt.verify(localStorage.token, secretKey, (err: any, decoded: any) => {
        if (err) {
          this.props.logoutUser();
        }
      });
    } else {
      localStorage.setItem("urlPhone", this.customerNumber);
      return <Redirect to="/" />;
    }
    let { orders, customer, stores, storeData, addressData } = this.props;
    let { newUser, sizejson, addressesList } = this.state;
    const options: any = {
      sizePerPage: 10, // which size per page you want to locate as default
      page: 1,
      pageStartIndex: 1, // where to start counting the pages
      paginationSize: 3, // the pagination bar size.
      hideSizePerPage: true, //You can hide the dropdown for sizePerPage
      noDataText: "Orders Not Found",
    };

    let cities_arr: any = []
    let zones_arr: any = []
    let areas_arr: any = []
    this.props.cities_portal && this.props.cities_portal.map((city: any) => {
      cities_arr.push({ label: city.name, value: city.id })
    })

    this.props.tradeZones_portal && this.props.tradeZones_portal.map((zone: any) => {
      zones_arr.push({ label: zone.delivery_zone_name, value: zone.id })
    })

    this.props.tradeAreas_portal && this.props.tradeAreas_portal.map((area: any) => {
      areas_arr.push({ label: area.area_name, value: area.id, area: JSON.stringify(area) })
    })
    return (
      <div className="page">
        <Topbar />
        <div className="page-content d-flex align-items-stretch">
          <Sidebar />
          <div className="content-inner">
            {/* <!-- Page Header--> */}
            <header className="page-header py-0">
              <div className="container-fluid">
                <div className="d-flex justify-content-between py-3">
                  <h4 className="mt-2">Customers Management</h4>
                  {/* <div>
                  <button className="btn btn-danger mr-3" style={{ padding: '0 15px' }} onClick={()=> this.submitPhoneTrace(this.state.phone,this.state.orderId)} ><i className="fa fa-refresh"></i></button>
                  </div> */}
                </div>
              </div>
            </header>
            {/* <div className="breadcrumb-holder container-fluid"> */}
            {/* <ul className="breadcrumb"> */}
            <section className="tables mt-4 no-padding">
              <div className="container-fluid">
                <div className="row">
                  <div className="col">
                    <div className="card">
                      <div className="card-body">
                        <Tabs>
                          <div className="tab-btns-setting">
                            <div>
                              <TabLink
                                activeClassName="btn btn-danger"
                                className="btn"
                                to="tab1"
                                onClick={() => this.setState({
                                  phone: ""
                                })}
                              >
                                New Order
                              </TabLink>
                              <TabLink
                                activeClassName="btn btn-danger"
                                className="btn"
                                to="tab2"
                                onClick={() => this.setState({
                                  phone: ""
                                })}
                              >
                                Trace Order
                              </TabLink>
                            </div>
                            {this.props.customer && this.props.customer.is_active == 0 &&
                              <div style={{ display: 'flex' }}>
                                <label style={{ color: 'red' }}>Customer Blocked</label>
                              </div>}
                          </div>
                          {/* <TabLink activeClassName="btn btn-danger" className='btn' to="tab3">Register Feedback</TabLink> */}

                          <TabContent className="mt-4" for="tab1">
                            <div className="form-group row">
                              <label className="col-sm-2 form-control-label">
                                Enter Phone Number:
                              </label>
                              <div className="col-sm-10">
                                <input
                                  id="inputHorizontalSuccess"
                                  value={this.state.phone}
                                  type="number"
                                  max={11}
                                  name="phone"
                                  required
                                  placeholder="Phone Number (03XXXXXXXXX)"
                                  className="form-control form-control-success"
                                  onChange={this.validatePhone}
                                />
                                {this.state.isValidPhone == false &&
                                  this.state.phone !== "" && (
                                    <small className="form-text text-danger">
                                      Invalid Phone Number
                                    </small>
                                  )}
                              </div>
                            </div>
                            <div className="form-group row">
                              <label className="col-sm-2 form-control-label">
                                Enter Order Id:
                              </label>
                              <div className="col-sm-10">
                                <input
                                  id="inputHorizontalSuccess"
                                  type="tel"
                                  maxLength={11}
                                  name="orderId"
                                  required
                                  placeholder="Order Id"
                                  className="form-control form-control-success"
                                  onChange={this.handleInputChange}
                                />
                              </div>
                            </div>

                            <div className="d-flex">
                              <div className="form-group">
                                <input
                                  disabled={Object.keys(this.props.customer).length > 0 ? true : false}
                                  type="submit"
                                  value="Add Customer"
                                  onClick={() => {
                                    this.setState({ newUser: true });
                                  }}
                                  className="btn btn-danger"
                                />
                              </div>
                              <div className="block_unblock_submit">
                                {this.props.customer && this.props.customer.is_delete == 1 &&
                                  <div className="form-group">
                                    <button className="btn btn-danger px-4 btn-margin" type="button" onClick={this.handleActivation} >
                                      Activate Account
                                    </button>
                                  </div>
                                }
                                {this.props.customer && (this.props.customer.is_active == 1 && (this.props.customer.is_delete == 0 || !this.props.customer.is_delete)) ?
                                  <div className="form-group">
                                    <button
                                      disabled={this.state.phone.length >= 11 ? false : true}
                                      className="btn btn-danger px-4 btn-margin" type="button" onClick={this.handleBlockCustomer} >
                                      Block
                                    </button>
                                  </div> :
                                  this.props.customer && (this.props.customer.is_active == 0 && (this.props.customer.is_delete == 0 || !this.props.customer.is_delete)) &&
                                  <div className="form-group">
                                    <button
                                      disabled={this.state.phone.length >= 11 ? false : true}
                                      className="btn btn-danger px-4 btn-margin" type="button" onClick={this.handleUnBlockCustomer} >
                                      Unblock
                                    </button>
                                  </div>
                                }
                                <div className="form-group">
                                  <input
                                    type="submit"
                                    disabled={(this.state.phone.length >= 11 || this.state.orderId) ? false : true}
                                    value="Submit"
                                    onClick={() => {
                                      this.submitPhone(
                                        this.state.phone,
                                        this.state.orderId
                                      );
                                    }}
                                    className="btn btn-danger"
                                  />
                                </div>
                              </div>
                            </div>
                          </TabContent>

                          <TabContent className="mt-4" for="tab2">
                            <div className="form-group row">
                              <div className="col-4">
                                <input
                                  id="radioPickup"
                                  type="radio"
                                  checked={this.state.traceOrderby === "Phone"}
                                  value="Phone"
                                  onChange={() => {
                                    this.setState({ traceOrderby: "Phone" });
                                  }}
                                  name="Phone"
                                  className="radio-template"
                                />
                                <label className="form-control-label">
                                  Track Order By Phone Number
                                </label>
                              </div>
                              <div className="col-4">
                                <input
                                  id="radioPickup"
                                  type="radio"
                                  checked={this.state.traceOrderby === "OrderId"}
                                  value="OrderId"
                                  onChange={() => {
                                    this.setState({ traceOrderby: "OrderId" });
                                  }}
                                  name="OrderId"
                                  className="radio-template"
                                />
                                <label className="form-control-label">
                                  Track Order By Order ID
                                </label>
                              </div>
                            </div>

                            {this.state.traceOrderby === 'Phone' &&
                              <div className="form-group row">
                                <label className="col-sm-2 form-control-label">
                                  Track Order By Phone Number:
                                </label>
                                <div className="col-sm-10">
                                  <input
                                    id="inputHorizontalSuccess"
                                    type="number"
                                    max={11}
                                    name="phone"
                                    value={this.state.phone}
                                    required
                                    placeholder="Phone Number"
                                    className="form-control form-control-success"
                                    onChange={this.validatePhone}
                                  />
                                  {this.state.isValidPhone == false &&
                                    this.state.phone !== "" && (
                                      <small className="form-text text-danger">
                                        Invalid Phone Number
                                      </small>
                                    )}
                                </div>
                              </div>
                            }
                            {this.state.traceOrderby === "OrderId" &&
                              <div className="form-group row">
                                <label className="col-sm-2 form-control-label">
                                  Track Order By Order Id:
                                </label>
                                <div className="col-sm-10">
                                  <input
                                    id="inputHorizontalSuccess"
                                    type="tel"
                                    maxLength={11}
                                    name="orderId"
                                    required
                                    placeholder="Order Id"
                                    className="form-control form-control-success"
                                    onChange={this.handleInputChange}
                                  />
                                </div>
                              </div>
                            }
                            <div className="d-flex justify-content-between">
                              <div className="form-group">
                                <input
                                  type="submit"
                                  value="Add Customer"
                                  onClick={() => {
                                    this.setState({ newUser: true });
                                  }}
                                  className="btn btn-danger"
                                />
                              </div>
                              <div className="form-group">
                                <input
                                  type="submit"
                                  value="Submit"
                                  disabled={!this.isFormReady()}
                                  onClick={() => {
                                    this.submitPhoneTrace(
                                      this.state.phone,
                                      this.state.orderId
                                    );
                                  }}
                                  className="btn btn-danger"
                                />
                              </div>
                            </div>
                          </TabContent>
                          <TabContent className="mt-4" for="tab3">
                            <div className="container">
                              <div className="row">
                                <div className="col-md-12">
                                  <div className="panel panel-default">
                                    <div className="panel-body">
                                      <div className="col-md-12">
                                        <div className="form-row">
                                          <div className="form-group col-md-12">
                                            {" "}
                                            <br />
                                          </div>
                                          <div className="form-group col-md-6">
                                            <label id="txt">
                                              {" "}
                                              Full Name{" "}
                                              <sup
                                                style={{
                                                  color: "red",
                                                  fontSize: "12px",
                                                }}
                                              >
                                                *
                                              </sup>
                                            </label>
                                            <input
                                              onChange={this.handleInputChange}
                                              name="fullname"
                                              placeholder="Full Name"
                                              style={{ fontSize: "15px" }}
                                              type="text"
                                              className="form-control"
                                              id="round"
                                              required
                                            />
                                          </div>
                                          <div className="form-group col-md-6">
                                            <label id="txt">
                                              Phone{" "}
                                              <sup
                                                style={{
                                                  color: "red",
                                                  fontSize: "12px",
                                                }}
                                              >
                                                *
                                              </sup>
                                            </label>
                                            <input
                                              onChange={this.handleInputChange}
                                              name="phone"
                                              placeholder="Phone"
                                              style={{ fontSize: "15px" }}
                                              type="text"
                                              className="form-control"
                                              id="round"
                                              required
                                            />
                                          </div>

                                          <div className="form-group col-md-6">
                                            <label id="txt">
                                              Email{" "}
                                              <sup
                                                style={{
                                                  color: "red",
                                                  fontSize: "12px",
                                                }}
                                              >
                                                *
                                              </sup>
                                            </label>
                                            <input
                                              onChange={this.handleInputChange}
                                              name="email"
                                              placeholder="Email"
                                              style={{ fontSize: "15px" }}
                                              type="email"
                                              className="form-control"
                                              id="round"
                                              required
                                            />
                                          </div>
                                          <div className="form-group col-md-6">
                                            <label id="txt">
                                              Select Ordering Store{" "}
                                              <sup
                                                style={{
                                                  color: "red",
                                                  fontSize: "12px",
                                                }}
                                              >
                                                *
                                              </sup>
                                            </label>

                                            <select
                                              onChange={this.handleInputChange}
                                              className="custom-select mb-3 w-100"
                                              id="round1"
                                              name="storeId"
                                            >
                                              <option value="">
                                                Select Store
                                              </option>

                                              {stores &&
                                                stores.map(
                                                  (store: any, index: any) => (
                                                    <option
                                                      key={index}
                                                      value={store.store_id}
                                                    >
                                                      {store.store_name}
                                                    </option>
                                                  )
                                                )}
                                            </select>
                                          </div>

                                          <div className="form-group col-md-6">
                                            <label id="txt">
                                              Order Date{" "}
                                              <sup
                                                style={{
                                                  color: "red",
                                                  fontSize: "12px",
                                                }}
                                              >
                                                *
                                              </sup>{" "}
                                            </label>
                                            <input
                                              onChange={this.handleInputChange}
                                              type="date"
                                              name="dateOfOrder"
                                              required
                                              data-msg="Please Enter Order Date"
                                              className="form-control"
                                              id="round"
                                            />
                                          </div>
                                          <div className="form-group col-md-6">
                                            <label id="txt">
                                              {" "}
                                              Order ID{" "}
                                              <sup
                                                style={{
                                                  color: "red",
                                                  fontSize: "12px",
                                                }}
                                              >
                                                *
                                              </sup>
                                            </label>
                                            <input
                                              onChange={this.handleInputChange}
                                              name="receiptnumber"
                                              style={{ fontSize: "15px" }}
                                              type="text"
                                              placeholder="Enter receipt number"
                                              className="form-control"
                                              id="round"
                                            />
                                          </div>
                                          <div className="form-group col-md-12">
                                            <label id="txt">
                                              {" "}
                                              Feedback Type{" "}
                                              <sup
                                                style={{
                                                  color: "red",
                                                  fontSize: "12px",
                                                }}
                                              >
                                                *
                                              </sup>
                                            </label>
                                            <select
                                              onChange={this.handleInputChange}
                                              className="custom-select mb-3 w-100"
                                              id="round1"
                                              name="feedbackType"
                                            >
                                              <option value="">
                                                Select Feedback Type
                                              </option>
                                              <option value="Comments">
                                                Comment
                                              </option>
                                              <option value="Suggestions">
                                                Suggestion
                                              </option>
                                              <option value="Complaint">
                                                Complaint
                                              </option>
                                              <option value="Questions">
                                                Question
                                              </option>
                                            </select>
                                          </div>
                                          <div className="form-group col-md-12">
                                            <label id="txt">
                                              Feedback{" "}
                                              <sup
                                                style={{
                                                  color: "red",
                                                  fontSize: "12px",
                                                }}
                                              >
                                                *
                                              </sup>
                                            </label>
                                            <textarea
                                              onChange={this.handleInputChange}
                                              className="form-control"
                                              name="feedbackMessage"
                                              id="round2"
                                              rows={4}
                                              cols={120}
                                            ></textarea>
                                          </div>
                                          <div className="form-group col-md-4">
                                            <button
                                              onClick={
                                                this.handleSubmitComplaint
                                              }
                                              id="h"
                                              style={{
                                                borderRadius: "10px",
                                                backgroundColor: "#c00a27",
                                                color: "white",
                                                borderColor: "#c00a27",
                                              }}
                                              type="submit"
                                              className="btn btn-primary"
                                            >
                                              Submit
                                            </button>
                                          </div>
                                        </div>
                                      </div>
                                      <br />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </TabContent>
                        </Tabs>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            {/* {!_.isEmpty(storeData) &&
                            <div className="card-body">
                                <div className='container-fluid'>
                                    <p>Order Details:</p>
                                    <form>
                                        <div className="form-group row">
                                            <div className="col-lg-3">
                                                <label className="form-control-label">Order ID</label>
                                                <input type="email" disabled value={storeData.order_id} placeholder="Customer Name" className="form-control" />
                                            </div>
                                            <div className="col-lg-3">
                                                <label className="form-control-label">Order Total</label>
                                                <input type="email" disabled value={storeData.order_grossprice} placeholder="Phone Number" className="form-control" />
                                            </div>
                                            <div className="col-lg-3">
                                                <label className="form-control-label">Order Method</label>
                                                <input type="email" disabled value={storeData.delivery_status} placeholder="Email Address" className="form-control" />
                                            </div>
                                            <div className="col-lg-3">
                                                <label className="form-control-label">Order Status</label>
                                                <input type="email" disabled value={storeData.order_status_description} placeholder="Email Address" className="form-control" />
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <div className="col">
                                                <label className="form-control-label">Delivery Address</label>
                                                <input type="email" name="delivery_address" disabled value={storeData.delivery_address} placeholder="Customer Name" className="form-control" />
                                            </div>

                                        </div>
                                    </form>
                                </div>
                            </div>
                        } */}
            {!_.isEmpty(customer) && (
              // <div className="card-body">
              //     <div className='container-fluid'>
              //         <p>Customer Details:</p>
              //         <form>
              //             <div className="form-group row">
              //                 <div className="col-lg-4">
              //                     <label className="form-control-label">Name</label>
              //                     <input type="email" disabled value={customer.login_name} placeholder="Customer Name" className="form-control" />
              //                 </div>
              //                 <div className="col-lg-4">
              //                     <label className="form-control-label">Phone</label>
              //                     <input type="email" disabled value={customer.phone_number} placeholder="Phone Number" className="form-control" />
              //                 </div>
              //                 <div className="col-lg-4">
              //                     <label className="form-control-label">Email</label>
              //                     <input type="email" disabled value={customer.email_address} placeholder="Email Address" className="form-control" />
              //                 </div>
              //             </div>
              //             {customer.geo_address && customer.geo_address.full_address &&
              //                 <div className="form-group row">
              //                     <div className="col">
              //                         <label className="form-control-label">Delivery Address</label>
              //                         <input type="email" name="delivery_address" disabled value={customer.geo_address.full_address} onChange={this.props.handleAddressInput(customer.geo_address.full_address)} placeholder="Customer Name" className="form-control" />
              //                     </div>

              //                 </div>
              //             }
              //         </form>
              //     </div>
              // </div>
              <>
                <section className="pt-2 pb-2">
                  <div className="container-fluid">
                    <div id="accordion">
                      <div className="card-header no-padding" id="headingOne">
                        <h5 className="mb-0">
                          <button
                            className="btn btn-link"
                            data-toggle="collapse"
                            data-target="#collapseOne"
                            aria-expanded="true"
                            aria-controls="collapseOne"
                          >
                            Customer Information
                          </button>
                        </h5>
                      </div>

                      <div
                        id="collapseOne"
                        className="collapse show"
                        aria-labelledby="headingOne"
                        data-parent="#accordion"
                      >
                        <div className="project bg-white has-shadow ">
                          <div className="card-body">
                            <div className="container-fluid">
                              <form>
                                <div className="form-group row">
                                  <div className="col-lg-4">
                                    <label className="form-control-label">
                                      Name
                                    </label>
                                    <input
                                      type="text"
                                      disabled
                                      value={customer.login_name}
                                      placeholder="Customer Name"
                                      className="form-control"
                                    />
                                  </div>
                                  <div className="col-lg-4">
                                    <label className="form-control-label">
                                      Phone
                                    </label>
                                    <input
                                      type="text"
                                      disabled
                                      value={customer.phone_number}
                                      placeholder="Phone Number"
                                      className="form-control"
                                    />
                                  </div>
                                  <div className="col-lg-4">
                                    <label className="form-control-label">
                                      Email
                                    </label>
                                    <input
                                      type="email"
                                      disabled
                                      value={customer.email_address}
                                      placeholder="Email Address"
                                      className="form-control"
                                    />
                                  </div>
                                </div>
                                <div style={{ maxHeight: '350px', overflowY: 'scroll' }}>
                                  {addressesList && addressesList.length > 0 ?
                                    addressesList.map((address: any, i: any) => {
                                      return (
                                        <>
                                          <div key={i} className="form-group row no-padding ml-3">
                                            <div className="col">
                                              <label className="form-control-label">
                                                Delivery Address
                                              </label>
                                              <div className="d-flex">
                                                <input style={{ height: '25px', transform: 'scale(1.5)' }} className="form-check-input" onChange={async () => {
                                                  address.full_address && this.props.setHouseNo(address.full_address);
                                                  address.street_name && this.props.setStreetName(address.street_name);
                                                  address.street_number && this.props.setStreetNo(address.street_number);
                                                  address.extra_details && this.props.handleAddInfo(address.extra_details)
                                                  address.city_id && this.props.setCityName(address.city, address.city_id)
                                                  address.area_id && this.props.setTradeAreaName(address.area, address.area_id)
                                                  address.zone_id && this.props.setTradeZoneName(address.location, address.zone_id)
                                                  address.full_address && this.props.handleAddressInput(address.full_address)
                                                  address.city_id && this.props.getTradeZones(address.city_id)
                                                  localStorage.setItem("addressObj", JSON.stringify(address))
                                                  this.setState({ placeClick: address.place })
                                                  address.zone_id && this.props.getTradeAreas(address.zone_id)
                                                }} type="radio" name="delivery_address" id={'delivery_address' + i} />
                                                <input
                                                  type="text"
                                                  name="full_address"
                                                  value={
                                                    address.city_id !== null ? address.city + " " + address.location + " " + address.area + " " + address.full_address : address.full_address
                                                  }
                                                  disabled={address.enable ? !address.enable : true}
                                                  onChange={e => this.handleVariantInputChange(e, i)}
                                                  placeholder="Delivery Address"
                                                  className="form-control"
                                                />

                                                <AnchorLink offset={() => 150} href='#things'>
                                                  <button className="btn btn-sm btn-primary ml-2" type="button" onClick={async (e: any) => {    
                                                    this.handleAddClick(e); this.setState({ editClick: true, addressIndex: i, addClick: false,
                                                      cityObj: {
                                                      label: address.city,
                                                      value: address.city_id
                                                    },zoneObj: {
                                                      label: address.location,
                                                      value: address.zone_id
                                                    },areaObj: {
                                                      label: address.area,
                                                      value: address.area_id
                                                    } });
                                                    address.full_address && this.props.setHouseNo(address.full_address);
                                                    address.street_name && this.props.setStreetName(address.street_name);
                                                    address.street_number && this.props.setStreetNo(address.street_number);
                                                    address.extra_details && this.props.handleAddInfo(address.extra_details)
                                                    address.city && this.props.setCityName(address.city, address.city_id)
                                                    address.area && this.props.setTradeAreaName(address.area, address.area_id)
                                                    address.location && this.props.setTradeZoneName(address.location, address.zone_id)
                                                    address.full_address && this.props.handleAddressInput(address.full_address)
                                                    address.area_id && this.props.getTradeZones(address.area_id)
                                                    localStorage.setItem("addressObj", JSON.stringify(address))
                                                    this.setState({ placeClick: address.place })
                                                    address.zone_id && this.props.getTradeAreas(address.zone_id)
                                                  }}><i className="fa fa-edit"></i></button>
                                                </AnchorLink>
                                                {addressesList.length - 1 === i && <button className="btn btn-sm btn-primary ml-2" onClick={async (e: any) => {
                                                  this.handleAddClick(e); this.setState({ editClick: false, addClick: true });
                                                  this.props.setHouseNo("")
                                                  this.props.setStreetName("")
                                                  this.props.setStreetNo("")
                                                  this.props.handleAddInfo("")
                                                  this.props.setCityName("", null)
                                                  this.props.setTradeAreaName("", null)
                                                  this.props.setTradeZoneName("", null)
                                                  this.props.handleAddressInput("")
                                                  localStorage.removeItem("addressObj")
                                                }}><i className="fa fa-plus"></i></button>}
                                              </div>

                                            </div>

                                          </div>
                                          <div className="form-group row">
                                            <div className="col-4">
                                              <select disabled={true} name="place" onChange={e => this.handleVariantInputChange(e, i)} className="form-control mt-2" required data-msg="Please select address type" >
                                                <option key={'home' + i} value="Home"  {...(address.place == 'Home') && { selected: true }}>Home</option>
                                                <option key={'office' + i} value="Office" {...(address.place == 'Office') && { selected: true }}>Office</option>
                                                <option key={'other' + i} value="Other"  {...(address.place == 'Other') && { selected: true }}> Other</option>
                                              </select>
                                            </div>
                                          </div>
                                        </>
                                      )
                                    })
                                    :
                                    <>
                                      {sizejson.length === 0 &&
                                        <div className="row">
                                          <div className="col">
                                            <button className="btn btn-sm btn-primary ml-2" onClick={async (e: any) => {
                                              this.handleAddClick(e); this.setState({ editClick: false, addClick: true });
                                              this.props.setHouseNo("")
                                              this.props.setStreetName("")
                                              this.props.setStreetNo("")
                                              this.props.handleAddInfo("")
                                              this.props.setCityName("", null)
                                              this.props.setTradeAreaName("", null)
                                              this.props.setTradeZoneName("", null)
                                              this.props.handleAddressInput("")
                                              localStorage.removeItem("addressObj")
                                            }}><i className="fa fa-plus"></i></button>
                                          </div>
                                        </div>
                                      }
                                    </>
                                  }
                                </div>
                                {sizejson && sizejson.length > 0 && sizejson.map((x: any, i: any) => {
                                  return (
                                    <>
                                      <div id='things' className="row" style={{ padding: "unset", margin: "unset" }}>

                                        <div className="project bg-white p-0  col-12 ">
                                          {this.state.editClick ? "Edit Address" : "Add Address"}
                                          <div className="row mb-0">
                                            <div className="col-4">
                                              <div className="form-group">
                                                <label className="form-control-label">
                                                  City/Town{" "}
                                                </label>
                                                <span className="text-danger">*</span>
                                                <Select
                                                  name="cities"
                                                  ref={ref => { this.selectRef = ref; }}
                                                  value={{ label: this.props.cityName ? this.props.cityName : 'Select...', value: 'Select...' }}
                                                  options={cities_arr}
                                                  className="text-capitalize basic-multi-select mt-2"
                                                  classNamePrefix="select"
                                                  isLoading={cities_arr.length == 0 ? true : false}
                                                  onChange={
                                                    this.handleCityInput
                                                  }
                                                />
                                              </div>
                                            </div>
                                            <div className="col-4">
                                              <div className="form-group">
                                                <label className="form-control-label">
                                                  Area/Place{" "}
                                                </label>
                                                <span className="text-danger">*</span>
                                                <Select
                                                  ref={ref => { this.selectRef2 = ref; }}
                                                  name="zones"
                                                  value={{ label: this.props.tradeZoneName ? this.props.tradeZoneName : 'Select...', value: '' }}
                                                  options={this.props.cityName ? zones_arr : []}
                                                  className="text-capitalize basic-multi-select mt-2"
                                                  classNamePrefix="select"
                                                  isLoading={zones_arr.length == 0 ? true : false}
                                                  onChange={
                                                    this.handleZone
                                                  }
                                                />
                                              </div>
                                            </div>
                                            <div className="col-4">
                                              <div className="form-group">
                                                <label className="form-control-label">
                                                  Block/Street/Sector{" "}
                                                </label>
                                                <span className="text-danger">*</span>
                                                <Select
                                                  ref={ref => { this.selectRef3 = ref; }}
                                                  value={{ label: this.props.tradeAreaName ? this.props.tradeAreaName : 'Select...', value: '' }}
                                                  name="areas"
                                                  options={this.props.tradeZoneName != '' ? areas_arr : []}
                                                  className="text-capitalize basic-multi-select mt-2"
                                                  classNamePrefix="select"
                                                  isLoading={areas_arr.length == 0 ? true : false}
                                                  onChange={
                                                    this.handleArea
                                                  }
                                                />
                                              </div>
                                            </div>
                                            <div className="col-3">
                                              <div className="form-group">
                                                <label className="form-control-label">
                                                  House/Flat/Plot{" "}
                                                </label>
                                                <span className="text-danger">*</span>
                                                <input
                                                  id="houseNO"
                                                  type="text"
                                                  name="houseNo"
                                                  placeholder={this.props.houseNo ? this.props.houseNo : ""}
                                                  required
                                                  data-msg="Please enter House Number"
                                                  className="input-material"
                                                  onChange={(e) => this.props.setHouseNo(e.target.value)}
                                                  onKeyDown={this.blockInvalidChar}
                                                  onPaste={(e) => { e.preventDefault() }}
                                                />
                                              </div>
                                            </div>
                                            <div className="col-3">
                                              <div className="form-group">
                                                <label className="form-control-label">
                                                  Street Name{" "}
                                                </label>
                                                <span className="text-danger">*</span>
                                                <input
                                                  id="streetName"
                                                  type="text"
                                                  name="streetName"
                                                  placeholder={this.props.streetName ? this.props.streetName : ""}
                                                  required
                                                  data-msg="Please enter Street Name"
                                                  className="input-material"
                                                  onChange={(e) => this.props.setStreetName(e.target.value)}
                                                  onKeyDown={this.blockInvalidChar}
                                                  onPaste={(e) => { e.preventDefault() }}
                                                />
                                              </div>
                                            </div>
                                            <div className="col-3">
                                              <div className="form-group">
                                                <label className="form-control-label">
                                                  Street Number{" "}
                                                </label>
                                                <span className="text-danger">*</span>
                                                <input
                                                  id="streetNo"
                                                  type="text"
                                                  name="streetNo"
                                                  placeholder={this.props.streetNo ? this.props.streetNo : ""}
                                                  required
                                                  data-msg="Please enter Street No"
                                                  className="input-material"
                                                  onChange={(e) => this.props.setStreetNo(e.target.value)}
                                                  onKeyDown={this.blockInvalidChar}
                                                  onPaste={(e) => { e.preventDefault() }}
                                                />
                                              </div>
                                            </div>
                                            <div className="col-3">
                                              <div className="form-group">
                                                <label className="form-control-label">
                                                  Additional Information
                                                </label>
                                                <input
                                                  id="addInfo"
                                                  type="text"
                                                  name="Additional_address_info"
                                                  placeholder={this.props.Additional_address_info ? this.props.Additional_address_info : ""}
                                                  required
                                                  data-msg="Please enter additional Information"
                                                  className="input-material"
                                                  onChange={this.props.handleMenuInput}
                                                  onKeyDown={this.blockInvalidChar}
                                                  onPaste={(e) => { e.preventDefault() }}
                                                />
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="form-group row">
                                        <div className="col">
                                          <select name="place" onChange={e => this.handleNewVariantInputChange(e, i)} className="form-control mt-2" required data-msg="Please select address type" >
                                            <option key={'home' + i} value="Home" {...(this.state.placeClick == 'Home') && { selected: true }} >Home</option>
                                            <option key={'office' + i} value="Office" {...(this.state.placeClick == 'Office') && { selected: true }}>Office</option>
                                            <option key={'other' + i} value="Other" {...(this.state.placeClick == 'Other') && { selected: true }}> Other</option>

                                          </select>

                                        </div>

                                      </div>
                                    </>
                                  )
                                })
                                }
                                {((this.state.editClick || this.state.addClick) && (sizejson.length > 0 || addressesList.length > 0)) &&
                                  <div className="row">
                                    <div className="col">
                                      <button disabled={!this.isAddressReady()} className="btn btn-danger px-4" type="button" onClick={(e) => this.handleAddressSubmit(e, this.state.addressIndex)}>
                                        Update
                                      </button>
                                    </div>
                                  </div>
                                }
                              </form>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </>
            )}
            <div className="container-fluid">
              <div className="d-flex ml-4">
                <div className="form-group">
                  <Link to="/menu">
                    <button disabled={(this.props.customer.is_active == 1 && (this.props.customer.is_delete == 0 || !this.props.customer.is_delete)) ? false : true} className="btn btn-danger px-4">Start Order</button>
                  </Link>
                </div>
              </div>
            </div>
            {this.state.newUser === true && (
              <div>
                <section className="forms pt-3">
                  <div className="container-fluid">
                    <div className="row">
                      <div className="col-lg-12">
                        <div className="card">
                          <div className="card-body">
                            <div className="row">
                              <div className="col">
                                <div className="form-group">
                                  <label className="form-control-label">
                                    First name{" "}
                                    <span className="text-danger">*</span>
                                  </label>
                                  <input
                                    id="firstname"
                                    type="text"
                                    name="firstname"
                                    required
                                    data-msg="Please enter First Name"
                                    className="input-material"
                                    onChange={this.props.handleCustomerInput}
                                  />
                                </div>
                              </div>
                              <div className="col">
                                <div className="form-group">
                                  <label className="form-control-label">
                                    Last Name{" "}
                                    <span className="text-danger">*</span>
                                  </label>
                                  <input
                                    id="lastname"
                                    type="text"
                                    name="lastname"
                                    required
                                    data-msg="Please enter Last Name"
                                    className="input-material"
                                    onChange={this.props.handleCustomerInput}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col">
                                <div className="form-group">
                                  <label className="form-control-label">
                                    Email
                                  </label>
                                  <input
                                    id="email"
                                    type="text"
                                    name="email"
                                    required
                                    data-msg="Please enter Email"
                                    className="input-material"
                                    onChange={this.props.validateEmail}
                                  />
                                  {isValidEmail == false && email !== "" && (
                                    <small className="form-text text-danger">
                                      email is not correct
                                    </small>
                                  )}
                                </div>
                              </div>
                              <div className="col">
                                <div className="form-group">
                                  <label className="form-control-label">
                                    Phone <span className="text-danger">*</span>
                                  </label>
                                  <input
                                    id="phone"
                                    type="tel"
                                    maxLength={13}
                                    name="newUserphone"
                                    defaultValue={this.state.newUserphone}
                                    required
                                    data-msg="Please enter Phone (03xxxxxxxxx)"
                                    className="input-material"
                                    onChange={this.validatePhone}
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="row">
                              <div className="col-6">
                                <div className="form-group">
                                  <label className="form-control-label">
                                    House/Flat/Plot
                                  </label>
                                  <input
                                    id="houseNO"
                                    type="text"
                                    name="houseNo"
                                    required
                                    data-msg="Please enter House Number"
                                    className="input-material"
                                    onChange={(e) => this.props.setHouseNo(e.target.value)}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="form-group"></div>
                            </div>

                            <div className="form-group d-flex justify-content-end mt-4">
                              <button
                                className="btn btn-danger"
                                disabled={!this.isCustomerReady()}
                                onClick={this.handleSubmit}
                              >
                                Save
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            )}
            <section className="tables pt-2">
              <div className="container-fluid">
                <div className="row">
                  <div className="col">
                    <div className="card">
                      <div className="card-body">
                        <BootstrapTable
                          version="4"
                          data={orders}
                          search={true}
                          pagination={orders.length > 10 && true}
                          options={options}
                          hover
                        >
                          <TableHeaderColumn
                            dataField="order_id"
                            csvHeader="Order Id"
                            dataSort={true}
                            isKey
                          >
                            Order Id
                          </TableHeaderColumn>
                          <TableHeaderColumn
                            thStyle={{ fontSize: 11, whiteSpace: 'unset' }}
                            dataField="action"
                            dataFormat={invoiceFormatter}
                            formatExtraData={this.props}
                            width='100'
                            export={false}
                          >
                            Details
                          </TableHeaderColumn>
                          <TableHeaderColumn
                            dataField="store_name"
                            csvHeader="Store"
                            columnTitle
                          >
                            Store
                          </TableHeaderColumn>
                          <TableHeaderColumn
                            dataField="delivery_status"
                            csvHeader="Order Type"
                            columnTitle
                          >
                            Order Type
                          </TableHeaderColumn>
                          <TableHeaderColumn
                            dataField="order_channel"
                            csvHeader="Order Channel"
                            columnTitle
                          >
                            Order Channel
                          </TableHeaderColumn>
                          {/* <TableHeaderColumn dataField='action' width='150' dataFormat={selectDriverEditor} formatExtraData={this.props} csvHeader='Delivery Driver'>Delivery Driver</TableHeaderColumn> */}
                          {/* <TableHeaderColumn dataField='action' width='180' dataFormat={selectStatusEditor} formatExtraData={this.props}>Status</TableHeaderColumn> */}
                          <TableHeaderColumn
                            dataField="login_name"
                            csvHeader="Customer Name"
                            columnTitle
                          >
                            Name
                          </TableHeaderColumn>
                          <TableHeaderColumn
                            dataField="phone_number"
                            csvHeader="Phone"
                            columnTitle
                          >
                            Phone
                          </TableHeaderColumn>
                          <TableHeaderColumn
                            dataField="delivery_address"
                            csvHeader="Address"
                            width="150"
                            columnTitle
                          >
                            Address
                          </TableHeaderColumn>
                          <TableHeaderColumn
                            dataField="order_grossprice"
                            dataFormat={priceFormatter}
                            csvHeader="Price"
                          >
                            Order Price
                          </TableHeaderColumn>
                          {/* <TableHeaderColumn width='100' columnTitle>Delivery Zone</TableHeaderColumn> */}
                          <TableHeaderColumn
                            dataField="payment_method"
                            csvHeader="Payment Method"
                            columnTitle
                          >
                            Payment Method
                          </TableHeaderColumn>
                          <TableHeaderColumn
                            dataField="order_status_description"
                            csvHeader="Order Status"
                            columnTitle
                          >
                            Order Status
                          </TableHeaderColumn>
                          <TableHeaderColumn
                            dataField="date_created"
                            csvHeader="Order Recieved"
                            dataFormat={dateFormatter}
                            columnTitle
                          >
                            Order Recieved
                          </TableHeaderColumn>
                          {/* <TableHeaderColumn
                            dataField="action"
                            dataFormat={actionFormatter}
                            formatExtraData={this.props}
                            export={false}
                          >
                            Repeat Order
                          </TableHeaderColumn> */}
                          <TableHeaderColumn
                            dataField="action"
                            width="70"
                            dataFormat={complaintFormatter}
                            formatExtraData={this.props}
                            export={false}
                          >
                            Action
                          </TableHeaderColumn>
                        </BootstrapTable>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <Footer />
          </div>
          <ComplaintComp data={this.props} />
        </div>
      </div>
    );
  }
}
function dateFormatter(cell: any) {
  var DateCreated: any = moment(cell).local().format('YYYY-MM-DD HH:mm').split(' ');
  DateCreated = DateCreated[1] + " , " + DateCreated[0];
  return (
    <div>
      <span {...((cell !== "" && cell !== null) && { title: DateCreated })}> {(cell !== "" && cell !== null) && DateCreated} </span>
    </div>
  )
}
const mapStateToProps = (state: any) => {
  return {
    orders: state.customer.ordersList,
    orderdata: state.customer.orderDetail,
    cart: state.cart.cartData,
    customer: state.customer.customerData,
    taxdata: state.cart.taxData,
    stores: state.customer.storesList,
    firstname: state.customer.firstname,
    lastname: state.customer.lastname,
    email: state.customer.email,
    phone: state.customer.phone,
    delivery_address: state.menu.delivery_address,
    isValidEmail: state.customer.isValidEmail,
    time: state.customer.clockCount,
    houseNo: state.menu.houseNo,
    Block: state.menu.Block,
    Area: state.menu.Area,
    City: state.menu.City,
    storeData: state.customer.storeData,
    callduration: state.customer.clockCount,
    addressData: state.customer.addressData,
    reasonsList: state.customerComplaint.complaintReasonList,
    cities_portal: state.trade_zones_areas.cities,
    tradeZones_portal: state.trade_zones_areas.tradeZones,
    tradeAreas_portal: state.trade_zones_areas.tradeAreas,
    cityName: state.trade_zones_areas.cityName,
    tradeZoneName: state.trade_zones_areas.tradeZoneName,
    tradeAreaName: state.trade_zones_areas.tradeAreaName,
    Items: state.order.orderItems,
    orderDetail: state.order.order,
    streetName: state.menu.streetName,
    streetNo: state.menu.streetNo,
    Additional_address_info: state.menu.Additional_address_info,
    selectedRow: state.order.selectedRow,
  };
};
const mapDispatchToProps = (dispatch: any) => {
  return {
    logoutUser: function () {
      dispatch(logoutUser());
    },
    orderDetailForRepeat: function (orderId: any) {
      dispatch(orderDetail(orderId));
    },
    customerDetail: (data: any) => {
      dispatch(customerDetail(data));
    },
    BlockCustomer: (data: any) => {
      dispatch(BlockCustomer(data))
    },
    handleActivation: () => {
      dispatch(ActivateAccount())
    },
    updateStateCustomer: (data: any, customer: any) => {
      dispatch(updateStateCustomer(data, customer))
    },
    handleCustomerInput: (event: any) => {
      dispatch(handleCustomerInput(event));
    },
    addCustomer: (data: any) => {
      dispatch(addCustomer(data));
    },
    validateEmail: (event: any) => {
      dispatch(validateEmail(event));
    },
    handleAddressInput: (address: any) => {
      dispatch(handleAddressInput(address));
    },
    storesList: function () {
      dispatch(getStores());
    },
    getTaxValue: (state_id: any) => {
      dispatch(getTaxValue(state_id));
    },
    getCart: function () {
      dispatch(getCart());
    },
    saveCart: function (cart: any[]) {
      dispatch(saveCart(cart));
    },
    findLocalStores: function (lat: any, long: any) {
      dispatch(findLocalStores(lat, long));
    },
    StartClockTimer: function (time: any) {
      dispatch(StartClockTimer(time));
    },
    handleMenuInput: (event: any) => {
      dispatch(handleMenuInput(event));
    },
    submitComplaint: function (data: any, callStatsObj: any) {
      dispatch(submitComplaint(data, callStatsObj));
    },
    trackOrder: function (data: any) {
      dispatch(trackOrder(data));
    },
    stopTimer: function () {
      dispatch(stopTimer());
    },
    stopCount: function () {
      dispatch(stopCount());
    },
    addAddress: (data: any) => {
      dispatch(addAddressUser(data));
    },
    addressesListk: function () {
      dispatch(addressesListk());
    },
    complaintReasonsList: function () {
      dispatch(complaintReasonsList());
    },
    getCities: function () {
      dispatch(getCitiesWeb());
    },
    getTradeZones: function (id: any) {
      dispatch(getTradeZonesWeb(id))
    },
    getTradeAreas: function (id: any) {
      dispatch(getTradeAreas(id))
    },
    setTradeZoneName: function (name: String, value: any) {
      dispatch(setTradeZoneName(name, value))
    },
    setTradeAreaName: function (name: String, value: any) {
      dispatch(setTradeAreaName(name, value))
    },
    setCityName: function (name: String, value: any) {
      dispatch(setCityName(name, value))
    },
    setHouseNo: function (address: String) {
      dispatch(handleHouseNo(address))
    },
    setStreetName: function (address: String) {
      dispatch(handleStreetName(address))
    },
    setStreetNo: function (address: String) {
      dispatch(handleStreetNo(address))
    },
    handleAddInfo: (value: any) => {
      dispatch(handleAddInfo(value))
    },
    showorderItems: function (id: any, orders: any) {
      dispatch(showorderItems(id, orders))
    },
    updateSelectedRow: (row: any) => {
      dispatch(updateSelectedRow(row))
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(CustomerDetail);
