import React, { Component } from "react";
import { Redirect, Prompt } from "react-router-dom";
import Topbar from "../../components/topbar";
import Sidebar from "../../components/sidebar";
import Footer from "../../components/footer/main";
import jwt from "jsonwebtoken";
import _, { templateSettings } from "lodash";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { secretKey } from "../../secret";
import { connect } from "react-redux";
import {
  allGroupsList,
  clearCart,
  findLocalStores,
  getCart,
  getComboDetail,
  getItemDetail,
  getStores,
  getTaxValue,
  handleAddressInput,
  handleMenuInput,
  logoutUser,
  menuItemsListByGroupsID,
  ordersCounter,
  saveCart,
  saveOrder,
  saveStore,
  customerDetail
} from "../../redux";
import { MenuProps, MenuState } from "../../interfaces/menu";
import CheckChanges from "../../components/confirmOnLeave";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./menu.css";
import { stopTimer } from "../../redux/actions/headerAction";
import { applyCoupon, findPickupStores, handleGeoCodeAddress, saveBackupStores, SaveCartTotal, SaveDiscountValue, saveOrderPeriod, saveSelectStoreId, SaveTaxValue, storesListForMultiSelect, UpdateMenuItems, handleHouseNo, findLocalStoreWithoutMap, getTimezone, getTimezoneForLater, resetItemSize, handleStreetName, handleStreetNo, resetCoupon, handleAddInfo, removeCoupon, consumeCoupon, saveDeliveryFeeForCoupon, saveTaxDetails } from "../../redux/actions/menuAction";
import { BASE_URL, priceunit } from "../../client-config";
import { Modal } from "react-responsive-modal";
import 'react-responsive-modal/styles.css';
import moment from "moment";
import Select from 'react-select';
import Loader from "react-loader-spinner";
import { getCitiesWeb, getTradeZonesWeb, getTradeAreas, setTradeZoneName, setCityName, setTradeAreaName } from "../../redux/actions/trade_zones_areas_Action";

toast.configure();
const divStyle = {
  margin: "0px",
  padding: "15px"
};

class Menu extends Component<MenuProps, MenuState> {
  cartTotal: number;
  itemTotal: number;
  totalTax: any;
  discount: number;
  quantity: number;
  couponDiscount: number;
  appliedCoupon: any;
  codeExeCount: any;
  validTimeFlag: any;
  optionValue: any;
  selectRef: any;
  selectRef2: any;
  selectRef3: any;

  constructor(readonly props: any) {
    super(props);
    this.state = {
      emptyCart: false,
      selectedsize: {},
      size: [],
      itemPrice: "",
      netPrice: "",
      initialPrice: "",
      modifiers: {},
      halfNHalfModifiers: null,
      comboDetails: {},
      phone: "",
      paymentMethod: "Cash",
      isValidPhone: "",
      orderType: "Delivery",
      active_group_id: "",
      showCutomerPanel: true,
      fullAddress: "",
      lat: 0,
      lng: 0,
      prevMod: "",
      prevKey: '',
      firstHalfPrevMod: "",
      firstHalfPrevKey: "",
      secHalfPrevMod: "",
      secHalfPrevKey: "",
      discountFlag: false,
      storeOpenFlag: true,
      promiseTime: 0,
      areaDeliveryTime: 0,
      coupanCode: '',
      backupStorePromiseTime: 0,
      laterdatetime: new Date(),
      comboQuantityReached: true,
      open: false,
      showCartModel: false,
      changeStoreType: "",
      showChangeStoreModal: false,
      storeObj: "",
      validTime: false,
      netWork: false,
      isClicked: false,
      isPickupClicked: true,
      isPickUpSubmitClicked: false,
      make_combos: [],
      prevMakeComboPrice: 0,
      itemQuantity: 1,
      isMakeComboSelected: false,
      active_key: "1stHalf",
      combo_active_key: "0",
      choice_active_key: `choice01stHalf`,
      kitchen_inst: "",
      isAddButtonDisable: false,
      isCartButtonDisable: false,
    };
    this.handleSaveStore = this.handleSaveStore.bind(this);
    this.handlePaymentMethod = this.handlePaymentMethod.bind(this);
    this.menuItemsListByGroupsID = this.menuItemsListByGroupsID.bind(this);
    this.pickUpFormatAddress = this.pickUpFormatAddress.bind(this);
    this.handleChangeCoupan = this.handleChangeCoupan.bind(this);
    this.handleValidTime = this.handleValidTime.bind(this)
    this.cartTotal = 0;
    this.itemTotal = 0;
    this.totalTax = 0;
    this.discount = 0;
    this.quantity = 0;
    this.couponDiscount = 0;
    this.appliedCoupon = 0;
    this.codeExeCount = 0;
    this.validTimeFlag = false;
    this.selectRef = null;
    this.selectRef2 = null;
    this.selectRef3 = null;

  }
  componentDidMount() {
    let city: any = localStorage.getItem("addressObj")
    let area: any = localStorage.getItem("selectedArea") ? localStorage.getItem("selectedArea") : null
    let data = JSON.parse(city)
    let selectedArea = area ? JSON.parse(area) : null
    const { delivery_address } = this.props;
    if (selectedArea) {
      this.setState({ areaDeliveryTime: selectedArea[0]?.expected_delivery_time })
      this.props.findLocalStoreWithoutMap(selectedArea[0]?.store_id)
    }
    this.props.getCart();
    this.props.clearCart();
    this.props.storesList();
    // this.props.storesOptionList();
    this.props.ordersCounter();
    this.renderItemPrice = this.renderItemPrice.bind(this);
    this.props.getCities()
    this.props.getTradeZones(data && data.city_id)
    this.props.getTradeAreas(data && data.zone_id)
    document.title = "DominosCRM | Menu";
  }

  async UNSAFE_componentWillReceiveProps(nextProps: any) {
    if (this.props.callduration === nextProps.callduration) {
      if (!_.isEmpty(nextProps.itemData)) {
        if (nextProps.itemData.menu_item_id) {
          let sizearray = JSON.parse(nextProps.itemData.item_size);
          this.setState({
            size: sizearray,
            selectedsize: sizearray[0],
            itemPrice: ((sizearray[0].discount_price || sizearray[0].discount_price == 0) && (sizearray[0].order_channel == 'callcenter')) ? Math.round(sizearray[0].discount_price) : Math.round(sizearray[0].mrp),
            initialPrice: ((sizearray[0].discount_price || sizearray[0].discount_price == 0) && (sizearray[0].order_channel == 'callcenter')) ? Math.round(sizearray[0].discount_price) : Math.round(sizearray[0].mrp),
            modifiers: nextProps.itemData.modifiers
          });
          if (nextProps.itemData.is_half_n_half == 1) {
            nextProps.itemData.half_n_half_modifiers.map((half: any, halfIndex: any) => {
              Object.keys(nextProps.itemData.half_n_half_modifiers[halfIndex].data).map(key => {
                (!nextProps.itemData.half_n_half_modifiers[halfIndex].data[key].min) &&
                  nextProps.itemData.half_n_half_modifiers[halfIndex].data[key].map((item: any, index: any) => {
                    if (item.selected == true && item.is_condiment == 1) {
                      item.selected = false;
                    }
                  })
              })
            })
            this.setState({
              halfNHalfModifiers: nextProps.itemData.half_n_half_modifiers
            })
          } else {
            this.setState({
              halfNHalfModifiers: null
            })
          }
          let price: any = ((sizearray[0].discount_price || sizearray[0].discount_price == 0) && (sizearray[0].order_channel == 'callcenter')) ? Math.round(sizearray[0].discount_price) : Math.round(sizearray[0].mrp);
          Object.keys(nextProps.itemData.modifiers).map(key => {
            (!nextProps.itemData.modifiers[key].min) &&
              nextProps.itemData.modifiers[key].map((item: any, index: any) => {
                if (item.selected == true && item.is_condiment == 1) {
                  let modPrice = ((item.discount_price || item.discount_price == 0)) ? Math.round(item.discount_price) : item.modifier_sale_price;
                  this.setState({
                    itemPrice: parseInt(price) + modPrice,
                    prevMod: item.modifier_name,
                    prevKey: key
                  })
                }
              })
          })
        } else if (nextProps.itemData.combo_name) {
          this.setState({
            itemPrice: ((nextProps.itemData.discount_price || nextProps.itemData.discount_price == 0) && (nextProps.itemData.order_channel == 'callcenter')) ? Math.round(nextProps.itemData.discount_price) : Math.round(nextProps.itemData.combo_mrp_price),
            netPrice: ((nextProps.itemData.discount_price || nextProps.itemData.discount_price == 0) && (nextProps.itemData.order_channel == 'callcenter')) ? Math.round(nextProps.itemData.discount_price) : Math.round(nextProps.itemData.combo_mrp_price),
            modifiers: nextProps.itemData.choices[0].modifiers,
            comboDetails: nextProps.itemData.choices
          });
          if (nextProps.itemData.choices[0].is_half_n_half == 1) {
            this.setState({
              halfNHalfModifiers: nextProps.itemData.choices[0].half_n_half_modifiers
            })
          } else {
            this.setState({
              halfNHalfModifiers: null
            })
          }
          if (nextProps.itemData.choices) {
            let price: any = ((nextProps.itemData.discount_price || nextProps.itemData.discount_price == 0) && (nextProps.itemData.order_channel == 'callcenter')) ? Math.round(nextProps.itemData.discount_price) : Math.round(nextProps.itemData.combo_mrp_price)
            let totalprice: any = price
            nextProps.itemData.choices.map((choice: any, tabIndex: any) => {
              if (nextProps.itemData.choices[tabIndex].is_full_pizza == 1) {
                Object.keys(nextProps.itemData.choices[tabIndex].modifiers).map(key => {
                  (!nextProps.itemData.choices[tabIndex].modifiers[key].min) &&
                    nextProps.itemData.choices[tabIndex].modifiers[key].map((item: any, index: any) => {
                      if (item.selected == true && item.is_condiment == 1) {
                        let modPrice = ((item.discount_price || item.discount_price == 0)) ? Math.round(item.discount_price) : item.modifier_sale_price;
                        totalprice = parseInt(price) + modPrice;
                      }
                    })
                })
              } else if (nextProps.itemData.choices[tabIndex].is_half_n_half == 1) {
                Object.keys(nextProps.itemData.choices[tabIndex].modifiers).map(key => {
                  (!nextProps.itemData.choices[tabIndex].modifiers[key].min) &&
                    nextProps.itemData.choices[tabIndex].modifiers[key].map((item: any, index: any) => {
                      if (item.selected == true && item.is_condiment == 1) {
                        let modPrice = ((item.discount_price || item.discount_price == 0)) ? Math.round(item.discount_price) : item.modifier_sale_price;
                        totalprice += modPrice;
                      }
                    })
                })
                nextProps.itemData.choices[tabIndex].half_n_half_modifiers.map((half: any, halfIndex: any) => {
                  Object.keys(nextProps.itemData.choices[tabIndex].half_n_half_modifiers[halfIndex].data).map(key => {
                    (!nextProps.itemData.choices[tabIndex].half_n_half_modifiers[halfIndex].data[key].min) &&
                      nextProps.itemData.choices[tabIndex].half_n_half_modifiers[halfIndex].data[key].map((item: any, index: any) => {
                        if (item.selected == true && item.is_condiment == 1) {
                          item.selected = false;
                        }
                      })
                  })
                })
              }
            })
            this.setState({
              itemPrice: parseInt(totalprice)
            })
          }
        }
      }
      if(this.props.tradeAreas_portal !== nextProps.tradeAreas_portal &&  nextProps.tradeAreas_portal?.length > 0) {
        const areadFound = nextProps.tradeAreas_portal.find((area: any) => area.id === nextProps.tradeAreaValue)
        if (areadFound.backup_store_id) {
          let backupStore: any = [];
          backupStore.push(areadFound.backup_store_id)
          this.props.saveBackupStores(backupStore)
        } 
        else {
          this.props.saveBackupStores([])
        }
      }
    }
   
  }

  componentWillUnmount() {
    this.props.saveStore()
    this.props.setCityName('')
    this.props.setTradeAreaName('', null)
    this.props.setTradeZoneName('', null)
    this.props.setHouseNo('')
    this.props.handleAddressInput('')
    this.props.setStreetName("")
    this.props.setStreetNo("")
    this.props.handleAddInfo("")
    this.props.saveCart([])
    localStorage.removeItem("cart")
    // this.props.customerDetail({});
  }

  handleValidTime(event: { target: { name: any; value: any } }) {
    let obj = document as any
    this.props.saveStore({})
    var dropDown: any = obj.getElementById("clearPickupStore");
    var deliveryStore = obj.getElementById("clear")
    let o = ''
    dropDown && dropDown.selectedIndex ? dropDown.selectedIndex = 0 : o = '';
    deliveryStore && deliveryStore.selectedIndex ? deliveryStore.selectedIndex = 0 : o = '';
    this.selectRef && this.selectRef.select.clearValue();
    this.setState({
      [event.target.name]: event.target.value
    });

    let validMinDateTime = new Date();
    validMinDateTime.setHours(validMinDateTime.getHours() + 1);
    let futureDate = moment(event.target.value).startOf('day')
    let currentDate = moment(new Date()).startOf('day');

    if (futureDate.isSame(currentDate)) {
      let futureDatetime = new Date(event.target.value)
      if (futureDatetime >= validMinDateTime) {
        this.validTimeFlag = true;
        this.setState({ validTime: true })
      } else {
        toast.error("Future order can be placed minimum 1 hour ahead of current time");
        this.validTimeFlag = false;
        this.setState({ validTime: false })

      }
    } else {
      this.validTimeFlag = true;
      this.setState({ validTime: true })

    }

  }


  getItemDetail = async (obj: any) => {
    let { selectedStore } = this.props;
    this.setState({
      prevMakeComboPrice: 0, active_key: "1stHalf",
      combo_active_key: "0",
      choice_active_key: `choice01stHalf`,
      kitchen_inst: ""
    })
    if (obj.menu_item_id) {
      await this.props.getItemDetail(selectedStore.store_id, obj.menu_item_id);
    } else if (obj.combo_name) {
      await this.props.getComboDetail(selectedStore.store_id, obj.combo_id);
    }
    let {
      itemData,
      itemsbygroupid
    } = this.props
    if (!_.isEmpty(itemsbygroupid)) {
      let filtered = itemsbygroupid?.filter((item: { menu_item_id: any; }) => item.menu_item_id == obj.menu_item_id)
      if (filtered.length > 0 && filtered[0]?.combo_id == 0 && filtered[0]?.make_a_combo_options) {
        let arr: any = []
        filtered[0]?.make_a_combo_options?.map((options: any) => {
          Object.keys(options).map((key: any, index: any) => {
            arr.push({ option: options[key], quantity: 0 })
          })
        })
        await this.setState({ make_combos: arr })
      } else {
        await this.setState({ make_combos: [] })
      }
    }
  };

  handleChangeChk = (key: any, modName: any) => {
    //To check/uncheck the modifier checkbox
    let { modifiers, itemPrice } = this.state;
    let max_quantity = 0;
    let allowed_quantity = 0;
    modifiers[key].map((element: any) => {
      if (element.selected == true) {
        max_quantity += element.quantity;
      }
      else {
        allowed_quantity = element.modGroup_max_quantity;
      }
    })
    modifiers[key].map((item: any, index: any) => {
      if (item.modifier_name == modName) {
        let modPrice = ((item.discount_price || item.discount_price == 0)) ? Math.round(item.discount_price) : item.modifier_sale_price;
        if (item.selected == false) {
          if (max_quantity < allowed_quantity) {
            item.selected = !item.selected;
            this.setState({
              modifiers: modifiers,
              itemPrice: parseInt(itemPrice) + modPrice * item.quantity
            });
          }
        } else {
          item.selected = !item.selected;
          this.setState({
            modifiers: modifiers,
            itemPrice: itemPrice - modPrice * item.quantity
          });
        }
      }
    });
  };



  handleChangeCoupan(event: { target: { name: any; value: any } }) {
    this.setState({
      coupanCode: event.target.value
    });
  }

  isCoupanReady = () => {
    let { coupanCode } = this.state;
    return coupanCode !== '';
  };

  ApplyCoupan = () => {

    let { coupanCode, discountFlag, orderType } = this.state;
    let { cart, storeSelected, selectedStore, deliveryfee } = this.props;
    if (orderType == "Delivery") {
      localStorage.setItem("deliveryFee", deliveryfee)
    }
    this.appliedCoupon = true;
    this.codeExeCount = 0;
    let Cart: any = cart;
    let dataCart = JSON.parse(Cart);
    dataCart.forEach((item: any) => {
      if (item.combo) {
        if (item.combo.discount_price) {
          this.setState({ discountFlag: true })
        }
      }
      else {
        if (item.selectedsize.discount_price) {
          this.setState({ discountFlag: true })
        }
      }
    })
    if (coupanCode !== "" && discountFlag == false) {
      this.props.getCart();
      let parsedStore: any = selectedStore ? selectedStore : null
      let customerId: any = localStorage.getItem("customer");
      let data = {
        store_id: parsedStore ? parsedStore.store_id : null,
        coupon_code: coupanCode,
        order_mode: "call center",
        order_channel: orderType == "Delivery" ? "delivery" : "pickup"
      };
      if (customerId) {
        Object.assign(data, { customer_id: customerId })
      }

      this.props.applyCoupon(data);
      this.calcTotal(dataCart);
    } else {
      toast.error("Voucher can not be applied on discounted items", {
        position: toast.POSITION.BOTTOM_RIGHT,
        hideProgressBar: true,
        autoClose: 3000,
      });
    }

  };
  removeCoupon = () => {
    let fee: any = localStorage.getItem("deliveryFee")
    let { selectedStore } = this.props;
    // let appliedComboCoupon: any = sessionStorage.getItem("combovoucher");
    // if (appliedComboCoupon) {
    //   let bytes = CryptoJS.AES.decrypt(appliedComboCoupon, EncryptionKey);
    //   let decryptedcomboVoucherData = bytes.toString(CryptoJS.enc.Utf8);
    //   if (decryptedcomboVoucherData) {
    //     let combo_id = JSON.parse(props.comboVoucher).combo_id;
    //     let dataCart: any = null;
    //     let Cart: any = props.cart;
    //     if (Cart && Cart.length > 0) {
    //       dataCart = JSON.parse(Cart);
    //       if (dataCart.length > 0) {
    //         let findIndex = dataCart.findIndex((obj: any) => { return obj.combo && obj.combo.combo_id == combo_id })
    //         if (findIndex > -1) {
    //           dataCart.splice(findIndex, 1);
    //           props.saveCart(dataCart);
    //           props.getCart();
    //           if (dataCart.length === 0 && (props.route == "details" || props.route == "payment")) {
    //             props.history.push('home')
    //           }
    //         }
    //       }
    //     }
    //     props.removeCoupon()
    //     props.resetCoupon({});
    //   } else {
    //     props.removeCoupon()
    //     props.resetCoupon({});
    //   }
    // } else {
    this.props.removeCoupon()
    this.props.resetCoupon({});
    if (selectedStore && this.state.orderType === "Delivery") {
      console.log(selectedStore.state_id.state_name, "selectedStore.state_name.state_id.state_name");

      this.props.saveTaxDetails(selectedStore.state_id.state_name, selectedStore.store_id)
    }
    // }
  };

  addModifier = (key: any, subKey: any, modifier_name: any, modifier_type: any) => {
    let { itemPrice, prevMod, prevKey, modifiers } = this.state;
    let prevPrice = 0;
    if (modifier_type && modifier_type.min) {
      Object.keys(modifiers[key]).map(subkey => {
        if (Array.isArray(modifiers[key][subkey])) {
          modifiers[key][subkey].map((element: any) => {
            if (element.selected == true) {
              prevPrice = ((element.discount_price || element.discount_price == 0) && (element.order_channel == 'callcenter')) ? Math.round(element.discount_price) : element.modifier_sale_price;
            }
            if (element.modifier_name !== modifier_name) {
              element.selected = false;
            }
          })
        }
      })
      modifiers[key][subKey].map((item: any, index: any) => {
        if (modifier_name == item.modifier_name) {
          let modPrice = ((item.discount_price || item.discount_price == 0)) ? Math.round(item.discount_price) : item.modifier_sale_price;
          if (prevMod == '') {
            this.setState({
              itemPrice: parseInt(itemPrice) + modPrice,
              prevMod: modifier_name,
              prevKey: subKey
            })
            item.selected = true
          }
          else {
            if (prevKey == subKey) {
              item.selected = !item.selected;
              if (prevMod === modifier_name) {
                if (item.selected == true) {
                  this.setState({
                    itemPrice: parseInt(itemPrice) - prevPrice + modPrice,
                    prevMod: modifier_name,
                    prevKey: subKey
                  })
                } else {
                  this.setState({
                    itemPrice: parseInt(itemPrice) - prevPrice,
                    prevMod: modifier_name,
                    prevKey: subKey
                  })
                }
              }
              else {
                this.setState({
                  itemPrice: parseInt(itemPrice) - prevPrice + modPrice,
                  prevMod: modifier_name,
                  prevKey: subKey
                })
              }
            }
            else {
              item.selected = true;
              this.setState({
                itemPrice: parseInt(itemPrice) - prevPrice + modPrice,
                prevMod: modifier_name,
                prevKey: subKey
              })
            }
          }
        }
      })
    } else {
      modifiers[key].map((element: any) => {
        let modPrice = ((element.discount_price || element.discount_price == 0) && (element.order_channel == 'callcenter')) ? Math.round(element.discount_price) : element.modifier_sale_price;
        if (element.selected == true) {
          prevPrice = modPrice;
        }
        if (element.modifier_name !== modifier_name) {
          element.selected = false;
        }
      })
      modifiers[key].map((item: any, index: any) => {
        if (modifier_name == item.modifier_name) {
          let modPrice = ((item.discount_price || item.discount_price == 0)) ? Math.round(item.discount_price) : item.modifier_sale_price;
          if (prevMod == '') {
            this.setState({
              itemPrice: parseInt(itemPrice) + modPrice,
              prevMod: modifier_name,
              prevKey: key
            })
            item.selected = true
          } else {
            if (prevKey == key) {
              item.selected = !item.selected;
              if (prevMod === modifier_name) {
                if (item.selected == true) {
                  this.setState({
                    itemPrice: parseInt(itemPrice) - prevPrice + modPrice,
                    prevMod: modifier_name,
                    prevKey: key
                  })
                } else {
                  this.setState({
                    itemPrice: parseInt(itemPrice) - prevPrice,
                    prevMod: modifier_name,
                    prevKey: key
                  })
                }
              }
              else {
                this.setState({
                  itemPrice: parseInt(itemPrice) - prevPrice + modPrice,
                  prevMod: modifier_name,
                  prevKey: key
                })
              }
            } else {
              item.selected = true;
              this.setState({
                itemPrice: parseInt(itemPrice) - prevPrice + modPrice,
                prevMod: modifier_name,
                prevKey: key
              })
            }
          }
        }
      })
    }
  }

  renderItemPrice(e: any) {
    let selectedSize = JSON.parse(e.target.value);
    if (selectedSize.discount_price && (selectedSize.order_channel == 'callcenter')) {
      this.setState({
        selectedsize: selectedSize,
        itemPrice: selectedSize.discount_price,
        initialPrice: selectedSize.discount_price,
        netPrice: selectedSize.mrp
      });
    }
    else {
      this.setState({
        selectedsize: selectedSize,
        itemPrice: selectedSize.mrp,
        initialPrice: selectedSize.mrp,
        netPrice: selectedSize.mrp
      });
    }
    if (!_.isEmpty(this.state.modifiers)) {
      Object.keys(this.state.modifiers).map(key => {
        (this.state.modifiers[key].min) ?
          Object.keys(this.state.modifiers[key]).map((subkey, index) => {
            Array.isArray(this.state.modifiers[key][subkey]) &&
              this.state.modifiers[key][subkey].map((item: any, index: any) => {
                item.selected = false;
                this.setState({
                  modifiers: this.state.modifiers
                });
              });
          }) :
          this.state.modifiers[key].map((item: any, index: any) => {
            item.selected = false;
            this.setState({
              modifiers: this.state.modifiers
            });
          });
      });
    }
  }

  decrementQuantity(key: any, modName: any) {
    let { modifiers, itemPrice } = this.state;
    modifiers[key].map((item: any, index: any) => {
      if (item.modifier_name == modName) {
        if (item.quantity == 1) {
          return;
        }
        else {
          item.quantity -= 1;
          this.setState({
            modifiers: modifiers,
            itemPrice: parseInt(itemPrice) - item.modifier_sale_price
          });
        }
      }
    });
  }
  incrementQuantity(key: any, modName: any) {
    let { modifiers, itemPrice } = this.state;
    modifiers[key].map((item: any, index: any) => {
      if (item.modifier_name == modName) {
        item.quantity += 1;
        this.setState({
          modifiers: modifiers,
          itemPrice: parseInt(itemPrice) + item.modifier_sale_price
        });
      }
    });
  }

  decrementCartQuantity(currentIndex: any) {
    let cart: any = localStorage.getItem("cart");
    let Cart: any = cart;
    let dataCart = JSON.parse(Cart);
    if (dataCart[currentIndex].item) {
      let price = (dataCart[currentIndex].selectedsize.discount_price || dataCart[currentIndex].selectedsize.discount_price == 0) ? Math.round(dataCart[currentIndex].selectedsize.discount_price) : dataCart[currentIndex].selectedsize.mrp;
      let makeAComboPrice = 0;
      let extra_price = 0;
      if (dataCart[currentIndex].item && dataCart[currentIndex].item.make_combo_options && dataCart[currentIndex].item.make_combo_options.length > 0) {
        dataCart[currentIndex].item.make_combo_options.map((options: any) => {
          if (options.option.selected == true) {
            options.quantity -= 1
            makeAComboPrice += options.option.mrp
          }
        })
      }
      if (dataCart[currentIndex].item && dataCart[currentIndex].item.is_half_n_half == 1) {
        Object.keys(dataCart[currentIndex].item.modifiers).map(key => {
          if (dataCart[currentIndex].item.modifiers[key].min) {
            Object.keys(dataCart[currentIndex].item.modifiers[key]).map(subkey => {
              Array.isArray(dataCart[currentIndex].item.modifiers[key][subkey]) &&
                dataCart[currentIndex].item.modifiers[key][subkey].map((item: any, index: any) => {
                  if (item.selected == true && item.quantity > 0) {
                    extra_price += (item.discount_price || item.discount_price == 0) ? item.discount_price : item.modifier_sale_price;
                    item.quantity = item.quantity - 1
                  }
                });
            });
          } else {
            dataCart[currentIndex].item.modifiers[key].map((item: any, index: any) => {
              if (item.selected == true && item.quantity > 0) {
                extra_price += (item.discount_price || item.discount_price == 0) ? item.discount_price : item.modifier_sale_price;
                item.quantity = item.quantity - 1
              }
            });
          }
        });
        dataCart[currentIndex].item.half_n_half_modifiers.map((obj: any) => {
          Object.keys(obj.data).map(key => {
            if (obj.data[key].min) {
              Object.keys(obj.data[key]).map(subkey => {
                Array.isArray(obj.data[key][subkey]) &&
                  obj.data[key][subkey].map((item: any, index: any) => {
                    if (item.selected == true && item.quantity > 0) {
                      extra_price += (item.discount_price || item.discount_price == 0) ? item.discount_price : item.modifier_sale_price;
                      item.quantity = item.quantity - 1
                    }
                  });
              });
            } else {
              obj.data[key].map((item: any, index: any) => {
                if (item.selected == true && item.quantity > 0) {
                  extra_price += (item.discount_price || item.discount_price == 0) ? item.discount_price : item.modifier_sale_price;
                  item.quantity = item.quantity - 1
                }
              });
            }
          });
        });
      } else {
        Object.keys(dataCart[currentIndex].item.modifiers).map(key => {
          if (dataCart[currentIndex].item.modifiers[key].min) {
            Object.keys(dataCart[currentIndex].item.modifiers[key]).map(subkey => {
              Array.isArray(dataCart[currentIndex].item.modifiers[key][subkey]) &&
                dataCart[currentIndex].item.modifiers[key][subkey].map((item: any, index: any) => {
                  if (item.selected == true && item.quantity > 0) {
                    extra_price += (item.discount_price || item.discount_price == 0) ? item.discount_price : item.modifier_sale_price;
                    item.quantity = item.quantity - 1
                  }
                });
            });
          } else {
            dataCart[currentIndex].item.modifiers[key].map((item: any, index: any) => {
              if (item.selected == true && item.quantity > 0) {
                extra_price += (item.discount_price || item.discount_price == 0) ? item.discount_price : item.modifier_sale_price;
                item.quantity = item.quantity - 1
              }
            });
          }
        });
      }
      if (dataCart[currentIndex].quantity == 1) {
        dataCart.splice(currentIndex, 1);
        this.props.saveCart(dataCart);
        this.props.getCart();
      } else {
        dataCart[currentIndex].totalItemPrice = dataCart[currentIndex].totalItemPrice - price - extra_price - makeAComboPrice;
        dataCart[currentIndex].quantity -= 1;
        this.props.saveCart(dataCart);
        setTimeout(() => {
          this.props.getCart();
        }, 500);
      }
    }
    else {
      let price = (dataCart[currentIndex].combo.discount_price || dataCart[currentIndex].combo.discount_price == 0) ? Math.round(dataCart[currentIndex].combo.discount_price) : dataCart[currentIndex].combo.combo_mrp_price;
      let extra_price = 0
      dataCart[currentIndex].combo.choices.map((obj: any) => {
        if (obj.is_half_n_half == 1) {
          Object.keys(obj.modifiers).map(key => {
            if (obj.modifiers[key].min) {
              Object.keys(obj.modifiers[key]).map(subkey => {
                Array.isArray(obj.modifiers[key][subkey]) &&
                  obj.modifiers[key][subkey].map((item: any, index: any) => {
                    if (item.selected == true && item.quantity > 0) {
                      extra_price += item.modifier_sale_price;
                      item.quantity = item.quantity - 1
                    }
                  });
              });
            } else {
              obj.modifiers[key].map((item: any, index: any) => {
                if (item.selected == true && item.quantity > 0) {
                  extra_price += item.modifier_sale_price;
                  item.quantity = item.quantity - 1
                }
              });
            }
          });
          obj.half_n_half_modifiers.map((halfobj: any) => {
            Object.keys(halfobj.data).map(key => {
              if (halfobj.data[key].min) {
                Object.keys(halfobj.data[key]).map(subkey => {
                  Array.isArray(halfobj.data[key][subkey]) &&
                    halfobj.data[key][subkey].map((item: any, index: any) => {
                      if (item.selected == true && item.quantity > 0) {
                        extra_price += item.modifier_sale_price;
                        item.quantity = item.quantity - 1
                      }
                    });
                });
              } else {
                halfobj.data[key].map((item: any, index: any) => {
                  if (item.selected == true && item.quantity > 0) {
                    extra_price += item.modifier_sale_price;
                    item.quantity = item.quantity - 1
                  }
                });
              }
            });
          });
        } else {
          Object.keys(obj.modifiers).map(key => {
            if (obj.modifiers[key].min) {
              Object.keys(obj.modifiers[key]).map(subkey => {
                Array.isArray(obj.modifiers[key][subkey]) &&
                  obj.modifiers[key][subkey].map((item: any, index: any) => {
                    if (item.selected == true && item.quantity > 0) {
                      extra_price += item.modifier_sale_price;
                      item.quantity = item.quantity - 1
                    }
                  });
              });
            } else {
              obj.modifiers[key].map((item: any, index: any) => {
                if (item.selected == true && item.quantity > 0) {
                  extra_price += item.modifier_sale_price;
                  item.quantity = item.quantity - 1
                }
              });
            }
          });
        }
      })
      if (dataCart[currentIndex].quantity == 1) {
        let child_products = dataCart[currentIndex].combo.child_products;
        if (child_products) {
          for (let j = 0; j < child_products.length; j++) {
            let childProductsArray = child_products[j].suggestive_child_products;
            for (let k = 0; k < childProductsArray.length; k++) {
              let findIndex = dataCart.findIndex((obj: any) => { return obj.combo && obj.combo.combo_id == childProductsArray[k].suggestive_combo_id.combo_id })
              if (findIndex > -1) {
                dataCart.splice(findIndex, 1);
              }
            }
          }
        }
        if (dataCart[currentIndex].combo.is_voucher == 1) {
          this.props.resetCoupon({});
          this.props.removeCoupon()
        }
        dataCart.splice(currentIndex, 1);
        this.props.saveCart(dataCart);
        this.props.getCart();
      } else {
        dataCart[currentIndex].totalItemPrice = dataCart[currentIndex].totalItemPrice - price - extra_price;
        dataCart[currentIndex].quantity -= 1;
        this.props.saveCart(dataCart);
        setTimeout(() => {
          this.props.getCart();
        }, 500);
      }
    }
  }
  handleUpdate = async (e: any) => {
    this.setState({ itemPrice: e })
  }
  hanldeRadioCheckMakeCombo = async (e: any, id: any) => {
    let prevPrice = this.state.prevMakeComboPrice
    let curentItemPrice = this.state.itemPrice
    let updatedItemPrice = curentItemPrice - prevPrice
    await this.handleUpdate(updatedItemPrice)
    let { make_combos, itemPrice } = this.state
    let updatedMakeCombos = make_combos
    updatedMakeCombos.map((combo: any) => {
      if (combo.option.option_id == id) {
        combo.option.selected = !combo.option.selected
        if (combo.option.selected) {
          combo.quantity = this.state.itemQuantity
          prevPrice = combo.option.mrp
        } else {
          combo.quantity = 0
          prevPrice = 0
        }
      } else {
        combo.option.selected = false
        combo.quantity = 0
      }
    })
    let finalPrice: any = prevPrice + itemPrice
    await this.handleStateUpdate(finalPrice, prevPrice, updatedMakeCombos)
  }
  handleStateUpdate = async (finalPrice: any, prevPrice: any, updatedMakeCombos: any) => {
    this.setState({ itemPrice: finalPrice, prevMakeComboPrice: prevPrice })
    this.setState({ make_combos: updatedMakeCombos })
  }

  incrementCartQuantity(currentIndex: any) {
    let cart: any = localStorage.getItem("cart");
    let Cart: any = cart;
    let dataCart = JSON.parse(Cart);
    if (dataCart[currentIndex].item) {
      let extra_price = 0;
      let price = (dataCart[currentIndex].selectedsize.discount_price || dataCart[currentIndex].selectedsize.discount_price == 0) ? Math.round(dataCart[currentIndex].selectedsize.discount_price) : dataCart[currentIndex].selectedsize.mrp;
      if (dataCart[currentIndex].item && dataCart[currentIndex].item.make_combo_options && dataCart[currentIndex].item.make_combo_options.length > 0) {
        dataCart[currentIndex].item.make_combo_options.map((options: any) => {
          if (options.option.selected == true) {
            options.quantity += 1
            price = price + options.option.mrp
          }
        })
      }
      if (dataCart[currentIndex].item && dataCart[currentIndex].item.is_half_n_half == 1) {
        Object.keys(dataCart[currentIndex].item.modifiers).map(key => {
          if (dataCart[currentIndex].item.modifiers[key].min) {
            Object.keys(dataCart[currentIndex].item.modifiers[key]).map(subkey => {
              Array.isArray(dataCart[currentIndex].item.modifiers[key][subkey]) &&
                dataCart[currentIndex].item.modifiers[key][subkey].map((item: any, index: any) => {
                  if (item.selected == true && item.quantity > 0) {
                    extra_price += (item.discount_price || item.discount_price == 0) ? item.discount_price : item.modifier_sale_price;
                    item.quantity = item.quantity + 1
                  }
                });
            });
          } else {
            dataCart[currentIndex].item.modifiers[key].map((item: any, index: any) => {
              if (item.selected == true && item.quantity > 0) {
                extra_price += (item.discount_price || item.discount_price == 0) ? item.discount_price : item.modifier_sale_price;
                item.quantity = item.quantity + 1
              }
            });
          }
        });
        dataCart[currentIndex].item.half_n_half_modifiers.map((obj: any) => {
          Object.keys(obj.data).map(key => {
            if (obj.data[key].min) {
              Object.keys(obj.data[key]).map(subkey => {
                Array.isArray(obj.data[key][subkey]) &&
                  obj.data[key][subkey].map((item: any, index: any) => {
                    if (item.selected == true && item.quantity > 0) {
                      extra_price += (item.discount_price || item.discount_price == 0) ? item.discount_price : item.modifier_sale_price;
                      item.quantity = item.quantity + 1
                    }
                  });
              });
            } else {
              obj.data[key].map((item: any, index: any) => {
                if (item.selected == true && item.quantity > 0) {
                  extra_price += (item.discount_price || item.discount_price == 0) ? item.discount_price : item.modifier_sale_price;
                  item.quantity = item.quantity + 1
                }
              });
            }
          });
        });
      } else {
        Object.keys(dataCart[currentIndex].item.modifiers).map(key => {
          if (dataCart[currentIndex].item.modifiers[key].min) {
            Object.keys(dataCart[currentIndex].item.modifiers[key]).map(subkey => {
              Array.isArray(dataCart[currentIndex].item.modifiers[key][subkey]) &&
                dataCart[currentIndex].item.modifiers[key][subkey].map((item: any, index: any) => {
                  if (item.selected == true && item.quantity > 0) {
                    extra_price += (item.discount_price || item.discount_price == 0) ? item.discount_price : item.modifier_sale_price;
                    item.quantity = item.quantity + 1
                  }
                });
            });
          } else {
            dataCart[currentIndex].item.modifiers[key].map((item: any, index: any) => {
              if (item.selected == true && item.quantity > 0) {
                extra_price += (item.discount_price || item.discount_price == 0) ? item.discount_price : item.modifier_sale_price;
                item.quantity = item.quantity + 1
              }
            });
          }
        });
      }
      dataCart[currentIndex].totalItemPrice = dataCart[currentIndex].totalItemPrice + price + extra_price;
      dataCart[currentIndex].quantity += 1;
      this.props.saveCart(dataCart);
      setTimeout(() => {
        this.props.getCart();
      }, 500);
    }
    else {
      let price = (dataCart[currentIndex].combo.discount_price || dataCart[currentIndex].combo.discount_price == 0) ? Math.round(dataCart[currentIndex].combo.discount_price) : dataCart[currentIndex].combo.combo_mrp_price;
      let extra_price = 0
      dataCart[currentIndex].combo.choices.map((obj: any) => {
        if (obj.is_half_n_half == 1) {
          Object.keys(obj.modifiers).map(key => {
            if (obj.modifiers[key].min) {
              Object.keys(obj.modifiers[key]).map(subkey => {
                Array.isArray(obj.modifiers[key][subkey]) &&
                  obj.modifiers[key][subkey].map((item: any, index: any) => {
                    if (item.selected == true && item.quantity > 0) {
                      extra_price += item.modifier_sale_price;
                      item.quantity = item.quantity + 1
                    }
                  });
              });
            } else {
              obj.modifiers[key].map((item: any, index: any) => {
                if (item.selected == true && item.quantity > 0) {
                  extra_price += item.modifier_sale_price;
                  item.quantity = item.quantity + 1
                }
              });
            }
          });
          obj.half_n_half_modifiers.map((halfobj: any) => {
            Object.keys(halfobj.data).map(key => {
              if (halfobj.data[key].min) {
                Object.keys(halfobj.data[key]).map(subkey => {
                  Array.isArray(halfobj.data[key][subkey]) &&
                    halfobj.data[key][subkey].map((item: any, index: any) => {
                      if (item.selected == true && item.quantity > 0) {
                        extra_price += item.modifier_sale_price;
                        item.quantity = item.quantity + 1
                      }
                    });
                });
              } else {
                halfobj.data[key].map((item: any, index: any) => {
                  if (item.selected == true && item.quantity > 0) {
                    extra_price += item.modifier_sale_price;
                    item.quantity = item.quantity + 1
                  }
                });
              }
            });
          });
        } else {
          Object.keys(obj.modifiers).map(key => {
            if (obj.modifiers[key].min) {
              Object.keys(obj.modifiers[key]).map(subkey => {
                Array.isArray(obj.modifiers[key][subkey]) &&
                  obj.modifiers[key][subkey].map((item: any, index: any) => {
                    if (item.selected == true && item.quantity > 0) {
                      extra_price += item.modifier_sale_price;
                      item.quantity = item.quantity + 1
                    }
                  });
              });
            } else {
              obj.modifiers[key].map((item: any, index: any) => {
                if (item.selected == true && item.quantity > 0) {
                  extra_price += item.modifier_sale_price;
                  item.quantity = item.quantity + 1
                }
              });
            }
          });
        }
      })
      dataCart[currentIndex].totalItemPrice = dataCart[currentIndex].totalItemPrice + price + extra_price;
      dataCart[currentIndex].quantity += 1;
      this.props.saveCart(dataCart);
      setTimeout(() => {
        this.props.getCart();
      }, 500);
    }
  }
  calcSubTotal = (data: any) => {
    let sum = 0;
    data.forEach((item: any) => {
      sum += parseInt(item.totalItemPrice);
    });
    this.itemTotal = sum;
    return sum;
  };

  calcTotal = (data: any) => {
    let { deliveryfee, coupon } = this.props;
    this.couponDiscount = 0;
    this.discount = 0
    let sum: any = 0;
    let sum2: any = 0
    if (data) {
      data.forEach((item: any) => {
        sum += parseInt(item.totalItemPrice);
        sum2 += parseInt(item.totalItemPrice);
      });
    }
    let discountValue: any = 0;
    if (!_.isEmpty(coupon)) {
      if (coupon.type == "menu") {
        let itemSum = 0;
        let comboSum = 0;
        data.forEach((item: any, index: any) => {
          if (item.item) {
            if (sum >= coupon.min_amount) {
              if (coupon.discount_type == "value") {
                discountValue = coupon.coupon_value;
                this.couponDiscount = coupon.coupon_value;
                itemSum += parseInt(item.totalItemPrice);
              } else if (coupon.discount_type == "percentage") {
                discountValue = (item.totalItemPrice * coupon.percent) / 100;
                this.couponDiscount += discountValue;
              }
            }
          } else {
            comboSum += parseInt(item.totalItemPrice);
          }
        });
        if (coupon.discount_type !== "combo" && this.couponDiscount === 0 && this.codeExeCount == 0) {
          toast.error(sum >= coupon.min_amount ? "Voucher can not be applied on these items" : `Cart value should be minimum ${priceunit}${coupon.min_amount} to avail this voucher`, {
            position: toast.POSITION.BOTTOM_RIGHT,
            hideProgressBar: true,
            autoClose: 3000,
          })
          this.props.resetCoupon({});
          this.props.removeCoupon()
          this.codeExeCount = 1
          this.appliedCoupon = false
        } else {
          if (itemSum > 0) {
            sum = comboSum + (itemSum - this.couponDiscount)
          } else {
            sum = sum - this.couponDiscount
          }
        }
      }
      else if (coupon.type == 'group') {
        let itemSum: any = 0;
        let comboSum = 0;
        let groups_json: any = coupon.groups_json
        let parsedGroupsJson: any = groups_json ? JSON.parse(groups_json) : null
        data.forEach((item: any, index: any) => {
          parsedGroupsJson && parsedGroupsJson.map((json: any) => {
            if (item.item) {
              if (item.item.item_group_id == json.value || item.item.group_id == json.value) {
                if (sum >= coupon.min_amount) {
                  if (coupon.discount_type == "value") {
                    discountValue = coupon.coupon_value;
                    this.couponDiscount = coupon.coupon_value;
                    itemSum += parseInt(item.totalItemPrice);
                  } else if (coupon.discount_type == "percentage") {
                    discountValue = (item.totalItemPrice * coupon.percent) / 100;
                    this.couponDiscount += discountValue;
                  }
                }
              }
            } else {
              comboSum += parseInt(item.totalItemPrice);
            }
          })
        });
        if (coupon.discount_type !== "combo" && this.couponDiscount === 0 && this.codeExeCount == 0) {
          toast.error(sum >= coupon.min_amount ? "Voucher can not be applied on these items" : `Cart value should be minimum ${priceunit}${coupon.min_amount} to avail this voucher`, {
            position: toast.POSITION.BOTTOM_RIGHT,
            hideProgressBar: true,
            autoClose: 3000,
          })
          this.props.resetCoupon({});
          this.props.removeCoupon()
          this.codeExeCount = 1
          this.appliedCoupon = false
        } else {
          if (itemSum > 0) {
            sum = comboSum + (itemSum - this.couponDiscount)
          } else {
            sum = sum - this.couponDiscount
          }
        }
      }
      else if (coupon.type == "item") {
        let CouponitemsArr = JSON.parse(coupon.items_json);
        data.forEach((item: any, index: any) => {
          CouponitemsArr.map((coupon_items: any) => {
            if (item.combo && coupon_items.combo_id) {
              if (item.combo.combo_id == coupon_items.combo_id) {
                if (sum >= coupon.min_amount) {
                  if (coupon.discount_type == "value") {
                    discountValue = coupon.coupon_value * item.quantity;
                    this.couponDiscount += discountValue;
                  } else if (coupon.discount_type == "percentage") {
                    discountValue = (item.totalItemPrice * coupon.percent) / 100;
                    this.couponDiscount += discountValue;
                  }
                }
              }
            }
            if (item.item && coupon_items.menu_item_id) {
              if (item.item.menu_item_id == coupon_items.menu_item_id) {
                if (sum >= coupon.min_amount) {
                  if (coupon.discount_type == "value") {
                    discountValue = coupon.coupon_value * item.quantity;
                    this.couponDiscount += discountValue;
                  } else if (coupon.discount_type == "percentage") {
                    discountValue = (item.totalItemPrice * coupon.percent) / 100;
                    this.couponDiscount += discountValue;
                  }
                }
              }
            }
          })
        });
        if (coupon.discount_type !== "combo" && this.couponDiscount === 0 && this.codeExeCount == 0) {
          toast.error(sum >= coupon.min_amount ? "Voucher can not be applied on these items" : `Cart value should be minimum ${priceunit}${coupon.min_amount} to avail this voucher`, {
            position: toast.POSITION.BOTTOM_RIGHT,
            hideProgressBar: true,
            autoClose: 3000,
          })
          this.props.resetCoupon({});
          this.props.removeCoupon()
          this.codeExeCount = 1
          this.appliedCoupon = false
        }
      } else if (coupon.type == "store") {
        let itemSum = 0;
        let comboSum = 0;
        data.forEach((item: any, index: any) => {
          if (item.item) {
            if (sum >= coupon.min_amount) {
              if (coupon.discount_type == "value") {
                discountValue = coupon.coupon_value;
                this.couponDiscount = coupon.coupon_value;
                itemSum += parseInt(item.totalItemPrice);
              } else if (coupon.discount_type == "percentage") {
                discountValue = (item.totalItemPrice * coupon.percent) / 100;
                this.couponDiscount += discountValue;
              }
            }
          } else {
            comboSum += parseInt(item.totalItemPrice);
          }
        });
        if (coupon.discount_type !== "combo" && this.couponDiscount === 0 && this.codeExeCount == 0) {
          toast.error(sum >= coupon.min_amount ? "Voucher can not be applied on these items" : `Cart value should be minimum ${priceunit}${coupon.min_amount} to avail this voucher`, {
            position: toast.POSITION.BOTTOM_RIGHT,
            hideProgressBar: true,
            autoClose: 3000,
          })
          this.props.resetCoupon({});
          this.props.removeCoupon()
          this.codeExeCount = 1
          this.appliedCoupon = false
        } else {
          if (itemSum > 0) {
            sum = comboSum + (itemSum - this.couponDiscount)
          } else {
            sum = sum - this.couponDiscount
          }
        }
      }

      if (!_.isEmpty(this.props.coupon) && this.appliedCoupon && this.couponDiscount > 0) {
        this.props.consumeCoupon(this.props.coupon)
        toast.success("Voucher applied successfully", { position: toast.POSITION.BOTTOM_RIGHT, hideProgressBar: false, autoClose: 3000 })
        this.appliedCoupon = false
      }
    }
    else {
      this.discount = 0;
      this.couponDiscount = 0;
      this.cartTotal = 0;
    }
    if (this.state.orderType === "Delivery") {
      sum = sum + deliveryfee;
    } else if (this.state.orderType == "Pickup") {
      sum = sum;
    }
    this.props.SaveDiscountValue(this.couponDiscount);
    this.cartTotal = Math.round(sum);
    this.props.SaveCartTotal(this.cartTotal);
    this.props.SaveTaxValue(this.totalTax)

    if (this.props.taxDataCash.tax_type == 0) {
      sum = parseInt(sum);
    }

    if (this.props.taxDataCash.tax_type == 1) {
      sum = parseInt(sum) + this.totalTax;
    }
    let finalSum = _.isNaN(sum) ? sum2 : sum
    return Math.round(finalSum);
  };

  calculateOrderDiscount = (data: any) => {
    let discount = 0;
    data.forEach((item: any) => {
      if (item.combo) {
        if (item.combo.discount_price || item.combo.discount_price == 0) {
          discount += item.quantity * Math.round(item.combo.combo_mrp_price - item.combo.discount_price);
        }
      } else {
        if (item.selectedsize.discount_price || item.selectedsize.discount_price == 0) {
          let price: any = item.selectedsize.mrp - item.selectedsize.discount_price;
          discount += item.quantity * (price).toFixed(1);
        }
        {
          Object.keys(item.item.modifiers).map(
            (key: any, index) => {
              item.item.modifiers[key].min ?
                Object.keys(item.item.modifiers[key]).map(
                  (subkey: any, index) => {
                    Array.isArray(item.item.modifiers[key][subkey]) &&
                      item.item.modifiers[key][subkey].map(
                        (obj: any, index: any) => {
                          let price: any = obj.modifier_sale_price - obj.discount_price;
                          if (obj.selected === true && (obj.discount_price || obj.discount_price == 0)) {
                            discount += obj.quantity * (price).toFixed(1)
                          }
                        });
                  }
                )
                :
                item.item.modifiers[key].map(
                  (obj: any, index: any) => {
                    let price: any = obj.modifier_sale_price - obj.discount_price;
                    if (obj.selected === true && (obj.discount_price || obj.discount_price == 0)) {
                      discount += obj.quantity * (price).toFixed(1)
                    }
                  })
            }
          )
        }
      }
    })
    return (discount).toFixed(1);
  }

  onCloseModal = () => {
    this.setState({ open: false });
  };

  GotoHome = () => {
    window.location.href = "/customerDetail";
  }

  calcTotalTax = (cart_total: any) => {
    let totalTax = 0;
    let tax_rate = this.props.taxDataCash.tax_percent
    if (this.props.taxDataCash.tax_type == 1) {
      totalTax = cart_total * this.props.taxDataCash.tax_percent / 100;
      this.totalTax = totalTax;
    }

    if (this.props.taxDataCash.tax_type == 0) {
      totalTax = ((cart_total - this.props.deliveryfee) * (parseInt(tax_rate) / (100 + parseInt(tax_rate))))
      this.totalTax = totalTax;
    }
    this.props.SaveTaxValue(this.totalTax)
    return totalTax;
  }

  calcTotalDiscount = (discount: any) => {
    return discount;
  }
  checkMakeAComboSelected(makeCombos: any) {
    let success = false;
    makeCombos && makeCombos.map(
      (item: any, index: any) => {
        if (item.option.selected === true) {
          success = true
          return;
        }
      })
    return success;
  }
  flavour2halfReady = (halfNHalfModifiers: any) => {
    //For only flavours and Toppings
    let quantityFlag = true;
    let foundModifier = false;
    if (!_.isEmpty(halfNHalfModifiers[1].data)) {
      Object.keys(halfNHalfModifiers[1].data).map(key => {
        let ModifierQuantityCount = 0
        if (quantityFlag) {
          if ((halfNHalfModifiers[1].data[key].is_flavour && halfNHalfModifiers[1].data[key].is_flavour == 1)) {
            if (halfNHalfModifiers[1].data[key].min > 0) {
              Object.keys(halfNHalfModifiers[1].data[key]).map((subkey: any, index: any) => {
                if (quantityFlag) {
                  if (Array.isArray(halfNHalfModifiers[1].data[key][subkey])) {
                    for (let j = 0; j < halfNHalfModifiers[1].data[key][subkey].length; j++) {
                      if (halfNHalfModifiers[1].data[key][subkey][j].selected && halfNHalfModifiers[1].data[key][subkey][j].quantity > 0) {
                        ModifierQuantityCount += halfNHalfModifiers[1].data[key][subkey][j].quantity;
                      }
                    }
                    if (index == Object.keys(halfNHalfModifiers[1].data[key]).length - 1) {
                      if (ModifierQuantityCount < halfNHalfModifiers[1].data[key].min) {
                        if (!foundModifier) {
                          // toast.warn("Please select max quantity for required option in " + halfNHalfModifiers[1].data[key][i].mod_group_name, { position: toast.POSITION.BOTTOM_RIGHT, hideProgressBar: true, autoClose: 3000 })
                          foundModifier = true;
                          quantityFlag = false;
                          // break;
                        }
                      }
                      else {
                        quantityFlag = true;
                      }
                    }
                  }
                }
              })
            }
          } else {
            for (let i = 0; i < halfNHalfModifiers[1].data[key].length; i++) {
              if (halfNHalfModifiers[1].data[key][i].modGroup_min_quantity > 0) {
                if (halfNHalfModifiers[1].data[key][i].selected && halfNHalfModifiers[1].data[key][i].quantity > 0) {
                  ModifierQuantityCount += halfNHalfModifiers[1].data[key][i].quantity;
                }
                if (i == halfNHalfModifiers[1].data[key].length - 1) {
                  if (ModifierQuantityCount < halfNHalfModifiers[1].data[key][i].modGroup_min_quantity) {
                    if (!foundModifier) {
                      // toast.warn("Please select max quantity for required option in " + modifiers[key][i].mod_group_name, { position: toast.POSITION.BOTTOM_RIGHT, hideProgressBar: true, autoClose: 3000 })
                      foundModifier = true;
                      quantityFlag = false;
                      break;
                    }
                  }
                  else {
                    quantityFlag = true;
                  }
                }
              }
            }
          }
        }
      })
    }
    else {
      quantityFlag = true;
    }
    return quantityFlag
  }
  cartItem = (data: any) => {
    let { deliveryfee, tax, coupon, open, order_id, selectedStore } = this.props;
    if (!_.isEmpty(data)) {
      let cart: any[] = JSON.parse(data);
      if (cart.length > 0) {
        return (
          <div className="daily-feeds card">
            <div className="card-header">
              <h3 className="h4">Cart</h3>
              <div className="d-flex justify-content-between pt-4">
                <button
                  className="btn btn-xs btn-danger"
                  disabled={this.state.netWork}
                  onClick={() => this.ValidateOrder()}
                >
                  {this.state.netWork ? "Loading" : "Confirm"}
                </button>
                <button
                  className="btn btn-xs btn-danger"
                  disabled={this.state.netWork}
                  onClick={() => {
                    this.props.clearCart();
                  }}
                >
                  Clear
                </button>
              </div>

            </div>
            <div className="card-body no-padding">
              {cart.map((carts, indexes) => (
                <div className="item">
                  <div className="feed d-flex justify-content-between">
                    <div className="feed-body d-flex justify-content-between w-100">
                      <div className="content w-100">
                        <div className="d-flex justify-content-between">
                          <h5 style={{ fontSize: 13 }}>
                            {(!_.isEmpty(carts.item) &&
                              carts.item.item_name) ||
                              (!_.isEmpty(carts.combo) &&
                                carts.combo.combo_name)}{" x "}{carts.quantity}
                            {carts.selectedsize &&
                              `(${carts.selectedsize.size})`}
                          </h5>
                          {!_.isEmpty(carts.combo) &&
                            <strong>{priceunit + carts.price * carts.quantity}</strong>
                          }
                          {!_.isEmpty(carts.item) &&
                            <strong>{priceunit}{(carts.selectedsize.discount_price || carts.selectedsize.discount_price == 0) ? carts.selectedsize.discount_price * carts.quantity : carts.selectedsize.mrp * carts.quantity}</strong>
                          }
                        </div>
                        {/* For Combos */}
                        {
                          !_.isEmpty(carts.combo) && typeof carts.combo.modifiers == "string" ?
                            JSON.parse(carts.combo.modifiers).map((item: any) => {
                              return (
                                `${item.modifier_name} * ${item.quantity}, `
                              )
                            }) :
                            (!_.isEmpty(carts.combo) && !_.isEmpty(carts.combo.modifiers)) &&
                            <>
                              {/* {this.checkAddOneSelected(carts.combo.modifiers) && <p className="cart-extra">Addon</p>} */}
                              {Object.keys(carts.combo.modifiers).map(
                                (key: any, index) => {
                                  return (
                                    carts.combo.modifiers[key].map(
                                      (item: any, index: any) => {
                                        return (
                                          item.selected === true &&
                                          <div key={index} className="d-flex justify-content-between p-2">
                                            <li style={{ paddingLeft: '1rem' }}>{item.modifier_name + ' x ' + item.quantity}</li>
                                            <p>{priceunit + item.modifier_sale_price * item.quantity}</p>
                                          </div>
                                        )
                                      })
                                  );
                                }
                              )
                              }
                            </>
                        }

                        {
                          (!_.isEmpty(carts.combo) && !_.isEmpty(carts.combo.choices)) &&
                          <>
                            {carts.combo.choices.map((choiceObj: any) => {
                              return <><p className="mb-0">{choiceObj.group_name} </p>
                                {choiceObj.is_full_pizza == 1 ? Object.keys(choiceObj.modifiers).map(
                                  (key: any, index) => {
                                    return (
                                      choiceObj.modifiers[key].min ?

                                        Object.keys(choiceObj.modifiers[key]).map(
                                          (subkey: any, index) => {
                                            return (
                                              Array.isArray(choiceObj.modifiers[key][subkey]) &&
                                              choiceObj.modifiers[key][subkey].map(
                                                (item: any, index: any) => {
                                                  return (
                                                    item.selected === true &&
                                                    <div key={index} className="d-flex justify-content-between px-2">
                                                      <li style={{ paddingLeft: '1rem', fontSize: 13 }}>{item.modifier_name + ' x ' + item.quantity}</li>
                                                      <p style={{ paddingLeft: '1rem', fontSize: 13 }}>{priceunit + item.modifier_sale_price * item.quantity}</p>
                                                    </div>
                                                  )
                                                })
                                            );
                                          }
                                        )
                                        :
                                        choiceObj.modifiers[key].map(
                                          (item: any, index: any) => {
                                            return (
                                              item.selected === true &&
                                              <div key={index} className="d-flex justify-content-between px-2">
                                                <li style={{ paddingLeft: '1rem', fontSize: 13 }}>{item.modifier_name + ' x ' + item.quantity}</li>
                                                <p style={{ paddingLeft: '1rem', fontSize: 13 }}>{priceunit + item.modifier_sale_price * item.quantity}</p>
                                              </div>
                                            )
                                          })
                                    )
                                  }
                                  //  For Half N Half Modifiers 
                                ) : choiceObj.is_half_n_half == 1 ?
                                  <>
                                    {Object.keys(choiceObj.modifiers).map(
                                      (key: any, index) => {
                                        return (
                                          choiceObj.modifiers[key].min ?

                                            Object.keys(choiceObj.modifiers[key]).map(
                                              (subkey: any, index) => {
                                                return (
                                                  Array.isArray(choiceObj.modifiers[key][subkey]) &&
                                                  choiceObj.modifiers[key][subkey].map(
                                                    (item: any, index: any) => {
                                                      return (
                                                        item.selected === true &&
                                                        <div key={index} className="d-flex justify-content-between px-2">
                                                          <li style={{ paddingLeft: '1rem', fontSize: 13 }}>{item.modifier_name + ' x ' + item.quantity}</li>
                                                          <p style={{ paddingLeft: '1rem', fontSize: 13 }}>{priceunit + item.modifier_sale_price * item.quantity}</p>
                                                        </div>
                                                      )
                                                    })
                                                );
                                              }
                                            )
                                            :
                                            choiceObj.modifiers[key].map(
                                              (item: any, index: any) => {
                                                return (
                                                  item.selected === true &&
                                                  <div key={index} className="d-flex justify-content-between px-2">
                                                    <li style={{ paddingLeft: '1rem', fontSize: 13 }}>{item.modifier_name + ' x ' + item.quantity}</li>
                                                    <p style={{ paddingLeft: '1rem', fontSize: 13 }}>{priceunit + item.modifier_sale_price * item.quantity}</p>
                                                  </div>
                                                )
                                              })
                                        )
                                      }
                                      //  For Half N Half Modifiers 
                                    )}
                                    {(choiceObj.is_half_n_half == 1 && !_.isEmpty(choiceObj.half_n_half_modifiers)) &&
                                      choiceObj.half_n_half_modifiers.map((obj: any, index: any) => {
                                        return <div className='px-2'>
                                          {index == 0 ? <p className="mb-0">1st Half </p> : this.flavour2halfReady(choiceObj.half_n_half_modifiers) && <p className="mb-0">2nd Half</p>}
                                          {Object.keys(obj.data).map(
                                            (key: any, index) => {
                                              return (
                                                (obj.data[key].is_flavour && obj.data[key].is_flavour == 1) ?
                                                  Object.keys(obj.data[key]).map(
                                                    (subkey: any, index) => {
                                                      return (
                                                        Array.isArray(obj.data[key][subkey]) &&
                                                        obj.data[key][subkey].map(
                                                          (item: any, index: any) => {
                                                            return (
                                                              item.selected === true &&
                                                              <div key={index} className="d-flex justify-content-between px-2">
                                                                <li style={{ paddingLeft: '1rem', fontSize: 13 }}>{item.modifier_name + ' x ' + item.quantity}</li>
                                                                <p style={{ paddingLeft: '1rem', fontSize: 13 }}>{priceunit + item.modifier_sale_price * item.quantity}</p>
                                                              </div>
                                                            )
                                                          })
                                                      );
                                                    }
                                                  ) : (!obj.data[key].is_flavour && !obj.data[key].is_crust) &&
                                                  obj.data[key].map(
                                                    (item: any, index: any) => {
                                                      return (
                                                        item.selected === true &&
                                                        <div key={index} className="d-flex justify-content-between px-2">
                                                          <li style={{ paddingLeft: '1rem', fontSize: 13 }}>{item.modifier_name + ' x ' + item.quantity}</li>
                                                          <p style={{ paddingLeft: '1rem', fontSize: 13 }}>{priceunit + item.modifier_sale_price * item.quantity}</p>
                                                        </div>
                                                      )
                                                    })
                                              )
                                            }
                                          )
                                          }
                                        </div>
                                      })}
                                  </>
                                  : Object.keys(choiceObj.modifiers).map(
                                    (key: any, index) => {
                                      return (
                                        choiceObj.modifiers[key].min ?

                                          Object.keys(choiceObj.modifiers[key]).map(
                                            (subkey: any, index) => {
                                              return (
                                                Array.isArray(choiceObj.modifiers[key][subkey]) &&
                                                choiceObj.modifiers[key][subkey].map(
                                                  (item: any, index: any) => {
                                                    return (
                                                      item.selected === true &&
                                                      <div key={index} className="d-flex justify-content-between px-2">
                                                        <li style={{ paddingLeft: '1rem', fontSize: 13 }}>{item.modifier_name + ' x ' + item.quantity}</li>
                                                        <p style={{ paddingLeft: '1rem', fontSize: 13 }}>{priceunit + item.modifier_sale_price * item.quantity}</p>
                                                      </div>
                                                    )
                                                  })
                                              );
                                            }
                                          )
                                          :
                                          choiceObj.modifiers[key].map(
                                            (item: any, index: any) => {
                                              return (
                                                item.selected === true &&
                                                <div key={index} className="d-flex justify-content-between px-2">
                                                  <li style={{ paddingLeft: '1rem', fontSize: 13 }}>{item.modifier_name + ' x ' + item.quantity}</li>
                                                  <p style={{ paddingLeft: '1rem', fontSize: 13 }}>{priceunit + item.modifier_sale_price * item.quantity}</p>
                                                </div>
                                              )
                                            })
                                      )
                                    })
                                }
                              </>
                            })}
                          </>
                        }
                        {
                          !_.isEmpty(carts.item) && typeof carts.item.modifiers == "string" ?
                            JSON.parse(carts.item.modifiers).map((item: any) => {
                              return (
                                `${item.modifier_name} * ${item.quantity}, `
                              )
                            }) :
                            (!_.isEmpty(carts.item) && !_.isEmpty(carts.item.modifiers)) &&
                            Object.keys(carts.item.modifiers).map(
                              (key: any, index) => {
                                return (
                                  carts.item.modifiers[key].min ?

                                    Object.keys(carts.item.modifiers[key]).map(
                                      (subkey: any, index) => {
                                        return (
                                          Array.isArray(carts.item.modifiers[key][subkey]) &&
                                          carts.item.modifiers[key][subkey].map(
                                            (item: any, index: any) => {
                                              return (
                                                item.selected === true &&
                                                <div key={index} className="d-flex justify-content-between px-2">
                                                  <li style={{ paddingLeft: '1rem', fontSize: 13 }}>{item.modifier_name + ' x ' + item.quantity}</li>
                                                  <p style={{ paddingLeft: '1rem', fontSize: 13 }}>{((item.discount_price || item.discount_price == 0)) ?
                                                    <>
                                                      {priceunit + Math.round(item.discount_price) * item.quantity}
                                                    </>
                                                    :
                                                    <>
                                                      {priceunit + item.modifier_sale_price * item.quantity}
                                                    </>
                                                  }</p>
                                                </div>
                                              )
                                            })
                                        );
                                      }
                                    )
                                    :
                                    carts.item.modifiers[key].map(
                                      (item: any, index: any) => {
                                        return (
                                          item.selected === true &&
                                          <div key={index} className="d-flex justify-content-between px-2">
                                            <li style={{ paddingLeft: '1rem', fontSize: 13 }}>{item.modifier_name + ' x ' + item.quantity}</li>
                                            <p style={{ paddingLeft: '1rem', fontSize: 13 }}>{((item.discount_price || item.discount_price == 0)) ?
                                              <>
                                                {priceunit + Math.round(item.discount_price) * item.quantity}
                                              </>
                                              :
                                              <>
                                                {priceunit + item.modifier_sale_price * item.quantity}
                                              </>
                                            }</p>
                                          </div>
                                        )
                                      })
                                )
                              }
                            )
                        }
                        {/* For Half N Half Modifiers */}
                        {
                          !_.isEmpty(carts.item) && carts.item.is_half_n_half == 1 && typeof carts.item.half_n_half_modifiers == "string" ?
                            JSON.parse(carts.item.half_n_half_modifiers).map((item: any) => {
                              return (
                                `${item.modifier_name} * ${item.quantity}, `
                              )
                            }) :
                            (!_.isEmpty(carts.item) && carts.item.is_half_n_half == 1 && !_.isEmpty(carts.item.half_n_half_modifiers)) &&
                            <>
                              {
                                carts.item.half_n_half_modifiers.map((obj: any, index: any) => {
                                  return <div>
                                    {index == 0 ? <p className="mb-0">1st Half </p> : this.flavour2halfReady(carts.item.half_n_half_modifiers) && <p className="mb-0">2nd Half</p>}
                                    {Object.keys(obj.data).map(
                                      (key: any, index) => {
                                        return (
                                          (obj.data[key].is_flavour && obj.data[key].is_flavour == 1) ?
                                            Object.keys(obj.data[key]).map(
                                              (subkey: any, index) => {
                                                return (
                                                  Array.isArray(obj.data[key][subkey]) &&
                                                  obj.data[key][subkey].map(
                                                    (item: any, index: any) => {
                                                      return (
                                                        item.selected === true &&
                                                        <div key={index} className="d-flex justify-content-between px-2">
                                                          <li style={{ paddingLeft: '1rem', fontSize: 13 }}>{item.modifier_name + ' x ' + item.quantity}</li>
                                                          <p style={{ paddingLeft: '1rem', fontSize: 13 }}>{((item.discount_price || item.discount_price == 0)) ?
                                                            <>
                                                              {priceunit + Math.round(item.discount_price) * item.quantity}
                                                            </>
                                                            :
                                                            <>
                                                              {priceunit + item.modifier_sale_price * item.quantity}
                                                            </>
                                                          }</p>
                                                        </div>
                                                      )
                                                    })
                                                );
                                              }
                                            ) : (!obj.data[key].is_flavour && !obj.data[key].is_crust) &&
                                            obj.data[key].map(
                                              (item: any, index: any) => {
                                                return (
                                                  item.selected === true &&
                                                  <div key={index} className="d-flex justify-content-between px-2">
                                                    <li style={{ paddingLeft: '1rem', fontSize: 13 }}>{item.modifier_name + ' x ' + item.quantity}</li>
                                                    <p style={{ paddingLeft: '1rem', fontSize: 13 }}>{((item.discount_price || item.discount_price == 0)) ?
                                                      <>
                                                        {priceunit + Math.round(item.discount_price) * item.quantity}
                                                      </>
                                                      :
                                                      <>
                                                        {priceunit + item.modifier_sale_price * item.quantity}
                                                      </>
                                                    }</p>
                                                  </div>
                                                )
                                              })
                                        )
                                      }
                                    )
                                    }
                                  </div>
                                })
                              }
                            </>
                        }
                        <>
                          {!_.isEmpty(carts.item) && carts.item.make_combo_options && this.checkMakeAComboSelected(carts.item.make_combo_options) && <span className="cart-extra">Make A Combo</span>}

                          {!_.isEmpty(carts.item) && carts.item.make_combo_options && carts.item.make_combo_options.length > 0 &&

                            carts.item.make_combo_options.map((options: any) => {

                              return (

                                options.option.selected === true &&
                                <div>
                                  <span style={{ paddingLeft: '1rem' }}>
                                    {options.option.item_name + ` (${options.option.size})` + ' x ' + options.quantity}
                                  </span>

                                </div>
                              )
                            })}
                        </>
                        <div className="d-flex justify-content-between">
                          <span> Item total</span>
                          <strong>{priceunit + carts.totalItemPrice}</strong>
                        </div>

                        <div className="full-date">
                          <div style={{ display: "flex", width: '9rem', justifyContent: "space-between" }}>
                            <div className="decrement-btn" onClick={() => this.decrementCartQuantity(indexes)}><a className="dec-icon">-</a></div>
                            <div style={{ fontWeight: "bold", fontSize: 18 }}>{carts.quantity}</div>
                            <div className="increment-btn" onClick={() => this.incrementCartQuantity(indexes)}><a className="inc-icon">+</a></div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* <div className="date d-flex flex-column text-right">


                      {(!_.isEmpty(carts.combo) && !_.isEmpty(carts.combo.choices)) &&
                        typeof carts.combo.choices == "string" ?
                        JSON.parse(carts.combo.choices).map((item: any) => {
                          return (
                            <div>
                              {item.extra_price > 0 ?
                                <span>
                                  {priceunit + item.extra_price * item.quantity * carts.quantity}
                                </span>
                                :
                                <span>
                                  {priceunit + item.extra_price * item.quantity}
                                </span>
                              }
                            </div>
                          )

                        })
                        :
                        (!_.isEmpty(carts.combo) && !_.isEmpty(carts.combo.choices)) && typeof carts.combo.choices == "object" &&
                        Object.keys(carts.combo.choices).map(
                          (key: any, index) => {
                            return (
                              carts.combo.choices[key].map(
                                (item: any, index: any) => {
                                  return (
                                    item.selected === true && item.quantity > 0 &&
                                    <div>
                                      <>
                                        {item.extra_price > 0 ?
                                          <span>
                                            {priceunit + item.extra_price * item.quantity * carts.quantity}
                                          </span>
                                          :
                                          <span>
                                            {priceunit + item.extra_price * item.quantity}
                                          </span>
                                        }
                                      </>

                                    </div>
                                  )
                                })
                            );
                          }
                        )
                      }
                      {
                        !_.isEmpty(carts.combo) && typeof carts.combo.modifiers == "string" ?
                          JSON.parse(carts.combo.modifiers).map((item: any) => {
                            return (
                              <div>
                                <span>
                                  {priceunit + item.modifier_sale_price * item.quantity}
                                </span>
                              </div>
                            )
                          }) :
                          (!_.isEmpty(carts.combo) && !_.isEmpty(carts.combo.modifiers)) &&
                          Object.keys(carts.combo.modifiers).map(
                            (key: any, index) => {
                              return (
                                carts.combo.modifiers[key].map(
                                  (item: any, index: any) => {
                                    return (
                                      item.selected === true &&
                                      <div>

                                        <span>
                                          {priceunit + item.modifier_sale_price * item.quantity}

                                        </span>
                                      </div>
                                    )
                                  })
                              );
                            }
                          )
                      }

                      {
                        !_.isEmpty(carts.item) && typeof carts.item.modifiers == "string" ?
                          JSON.parse(carts.item.modifiers).map((item: any) => {
                            return (
                              `${item.modifier_name} * ${item.quantity}, `
                            )
                          }) :
                          (!_.isEmpty(carts.item) && !_.isEmpty(carts.item.modifiers)) &&
                          Object.keys(carts.item.modifiers).map(
                            (key: any, index) => {
                              return (
                                carts.item.modifiers[key].min ?
                                  Object.keys(carts.item.modifiers[key]).map(
                                    (subkey: any, index) => {
                                      return (
                                        Array.isArray(carts.item.modifiers[key][subkey]) &&
                                        carts.item.modifiers[key][subkey].map(
                                          (item: any, index: any) => {
                                            return (
                                              item.selected === true &&
                                              <div>

                                                <span>
                                                  {priceunit + item.modifier_sale_price * item.quantity}

                                                </span>


                                              </div>
                                            )
                                          })
                                      );
                                    }
                                  )
                                  :
                                  carts.item.modifiers[key].map(
                                    (item: any, index: any) => {
                                      return (
                                        item.selected === true &&
                                        <div>

                                          <span>
                                            {priceunit + item.modifier_sale_price * item.quantity}

                                          </span>


                                        </div>
                                      )
                                    })
                              )
                            }
                          )
                      }
                      <>
                        &nbsp;
                        {!_.isEmpty(carts.item) && carts.item.make_combo_options && carts.item.make_combo_options.length > 0 &&

                          carts.item.make_combo_options.map((options: any) => {

                            return (

                              options.option.selected === true &&
                              <div>
                                <span>
                                  {priceunit + options.option.mrp * options.quantity}
                                </span>

                              </div>
                            )
                          })}
                      </>
                      {carts.combo && carts.combo.choices && typeof carts.combo.choices == 'string' ?
                        <strong>{priceunit + carts.totalItemPrice}</strong>

                        :
                        <strong>{priceunit + carts.totalItemPrice}</strong>
                      }
                      {carts.item &&
                        <strong>{priceunit + carts.totalItemPrice}</strong>
                      }



                    </div> */}
                  </div>
                  {carts.kitchen_instruction && carts.kitchen_instruction.length > 0 &&

                    <><h4>Kitchen Instructions   </h4><span> {carts.kitchen_instruction}</span></>
                  }
                </div>
              ))}

              {/* {coupon && !coupon.coupon_id && (
                <div >
                  <div
                    className="item d-flex justify-content-between"
                    // action="#"
                    style={{ paddingTop: "10px", marginBottom: "-1px" }}
                  >
                    <div className="input-group">
                      <input
                        type="text"
                        className="form-control form-control-sm"
                        placeholder="Enter discount code"
                        name="coupanCode"
                        onChange={this.handleChangeCoupan}
                      />
                      <div className="input-group-append">
                        <button
                          id="roundb"
                          className="btn btn-sm btn-primary"
                          style={{
                            backgroundColor: "#c00a27",
                            borderColor: "#c00a27"
                          }}
                          disabled={!this.isCoupanReady()}
                          onClick={() => this.ApplyCoupan()}
                        // type="submit"
                        >
                          Apply Coupon
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              )} */}


              <div className="item d-flex justify-content-between">
                <div className="info d-flex">
                  <div className="title">
                    <h5>Subtotal</h5>
                  </div>
                </div>
                <div className="date text-right">
                  <span>{priceunit}</span>
                  <strong>{this.calcSubTotal(cart)}</strong>

                </div>
              </div>

              {/* {this.props.taxDataCash &&
                <React.Fragment>
                  {this.props.taxDataCash.tax_type == 1 &&
                    <div className="item d-flex justify-content-between">
                      <div className="info d-flex">
                        <div className="title">
                          <h5>Tax: {this.props.taxDataCash.tax_percent}%</h5>
                        </div>
                      </div>
                      <div className="text-right">
                        <span>{priceunit + Math.round(this.calcTotalTax(this.props.CartTotal))} </span>
                      </div>
                    </div>
                  }

                  {this.props.taxDataCash.tax_type == 0 &&
                    <div className="item d-flex justify-content-between">
                      <div className="info d-flex">
                        <div className="title">
                          <h5>Tax: {this.props.taxDataCash.tax_percent}%</h5>
                        </div>
                      </div>
                      <div className="text-right">
                        <span>(Inclusive of Tax {priceunit + (this.calcTotalTax(this.props.CartTotal).toFixed(2))}) </span>
                      </div>
                    </div>
                  }
                </React.Fragment>

              } */}

              {/* {!_.isEmpty(tax) && (
                <div className="item d-flex justify-content-between">
                  <div className="info d-flex">
                    <div className="title">
                      <h5>Tax</h5>
                    </div>
                  </div>
                  <div className="text-right">
                    <span>Inclusive of tax {tax.tax_percent}%</span>
                  </div>
                </div>
              )} */}
              {this.state.orderType == "Delivery" && deliveryfee !== "" && (
                <div className="item d-flex justify-content-between">
                  <div className="info d-flex">
                    <div className="title">
                      <h5>Delivery Fee</h5>
                    </div>
                  </div>
                  <div className="date text-right">
                    <span>{priceunit}</span>
                    <strong>{deliveryfee}</strong>
                  </div>
                </div>
              )}
              {(selectedStore.state_id.pos_service_fee !== "" && selectedStore.state_id.pos_service_fee > 0) && (
                <div className="item d-flex justify-content-between">
                  <div className="info d-flex">
                    <div className="title">
                      <h5>POS Fee</h5>
                    </div>
                  </div>
                  <div className="date text-right">
                    <span>{priceunit}</span>
                    <strong>{selectedStore.state_id.pos_service_fee}</strong>
                  </div>
                </div>
              )}
              {!_.isEmpty(coupon) &&
                // Coupon.charAt(0).toUpperCase() + Coupon.slice(1) ==
                // this.props.orderType && (
                <div className="item d-flex justify-content-between">
                  <div className="info d-flex">
                    <div className="title">
                      <h5>Discount</h5>
                    </div>
                  </div>
                  <div className="date text-right">
                    <span>{priceunit}</span>
                    <strong>{Math.round(this.calcTotalDiscount(this.props.discountAmount))}</strong>
                  </div>
                </div>
                // )
              }
              {/* {this.calculateOrderDiscount(cart) > 0 && */}
              <div className="item d-flex justify-content-between">
                <div className="info d-flex">
                  <div className="title">
                    <h5>Your Discount</h5>
                  </div>
                </div>
                <div className="date text-right">
                  <span>{priceunit}</span>
                  <strong>{this.calculateOrderDiscount(cart)}</strong>
                </div>
              </div>
              {/* } */}
              <div className="item d-flex justify-content-between">
                <div className="info d-flex">
                  <div className="title">
                    <h4>Total</h4>
                  </div>
                </div>
                <div className="date text-right">
                  <span> {priceunit}</span>
                  <strong>{this.calcTotal(cart) + selectedStore.state_id.pos_service_fee}</strong>
                </div>
              </div>


            </div>
          </div>
        );
      } else {
        return (
          <div className="daily-feeds card">
            <div className="card-header">
              <h3 className="h4">Empty Cart</h3>
            </div>
          </div>
        );
      }
    } else {
      return (
        <div className="daily-feeds card">
          <div className="card-header">
            <h3 className="h4">Empty Cart</h3>
          </div>
        </div>
      );
    }
  };
  itemReady = () => {
    let quantityFlag = true;
    let foundModifier = false;
    if (!_.isEmpty(this.state.modifiers)) {
      Object.keys(this.state.modifiers).map(key => {
        let ModifierQuantityCount = 0
        if (quantityFlag) {
          if ((this.state.modifiers[key].is_crust && this.state.modifiers[key].is_crust == 1)) {
            if (this.state.modifiers[key].min > 0) {
              Object.keys(this.state.modifiers[key]).map((subkey: any, index: any) => {
                if (quantityFlag) {
                  if (Array.isArray(this.state.modifiers[key][subkey])) {
                    for (let j = 0; j < this.state.modifiers[key][subkey].length; j++) {
                      if (this.state.modifiers[key][subkey][j].selected && this.state.modifiers[key][subkey][j].quantity > 0) {
                        ModifierQuantityCount += this.state.modifiers[key][subkey][j].quantity;
                      }
                    }
                    if (index == Object.keys(this.state.modifiers[key]).length - 1) {
                      if (ModifierQuantityCount < this.state.modifiers[key].min) {
                        if (!foundModifier) {
                          // toast.warn("Please select max quantity for required option in " + this.state.modifiers[key][i].mod_group_name, { position: toast.POSITION.TOP_CENTER, hideProgressBar: true, autoClose: 3000 })
                          foundModifier = true;
                          quantityFlag = false;
                          // break;
                        }
                      }
                      else {
                        quantityFlag = true;
                      }
                    }
                  }
                }
              })
            }
          } else {
            for (let i = 0; i < this.state.modifiers[key].length; i++) {
              if (this.state.modifiers[key][i].modGroup_min_quantity > 0) {
                if (this.state.modifiers[key][i].selected && this.state.modifiers[key][i].quantity > 0) {
                  ModifierQuantityCount += this.state.modifiers[key][i].quantity;
                }
                if (i == this.state.modifiers[key].length - 1) {
                  if (ModifierQuantityCount < this.state.modifiers[key][i].modGroup_min_quantity) {
                    if (!foundModifier) {
                      // toast.warn("Please select max quantity for required option in " + modifiers[key][i].mod_group_name, { position: toast.POSITION.TOP_CENTER, hideProgressBar: true, autoClose: 3000 })
                      foundModifier = true;
                      quantityFlag = false;
                      break;
                    }
                  }
                  else {
                    quantityFlag = true;
                  }
                }
              }
            }
          }
        }
      })
    }
    else {
      quantityFlag = true;
    }
    return quantityFlag
  }
  halfNHalfItemReady = () => {
    //For only flavours and Toppings
    let quantityFlag = true;
    let foundModifier = false;
    if (!_.isEmpty(this.state.halfNHalfModifiers[0].data)) {
      Object.keys(this.state.halfNHalfModifiers[0].data).map(key => {
        let ModifierQuantityCount = 0
        if (quantityFlag) {
          if ((this.state.halfNHalfModifiers[0].data[key].is_flavour && this.state.halfNHalfModifiers[0].data[key].is_flavour == 1)) {
            if (this.state.halfNHalfModifiers[0].data[key].min > 0) {
              Object.keys(this.state.halfNHalfModifiers[0].data[key]).map((subkey: any, index: any) => {
                if (quantityFlag) {
                  if (Array.isArray(this.state.halfNHalfModifiers[0].data[key][subkey])) {
                    for (let j = 0; j < this.state.halfNHalfModifiers[0].data[key][subkey].length; j++) {
                      if (this.state.halfNHalfModifiers[0].data[key][subkey][j].selected && this.state.halfNHalfModifiers[0].data[key][subkey][j].quantity > 0) {
                        ModifierQuantityCount += this.state.halfNHalfModifiers[0].data[key][subkey][j].quantity;
                      }
                    }
                    if (index == Object.keys(this.state.halfNHalfModifiers[0].data[key]).length - 1) {
                      if (ModifierQuantityCount < this.state.halfNHalfModifiers[0].data[key].min) {
                        if (!foundModifier) {
                          // toast.warn("Please select max quantity for required option in " + this.state.halfNHalfModifiers[0].data[key][i].mod_group_name, { position: toast.POSITION.BOTTOM_RIGHT, hideProgressBar: true, autoClose: 3000 })
                          foundModifier = true;
                          quantityFlag = false;
                          // break;
                        }
                      }
                      else {
                        quantityFlag = true;
                      }
                    }
                  }
                }
              })
            }
          } else {
            for (let i = 0; i < this.state.halfNHalfModifiers[0].data[key].length; i++) {
              if (this.state.halfNHalfModifiers[0].data[key][i].modGroup_min_quantity > 0) {
                if (this.state.halfNHalfModifiers[0].data[key][i].selected && this.state.halfNHalfModifiers[0].data[key][i].quantity > 0) {
                  ModifierQuantityCount += this.state.halfNHalfModifiers[0].data[key][i].quantity;
                }
                if (i == this.state.halfNHalfModifiers[0].data[key].length - 1) {
                  if (ModifierQuantityCount < this.state.halfNHalfModifiers[0].data[key][i].modGroup_min_quantity) {
                    if (!foundModifier) {
                      // toast.warn("Please select max quantity for required option in " + modifiers[key][i].mod_group_name, { position: toast.POSITION.BOTTOM_RIGHT, hideProgressBar: true, autoClose: 3000 })
                      foundModifier = true;
                      quantityFlag = false;
                      break;
                    }
                  }
                  else {
                    quantityFlag = true;
                  }
                }
              }
            }
          }
        }
      })
    }
    else {
      quantityFlag = true;
    }
    return quantityFlag
  }
  secHalfNHalfItemReady = () => {
    //For only flavours and Toppings
    let quantityFlag = true;
    let foundModifier = false;
    if (!_.isEmpty(this.state.halfNHalfModifiers[1].data)) {
      Object.keys(this.state.halfNHalfModifiers[1].data).map(key => {
        let ModifierQuantityCount = 0
        if (quantityFlag) {
          if ((this.state.halfNHalfModifiers[1].data[key].is_flavour && this.state.halfNHalfModifiers[1].data[key].is_flavour == 1)) {
            if (this.state.halfNHalfModifiers[1].data[key].min > 0) {
              Object.keys(this.state.halfNHalfModifiers[1].data[key]).map((subkey: any, index: any) => {
                if (quantityFlag) {
                  if (Array.isArray(this.state.halfNHalfModifiers[1].data[key][subkey])) {
                    for (let j = 0; j < this.state.halfNHalfModifiers[1].data[key][subkey].length; j++) {
                      if (this.state.halfNHalfModifiers[1].data[key][subkey][j].selected && this.state.halfNHalfModifiers[1].data[key][subkey][j].quantity > 0) {
                        ModifierQuantityCount += this.state.halfNHalfModifiers[1].data[key][subkey][j].quantity;
                      }
                    }
                    if (index == Object.keys(this.state.halfNHalfModifiers[1].data[key]).length - 1) {
                      if (ModifierQuantityCount < this.state.halfNHalfModifiers[1].data[key].min) {
                        if (!foundModifier) {
                          // toast.warn("Please select max quantity for required option in " + this.state.halfNHalfModifiers[1].data[key][i].mod_group_name, { position: toast.POSITION.BOTTOM_RIGHT, hideProgressBar: true, autoClose: 3000 })
                          foundModifier = true;
                          quantityFlag = false;
                          // break;
                        }
                      }
                      else {
                        quantityFlag = true;
                      }
                    }
                  }
                }
              })
            }
          } else {
            for (let i = 0; i < this.state.halfNHalfModifiers[1].data[key].length; i++) {
              if (this.state.halfNHalfModifiers[1].data[key][i].modGroup_min_quantity > 0) {
                if (this.state.halfNHalfModifiers[1].data[key][i].selected && this.state.halfNHalfModifiers[1].data[key][i].quantity > 0) {
                  ModifierQuantityCount += this.state.halfNHalfModifiers[1].data[key][i].quantity;
                }
                if (i == this.state.halfNHalfModifiers[1].data[key].length - 1) {
                  if (ModifierQuantityCount < this.state.halfNHalfModifiers[1].data[key][i].modGroup_min_quantity) {
                    if (!foundModifier) {
                      // toast.warn("Please select max quantity for required option in " + modifiers[key][i].mod_group_name, { position: toast.POSITION.BOTTOM_RIGHT, hideProgressBar: true, autoClose: 3000 })
                      foundModifier = true;
                      quantityFlag = false;
                      break;
                    }
                  }
                  else {
                    quantityFlag = true;
                  }
                }
              }
            }
          }
        }
      })
    }
    else {
      quantityFlag = true;
    }
    return quantityFlag
  }
  checkOptions = (comboDetails: any) => {
    if (comboDetails && comboDetails.length > 0) {
      let tabReady = true;
      for (let i = 0; i < comboDetails.length; i++) {
        let choiceReady = this.choiceTabItemReady(i);
        if (!choiceReady) {
          tabReady = false;
        }
      }
      return tabReady;
    }
  }
  handleAdd = async (item: any) => {
    this.setState({ isAddButtonDisable: true })
    this.setState({ isCartButtonDisable: true })
    let cart: any = [];
    let { itemData, selectedStore } = this.props;
    if (!_.isEmpty(item)) {
      if (item.menu_item_id) {
        let itemReadyFlag = this.itemReady();
        if (itemReadyFlag) {
          if (itemData.is_half_n_half == 1) {
            //For halfNhalf Pizza Add to cart
            let halfItemReady = this.halfNHalfItemReady();
            if (halfItemReady) {
              let secHalfReady = this.secHalfNHalfItemReady();
              if (secHalfReady) {
                this.props.getCart();
                let data: any = this.props.cart;

                if (!_.isEmpty(data)) {
                  cart = JSON.parse(data);
                }

                let { make_combos } = this.state
                if (make_combos && make_combos.length > 0) {
                  Object.assign(item, { make_combo_options: make_combos })
                }

                this.setState({ prevMakeComboPrice: 0 })

                let discounted_item: any = false;
                let sizearray: any = JSON.parse(item.item_size);
                if (this.state.selectedsize.discount_price && (this.state.selectedsize.order_channel == "callcenter")) {
                  discounted_item = true;
                } else {
                  discounted_item = false;
                }
                //     //if Item is menu_item
                if (!_.isEmpty(this.props.coupon) && discounted_item) {
                  toast.error(`You cannot add to cart discounted items with applied coupon`, {
                    position: toast.POSITION.TOP_CENTER,
                    hideProgressBar: true,
                    autoClose: 3000
                  });
                } else {
                  const itemcart = {
                    item: item,
                    quantity: 1,
                    selectedsize: this.state.selectedsize,
                    price: Math.round(this.state.initialPrice),
                    totalItemPrice: Math.round(this.state.itemPrice),
                    kitchen_instruction: this.state.kitchen_inst,
                  };

                  cart.push(itemcart);
                }

                this.props.saveCart(cart);
                this.props.getCart();
                if (item.menu_item_id) {
                  this.props.getItemDetail(selectedStore.store_id, itemData.menu_item_id);
                  this.setState({
                    itemPrice: ((JSON.parse(itemData.item_size)[0].discount_price || JSON.parse(itemData.item_size)[0].discount_price == 0) && (itemData.order_channel == 'callcenter')) ? Math.round(JSON.parse(itemData.item_size)[0].discount_price) : Math.round(JSON.parse(itemData.item_size)[0].mrp),
                  })
                }
                this.state.make_combos.map((options: any) => {
                  options.quantity = 0
                  options.option.selected = false
                })
                this.setState({ active_key: "1stHalf" })
                toast.error(
                  `${(item.item_name && item.item_name) ||
                  (item.combo_name && item.combo_name)
                  } added to cart`,
                  {
                    position: toast.POSITION.TOP_CENTER,
                    hideProgressBar: true,
                    autoClose: 3000
                  }
                );
              } else {
                toast.error(`Please select a 2nd flavour`, { position: toast.POSITION.BOTTOM_RIGHT, hideProgressBar: true, autoClose: 3000 });
              }
            } else {
              toast.error(`Please select a first flavour`, { position: toast.POSITION.BOTTOM_RIGHT, hideProgressBar: true, autoClose: 3000 });
            }
          } else {
            //For Normal Item Add to cart
            this.props.getCart();
            let data: any = this.props.cart;

            if (!_.isEmpty(data)) {
              cart = JSON.parse(data);
            }

            let { make_combos } = this.state
            if (make_combos && make_combos.length > 0) {
              Object.assign(item, { make_combo_options: make_combos })
            }

            this.setState({ prevMakeComboPrice: 0 })

            let discounted_item: any = false;
            let sizearray: any = JSON.parse(item.item_size);
            if (this.state.selectedsize.discount_price && (this.state.selectedsize.order_channel == "callcenter")) {
              discounted_item = true;
            } else {
              discounted_item = false;
            }
            //     //if Item is menu_item
            if (!_.isEmpty(this.props.coupon) && discounted_item) {
              toast.error(`You cannot add to cart discounted items with applied coupon`, {
                position: toast.POSITION.TOP_CENTER,
                hideProgressBar: true,
                autoClose: 3000
              });
            } else {
              //     //if Item is menu_item
              if (!_.isEmpty(cart)) {
                let found: any = "";
                let modifier_comparison = true;
                let make_a_combo_comparison: any = true;
                cart.forEach((element: any) => {
                  if (element["item"]) {
                    if (element["item"].menu_item_id == item.menu_item_id) {
                      if (this.state.selectedsize.size == element.selectedsize.size) {
                        let cartmodifiers = element['item'].modifiers
                        let cartMakeCombo = element['item'].make_combo_options;
                        if (cartmodifiers !== undefined) {
                          Object.keys(cartmodifiers).map(key => {
                            if (cartmodifiers[key].min) {
                              Object.keys(cartmodifiers[key]).map(subkey => {
                                Array.isArray(cartmodifiers[key][subkey]) &&
                                  cartmodifiers[key][subkey].map((obj: any, index: any) => {
                                    Object.keys(this.state.modifiers).map(key => {
                                      if (this.state.modifiers[key].min) {
                                        Object.keys(this.state.modifiers[key]).map(modsubkey => {
                                          if (Array.isArray(this.state.modifiers[key][modsubkey])) {
                                            this.state.modifiers[key][modsubkey].map((item: any, index: any) => {
                                              if (obj.modifier_name == item.modifier_name) {
                                                if (obj.selected == item.selected) {
                                                  return
                                                }
                                                else {
                                                  return modifier_comparison = false;
                                                }
                                              }
                                            });
                                          }
                                        });
                                      }
                                    });
                                  });
                              });
                            } else {
                              cartmodifiers[key].map((obj: any, index: any) => {
                                Object.keys(this.state.modifiers).map(key => {
                                  if (!this.state.modifiers[key].min) {
                                    this.state.modifiers[key].map((item: any, index: any) => {
                                      if (obj.modifier_name == item.modifier_name) {
                                        if (obj.selected == item.selected) {
                                          return
                                        }
                                        else {
                                          return modifier_comparison = false;
                                        }
                                      }
                                    });
                                  }
                                });
                              })
                            }
                          });
                          if (cartMakeCombo != undefined) {
                            cartMakeCombo && cartMakeCombo.map((cartItem: any, index: any) => {
                              this.state.make_combos.map((item: any, index: any) => {
                                if (cartItem.option.option_id == item.option.option_id) {
                                  if (cartItem.option.selected == item.option.selected) {
                                    // if (cartItem.option.selected == item.option.selected && cartItem.quantity == item.quantity) {
                                    return
                                  }
                                  else {
                                    return make_a_combo_comparison = false;
                                  }
                                }
                              });
                            });
                          }
                        }
                        if (modifier_comparison && make_a_combo_comparison) {
                          Object.keys(cartmodifiers).map(key => {
                            if (cartmodifiers[key].min) {
                              Object.keys(cartmodifiers[key]).map(subkey => {
                                Array.isArray(cartmodifiers[key][subkey]) &&
                                  cartmodifiers[key][subkey].map((obj: any, index: any) => {
                                    Object.keys(this.state.modifiers).map(key => {
                                      if (this.state.modifiers[key].min) {
                                        Object.keys(this.state.modifiers[key]).map(modsubkey => {
                                          Array.isArray(this.state.modifiers[key][modsubkey]) &&
                                            this.state.modifiers[key][modsubkey].map((item: any, index: any) => {
                                              if (obj.selected == item.selected) {
                                                obj.quantity += item.quantity;
                                              }
                                            });
                                        });
                                      }
                                    });
                                  });
                              });
                            } else {
                              cartmodifiers[key].map((obj: any, index: any) => {
                                Object.keys(this.state.modifiers).map(key => {
                                  if (!this.state.modifiers[key].min) {
                                    this.state.modifiers[key].map((item: any, index: any) => {
                                      if (obj.modifier_name == item.modifier_name) {
                                        if (obj.selected == item.selected) {
                                          obj.quantity += item.quantity;
                                        }
                                      }
                                    });
                                  }
                                });
                              })
                            }
                          });
                          cartMakeCombo && cartMakeCombo.map((cartItem: any, index: any) => {
                            this.state.make_combos.map((item: any, index: any) => {
                              if (cartItem.option.option_id == item.option.option_id) {
                                if (cartItem.option.selected == item.option.selected) {
                                  cartItem.quantity += 1;
                                }
                              }
                            })
                          });
                          element.quantity = element.quantity + 1;
                          element.totalItemPrice = this.state.itemPrice + element.totalItemPrice;
                          return (found = 1);
                        }
                      }
                    }
                  }
                });
                if (found == "") {
                  const itemcart = {
                    item: item,
                    quantity: 1,
                    selectedsize: this.state.selectedsize,
                    price: Math.round(this.state.initialPrice),
                    totalItemPrice: Math.round(this.state.itemPrice),
                    kitchen_instruction: this.state.kitchen_inst,
                  };
                  cart.push(itemcart);
                }
              } else {
                const itemcart = {
                  item: item,
                  quantity: 1,
                  selectedsize: this.state.selectedsize,
                  price: Math.round(this.state.initialPrice),
                  totalItemPrice: Math.round(this.state.itemPrice),
                  kitchen_instruction: this.state.kitchen_inst,
                };

                cart.push(itemcart);
              }
            }


            this.props.saveCart(cart);
            this.props.getCart();
            if (item.menu_item_id) {
              this.props.getItemDetail(selectedStore.store_id, itemData.menu_item_id);
              this.setState({
                itemPrice: ((JSON.parse(itemData.item_size)[0].discount_price || JSON.parse(itemData.item_size)[0].discount_price == 0) && (itemData.order_channel == 'callcenter')) ? Math.round(JSON.parse(itemData.item_size)[0].discount_price) : Math.round(JSON.parse(itemData.item_size)[0].mrp),
              })
            }
            this.state.make_combos.map((options: any) => {
              options.quantity = 0
              options.option.selected = false
            })
            toast.error(
              `${(item.item_name && item.item_name) ||
              (item.combo_name && item.combo_name)
              } added to cart`,
              {
                position: toast.POSITION.TOP_CENTER,
                hideProgressBar: true,
                autoClose: 3000
              }
            );
          }
        } else {
          toast.error(`Please select required options`, { position: toast.POSITION.BOTTOM_RIGHT, hideProgressBar: true, autoClose: 3000 });
        }
      } else if (item.combo_id) {
        let comboReady = this.checkOptions(this.state.comboDetails);
        if (comboReady) {
          this.props.getCart();
          let data: any = this.props.cart;

          if (!_.isEmpty(data)) {
            cart = JSON.parse(data);
          }

          // if Item is combo item
          let discounted_item: any = false;
          let modifier_comparison = true;
          let choices_comparison = true;
          if (item.discount_price && item.order_channel == "callcenter") {
            discounted_item = true;
          } else {
            discounted_item = false;
          }
          if (!_.isEmpty(this.props.coupon) && discounted_item) {
            toast.error(`You cannot add to cart discounted items with applied coupon`, {
              position: toast.POSITION.TOP_CENTER,
              hideProgressBar: true,
              autoClose: 3000
            });
          } else {
            const itemcart = {
              combo: item,
              quantity: 1,
              price: Math.round(this.state.netPrice),
              totalItemPrice: Math.round(this.state.itemPrice),
              image: item.images.length > 0 ? item.images[0].image_url : ''
            };
            cart.push(itemcart);
          }
          this.props.saveCart(cart);
          this.props.getCart();
          if (item.combo_id) {
            this.props.getComboDetail(selectedStore.store_id, itemData.combo_id);
            this.setState({
              itemPrice: ((itemData.discount_price || itemData.discount_price == 0) && (itemData.order_channel == 'callcenter')) ? Math.round(itemData.discount_price) : Math.round(itemData.combo_mrp_price),
            })
          }
          this.setState({ choice_active_key: "choice01stHalf", combo_active_key: "0" })
          toast.error(
            `${(item.combo_name && item.combo_name)
            } added to cart`,
            {
              position: toast.POSITION.TOP_CENTER,
              hideProgressBar: true,
              autoClose: 3000
            }
          );
        } else {
          toast.error(`Please select all required options`, { position: toast.POSITION.BOTTOM_RIGHT, hideProgressBar: true, autoClose: 3000 });
        }
      }
    }
    setTimeout(() => {
      this.setState({ isAddButtonDisable: false })
      this.setState({ isCartButtonDisable: false })
    }, 1000);
  };


  HoursArray(hourDiff: any, starttime: any) {
    if (String(hourDiff).indexOf('-') !== -1) {
      hourDiff = hourDiff + 24;
    }
    var hours: any = [];
    let hour = starttime;
    for (let i = 0; i <= hourDiff; i++) {
      hour = starttime + i;
      let AmOrPm = '';
      hour >= 12 && hour < 24 ? (AmOrPm = 'pm') : (AmOrPm = 'am');
      hour = hour % 12 || 12;
      hours.push(hour + AmOrPm);
    }
    var string = hours.join(':');
    return string;
  };


  compareOpenClosingTime = (storeSelected: any) => {
    this.props.getTimezone(storeSelected.store_id).then((serverTime: any) => {
      let today = new Date();
      //convert the current time 24 hour to 12 hour
      // let CurrentHours: any = today.getHours();
      // let currentMinutes: any = today.getMinutes();
      let CurrentHours: any = serverTime.split(":")[0];
      let currentMinutes: any = serverTime.split(":")[1];
      let CurrentAmOrPm = CurrentHours >= 12 ? 'pm' : 'am';
      CurrentHours = CurrentHours % 12 || 12;
      let Currenttime: any = CurrentHours + '' + CurrentAmOrPm;
      //define hours or minutes
      var timeStartMin = new Date(
        '01/01/2007 ' + storeSelected.store_open_time,
      ).getMinutes();
      var timeEndMin = new Date(
        '01/01/2007 ' + storeSelected.store_close_time,
      ).getMinutes();
      var timeStartHours = new Date(
        '01/01/2007 ' + storeSelected.store_open_time,
      ).getHours();
      var timeEndHours = new Date(
        '01/01/2007 ' + storeSelected.store_close_time,
      ).getHours();
      var hourDiff: any = timeEndHours - timeStartHours;
      let HourString = this.HoursArray(hourDiff, timeStartHours);
      let splitHourArray = HourString.split(":")

      if (splitHourArray.indexOf(Currenttime) !== -1) { //if Pick Hour with am/pm is exist in HourString
        if (timeStartMin == 0 && timeEndMin == 0) {  //if store opening minutes and closing minutes are 00
          if (splitHourArray.length - 1 == splitHourArray.indexOf(Currenttime)) {
            //if Pick Hour with am/pm is exist in HourString at last index
            this.setState({ storeOpenFlag: false })
            toast.error("Sorry! Store is closed at this moment");
          } else {
            this.setState({ storeOpenFlag: true })
            this.props.saveStore(storeSelected);
            // result ? this.props.getTax(result.state_id) : this.props.getTax();
            this.props.saveSelectStoreId(storeSelected, this.state.orderType)
          }
        } else {
          if (timeStartMin == 0 || timeEndMin == 0) {  //if store opening or closing minute is 00
            if (splitHourArray.length - 1 == splitHourArray.indexOf(Currenttime)) {
              if (currentMinutes < timeEndMin) {
                this.setState({ storeOpenFlag: true })
                this.props.saveStore(storeSelected);
                // result ? this.props.getTax(result.state_id) : this.props.getTax();
                this.props.saveSelectStoreId(storeSelected, this.state.orderType)
              } else {
                this.setState({ storeOpenFlag: false })
                toast.error("Sorry! Store is closed at this moment");
              }
            } else {
              this.setState({ storeOpenFlag: true })
              this.props.saveStore(storeSelected);
              // result ? this.props.getTax(result.state_id) : this.props.getTax();
              this.props.saveSelectStoreId(storeSelected, this.state.orderType)
            }
          } else { //if store opening or closing minute is not 00
            if (
              currentMinutes > timeStartMin ||
              currentMinutes < timeEndMin
            ) {
              this.setState({ storeOpenFlag: true })
              this.props.saveStore(storeSelected);
              // result ? this.props.getTax(result.state_id) : this.props.getTax();
              this.props.saveSelectStoreId(storeSelected, this.state.orderType)
            } else {
              this.setState({ storeOpenFlag: false })
              toast.error("Sorry! Store is closed at this moment");
            }
          }
        }
      } else {
        this.setState({ storeOpenFlag: false })
        toast.error("Sorry! Store is closed at this moment");
      }
    })

  }
  compareTimingbyEachDay = (storeSelected: any) => {
    this.props.getTimezoneForLater(storeSelected.store_id).then((serverTime: any) => {
      let dateIndexServer = serverTime.date.indexOf("T")
      let serverDate = serverTime.date.slice(0, dateIndexServer)

      let days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
      let weekDaysTimingArr = JSON.parse(storeSelected.daysTiming)
      let todayDay = new Date(serverDate).getDay();
      let dayName = days[todayDay];
      // let dayName = days[dayName]
      weekDaysTimingArr.map((storeDay: any) => {
        if (dayName == storeDay.day) {
          let today = new Date();
          //convert the current time 24 hour to 12 hour
          // let CurrentHours: any = today.getHours();
          // let currentMinutes: any = today.getMinutes();
          let CurrentHours: any = serverTime.time.split(":")[0]
          let currentMinutes: any = serverTime.time.split(":")[1]
          let CurrentAmOrPm = CurrentHours >= 12 ? 'pm' : 'am';
          CurrentHours = CurrentHours % 12 || 12;
          let Currenttime: any = CurrentHours + '' + CurrentAmOrPm;
          //define hours or minutes
          var timeStartMin = new Date(
            '01/01/2007 ' + storeDay.opening,
          ).getMinutes();
          var timeEndMin = new Date(
            '01/01/2007 ' + storeDay.closing,
          ).getMinutes();
          var timeStartHours = new Date(
            '01/01/2007 ' + storeDay.opening,
          ).getHours();
          var timeEndHours = new Date(
            '01/01/2007 ' + storeDay.closing,
          ).getHours();
          var hourDiff: any = timeEndHours - timeStartHours;
          let HourString = this.HoursArray(hourDiff, timeStartHours);
          let splitHourArray = HourString.split(":")

          if (splitHourArray.indexOf(Currenttime) !== -1) { //if Pick Hour with am/pm is exist in HourString
            if (timeStartMin == 0 && timeEndMin == 0) {  //if store opening minutes and closing minutes are 00
              if (splitHourArray.length - 1 == splitHourArray.indexOf(Currenttime)) {
                //if Pick Hour with am/pm is exist in HourString at last index
                toast.error("Sorry! Store is closed at this moment");
                this.setState({ storeOpenFlag: false })
              } else {
                this.setState({ storeOpenFlag: true })
                this.props.saveStore(storeSelected);
                // result ? this.props.getTax(result.state_id) : this.props.getTax();
                this.props.saveSelectStoreId(storeSelected, this.state.orderType)
              }
            } else {
              if (timeStartMin == 0 || timeEndMin == 0) {  //if store opening or closing minute is 00
                if (splitHourArray.length - 1 == splitHourArray.indexOf(Currenttime)) {
                  if (currentMinutes < timeEndMin) {
                    this.setState({ storeOpenFlag: true })
                    this.props.saveStore(storeSelected);
                    // result ? this.props.getTax(result.state_id) : this.props.getTax();
                    this.props.saveSelectStoreId(storeSelected, this.state.orderType)
                  } else {
                    toast.error("Sorry! Store is closed at this moment");
                    this.setState({ storeOpenFlag: false })
                  }
                } else {
                  this.setState({ storeOpenFlag: true })
                  this.props.saveStore(storeSelected);
                  // result ? this.props.getTax(result.state_id) : this.props.getTax();
                  this.props.saveSelectStoreId(storeSelected, this.state.orderType)
                }
              } else { //if store opening or closing minute is not 00
                if (
                  currentMinutes > timeStartMin ||
                  currentMinutes < timeEndMin
                ) {
                  this.setState({ storeOpenFlag: true })
                  this.props.saveStore(storeSelected);
                  // result ? this.props.getTax(result.state_id) : this.props.getTax();
                  this.props.saveSelectStoreId(storeSelected, this.state.orderType)
                } else {
                  toast.error("Sorry! Store is closed at this moment");
                  this.setState({ storeOpenFlag: false })
                }
              }
            }
          } else {
            toast.error("Sorry! Store is closed at this moment");
            this.setState({ storeOpenFlag: false })
          }
          return;
        }
      })
    })


  }

  compareLaterOpenClosingTime = (storeSelected: any) => {
    this.props.getTimezoneForLater(storeSelected.store_id).then((serverTime: any) => {
      let dateIndexServer = serverTime.date.indexOf("T")
      let serverDate = serverTime.date.slice(0, dateIndexServer)
      let dateIndexClient = this.state.laterdatetime.indexOf("T")
      let clientDate = this.state.laterdatetime.slice(0, dateIndexClient)
      let todayValidDate: any = new Date();
      let t = todayValidDate.getDate()

      let validDate = parseInt(t) == parseInt(serverDate.split("-")[2]) && serverDate.split("-")[0] == clientDate.split("-")[0] && serverDate.split("-")[1] == clientDate.split("-")[1] && (parseInt(serverDate.split("-")[2]) == clientDate.split("-")[2] || parseInt(serverDate.split("-")[2]) + 1 == clientDate.split("-")[2])
      // console.log('this.state.laterdatetime', this.state.laterdatetime.getHours());

      if (validDate) {

        let userSystemTime = this.state.laterdatetime.split("T")[1]
        let server_time = moment(serverTime.time, "HH:mm:ss");
        let client_time = moment(userSystemTime, "HH:mm:ss");
        let addedTime: any = null;
        let today = new Date();
        let todayHours = today.getHours()
        let todayMinutes = today.getMinutes()


        if (todayHours != parseInt(serverTime.time.split(":")[0])) {

          toast.error("Your device date or time is invalid!");
          this.setState({ netWork: false })
          return
        }

        let laterdatetime = new Date(this.state.laterdatetime);
        let futureHours: any = laterdatetime.getHours();
        let futureMinutes: any = laterdatetime.getMinutes();
        let futureAmOrPm = futureHours >= 12 ? 'pm' : 'am';
        futureHours = futureHours % 12 || 12;
        let futuretime: any = futureHours + '' + futureAmOrPm;
        //define hours or minutes
        var timeStartMin = new Date(
          '01/01/2007 ' + storeSelected.store_open_time,
        ).getMinutes();
        var timeEndMin = new Date(
          '01/01/2007 ' + storeSelected.store_close_time,
        ).getMinutes();
        var timeStartHours = new Date(
          '01/01/2007 ' + storeSelected.store_open_time,
        ).getHours();
        var timeEndHours = new Date(
          '01/01/2007 ' + storeSelected.store_close_time,
        ).getHours();
        var hourDiff: any = timeEndHours - timeStartHours;
        let HourString = this.HoursArray(hourDiff, timeStartHours);
        let splitHourArray = HourString.split(":")
        if (splitHourArray.indexOf(futuretime) !== -1) { //if Pick Hour with am/pm is exist in HourString
          if (timeStartMin == 0 && timeEndMin == 0) {  //if store opening minutes and closing minutes are 00
            if (splitHourArray.length - 1 == splitHourArray.indexOf(futuretime)) {
              //if Pick Hour with am/pm is exist in HourString at last index
              toast.error("Sorry! Store is closed at this moment");
            } else {
              this.setState({ storeOpenFlag: true })
              this.props.saveStore(storeSelected);
              // result ? this.props.getTax(result.state_id) : this.props.getTax();
              this.props.saveSelectStoreId(storeSelected, this.state.orderType)
            }
          } else {
            if (timeStartMin == 0 || timeEndMin == 0) {  //if store opening or closing minute is 00
              if (splitHourArray.length - 1 == splitHourArray.indexOf(futuretime)) {
                if (futureMinutes < timeEndMin) {
                  this.setState({ storeOpenFlag: true })
                  this.props.saveStore(storeSelected);
                  // result ? this.props.getTax(result.state_id) : this.props.getTax();
                  this.props.saveSelectStoreId(storeSelected, this.state.orderType)
                } else {
                  toast.error("Sorry! Store is closed at this moment");
                }
              } else {
                this.setState({ storeOpenFlag: true })
                this.props.saveStore(storeSelected);
                // result ? this.props.getTax(result.state_id) : this.props.getTax();
                this.props.saveSelectStoreId(storeSelected, this.state.orderType)
              }
            } else { //if store opening or closing minute is not 00
              if (
                futureMinutes > timeStartMin ||
                futureMinutes < timeEndMin
              ) {
                this.setState({ storeOpenFlag: true })
                this.props.saveStore(storeSelected);
                // result ? this.props.getTax(result.state_id) : this.props.getTax();
                this.props.saveSelectStoreId(storeSelected, this.state.orderType)
              } else {
                toast.error("Sorry! Store is closed at this moment");
              }
            }
          }
        } else {
          toast.error("Sorry! Store is closed at this moment");
        }
      } else {
        toast.error("Your device date or time is invalid!");
        this.setState({ netWork: false })
      }


    })

  }

  compareLaterTimingbyEachDay = (storeSelected: any) => {
    this.props.getTimezoneForLater(storeSelected.store_id).then((serverTime: any) => {
      let dateIndexServer = serverTime.date.indexOf("T")
      let serverDate = serverTime.date.slice(0, dateIndexServer)
      let dateIndexClient = this.state.laterdatetime.indexOf("T")
      let clientDate = this.state.laterdatetime.slice(0, dateIndexClient)
      let todayValidDate: any = new Date();
      let t = todayValidDate.getDate()

      let validDate = parseInt(t) == parseInt(serverDate.split("-")[2]) && serverDate.split("-")[0] == clientDate.split("-")[0] && serverDate.split("-")[1] == clientDate.split("-")[1] && (parseInt(serverDate.split("-")[2]) == clientDate.split("-")[2] || parseInt(serverDate.split("-")[2]) + 1 == clientDate.split("-")[2])
      // console.log('this.state.laterdatetime', this.state.laterdatetime.getHours());

      if (validDate) {

        let userSystemTime = this.state.laterdatetime.split("T")[1]
        let server_time = moment(serverTime.time, "HH:mm:ss");
        let client_time = moment(userSystemTime, "HH:mm:ss");
        let addedTime: any = null;
        let today = new Date();
        let todayHours = today.getHours()
        let todayMinutes = today.getMinutes()


        if (todayHours != parseInt(serverTime.time.split(":")[0])) {

          toast.error("Your device date or time is invalid!");
          this.setState({ netWork: false })
          return
        }
        let days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
        let weekDaysTimingArr = JSON.parse(storeSelected.daysTiming)
        let futureDay = new Date(this.state.laterdatetime).getDay();
        let dayName = days[futureDay];
        weekDaysTimingArr.map((storeDay: any) => {
          if (dayName == storeDay.day) {
            let laterdatetime = new Date(this.state.laterdatetime);
            let futureHours: any = laterdatetime.getHours();
            let futureMinutes: any = laterdatetime.getMinutes();
            let futureAmOrPm = futureHours >= 12 ? 'pm' : 'am';
            futureHours = futureHours % 12 || 12;
            let futuretime: any = futureHours + '' + futureAmOrPm;
            //define hours or minutes
            var timeStartMin = new Date(
              '01/01/2007 ' + storeDay.opening,
            ).getMinutes();
            var timeEndMin = new Date(
              '01/01/2007 ' + storeDay.closing,
            ).getMinutes();
            var timeStartHours = new Date(
              '01/01/2007 ' + storeDay.opening,
            ).getHours();
            var timeEndHours = new Date(
              '01/01/2007 ' + storeDay.closing,
            ).getHours();
            var hourDiff: any = timeEndHours - timeStartHours;
            let HourString = this.HoursArray(hourDiff, timeStartHours);
            let splitHourArray = HourString.split(":")
            if (splitHourArray.indexOf(futuretime) !== -1) { //if Pick Hour with am/pm is exist in HourString
              if (timeStartMin == 0 && timeEndMin == 0) {  //if store opening minutes and closing minutes are 00
                if (splitHourArray.length - 1 == splitHourArray.indexOf(futuretime)) {
                  //if Pick Hour with am/pm is exist in HourString at last index
                  toast.error("Sorry! Store is closed at this moment");
                } else {
                  this.setState({ storeOpenFlag: true })
                  this.props.saveStore(storeSelected);
                  // result ? this.props.getTax(result.state_id) : this.props.getTax();
                  this.props.saveSelectStoreId(storeSelected, this.state.orderType)
                }
              } else {
                if (timeStartMin == 0 || timeEndMin == 0) {  //if store opening or closing minute is 00
                  if (splitHourArray.length - 1 == splitHourArray.indexOf(futuretime)) {
                    if (futureMinutes < timeEndMin) {
                      this.setState({ storeOpenFlag: true })
                      this.props.saveStore(storeSelected);
                      // result ? this.props.getTax(result.state_id) : this.props.getTax();
                      this.props.saveSelectStoreId(storeSelected, this.state.orderType)
                    } else {
                      toast.error("Sorry! Store is closed at this moment");
                    }
                  } else {
                    this.setState({ storeOpenFlag: true })
                    this.props.saveStore(storeSelected);
                    // result ? this.props.getTax(result.state_id) : this.props.getTax();
                    this.props.saveSelectStoreId(storeSelected, this.state.orderType)
                  }
                } else { //if store opening or closing minute is not 00
                  if (
                    futureMinutes > timeStartMin ||
                    futureMinutes < timeEndMin
                  ) {
                    this.setState({ storeOpenFlag: true })
                    this.props.saveStore(storeSelected);
                    // result ? this.props.getTax(result.state_id) : this.props.getTax();
                    this.props.saveSelectStoreId(storeSelected, this.state.orderType)
                  } else {
                    toast.error("Sorry! Store is closed at this moment");
                  }
                }
              }
            } else {
              toast.error("Sorry! Store is closed at this moment");
            }
          }
        })
      } else {
        toast.error("Your device date or time is invalid!");
        this.setState({ netWork: false })
      }



    })

  }

  handleStoresInputChange = (e: any, index: any) => {   //For Delivery
    // if (_.isEmpty(this.props.tradeAreaName) && !_.isEmpty(this.props.tradeZoneName)) {
    let { allStoresList } = this.props;
    if (e) {
      this.setState({ isClicked: true })
      let result: any = allStoresList.find((x: any) => x.store_id == e.value); //find the store from the stores list
      this.setState({
        promiseTime: result.expected_delivery_time,
        // areaDeliveryTime: 0
      })
      if (result.is_active == 0) {
        toast.error(result.store_name + " Store is temprorily closed!")
        this.setState({
          storeOpenFlag: false,
        })
        // if (result.mainStoreId.length > 0) {
        //   let backupStoreData: any = [];
        //   result.mainStoreId.map((backupStore: any) => {
        //     if (backupStore.backupStoreId.is_active == 1) {
        //       this.setState({ backupStorePromiseTime: result.expected_delivery_time + 15, })
        //       backupStoreData.push(backupStore);
        //     }
        //   })
        //   //sorting array with priority basis if backup stores are greater than 1
        //   if (backupStoreData.length > 1) {
        //     backupStoreData.sort((a: any, b: any) => {
        //       return a.priority - b.priority
        //     })
        //   }
        //   //finding the backupstore object from response data and match them by store_id
        //   let foundedStore: any = []
        //   if (backupStoreData.length > 0) {
        //     foundedStore.push(allStoresList.find((store: any) => backupStoreData[0].backupStoreId.store_id == store.store_id))
        //     this.props.saveBackupStores(backupStoreData)
        //   }
        // }
      }
      if (e.value != '') {
        if (this.props.orderPeriod == 'now') {
          if (result.isDays == 0 && result.is_active == 1) {
            this.compareOpenClosingTime(result)
          }
          else if (result.isDays == 1 && result.is_active == 1) {
            this.compareTimingbyEachDay(result)
          }
        }
        else if (this.props.orderPeriod == 'later') {
          if (result.isDays == 0 && result.is_active == 1) {
            this.compareLaterOpenClosingTime(result)
          }
          else if (result.isDays == 1 && result.is_active == 1) {
            this.compareLaterTimingbyEachDay(result)
          }
        }
      }
    }
    // }
  };

  handleSaveStore(event: { target: { name: any; value: any } }) { //For Pickup
    this.props.saveStore({})
    let { allStoresList } = this.props;
    let result: any = allStoresList.find((x: any) => x.store_id == event.target.value); //find the store from the stores list
    if (result) {
      this.setState({
        promiseTime: result.expected_delivery_time,
        areaDeliveryTime: result.expected_delivery_time
      })
    }

    this.props.allGroupsList(event.target.value, this.state.orderType)
    if (result && result.is_active == 0) {
      toast.error(result.store_name + " Store is temprorily closed!")
      this.setState({
        storeOpenFlag: false,
      })
      // if (result.mainStoreId.length > 0) {
      //   let backupStoreData: any = [];
      //   result.mainStoreId.map((backupStore: any) => {
      //     if (backupStore.backupStoreId.is_active == 1) {
      //       this.setState({ backupStorePromiseTime: result.expected_delivery_time + 15, areaDeliveryTime: result.expected_delivery_time })
      //       backupStoreData.push(backupStore);
      //     }
      //   })
      //   //sorting array with priority basis if backup stores are greater than 1
      //   if (backupStoreData.length > 1) {
      //     backupStoreData.sort((a: any, b: any) => {
      //       return a.priority - b.priority
      //     })
      //   }
      //   //finding the backupstore object from response data and match them by store_id
      //   let foundedStore: any = []
      //   if (backupStoreData.length > 0) {
      //     foundedStore.push(allStoresList.find((store: any) => backupStoreData[0].backupStoreId.store_id == store.store_id))
      //     this.props.saveBackupStores(backupStoreData)
      //   }
      // }
    }
    if (event.target.value != '') {
      if (this.props.orderPeriod == 'now') {
        if (result.isDays == 0 && result.is_active == 1) {
          this.compareOpenClosingTime(result)
        }
        else if (result.isDays == 1 && result.is_active == 1) {
          this.compareTimingbyEachDay(result)
        }
      }
      else if (this.props.orderPeriod == 'later') {
        if (result.isDays == 0 && result.is_active == 1) {
          this.compareLaterOpenClosingTime(result)
        }
        else if (result.isDays == 1 && result.is_active == 1) {
          this.compareLaterTimingbyEachDay(result)
        }
      }
    }

  }
  handlePaymentMethod(event: { target: { name: any; value: any } }) {
    this.setState({ paymentMethod: event.target.value })
  }

  ValidateOrder() {
    this.props.handleAddressInput(this.props.houseNo.replaceAll("/", " ") + ' ' + this.props.tradeZoneName + ' ' + this.props.tradeAreaName + ' ' + this.props.cityName)
    let temp = true;
    let customerName = localStorage.getItem('customername');
    let customerphone = localStorage.getItem('phone');
    let customerEmail = localStorage.getItem('email');
    let { selectedStore, delivery_address, orderPeriod } = this.props;
    let { validTime } = this.state;
    if ((customerName === '' || customerName === null) && (customerphone === '' || customerphone === null)) {
      toast.error("Customer information is incomplete! Please complete customer information in order to place order. ")
      return temp = false;
    }
    else if (_.isEmpty(selectedStore)) {
      toast.error("Please select the store in order to place order!")
      return temp = false;
    }
    // else if (this.state.orderType === 'Delivery' && delivery_address === '') {
    //   toast.error("Please enter delivery address in order to proceed order!")
    //   return temp = false;
    // }

    else if (this.state.orderType === 'Delivery' && this.props.houseNo === '') {
      toast.error("Please enter delivery address in order to proceed order!")
      return temp = false;
    } else if (this.state.orderType === "Delivery" && this.props.streetName === '') {
      toast.error("Please enter Street Name in order to proceed order!")
      return temp = false;
    } else if (this.state.orderType === "Delivery" && this.props.streetNo === '') {
      toast.error("Please enter Street No in order to proceed order!")
      return temp = false;
    }
    else if (orderPeriod === 'later' && validTime === false) {
      toast.error("Please enter valid date time in order to proceed order!")
      return temp = false;
    }
    if (temp === true) {
      this.setState({ netWork: true })
      // setTimeout(() => {
      //   this.setState({ netWork: false })
      // }, 2500);
      this.confirmOrder();
    }

  }

  disableBtn = () => {
    this.setState({ netWork: false })
  }

  confirmOrder = async () => {
    let { callduration } = this.props;
    let { storeSelected, taxDataCard, taxDataCash, selectedStore } = this.props;
    let pos_fee: any = selectedStore.state_id.pos_service_fee ? selectedStore.state_id.pos_service_fee : 0
    let data: any = this.props.cart;
    let tax: any = 0;
    tax = taxDataCash.tax_percent
    let cart: any = JSON.parse(data);
    let discount = 0;
    cart.forEach((item: any) => {
      item.tax_percent = tax;
      item.check_number = '';
      if (item.combo) {
        if (item.combo.discount_price || item.combo.discount_price == 0) {
          discount += item.quantity * Math.round(item.combo.combo_mrp_price - item.combo.discount_price);
        }
        this.quantity += item.quantity;
      } else {
        this.quantity += item.quantity;
        if (item.selectedsize.discount_price || item.selectedsize.discount_price == 0) {
          discount += item.quantity * Math.round(item.selectedsize.mrp - item.selectedsize.discount_price);
        }
      }
    })

    if (taxDataCash.tax_type == 1 || taxDataCash.tax_type == 0) {
      cart.forEach((item: any) => {
        if (item.combo) {
          item.tax_amount = taxDataCash.tax_type == 1 ? (item.totalItemPrice * tax / 100) : ((item.totalItemPrice) * (parseInt(tax) / (100 + parseInt(tax))))
          if (item.combo.choices) {
            item.combo.choices.map((obj: any, index: any) => {
              if (obj.modifiers) {
                Object.keys(obj.modifiers).map((key: any) => {
                  if (obj.modifiers[key].min) {
                    Object.keys(obj.modifiers[key]).map(subkey => {
                      Array.isArray(obj.modifiers[key][subkey]) &&
                        obj.modifiers[key][subkey].map(
                          (item: any, index: any) => {
                            if (item.selected == true) {
                              item.tax_percent = tax;
                              item.tax_amount = taxDataCash.tax_type == 1 ? item.modifier_sale_price * tax / 100 : ((item.modifier_sale_price) * (parseInt(tax) / (100 + parseInt(tax)))).toFixed(2);
                              item.tax_type = taxDataCash.tax_type
                            }
                          })
                    });
                  } else {
                    obj.modifiers[key].map(
                      (item: any, index: any) => {
                        if (item.selected == true) {
                          item.tax_percent = tax;
                          item.tax_amount = taxDataCash.tax_type == 1 ? item.modifier_sale_price * tax / 100 : ((item.modifier_sale_price) * (parseInt(tax) / (100 + parseInt(tax)))).toFixed(2);
                          item.tax_type = taxDataCash.tax_type
                        }
                      })
                  }
                })
              }
              if (obj.is_half_n_half && obj.is_half_n_half == 1 && obj.half_n_half_modifiers) {
                obj.half_n_half_modifiers.map((halfobj: any) => {
                  Object.keys(halfobj.data).map((key: any) => {
                    if (halfobj.data[key].min) {
                      Object.keys(halfobj.data[key]).map(subkey => {
                        Array.isArray(halfobj.data[key][subkey]) &&
                          halfobj.data[key][subkey].map(
                            (item: any, index: any) => {
                              if (item.selected == true) {
                                item.tax_percent = tax;
                                item.tax_amount = taxDataCash.tax_type == 1 ? item.modifier_sale_price * tax / 100 : ((item.modifier_sale_price) * (parseInt(tax) / (100 + parseInt(tax)))).toFixed(2);
                                item.tax_type = taxDataCash.tax_type
                              }
                            })
                      });
                    } else {
                      halfobj.data[key].map(
                        (item: any, index: any) => {
                          if (item.selected == true) {
                            item.tax_percent = tax;
                            item.tax_amount = taxDataCash.tax_type == 1 ? item.modifier_sale_price * tax / 100 : ((item.modifier_sale_price) * (parseInt(tax) / (100 + parseInt(tax)))).toFixed(2);
                            item.tax_type = taxDataCash.tax_type
                          }
                        })
                    }
                  })
                })
              }
            })
          }
        } else {
          item.tax_amount = taxDataCash.tax_type == 1 ? (item.totalItemPrice * tax / 100) : ((item.totalItemPrice) * (parseInt(tax) / (100 + parseInt(tax))))
          if (item.item.modifiers) {
            Object.keys(item.item.modifiers).map((key: any) => {
              if (item.item.modifiers[key].min) {
                Object.keys(item.item.modifiers[key]).map(subkey => {
                  Array.isArray(item.item.modifiers[key][subkey]) &&
                    item.item.modifiers[key][subkey].map(
                      (item: any, index: any) => {
                        if (item.selected == true) {
                          item.tax_percent = tax;
                          item.tax_amount = taxDataCash.tax_type == 1 ? item.modifier_sale_price * tax / 100 : ((item.modifier_sale_price) * (parseInt(tax) / (100 + parseInt(tax)))).toFixed(2);
                          item.tax_type = taxDataCash.tax_type
                        }
                      })
                });
              } else {
                item.item.modifiers[key].map(
                  (item: any, index: any) => {
                    if (item.selected == true) {
                      item.tax_percent = tax;
                      item.tax_amount = taxDataCash.tax_type == 1 ? item.modifier_sale_price * tax / 100 : ((item.modifier_sale_price) * (parseInt(tax) / (100 + parseInt(tax)))).toFixed(2);
                      item.tax_type = taxDataCash.tax_type
                    }
                  })
              }
            })
          }
          if (item.item.is_half_n_half && item.item.is_half_n_half == 1 && item.item.half_n_half_modifiers) {
            item.item.half_n_half_modifiers.map((obj: any) => {
              Object.keys(obj.data).map((key: any) => {
                if (obj.data[key].min) {
                  Object.keys(obj.data[key]).map(subkey => {
                    Array.isArray(obj.data[key][subkey]) &&
                      obj.data[key][subkey].map(
                        (item: any, index: any) => {
                          if (item.selected == true) {
                            item.tax_percent = tax;
                            item.tax_amount = taxDataCash.tax_type == 1 ? item.modifier_sale_price * tax / 100 : ((item.modifier_sale_price) * (parseInt(tax) / (100 + parseInt(tax)))).toFixed(2);
                            item.tax_type = taxDataCash.tax_type
                          }
                        })
                  });
                } else {
                  obj.data[key].map(
                    (item: any, index: any) => {
                      if (item.selected == true) {
                        item.tax_percent = tax;
                        item.tax_amount = taxDataCash.tax_type == 1 ? item.modifier_sale_price * tax / 100 : ((item.modifier_sale_price) * (parseInt(tax) / (100 + parseInt(tax)))).toFixed(2);
                        item.tax_type = taxDataCash.tax_type
                      }
                    })
                }
              })
            })
          }
        }
      })
    }
    this.discount = discount;
    const token = await localStorage.getItem("token");
    if (this.state.orderType !== "" && !_.isEmpty(selectedStore)) {
      if (token) {
        let obj: any = {
          order_status_code: 1,
          order_grossprice: (taxDataCash.tax_type == 1) ? this.cartTotal + this.totalTax + selectedStore.state_id.pos_service_fee : this.cartTotal + selectedStore.state_id.pos_service_fee,
          order_channel: "call center",
          discount: discount,
          store_id: selectedStore && selectedStore.store_id,
          trade_zone: this.props.tradeZoneValue && this.props.tradeZoneValue,
          trade_area: this.props.tradeAreaValue && this.props.tradeAreaValue,
          branch_code: selectedStore && selectedStore.branch_code,
          delivery_status: this.state.orderType,
          special_inst: this.props.special_inst,

          check_number: '',
          payment_method: this.state.paymentMethod == "Cash" ? 'Cash' : 'MachineAtDoorstep',
          tax_percent: tax,
          lat: this.state.lat,
          lng: this.state.lng,
          quantity: this.quantity,
          cartItems: cart,
          pos_service_fee: selectedStore.state_id.pos_service_fee ? selectedStore.state_id.pos_service_fee : 0
        };
        if (this.state.orderType == "Delivery") {
          obj.order_netprice = this.cartTotal - this.props.deliveryfee;
          obj.delivery_fee = this.props.deliveryfee;
        } else if (this.state.orderType == "Pickup") {
          obj.order_netprice = this.cartTotal;
        }
        if (this.props.delivery_address !== "" && this.state.orderType == "Delivery") {
          obj.delivery_address =
            this.props.Additional_address_info !== ""
              ? this.props.streetNo + "/" + this.props.streetName + "/" + this.props.Additional_address_info + " /" + this.props.delivery_address
              : this.props.streetNo + "/" + this.props.streetName + "/" + " " + " /" + this.props.delivery_address;
        }
        if (!_.isEmpty(this.props.coupon)) {
          obj.coupon = this.props.coupon;
          obj.coupon_redeem = 1;
          obj.coupon_discount_value = this.props.discountAmount;
        }
        if (this.state.orderType == "Delivery") {
          obj.order_netprice = this.cartTotal - this.props.deliveryfee;
          // obj.tax_amount = (taxDataCash.tax_type == 1) ? this.cartTotal * tax / 100 : 0;
          obj.tax_amount = (taxDataCash.tax_type == 1) ? this.cartTotal * tax / 100 : ((obj.order_netprice) * (parseInt(tax) / (100 + parseInt(tax)))).toFixed(2);
          obj.tax_type = taxDataCash.tax_type
          obj.order_grosspricewd = (taxDataCash.tax_type == 1) ? obj.order_netprice + obj.tax_amount + pos_fee : obj.order_netprice + pos_fee
          obj.delivery_fee = this.props.deliveryfee;
        } else if (this.state.orderType == "Pickup") {
          obj.order_netprice = this.cartTotal;
          // obj.tax_amount = (taxDataCash.tax_type == 1) ? this.cartTotal * tax / 100 : 0;
          obj.tax_amount = (taxDataCash.tax_type == 1) ? this.cartTotal * tax / 100 : ((obj.order_netprice) * (parseInt(tax) / (100 + parseInt(tax)))).toFixed(2);
          obj.tax_type = taxDataCash.tax_type
          obj.order_grosspricewd = (taxDataCash.tax_type == 1) ? obj.order_netprice + obj.tax_amount + pos_fee : obj.order_netprice + pos_fee
        }
        if (this.props.orderPeriod == 'later') {
          obj.future_status = 1;
          obj.future_date = moment(this.state.laterdatetime).utc(false).format('YYYY-MM-DD HH:mm')
          obj.laterdatetime = new Date(this.state.laterdatetime);
        }
        //   if (this.props.orderperiod == 'later') {
        //     this.props.saveLaterOrder(obj, this.props.selectedStore, tax);
        //   } else if (this.props.orderperiod == 'now') {
        callduration = callduration / 60;
        let rounded_time = Math.round(callduration * 10) / 10
        let callStatsObj = {
          call_duration: rounded_time,
          call_status: 1,
          customer_number: localStorage.getItem('phone'),
          total_sale: this.cartTotal,
          order_method: this.state.orderType
        }
        obj.mode = 'callcenter'
        this.props.saveOrder(obj, this.props.selectedStore, tax, callStatsObj);
        this.props.stopTimer();
        //   }
      } else {
        toast.error("error");
      }
    }
  };
  getStyle() {
    let Activeclasses = "statistic bg-danger d-flex justify-content-center has-shadow img-fluid text-white";
    return Activeclasses;
  }
  pickUpFormatAddress(city?: any) {
    let obj = document as any
    var dropDown: any = obj.getElementById("clearPickupStore");
    let o = ''
    dropDown && dropDown.selectedIndex ? dropDown.selectedIndex = 0 : o = '';
    this.setState({ isPickupClicked: true, isPickUpSubmitClicked: true })
    //let { Area, City } = this.props;
    this.props.findPickupStores(
      this.state.lat,
      this.state.lng,
      city
    );
  }
  menuItemsListByGroupsID(group_id: any) {
    let { groupsData } = this.props;
    this.setState({ active_group_id: group_id, kitchen_inst: "" });
    let data: any = {
      group_id: group_id,
    }
    groupsData.map((item: any, index: any) => {
      if (group_id == item.group_id) {
        data.menuItemsData = item.data
      }
    })
    this.props.UpdateMenuItems(data);
    this.props.resetItemSize();
    // this.props.menuItemsListByGroupsID(group_id);
  }
  isAddressReady = (ordertab: any) => {
    if (ordertab == 'delivery') {
      let { Area, City, cityName, tradeZoneName, tradeAreaName } = this.props;
      // return (Area !== "" && City !== "");
      return (tradeZoneName !== "" && tradeAreaName !== "" && cityName !== "");
    }
    else {
      let { City } = this.props;
      return (City !== "");
    }
  }

  handleCityInput = (e: any) => {
    if (!_.isEmpty(this.props.selectedStore) && (this.props.cart.length > 0 && this.props.cart != '[]')) {
      let answer = window.confirm("Are you sure? Your cart will be emptied")
      if (answer) {
        this.props.saveStore({})
        localStorage.removeItem("cart")
        this.props.saveCart([])
        if (!_.isEmpty(e)) {
          // let zone = JSON.parse(e.zone)
          if (this.props.tradeAreaName !== '') {
            this.selectRef.select.clearValue();
            this.selectRef3.select.clearValue();
          }
          // console.log('zone', zone);
          // this.props.findLocalStoreWithoutMap(zone.store_id)
          this.props.setCityName(e.label)
          this.props.setTradeZoneName('', null)
          this.props.setTradeAreaName('', null)
          this.props.getTradeZones(e.value)
          this.setState({ areaDeliveryTime: 0 })

        }

      }

    }
    else {
      this.props.saveStore({})
      //this.props.saveStore({})
      // let city = JSON.parse(e.value)
      if (this.props.tradeZoneName !== '') {
        this.selectRef.select.clearValue();
        this.selectRef2.select.clearValue();
      }

      if (this.props.tradeAreaName !== '') {
        this.selectRef.select.clearValue();
        this.selectRef3.select.clearValue();
      }
      this.props.setCityName(e.label)
      this.props.setTradeZoneName('', null)
      this.props.setTradeAreaName('', null)
      this.props.getTradeZones(e.value)
      this.setState({ areaDeliveryTime: 0 })
      // if (this.props.cityName != '') {
      //   this.props.saveStore()
      // }
    }

  }

  handleZone = (e: any) => {
    let answer

    if (!_.isEmpty(this.props.selectedStore) && (this.props.cart.length > 0 && this.props.cart != '[]')) {
      if (!_.isEmpty(e)) {
        answer = window.confirm("Are you sure? Your cart will be emptied")
      }
      if (answer) {
        this.setState({ areaDeliveryTime: 0 })
        this.props.saveStore({})
        localStorage.removeItem("cart")
        this.props.saveCart([])
        if (!_.isEmpty(e)) {
          if (this.props.tradeAreaName !== '') {
            this.selectRef.select.clearValue();
            this.selectRef3.select.clearValue();
          }
          this.props.setTradeZoneName(e.label, e.value)
          this.props.setTradeAreaName('', null)
          this.props.getTradeAreas(e.value)
        }
        this.props.setTradeAreaName('', null)
      }

    } else {
      this.setState({ areaDeliveryTime: 0 })
      this.props.saveStore({})
      if (!_.isEmpty(e)) {
        // let zone = JSON.parse(e.zone)
        if (this.props.tradeAreaName !== '') {
          this.selectRef.select.clearValue();
          this.selectRef3.select.clearValue();
        }
        // console.log('zone', zone);
        // this.props.findLocalStoreWithoutMap(zone.store_id)
        this.props.setTradeZoneName(e.label, e.value)
        this.props.setTradeAreaName('', null)
        this.props.getTradeAreas(e.value)
      }
      this.props.setTradeAreaName('', null)
    }


  }

  handleArea = (e: any) => {
    let answer
    if (!_.isEmpty(this.props.selectedStore) && (this.props.cart.length > 0 && this.props.cart != '[]')) {
      if (!_.isEmpty(e)) {
        answer = window.confirm("Are you sure? Your cart will be emptied")
      }
      if (answer) {
        this.props.saveStore({})
        localStorage.removeItem("cart")
        this.props.saveCart([])
        let obj = document as any;
        let o
        obj.getElementById("clear") ? obj.getElementById("clear").selectedIndex = 0 : o = ''
        this.setState({ isClicked: false })
        if (!_.isEmpty(e)) {
          this.props.saveBackupStores([])
          this.selectRef.select.clearValue();
          let area = JSON.parse(e.area)
          if (area.backup_store_id) {
            let backupStore: any = [];
            backupStore.push(area.backup_store_id)
            this.props.saveBackupStores(backupStore)
          } else {
            this.props.saveBackupStores([])
          }
          this.setState({ promiseTime: 0, backupStorePromiseTime: area.expected_delivery_time, areaDeliveryTime: area.expected_delivery_time })
          this.props.setTradeAreaName(e.label, e.value)
          this.props.findLocalStoreWithoutMap(area.store_id)
        }

      }

    }
    else {
      this.props.saveStore({})
      if (!_.isEmpty(e)) {
        this.selectRef.select.clearValue();
        let area = JSON.parse(e.area)
        if (area.backup_store_id) {
          let backupStore: any = [];
          backupStore.push(area.backup_store_id)
          this.props.saveBackupStores(backupStore)
        } else {
          this.props.saveBackupStores([])
        }
        this.setState({ promiseTime: 0, backupStorePromiseTime: area.expected_delivery_time, areaDeliveryTime: area.expected_delivery_time })
        this.props.setTradeAreaName(e.label, e.value)
        this.props.findLocalStoreWithoutMap(area.store_id)
      }
    }
  }

  blockInvalidChar = (e: any) => {
    var regex = new RegExp("^[a-zA-Z0-9]");
    let specialkey: any = e.keyCode;

    var key: any = String.fromCharCode(!e.charCode ? e.which : e.charCode);
    if (specialkey == 8 || specialkey == 9) {
      return true;
    } else {
      if (/\S/.test(e.target.value)) {
        // string is not empty and not just whitespace
        if (!regex.test(key)) {
          if (specialkey == 32) {
          } else {
            e.preventDefault()
          }
        }
      } else {
        ((!regex.test(key) || specialkey == 32)) && e.preventDefault()
      }
    }
  }
  handleChangeDeliveryChanell = () => {
    this.props.saveStore({})
    this.props.clearCart()
    this.setState({ isPickupClicked: false, isPickUpSubmitClicked: false })
    this.setState({ orderType: "Delivery" });
  }
  handleChangePickupChanell = () => {
    this.props.saveStore({})
    this.props.clearCart()
    this.setState({ isPickupClicked: false })
    this.setState({ orderType: "Pickup" });
  }

  //Half N Half Modifiers
  addHalfModifier = (key: any, subKey: any, modifier_name: any, modifier_type: any, halfNumber: any) => {
    let prevPrice = 0;
    if (modifier_type && modifier_type.min) {
      Object.keys(this.state.halfNHalfModifiers[halfNumber].data[key]).map(subkey => {
        if (Array.isArray(this.state.halfNHalfModifiers[halfNumber].data[key][subkey])) {
          this.state.halfNHalfModifiers[halfNumber].data[key][subkey].map((element: any) => {
            if (element.selected == true) {
              prevPrice = ((element.discount_price || element.discount_price == 0) && (element.order_channel == 'callcenter')) ? Math.round(element.discount_price) : element.modifier_sale_price;
            }
            if (element.modifier_name !== modifier_name) {
              element.selected = false;
            }
          })
        }
      })
      if (halfNumber == 0) {
        this.state.halfNHalfModifiers[0].data[key][subKey].map((firstitem: any, index: any) => {
          if (modifier_name == firstitem.modifier_name) {
            let modPrice = ((firstitem.discount_price || firstitem.discount_price == 0)) ? Math.round(firstitem.discount_price) : firstitem.modifier_sale_price;
            if (this.state.firstHalfPrevMod == '') {
              this.setState({
                itemPrice: parseInt(this.state.itemPrice) + modPrice,
                firstHalfPrevMod: modifier_name,
                firstHalfPrevKey: subKey
              })
              firstitem.selected = true
              // setValue(value => value + 1);  //ForceFull Render component at price 0
            }
            else {
              if (this.state.firstHalfPrevKey == subKey) {
                firstitem.selected = !firstitem.selected;
                if (this.state.firstHalfPrevMod === modifier_name) {
                  if (firstitem.selected == true) {
                    this.setState({
                      itemPrice: parseInt(this.state.itemPrice) - prevPrice + modPrice,
                      firstHalfPrevMod: modifier_name,
                      firstHalfPrevKey: subKey
                    })
                    // setValue(value => value + 1);  //ForceFull Render component at price 0
                  } else {
                    this.setState({
                      itemPrice: parseInt(this.state.itemPrice) - prevPrice,
                      firstHalfPrevMod: modifier_name,
                      firstHalfPrevKey: subKey
                    })
                    // setValue(value => value + 1);  //ForceFull Render component at price 0
                  }
                }
                else {
                  this.setState({
                    itemPrice: parseInt(this.state.itemPrice) - prevPrice + modPrice,
                    firstHalfPrevMod: modifier_name,
                    firstHalfPrevKey: subKey
                  })
                  // setValue(value => value + 1);  //ForceFull Render component at price 0
                }
              }
              else {
                firstitem.selected = true;
                this.setState({
                  itemPrice: parseInt(this.state.itemPrice) - prevPrice + modPrice,
                  firstHalfPrevMod: modifier_name,
                  firstHalfPrevKey: subKey
                })
                // setValue(value => value + 1);  //ForceFull Render component at price 0
              }
            }
          }
        })
      } else {
        this.state.halfNHalfModifiers[1].data[key][subKey].map((item: any, index: any) => {
          if (modifier_name == item.modifier_name) {
            let modPrice = ((item.discount_price || item.discount_price == 0)) ? Math.round(item.discount_price) : item.modifier_sale_price;
            if (this.state.secHalfPrevMod == '') {
              this.setState({
                itemPrice: parseInt(this.state.itemPrice) + modPrice,
                secHalfPrevMod: modifier_name,
                secHalfPrevKey: subKey
              })
              item.selected = true
              // setValue(value => value + 1);  //ForceFull Render component at price 0
            }
            else {
              if (this.state.secHalfPrevKey == subKey) {
                item.selected = !item.selected;
                if (this.state.secHalfPrevMod === modifier_name) {
                  if (item.selected == true) {
                    this.setState({
                      itemPrice: parseInt(this.state.itemPrice) - prevPrice + modPrice,
                      secHalfPrevMod: modifier_name,
                      secHalfPrevKey: subKey
                    })
                    // setValue(value => value + 1);  //ForceFull Render component at price 0
                  } else {
                    this.setState({
                      itemPrice: parseInt(this.state.itemPrice) - prevPrice,
                      secHalfPrevMod: modifier_name,
                      secHalfPrevKey: subKey
                    })
                    // setValue(value => value + 1);  //ForceFull Render component at price 0
                  }
                }
                else {
                  this.setState({
                    itemPrice: parseInt(this.state.itemPrice) - prevPrice + modPrice,
                    secHalfPrevMod: modifier_name,
                    secHalfPrevKey: subKey
                  })
                  // setValue(value => value + 1);  //ForceFull Render component at price 0
                }
              }
              else {
                item.selected = true;
                this.setState({
                  itemPrice: parseInt(this.state.itemPrice) - prevPrice + modPrice,
                  secHalfPrevMod: modifier_name,
                  secHalfPrevKey: subKey
                })
                // setValue(value => value + 1);  //ForceFull Render component at price 0
              }
            }
          }
        })
      }
    } else {
      this.state.halfNHalfModifiers[halfNumber].data[key].map((element: any) => {
        if (element.selected == true) {
          prevPrice = element.modifier_sale_price;
        }
        if (element.modifier_name !== modifier_name) {
          element.selected = false;
        }
      })
      if (halfNumber == 0) {
        this.state.halfNHalfModifiers[0].data[key].map((item: any, index: any) => {
          if (modifier_name == item.modifier_name) {
            if (this.state.firstHalfPrevMod == '') {
              this.setState({
                itemPrice: parseInt(this.state.itemPrice) + item.modifier_sale_price,
                firstHalfPrevMod: modifier_name,
                firstHalfPrevKey: subKey
              })
              item.selected = true
              // setValue(value => value + 1);  //ForceFull Render component at price 0
            }
            else {
              if (this.state.firstHalfPrevKey == key) {
                item.selected = !item.selected;
                if (this.state.firstHalfPrevMod === modifier_name) {
                  if (item.selected == true) {
                    this.setState({
                      itemPrice: parseInt(this.state.itemPrice) - prevPrice + item.modifier_sale_price,
                      firstHalfPrevMod: modifier_name,
                      firstHalfPrevKey: subKey
                    })
                    // setValue(value => value + 1);  //ForceFull Render component at price 0
                  } else {
                    this.setState({
                      itemPrice: parseInt(this.state.itemPrice) - prevPrice,
                      firstHalfPrevMod: modifier_name,
                      firstHalfPrevKey: subKey
                    })
                    // setValue(value => value + 1);  //ForceFull Render component at price 0
                  }
                }
                else {
                  this.setState({
                    itemPrice: parseInt(this.state.itemPrice) - prevPrice + item.modifier_sale_price,
                    firstHalfPrevMod: modifier_name,
                    firstHalfPrevKey: subKey
                  })
                  // setValue(value => value + 1);  //ForceFull Render component at price 0
                }
              }
              else {
                item.selected = true;
                this.setState({
                  itemPrice: parseInt(this.state.itemPrice) - prevPrice + item.modifier_sale_price,
                  firstHalfPrevMod: modifier_name,
                  firstHalfPrevKey: subKey
                })
                // setValue(value => value + 1);  //ForceFull Render component at price 0
              }
            }
          }
        })
      } else if (halfNumber == 1) {
        this.state.halfNHalfModifiers[1].data[key].map((item: any, index: any) => {
          if (modifier_name == item.modifier_name) {
            if (this.state.secHalfPrevMod == '') {
              this.setState({
                itemPrice: parseInt(this.state.itemPrice) + item.modifier_sale_price,
                firstHalfPrevMod: modifier_name,
                firstHalfPrevKey: subKey
              })
              // setValue(value => value + 1);  //ForceFull Render component at price 0
              item.selected = true
            }
            else {
              if (this.state.secHalfPrevKey == key) {
                item.selected = !item.selected;
                if (this.state.secHalfPrevMod === modifier_name) {
                  if (item.selected == true) {
                    this.setState({
                      itemPrice: parseInt(this.state.itemPrice) - prevPrice + item.modifier_sale_price,
                      secHalfPrevMod: modifier_name,
                      secHalfPrevKey: subKey
                    })
                    // setValue(value => value + 1);  //ForceFull Render component at price 0
                  } else {
                    this.setState({
                      itemPrice: parseInt(this.state.itemPrice) - prevPrice,
                      secHalfPrevMod: modifier_name,
                      secHalfPrevKey: subKey
                    })
                    // setValue(value => value + 1);  //ForceFull Render component at price 0
                  }
                }
                else {
                  this.setState({
                    itemPrice: parseInt(this.state.itemPrice) - prevPrice + item.modifier_sale_price,
                    secHalfPrevMod: modifier_name,
                    secHalfPrevKey: subKey
                  })
                  // setValue(value => value + 1);  //ForceFull Render component at price 0
                }
              }
              else {
                item.selected = true;
                this.setState({
                  itemPrice: parseInt(this.state.itemPrice) - prevPrice + item.modifier_sale_price,
                  secHalfPrevMod: modifier_name,
                  secHalfPrevKey: subKey
                })
                // setValue(value => value + 1);  //ForceFull Render component at price 0
              }
            }
          }
        })
      }
    }
  }
  handleCheckBoxForHalf = (key: any, modName: any, halfNumber: any) => {
    let max_quantity = 0;
    let allowed_quantity = 0;
    this.state.halfNHalfModifiers[halfNumber].data[key].map((element: any) => {
      if (element.selected == true) {
        max_quantity += element.quantity;
      }
      else {
        allowed_quantity = element.modGroup_max_quantity;
      }
    })
    this.state.halfNHalfModifiers[halfNumber].data[key].map((item: any, index: any) => {
      if (item.modifier_name == modName) {
        let modPrice = ((item.discount_price || item.discount_price == 0)) ? Math.round(item.discount_price) : item.modifier_sale_price;
        if (item.selected == false) {
          if (max_quantity < allowed_quantity) {
            item.selected = !item.selected;
            this.setState({ itemPrice: parseInt(this.state.itemPrice) + modPrice * item.quantity, halfNHalfModifiers: this.state.halfNHalfModifiers })
          }
        } else {
          item.selected = !item.selected;
          this.setState({ itemPrice: parseInt(this.state.itemPrice) - modPrice * item.quantity, halfNHalfModifiers: this.state.halfNHalfModifiers })
        }
      }
    });
  };
  comboitemReady = (selectedTabModifiers: any) => {
    //For only crusts and Toppings and other addons
    let quantityFlag = true;
    let foundModifier = false;
    if (!_.isEmpty(selectedTabModifiers)) {
      Object.keys(selectedTabModifiers).map(key => {
        let ModifierQuantityCount = 0
        if (quantityFlag) {
          if ((selectedTabModifiers[key].is_crust && selectedTabModifiers[key].is_crust == 1)) {
            if (selectedTabModifiers[key].min > 0) {
              Object.keys(selectedTabModifiers[key]).map((subkey: any, index: any) => {
                if (quantityFlag) {
                  if (Array.isArray(selectedTabModifiers[key][subkey])) {
                    for (let j = 0; j < selectedTabModifiers[key][subkey].length; j++) {
                      if (selectedTabModifiers[key][subkey][j].selected && selectedTabModifiers[key][subkey][j].quantity > 0) {
                        ModifierQuantityCount += selectedTabModifiers[key][subkey][j].quantity;
                      }
                    }
                    if (index == Object.keys(selectedTabModifiers[key]).length - 1) {
                      if (ModifierQuantityCount < selectedTabModifiers[key].min) {
                        if (!foundModifier) {
                          // toast.warn("Please select max quantity for required option in " + selectedTabModifiers[key][i].mod_group_name, { position: toast.POSITION.BOTTOM_RIGHT, hideProgressBar: true, autoClose: 3000 })
                          foundModifier = true;
                          quantityFlag = false;
                          // break;
                        }
                      }
                      else {
                        quantityFlag = true;
                      }
                    }
                  }
                }
              })
            }
          } else {
            for (let i = 0; i < selectedTabModifiers[key].length; i++) {
              if (selectedTabModifiers[key][i].modGroup_min_quantity > 0) {
                if (selectedTabModifiers[key][i].selected && selectedTabModifiers[key][i].quantity > 0) {
                  ModifierQuantityCount += selectedTabModifiers[key][i].quantity;
                }
                if (i == selectedTabModifiers[key].length - 1) {
                  if (ModifierQuantityCount < selectedTabModifiers[key][i].modGroup_min_quantity) {
                    if (!foundModifier) {
                      // toast.warn("Please select max quantity for required option in " + modifiers[key][i].mod_group_name, { position: toast.POSITION.BOTTOM_RIGHT, hideProgressBar: true, autoClose: 3000 })
                      foundModifier = true;
                      quantityFlag = false;
                      break;
                    }
                  }
                  else {
                    quantityFlag = true;
                  }
                }
              }
            }
          }
        }
      })
    }
    else {
      quantityFlag = true;
    }
    return quantityFlag
  }
  combohalfNHalfItemReady = (half_n_half_modifiers: any) => {
    //For only flavours and Toppings
    let quantityFlag = true;
    let foundModifier = false;
    if (!_.isEmpty(half_n_half_modifiers[0].data)) {
      Object.keys(half_n_half_modifiers[0].data).map(key => {
        let ModifierQuantityCount = 0
        if (quantityFlag) {
          if ((half_n_half_modifiers[0].data[key].is_flavour && half_n_half_modifiers[0].data[key].is_flavour == 1)) {
            if (half_n_half_modifiers[0].data[key].min > 0) {
              Object.keys(half_n_half_modifiers[0].data[key]).map((subkey: any, index: any) => {
                if (quantityFlag) {
                  if (Array.isArray(half_n_half_modifiers[0].data[key][subkey])) {
                    for (let j = 0; j < half_n_half_modifiers[0].data[key][subkey].length; j++) {
                      if (half_n_half_modifiers[0].data[key][subkey][j].selected && half_n_half_modifiers[0].data[key][subkey][j].quantity > 0) {
                        ModifierQuantityCount += half_n_half_modifiers[0].data[key][subkey][j].quantity;
                      }
                    }
                    if (index == Object.keys(half_n_half_modifiers[0].data[key]).length - 1) {
                      if (ModifierQuantityCount < half_n_half_modifiers[0].data[key].min) {
                        if (!foundModifier) {
                          // toast.warn("Please select max quantity for required option in " + half_n_half_modifiers[0].data[key][i].mod_group_name, { position: toast.POSITION.BOTTOM_RIGHT, hideProgressBar: true, autoClose: 3000 })
                          foundModifier = true;
                          quantityFlag = false;
                          // break;
                        }
                      }
                      else {
                        quantityFlag = true;
                      }
                    }
                  }
                }
              })
            }
          } else {
            for (let i = 0; i < half_n_half_modifiers[0].data[key].length; i++) {
              if (half_n_half_modifiers[0].data[key][i].modGroup_min_quantity > 0) {
                if (half_n_half_modifiers[0].data[key][i].selected && half_n_half_modifiers[0].data[key][i].quantity > 0) {
                  ModifierQuantityCount += half_n_half_modifiers[0].data[key][i].quantity;
                }
                if (i == half_n_half_modifiers[0].data[key].length - 1) {
                  if (ModifierQuantityCount < half_n_half_modifiers[0].data[key][i].modGroup_min_quantity) {
                    if (!foundModifier) {
                      // toast.warn("Please select max quantity for required option in " + modifiers[key][i].mod_group_name, { position: toast.POSITION.BOTTOM_RIGHT, hideProgressBar: true, autoClose: 3000 })
                      foundModifier = true;
                      quantityFlag = false;
                      break;
                    }
                  }
                  else {
                    quantityFlag = true;
                  }
                }
              }
            }
          }
        }
      })
    }
    else {
      quantityFlag = true;
    }
    return quantityFlag
  }
  choiceTabItemReady = (tabIndex: any) => {
    //For only last tab
    let quantityFlag = true;
    let foundModifier = false;
    if (!_.isEmpty(this.state.comboDetails)) {
      if (this.state.comboDetails[tabIndex].is_full_pizza == 1) {
        let itemReadyFlag = this.comboitemReady(this.state.comboDetails[tabIndex].modifiers);
        if (itemReadyFlag) {
          quantityFlag = true;
        } else {
          quantityFlag = false;
        }
      } else if (this.state.comboDetails[tabIndex].is_half_n_half == 1) {
        let itemReadyFlag = this.comboitemReady(this.state.comboDetails[tabIndex].modifiers);
        if (itemReadyFlag) {
          let firsthalfItemReady = this.combohalfNHalfItemReady(this.state.comboDetails[tabIndex].half_n_half_modifiers);
          if (firsthalfItemReady) {
            quantityFlag = true;
          } else {
            quantityFlag = false;
          }
        } else {
          quantityFlag = false;
        }
      } else if (this.state.comboDetails[tabIndex].is_full_pizza == 0 && this.state.comboDetails[tabIndex].is_half_n_half == 0) {
        if (!_.isEmpty(this.state.comboDetails[tabIndex].modifiers)) {
          Object.keys(this.state.comboDetails[tabIndex].modifiers).map(key => {
            let ModifierQuantityCount = 0
            if (quantityFlag) {
              if ((this.state.comboDetails[tabIndex].modifiers[key].is_addon && this.state.comboDetails[tabIndex].modifiers[key].is_addon == 1)) {
                if (this.state.comboDetails[tabIndex].modifiers[key].min > 0) {
                  Object.keys(this.state.comboDetails[tabIndex].modifiers[key]).map((subkey: any, index: any) => {
                    if (quantityFlag) {
                      if (Array.isArray(this.state.comboDetails[tabIndex].modifiers[key][subkey])) {
                        for (let j = 0; j < this.state.comboDetails[tabIndex].modifiers[key][subkey].length; j++) {
                          if (this.state.comboDetails[tabIndex].modifiers[key][subkey][j].selected && this.state.comboDetails[tabIndex].modifiers[key][subkey][j].quantity > 0) {
                            ModifierQuantityCount += this.state.comboDetails[tabIndex].modifiers[key][subkey][j].quantity;
                          }
                        }
                        if (index == Object.keys(this.state.comboDetails[tabIndex].modifiers[key]).length - 1) {
                          if (ModifierQuantityCount < this.state.comboDetails[tabIndex].modifiers[key].min) {
                            if (!foundModifier) {
                              // toast.warn("Please select max quantity for required option in " + comboDetails[tabIndex].modifiers[key][i].mod_group_name, { position: toast.POSITION.BOTTOM_RIGHT, hideProgressBar: true, autoClose: 3000 })
                              foundModifier = true;
                              quantityFlag = false;
                              // break;
                            }
                          }
                          else {
                            quantityFlag = true;
                          }
                        }
                      }
                    }
                  })
                }
              } else {
                for (let i = 0; i < this.state.comboDetails[tabIndex].modifiers[key].length; i++) {
                  if (this.state.comboDetails[tabIndex].modifiers[key][i].modGroup_min_quantity > 0) {
                    if (this.state.comboDetails[tabIndex].modifiers[key][i].selected && this.state.comboDetails[tabIndex].modifiers[key][i].quantity > 0) {
                      ModifierQuantityCount += this.state.comboDetails[tabIndex].modifiers[key][i].quantity;
                    }
                    if (i == this.state.comboDetails[tabIndex].modifiers[key].length - 1) {
                      if (ModifierQuantityCount < this.state.comboDetails[tabIndex].modifiers[key][i].modGroup_min_quantity) {
                        if (!foundModifier) {
                          // toast.warn("Please select max quantity for required option in " + modifiers[key][i].mod_group_name, { position: toast.POSITION.BOTTOM_RIGHT, hideProgressBar: true, autoClose: 3000 })
                          foundModifier = true;
                          quantityFlag = false;
                          break;
                        }
                      }
                      else {
                        quantityFlag = true;
                      }
                    }
                  }
                }
              }
            }
          })
        }
        else {
          quantityFlag = true;
        }
      }
    } else {
      quantityFlag = true;
    }
    return quantityFlag
  }
  changeTab = (key: any) => {
    if (key > this.state.combo_active_key) {
      let itemReadyFlag = this.choiceTabItemReady(key - 1);
      if (!itemReadyFlag) {
        toast.error(`Please select all required options of ${this.state.comboDetails[key - 1].group_name}`, { position: toast.POSITION.BOTTOM_RIGHT, hideProgressBar: true, autoClose: 3000 });
      } else {
        let modifiers = this.props.itemData.choices[key].modifiers;
        this.setState({
          combo_active_key: key,
          choice_active_key: `choice${key}1stHalf`,
          modifiers: modifiers
        })
        if (this.props.itemData.choices[key].is_half_n_half == 1) {
          this.setState({
            halfNHalfModifiers: this.props.itemData.choices[key].half_n_half_modifiers
          })
        } else {
          this.setState({
            halfNHalfModifiers: null
          })
        }
      }
    } else {
      let modifiers = this.props.itemData.choices[key].modifiers;
      this.setState({
        combo_active_key: key,
        choice_active_key: `choice${key}1stHalf`,
        modifiers: modifiers
      })
      if (this.props.itemData.choices[key].is_half_n_half == 1) {
        this.setState({
          halfNHalfModifiers: this.props.itemData.choices[key].half_n_half_modifiers
        })
      } else {
        this.setState({
          halfNHalfModifiers: null
        })
      }
    }
  }
  applyKitchenInstruction = (instruction: any, choiceIndex: any) => {
    this.state.comboDetails[choiceIndex].kitchen_instruction = instruction;
  }
  render() {
    let {
      groups,
      itemsbygroupid,
      cart,
      stores,
      counter,
      itemData,
      order_id,
      open,
      backupStores,
      isLoading,
      customer,
      selectedStore,
      groupsData
    } = this.props;
    let { modifiers, halfNHalfModifiers, comboDetails } = this.state;
    let todayDateTime = new Date();
    todayDateTime.setSeconds(0, 0);

    // 

    let m = moment(todayDateTime)
    let momentDate = m.format()
    let momentUpdate = momentDate.split("+")[0]
    let today = momentUpdate

    // 


    // let today = todayDateTime.toISOString().split(".")[0];
    let maxDateTime = todayDateTime.setDate(todayDateTime.getDate() + 1);
    // 

    let maxMomentDate = m.add(1, "days")
    let maxMomentDateUpdate = maxMomentDate.format().split("+")[0]
    let maxDate = maxMomentDateUpdate

    // 

    // let maxDate = new Date(maxDateTime).toISOString().split(".")[0];
    // let data: any[] = (cart && cart.length > 0) ? JSON.parse(cart) : [];
    if (localStorage.token) {
      jwt.verify(localStorage.token, secretKey, (err: any, decoded: any) => {
        if (err) {
          this.props.logoutUser();
        }
      });
    } else {
      return <Redirect to="/" />;
    }

    let cities_arr: any = []
    let zones_arr: any = []
    let areas_arr: any = []
    this.props.cities_portal && this.props.cities_portal.map((city: any) => {
      cities_arr.push({ label: city.name, value: city.id })
    })

    this.props.tradeZones_portal && this.props.tradeZones_portal.map((zone: any) => {
      zones_arr.push({ label: zone.delivery_zone_name, value: zone.id })
    })

    this.props.tradeAreas_portal && this.props.tradeAreas_portal.map((area: any) => {
      areas_arr.push({ label: area.area_name, value: area.id, area: JSON.stringify(area) })
    })

    if (this.props.houseNo == '') {
      this.selectRef && this.selectRef.select.clearValue();
      let o;
      let obj = document as any;
      obj.getElementById("clear") ? obj.getElementById("clear").selectedIndex = 0 : o = ''
    }

    return (
      <div className="page">
        <CheckChanges path="/menu" />
        <Topbar />
        <div className="page-content d-flex align-items-stretch">
          <Sidebar />
          <div className="content-inner p-1">
            {/* <!-- Page Header--> */}
            <header className="container-fluid py-0 mt-2">
              <div className="page-header">
                <div className="d-flex justify-content-between align-items-center">
                  <h4 className="mt-2">Choose Items</h4>
                  {!_.isEmpty(customer) &&
                    <div style={{ display: 'flex' }} className='customer-detail-section'>
                      <div >
                        <div>Customer Name: {customer.login_name}</div>
                        <div>Customer Phone: {customer.phone_number}</div>
                      </div>
                      <div style={{ marginLeft: '10px' }}>
                        <div>Total Revenue: {customer?.totalRevenue ? customer?.totalRevenue : 0}</div>
                        {customer && customer.lastOrderDate ? <div>
                          Last order date: {customer?.lastOrderDate[0]}{' '} {customer?.lastOrderDate[1]}
                        </div> : <div>
                          Last order date: No order
                        </div>}
                      </div>
                    </div>
                  }
                </div>
              </div>
            </header>
            <section className="dashboard-counts no-padding-bottom py-3">
              <div className="container-fluid">
                <div className="row bg-white has-shadow p-2">
                  <div className="col-6">
                    <div className="item d-flex align-items-center no-padding">
                      <div>
                        <input
                          id="radioDelivery"
                          type="radio"
                          checked={this.state.orderType === "Delivery"}
                          value="Delivery"
                          onChange={() => {
                            this.handleChangeDeliveryChanell()
                          }}
                          name="Delivery"
                          className="radio-template"
                        />
                        <label htmlFor="radioDelivery">Delivery</label>
                      </div>
                      <div className="icon bg-blue ml-3">
                        <i className="fa fa-motorcycle"></i>
                      </div>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="item d-flex align-items-center no-padding">
                      <div>
                        <input
                          id="radioPickup"
                          type="radio"
                          checked={this.state.orderType === "Pickup"}
                          value="Pickup"
                          onChange={() => {

                            this.handleChangePickupChanell()
                          }}
                          name="Pickup"
                          className="radio-template"
                        />
                        <label htmlFor="radioPickup">Pickup</label>
                      </div>
                      <div className="icon bg-red ml-3">
                        <i className="fa fa-suitcase"></i>
                      </div>

                    </div>


                  </div>
                </div>
                <div className="row bg-white has-shadow p-1 mt-3">
                  <div className="col-md-3 col-6">
                    <div className="form-group">
                      <div
                        onClick={() => {
                          this.props.saveOrderPeriod("now");
                        }}
                      >
                        <input
                          style={{ fontSize: "16px" }}
                          type="radio"
                          name="orderPeriod"
                          value="now"
                          {...(this.props.orderPeriod == "now" && {
                            checked: true,
                          })}
                          className="radio-template"
                          id="nowMobile"
                        />
                        <label style={{ marginLeft: "10px" }}>NOW </label>
                      </div>
                      <div></div>
                      <div></div>
                    </div>
                  </div>
                  <div className="col-md-3 col-6">
                    <div
                      className=""
                      onClick={() => {
                        this.props.saveOrderPeriod("later");
                      }}
                    >
                      <input
                        style={{ fontSize: "16px" }}
                        type="radio"
                        name="orderPeriod"
                        value="later"
                        {...(this.props.orderPeriod == "later" && {
                          checked: true,
                        })}
                        className="radio-template"
                        id="nowMobile"
                      />
                      <label style={{ marginLeft: "10px" }}>LATER</label>
                      {this.props.orderPeriod == "later" ? (
                        <div>
                          <input
                            style={{ fontSize: "16px" }}
                            type="datetime-local"
                            min={today}
                            max={maxDate}
                            value={this.state.laterdatetime}
                            className="form-control laterDateInput"
                            placeholder="DD/MM/YYYY"
                            name="laterdatetime"
                            onChange={this.handleValidTime}
                          />
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-md-6 col-12 d-flex align-items-center">
                    <div className="form-group col">
                      <div className="row-wrapper">
                        <div>
                          <label className="form-control-label">
                            Select Payment Method
                          </label>
                          <span className="text-danger">*</span>
                        </div>
                      </div>

                      <select
                        id='clear'
                        name="payment_method"
                        className="form-control mr-3"
                        value={this.state.paymentMethod}
                        onChange={this.handlePaymentMethod}
                      >
                        <option value="">Select Payment Method</option>
                        <option value={"Cash"}>
                          Cash
                        </option>
                        {this.state.orderType == "Delivery" && <option value={"MachineAtDoorstep"}>
                          Machine at doorstep
                        </option>}
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            {this.state.orderType == "Delivery" && (
              <section className="pt-2 pb-2">
                <div className="container-fluid">
                  <div id="accordion">
                    <div className="card-header no-padding" id="headingOne">
                      <h5 className="mb-0">
                        <button
                          className="btn btn-link"
                          data-toggle="collapse"
                          data-target="#collapseOne"
                          aria-expanded="true"
                          aria-controls="collapseOne"
                        >
                          Customer Address Information
                        </button>
                      </h5>
                    </div>

                    <div
                      id="collapseOne"
                      className="collapse show"
                      aria-labelledby="headingOne"
                      data-parent="#accordion"
                    >
                      <div className="project bg-white has-shadow ">
                        <div className="row mb-0 p-1">
                          <div className="col-3">
                            <div className="form-group">
                              <label className="form-control-label">
                                Delivery Address{" "}
                              </label>
                              <span className="text-danger">*</span>
                              <input
                                style={{ marginTop: '10px', borderRadius: 5, height: '38px', border: '1px solid rgb(204,204,204)' }}
                                className="form-control form-control-sm"
                                name="houseNo"
                                onKeyDown={this.blockInvalidChar}
                                onPaste={(e) => { e.preventDefault() }}
                                value={this.props.houseNo.replaceAll("/", "")}
                                onChange={(e) => {
                                  // this.props.handleMenuInput
                                  this.props.saveStore({})
                                  localStorage.removeItem("cart")
                                  this.props.saveCart([])
                                  this.props.handleAddressInput(e.target.value)
                                  this.props.setHouseNo(e.target.value)
                                }}
                                placeholder="Enter Delivery Address"
                              />
                            </div>
                          </div>
                          <div className="col-3">
                            <div className="form-group">
                              <label className="form-control-label">
                                City/Town{" "}
                              </label>
                              <span className="text-danger">*</span>
                              {/* <input
                                className="form-control form-control-sm"
                                name="Block"
                                value={this.props.Block}
                                onChange={this.props.handleMenuInput}
                                placeholder="Enter Block/Street/Sector"
                              /> */}
                              <Select
                                name="cities"
                                value={{ label: this.props.cityName ? this.props.cityName : 'Select...', value: 'Select...' }}
                                options={cities_arr}
                                className="text-capitalize basic-multi-select mt-2"
                                classNamePrefix="select"
                                isLoading={cities_arr.length == 0 ? true : false}
                                onChange={
                                  this.handleCityInput
                                }
                              />
                            </div>
                          </div>
                          <div className="col-3">
                            <div className="form-group">
                              <label className="form-control-label">
                                Area/Place{" "}
                              </label>
                              <span className="text-danger">*</span>
                              {/* <input
                                className="form-control form-control-sm"
                                name="Area"
                                value={this.props.Area}
                                onChange={this.props.handleMenuInput}
                                placeholder="Enter Area/Road/Place"
                              /> */}
                              <Select
                                ref={ref => { this.selectRef2 = ref; }}
                                name="zones"
                                value={{ label: this.props.tradeZoneName ? this.props.tradeZoneName : 'Select...', value: '' }}
                                options={this.props.cityName ? zones_arr : []}
                                className="text-capitalize basic-multi-select mt-2"
                                classNamePrefix="select"
                                isLoading={zones_arr.length == 0 ? true : false}
                                onChange={
                                  this.handleZone
                                }
                              />
                            </div>
                          </div>
                          <div className="col-3">
                            <div className="form-group">
                              <label className="form-control-label">
                                Block/Street/Sector{" "}
                              </label>
                              <span className="text-danger">*</span>
                              <Select
                                ref={ref => { this.selectRef3 = ref; }}
                                value={{ label: this.props.tradeAreaName ? this.props.tradeAreaName : 'Select...', value: '' }}
                                name="areas"
                                options={this.props.tradeZoneName != '' ? areas_arr : []}
                                className="text-capitalize basic-multi-select mt-2"
                                classNamePrefix="select"
                                isLoading={areas_arr.length == 0 ? true : false}
                                onChange={
                                  this.handleArea
                                }
                              />
                            </div>
                          </div>
                        </div>
                        <div className="row no-padding">
                          <div className="col">
                            <div className="form-group">
                              <label className="form-control-label">
                                Street Name
                              </label>
                              <span className="text-danger">*</span>
                              <input
                                name="streetName"
                                className="form-control form-control-sm"
                                onKeyDown={this.blockInvalidChar}
                                onPaste={(e) => { e.preventDefault() }}
                                value={this.props.streetName.replaceAll("/", "")}
                                onChange={(e) => {
                                  var inputValue = e.target.value.trimStart()
                                  this.props.setStreetName(inputValue)
                                }
                                }
                                placeholder="Enter Street Name"
                              />

                            </div>
                          </div>
                          <div className="col">
                            <div className="form-group">
                              <label className="form-control-label">
                                Street No
                              </label>
                              <span className="text-danger">*</span>
                              <input
                                name="streetNo"
                                className="form-control form-control-sm"
                                onKeyDown={this.blockInvalidChar}
                                onPaste={(e) => { e.preventDefault() }}
                                value={this.props.streetNo.replaceAll("/", "")}
                                onChange={(e) => {
                                  var inputValue = e.target.value.trimStart()
                                  this.props.setStreetNo(inputValue)
                                }
                                }
                                placeholder="Enter Street No"
                              />
                            </div>
                          </div>
                          <div className="col">
                            <div className="form-group">
                              <label className="form-control-label">
                                Additional address info
                              </label>
                              <input
                                name="Additional_address_info"
                                className="form-control form-control-sm"
                                onKeyDown={this.blockInvalidChar}
                                onPaste={(e) => { e.preventDefault() }}
                                value={this.props.Additional_address_info.replaceAll("/", "")}
                                onChange={(e) => {
                                  var inputValue = e.target.value.trimStart()
                                  e.target.value = inputValue
                                  this.props.handleMenuInput(e)
                                }}
                                placeholder="Enter Additional Address Info"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            )}
            {this.state.orderType == "Pickup" && (
              <section className="pt-2 pb-2">
                <div className="container-fluid">
                  <div id="accordion">
                    <div className="card-header no-padding" id="headingOne">
                      <h5 className="mb-0">
                        <button
                          className="btn btn-link"
                          data-toggle="collapse"
                          data-target="#collapseOne"
                          aria-expanded="true"
                          aria-controls="collapseOne"
                        >
                          Customer Address Information
                        </button>
                      </h5>
                    </div>

                    <div
                      id="collapseOne"
                      className="collapse show"
                      aria-labelledby="headingOne"
                      data-parent="#accordion"
                    >
                      <div className="project bg-white has-shadow ">
                        {/* <div className="row mb-0 p-1">
                          <div className="col">
                            <div className="form-group">
                              <label className="form-control-label"> //here
                                City/Area
                              </label>
                              <span className="text-danger">*</span>
                              <input
                                className="form-control form-control-sm"
                                name="City"
                                value={this.props.City}
                                onChange={this.props.handleMenuInput}
                                placeholder="Enter City/Town"
                              />
                            </div>
                          </div>
                        </div> */}
                        <div className="col-md-12 col-12 d-flex align-items-center">
                          <div className="form-group col">
                            <div className="row-wrapper">
                              <div>
                                <label className="form-control-label">
                                  Select City
                                </label>
                                <span className="text-danger">*</span>
                              </div>
                            </div>

                            <select
                              id='clear'
                              name="City"
                              className="form-control mr-3"
                              //value={this.props.City}
                              onChange={(e) => { this.pickUpFormatAddress(e.target.value) }} //here2
                            >
                              <option value="">Select City</option>
                              {
                                cities_arr.map((city: any) => <option value={city.label}>{city.label}</option>)
                              }

                            </select>
                          </div>
                        </div>
                        <div className="row no-padding">
                          <div className="col">
                            <div className="form-group">
                              {/* <button
                                onClick={this.pickUpFormatAddress}
                                disabled={!this.isAddressReady("pickup")}
                                className="btn-danger btn mt-2"
                              >
                                Submit
                              </button> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            )}
            <section className="projects pt-2 no-padding-bottom">
              <div className="container-fluid">
                <div className="project">
                  {this.state.orderType == "Delivery" && (
                    <div className="row bg-white has-shadow p-1">
                      <div className="col-lg-3 d-flex align-items-center justify-content-between">
                        <div className="project-title d-flex align-items-center">
                          <div style={{ wordBreak: 'break-word', overflow: "hidden" }} className="form-group">
                            <label className="form-control-label">
                              Delivery Address
                            </label>
                            <span className="text-danger">*</span><br />
                            {this.props.streetNo.replaceAll("/", "") + "/" + this.props.streetName.replaceAll("/", "") + "/" + this.props.Additional_address_info.replaceAll("/", "") + " /" + this.props.houseNo.replaceAll("/", "") + ' ' + this.props.tradeZoneName + ' ' + this.props.tradeAreaName + ' ' + this.props.cityName}
                          </div>
                          {/* <div style={{ width: '21rem' }} className="form-group">
                          <label className="form-control-label">
                            Geo Coded Address
                              </label>
                          <Map findLocalStores={this.props.findLocalStores} handleGeoCodeAddress={this.props.handleGeoCodeAddress} handleAddressInput={this.props.handleAddressInput} />

                        </div> */}
                        </div>
                      </div>
                      <div className="col-lg-3 d-flex align-items-center">
                        <div className="project-title d-flex align-items-center">
                          <div className="form-group">
                            <label className="form-control-label">
                              Special Instructions
                            </label>
                            <input
                              id="special_inst"
                              type="text"
                              name="special_inst"
                              required
                              data-msg="Please enter special Instructions"
                              className="input-material"
                              onChange={(e) => {
                                var inputValue = e.target.value.trimStart()
                                e.target.value = inputValue
                                this.props.handleMenuInput(e)
                              }}
                            // onChange={this.props.handleMenuInput}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-3 d-flex align-items-center">
                        <div className="form-group col">
                          <div className="row-wrapper">
                            <div>
                              {this.props.storesOption && this.props.storesOption.length == 1 && !_.isEmpty(this.props.tradeAreaName) && !_.isEmpty(this.props.tradeZoneName) && !_.isEmpty(this.props.cityName) && !_.isEmpty(this.props.houseNo) ? (
                                <label className="form-control-label green-text">
                                  Store Detected
                                </label>
                              ) : (
                                <label className="form-control-label">
                                  Select Nearest Store
                                </label>
                              )}
                              <span className="text-danger">*</span>
                            </div>
                            <div>
                              <label
                                style={{ fontWeight: 700 }}
                                className="form-control-label"
                              >
                                EDT: {this.state.areaDeliveryTime} {"Min"}

                              </label>
                            </div>
                          </div>
                          <Select
                            ref={ref => { this.selectRef = ref; }}
                            defaultValue={{ label: "Select Store", value: 0 }}
                            name="stores"
                            options={!_.isEmpty(this.props.houseNo) && !_.isEmpty(this.props.tradeAreaName) && !_.isEmpty(this.props.tradeZoneName) ? this.props.storesOption : []}
                            className="text-capitalize basic-multi-select mt-2"
                            classNamePrefix="select"
                            onChange={(e: any, i: any) =>

                              this.handleStoresInputChange(e, i)
                            }
                          />
                        </div>
                      </div>
                      <div className="col-lg-3 d-flex align-items-center">
                        <div className="form-group col">
                          <div className="row-wrapper">
                            <div>
                              <label className="form-control-label">
                                Select Backup Store
                              </label>

                              <span className="text-danger">*</span>
                            </div>
                            <div>
                              <label
                                style={{ fontWeight: 700 }}
                                className="form-control-label"
                              >
                                EDT: {this.state.backupStorePromiseTime} {"Min"}
                              </label>
                            </div>
                          </div>

                          <select
                            id='clear'
                            name="store"
                            className="form-control mr-3"
                            onChange={this.handleSaveStore}
                          >
                            <option value="">Select Store</option>
                            {!_.isEmpty(this.props.tradeAreaName) && !_.isEmpty(this.props.tradeZoneName) && this.state.isClicked && backupStores &&
                              backupStores.map((store: any, index: any) => (
                                <option
                                  onClick={() =>
                                    this.props.saveSelectStoreId(store, this.state.orderType)
                                  }
                                  key={index}
                                  value={store.store_id}
                                >
                                  {store.store_name}
                                </option>
                              ))}
                          </select>
                        </div>
                      </div>
                    </div>
                  )}

                  {this.state.orderType == "Pickup" && (
                    <div className="row bg-white has-shadow p-1">
                      <div className="col-lg-3 d-flex align-items-center">
                        <div className="project-title d-flex align-items-center">
                          <div className="form-group">
                            <label className="form-control-label">
                              Special Instructions
                            </label>
                            <input
                              id="special_inst"
                              type="text"
                              name="special_inst"
                              required
                              data-msg="Please enter special Instructions"
                              className="input-material"
                              // onChange={this.props.handleMenuInput}
                              onChange={(e) => {
                                var inputValue = e.target.value.trimStart()
                                e.target.value = inputValue
                                this.props.handleMenuInput(e)
                              }}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-3 d-flex align-items-center">
                        <div className="form-group col">
                          <div className="row-wrapper">
                            <div>
                              {(stores && stores.length >= 1) && this.state.isPickUpSubmitClicked ? (
                                <label className="form-control-label green-text">
                                  Store Detected
                                </label>
                              ) : (
                                <label className="form-control-label">
                                  Select Pickup Store
                                </label>
                              )}
                              <span className="text-danger">*</span>
                            </div>
                          </div>

                          <select
                            id='clearPickupStore'
                            name="store"
                            className="form-control mr-3"
                            onChange={this.handleSaveStore}
                          >
                            <option value="">Select Store</option>
                            {stores && this.state.isPickupClicked &&
                              stores.map((store: any, index: any) => (
                                <option
                                  onClick={() => {
                                    this.props.saveSelectStoreId(store, this.state.orderType)
                                  }
                                  }
                                  key={index}
                                  value={store.store_id}
                                >
                                  {store.store_name}
                                </option>
                              ))}
                          </select>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </section>

            <section className="updates no-padding">
              {(!_.isEmpty(customer) && !_.isEmpty(selectedStore)) ?
                <div className="container-fluid">
                  <div className="row">
                    {/* <!-- Groups --> */}
                    <div className="statistics col-lg-2 col-12 listScroll">
                      <div style={{ textAlign: 'center' }}>
                        <Loader
                          type="ThreeDots"
                          color="#e41837"
                          visible={isLoading}
                          height={80}
                          width={80}
                        // timeout={3000} //3 secs
                        />
                      </div>
                      {groups && isLoading === false &&
                        groups.map((obj: any, index: any) => {
                          return (
                            // <div className="statistic d-flex justify-content-center has-shadow img-fluid" key={index} style={{ cursor: "pointer" }} onClick={() => { this.props.menuItemsListByGroupsID(obj.group_id) }}>
                            <div
                              className={
                                obj.group_id == this.state.active_group_id
                                  ? this.getStyle()
                                  : "statistic d-flex justify-content-center bg-white has-shadow img-fluid"
                              }
                              key={index}
                              style={{ cursor: "pointer" }}
                              onClick={() =>
                                this.menuItemsListByGroupsID(obj.group_id)
                              }
                            >
                              <h6 style={{ margin: '0px' }}>{obj.group_name}</h6>
                            </div>
                          );
                        })}
                    </div>
                    <div className="statistics col-lg-3 col-12 listScroll">
                      {itemsbygroupid.length > 0 ? (
                        itemsbygroupid.map((obj: any, index: any) => {
                          return (
                            <div
                              className="card"
                              style={{ padding: "20px", cursor: "pointer" }}
                            >
                              <div
                                className="row d-flex align-items-center mx-1"
                                onClick={() => this.getItemDetail(obj)}
                              >


                                <div className="d-flex ">
                                  {obj.menu_item_id && obj.menu_item_id !== "" ? (
                                    <img
                                      style={{ width: "50px", height: "50px" }}
                                      src={`${BASE_URL}${JSON.parse(obj.item_size)[0].image_url
                                        }`}
                                    />
                                  ) : (
                                    [
                                      obj.image_url ? (
                                        <img
                                          style={{
                                            width: "50px",
                                            height: "50px",
                                          }}
                                          src={`${BASE_URL}${obj.image_url}`}
                                        />
                                      ) : (
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/assets/img/optp.png"
                                          }
                                          alt="product"
                                          style={{
                                            width: "50px",
                                            height: "50px",
                                          }}
                                          className="img-fluid backgroud"
                                        />
                                      ),
                                    ]
                                  )}
                                  <div className="d-flex flex-column ml-2">

                                    <h6>
                                      {" "}
                                      {(obj.item_name && obj.item_name) ||
                                        (obj.combo_name && obj.combo_name)}
                                    </h6>
                                    {obj.item_size &&
                                      (JSON.parse(obj.item_size)[0].discount_price || JSON.parse(obj.item_size)[0].discount_price == 0) &&
                                      JSON.parse(obj.item_size)[0].order_channel ===
                                      "callcenter" && (
                                        <div className="price-box">
                                          <h6 className="product-price">
                                            {priceunit +
                                              JSON.parse(obj.item_size)[0]
                                                .discount_price
                                            }
                                          </h6>
                                          <h6
                                            style={{
                                              textDecoration: "line-through",
                                              color: "rgb(192 10 39)",
                                              paddingLeft: "1rem",
                                            }}
                                            className="product-price"
                                          >
                                            {priceunit + JSON.parse(obj.item_size)[0].mrp}
                                          </h6>
                                        </div>
                                      )}
                                    {obj.item_size &&
                                      JSON.parse(obj.item_size)[0].discount_price ==
                                      null && (
                                        <div className="price-box">
                                          <h6 className="product-price">
                                            {priceunit + JSON.parse(obj.item_size)[0].mrp}
                                          </h6>
                                        </div>
                                      )}

                                    {obj.combo_id > 0 &&
                                      (obj.discount_price || obj.discount_price == 0) &&
                                      obj.order_channel == "callcenter" && (
                                        <div className="price-box">
                                          <h6 className="product-price">
                                            {/* {comboData.combo_mrp_price} PKR */}
                                            {priceunit + obj.discount_price}
                                          </h6>

                                          <h6
                                            style={{
                                              textDecoration: "line-through",
                                              color: "rgb(192 10 39)",
                                              paddingLeft: "1rem",
                                            }}
                                            className="product-price"
                                          >
                                            {priceunit + obj.combo_mrp_price}
                                          </h6>
                                        </div>
                                      )}

                                    {obj.combo_id > 0 &&
                                      obj.discount_price == null && (
                                        <div className="price-box">
                                          <h6 className="product-price">
                                            {priceunit + obj.combo_mrp_price}
                                          </h6>
                                        </div>
                                      )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        })
                      ) : (
                        <div className="col-12 statistic d-flex justify-content-center">
                          <h4>No Item</h4>
                        </div>
                      )}
                    </div>
                    <div className="col-lg-3 col-12 no-padding listScroll">
                      <div className="daily-feeds card">
                        {!_.isEmpty(itemData) && (
                          <div className="d-flex justify-content-between">
                            <div className="card-header">
                              <h3 className="h4">
                                {itemData.item_name
                                  ? itemData.item_name
                                  : itemData.combo_name}
                              </h3>
                              <h5>{priceunit + this.state.itemPrice}</h5>
                            </div>
                            <button
                              className="btn btn-xs btn-danger m-2"
                              onClick={() => this.handleAdd(itemData)}
                              disabled={this.state.isAddButtonDisable}
                            >
                              {
                                this.state.isAddButtonDisable ? 'Adding' : 'Add'
                              }
                            </button>
                          </div>
                        )}
                        {!_.isEmpty(itemData) && itemData.item_size && (
                          <div className="product-filters-container">
                            <div className="product-single-filter">
                              <div
                                className="form-group mb-0 p-2 pb-0"
                                style={{ paddingLeft: 0 }}
                              >
                                <h5 style={{ paddingBottom: "0.5rem" }} id="txt">
                                  Choose an option
                                </h5>
                                <select
                                  onChange={this.renderItemPrice}
                                  className="custom-select drop-down custom-select-md mb-3"
                                  name="country"
                                >
                                  {JSON.parse(itemData.item_size).map(
                                    (itemSize: any, index: any) => (
                                      <option
                                        key={index}
                                        value={JSON.stringify(itemSize)}
                                        {...(this.state.selectedsize.size ==
                                          itemSize.size && { selected: true })}
                                      >
                                        {itemSize.size}
                                      </option>
                                    )
                                  )}
                                </select>
                              </div>
                            </div>
                          </div>
                        )}

                        {!_.isEmpty(itemData) && (
                          <div className="card-body no-padding">
                            <div className="item">
                              <div className="form-group row">
                                <div className="col px-1">
                                  {/* For Combos */}
                                  {(!_.isEmpty(itemData) && !_.isEmpty(itemData.choices) && itemData.choices.length > 0 && !_.isEmpty(comboDetails)) && <div className="ComboTabs">
                                    <Tabs
                                      id="uncontrolled-tab-example"
                                      className="mb-1 mt-0 justify-content-center"
                                      activeKey={this.state.combo_active_key}
                                      onSelect={(key, e) => {
                                        this.changeTab(key)
                                      }}
                                    >
                                      {comboDetails.map((obj: any, tabIndex: any) => {
                                        return <Tab className="select-item" eventKey={tabIndex} title={`${obj.group_name}`}>
                                          {/* For Full Pizza */}
                                          {(obj.is_full_pizza == 1 && !_.isEmpty(modifiers)) &&
                                            Object.keys(modifiers).map((key, index) => {
                                              return (
                                                <div
                                                  key={key}
                                                  className={`form-group-custom-control ${index > 0 && 'mt-4'}`}
                                                >
                                                  <div className="d-flex justify-content-between">
                                                    <h4>{key}</h4>
                                                    <span>{((modifiers[key].is_crust && modifiers[key].is_crust == 1)) ?
                                                      modifiers[key].min == 0 ?
                                                        <h6 className="mb-0 required-label">
                                                          Optional
                                                        </h6>
                                                        :
                                                        <h6 id={`${key}`} className="mb-0 required-label">
                                                          Required
                                                        </h6>
                                                      :
                                                      modifiers[key][0].modGroup_min_quantity == 0 ?
                                                        <h6 className="mb-0 required-label">
                                                          Optional
                                                        </h6>
                                                        :
                                                        <h6 id={`${key}`} className="required-label">
                                                          Required
                                                        </h6>
                                                    }</span>
                                                  </div>
                                                  {!modifiers[key].min ? <span>
                                                    <h6 className="mb-0 required-label">
                                                      Select any {modifiers[key][0].modGroup_max_quantity}
                                                    </h6>
                                                  </span> : <span>
                                                    <h6 className="mb-0 required-label">
                                                      Select any {modifiers[key].min}
                                                    </h6>
                                                  </span>
                                                  }
                                                  {
                                                    (modifiers[key].is_crust && modifiers[key].is_crust == 1) ?
                                                      Object.keys(modifiers[key]).map((subkey, index) => {
                                                        let found = 0;
                                                        const checkMod = (obj: any) => obj.variant_id && (obj.variant_id.id === (this.state.selectedsize.variant_id && this.state.selectedsize.variant_id.id));;
                                                        return (
                                                          Array.isArray(modifiers[key][subkey]) &&
                                                          <div
                                                            key={subkey}
                                                            className="form-group-custom-control mt-2"
                                                          >
                                                            {modifiers[key][subkey].map((obj: any) => {
                                                              if (found == 0) {
                                                                if (obj.mod_variant_id) {
                                                                  if (obj.mod_variant_id.filter(checkMod).length > 0) {
                                                                    found += 1;
                                                                    return <h6>{subkey}</h6>
                                                                  }
                                                                } else {
                                                                  found += 1;
                                                                  return <h6>{subkey}</h6>
                                                                }
                                                              } else {
                                                                return true;
                                                              }
                                                            })
                                                            }

                                                            {modifiers[key][subkey].map(
                                                              (item: any, index: any) => {
                                                                if (
                                                                  item.modifier_type ==
                                                                  "single"
                                                                ) {
                                                                  if (item.mod_variant_id) {
                                                                    const checkUsername = (obj: any) => obj.variant_id && (obj.variant_id.id === (this.state.selectedsize.variant_id && this.state.selectedsize.variant_id.id));;
                                                                    return (
                                                                      item.mod_variant_id.some(checkUsername) &&
                                                                      <div
                                                                        onClick={() =>
                                                                          this.addModifier(key, subkey, item.modifier_name, modifiers[key])
                                                                        }
                                                                        className="d-flex pt-2 justify-content-between"
                                                                      >
                                                                        <span>
                                                                          {item.modifier_name}
                                                                        </span>
                                                                        <div className="add-btn d-flex">
                                                                          {(item.discount_price || item.discount_price == 0) && (   //For modifiers
                                                                            item.modifier_sale_price === 0
                                                                              ? null
                                                                              : <span style={{ marginRight: 5, color: 'rgb(0, 105, 145)' }}>
                                                                                (<span style={{ textDecoration: (item.discount_price || item.discount_price == 0) ? 'line-through' : 'none', color: '#d60000', fontSize: 9 }}>
                                                                                  {priceunit + item.modifier_sale_price}
                                                                                </span>
                                                                                {priceunit + Math.round(item.discount_price)}
                                                                                )
                                                                              </span>
                                                                          )}
                                                                          {item.modifier_sale_price === 0
                                                                            ? null
                                                                            : item.discount_price == null && <span>
                                                                              ( +
                                                                              {priceunit +
                                                                                item.modifier_sale_price
                                                                              }{" "}
                                                                              )
                                                                            </span>
                                                                          }
                                                                          {item.selected ? (
                                                                            <div className="added">
                                                                              <span className="btn-text-clr-added">
                                                                                Added
                                                                              </span>
                                                                            </div>
                                                                          ) : (
                                                                            <div className="notAdded">
                                                                              <span className="btn-text-clr">
                                                                                Add
                                                                              </span>
                                                                            </div>
                                                                          )}
                                                                        </div>
                                                                      </div>
                                                                    );
                                                                  } else {
                                                                    return (
                                                                      <div
                                                                        onClick={() =>
                                                                          this.addModifier(key, subkey, item.modifier_name, modifiers[key])
                                                                        }
                                                                        className="d-flex pt-2 justify-content-between"
                                                                      >
                                                                        <span>
                                                                          {item.modifier_name}
                                                                        </span>
                                                                        <div className="add-btn d-flex">
                                                                          {(item.discount_price || item.discount_price == 0) && (   //For modifiers
                                                                            item.modifier_sale_price === 0
                                                                              ? null
                                                                              : <span style={{ marginRight: 5, color: 'rgb(0, 105, 145)' }}>
                                                                                (<span style={{ textDecoration: (item.discount_price || item.discount_price == 0) ? 'line-through' : 'none', color: '#d60000', fontSize: 9 }}>
                                                                                  {priceunit + item.modifier_sale_price}
                                                                                </span>
                                                                                {priceunit + Math.round(item.discount_price)}
                                                                                )
                                                                              </span>
                                                                          )}
                                                                          {item.modifier_sale_price === 0
                                                                            ? null
                                                                            : item.discount_price == null && <span>
                                                                              ( +
                                                                              {priceunit +
                                                                                item.modifier_sale_price
                                                                              }{" "}
                                                                              )
                                                                            </span>
                                                                          }
                                                                          {item.selected ? (
                                                                            <div className="added">
                                                                              <span className="btn-text-clr-added">
                                                                                Added
                                                                              </span>
                                                                            </div>
                                                                          ) : (
                                                                            <div className="notAdded">
                                                                              <span className="btn-text-clr">
                                                                                Add
                                                                              </span>
                                                                            </div>
                                                                          )}
                                                                        </div>
                                                                      </div>
                                                                    );
                                                                  }
                                                                } else {
                                                                  return (
                                                                    <div className="multi-addon-row">
                                                                      <div
                                                                        key={index}
                                                                        className="flex-nowrap justify-content-start"
                                                                        onClick={() =>
                                                                          this.handleChangeChk(
                                                                            key,
                                                                            item.modifier_name
                                                                          )
                                                                        }
                                                                      >
                                                                        <input
                                                                          type="checkbox"
                                                                          checked={
                                                                            item.selected
                                                                          }
                                                                          className="checkbox-template"
                                                                          id="checkboxCustom1"
                                                                          name="adon"
                                                                        />
                                                                        <span
                                                                          style={{
                                                                            marginLeft:
                                                                              "20px",
                                                                          }}
                                                                        >
                                                                          {" "}
                                                                          {
                                                                            item.modifier_name
                                                                          }{" "}
                                                                          {(item.discount_price || item.discount_price == 0) && (   //For modifiers
                                                                            item.modifier_sale_price === 0
                                                                              ? null
                                                                              : <span style={{ marginRight: 5, color: 'rgb(0, 105, 145)' }}>
                                                                                (<span style={{ textDecoration: (item.discount_price || item.discount_price == 0) ? 'line-through' : 'none', color: '#d60000', fontSize: 9 }}>
                                                                                  {priceunit + item.modifier_sale_price}
                                                                                </span>
                                                                                {priceunit + Math.round(item.discount_price)}
                                                                                )
                                                                              </span>
                                                                          )}
                                                                          {item.modifier_sale_price === 0
                                                                            ? null
                                                                            : item.discount_price == null && <span>
                                                                              ({priceunit + item.modifier_sale_price} ){" "}
                                                                            </span>
                                                                          }
                                                                        </span>
                                                                      </div>
                                                                      {item.selected && item.max_quantity > 1 && (
                                                                        <div
                                                                          style={{
                                                                            display: "flex",
                                                                            width: "5rem",
                                                                            justifyContent:
                                                                              "space-between",
                                                                          }}
                                                                        >
                                                                          <div className="decrement-btn">
                                                                            <a
                                                                              className="dec-icon"
                                                                              onClick={() =>
                                                                                this.decrementQuantity(
                                                                                  key,
                                                                                  item.modifier_name
                                                                                )
                                                                              }
                                                                            >
                                                                              -
                                                                            </a>
                                                                          </div>
                                                                          <div
                                                                            style={{
                                                                              fontWeight:
                                                                                "bold",
                                                                              fontSize: 18,
                                                                            }}
                                                                          >
                                                                            {item.quantity}
                                                                          </div>
                                                                          <div className="increment-btn">
                                                                            <a
                                                                              className="inc-icon"
                                                                              onClick={() =>
                                                                                this.incrementQuantity(
                                                                                  key,
                                                                                  item.modifier_name
                                                                                )
                                                                              }
                                                                            >
                                                                              +
                                                                            </a>
                                                                          </div>
                                                                        </div>

                                                                        /* <NumericInput
                                                                      min={1}
                                                                      value={item.quantity}
                                                                      max={100}
                                                                      type="number"
                                                                      mobile
                                                                      size={10}
                                                                      className="numericInput"
                                                                      onChange={(value) =>
                                                                        this.handleCheckQuantity(key,item.modifier_name,value)
                                                                      }
                                                                    /> */
                                                                      )}
                                                                    </div>
                                                                  );
                                                                }
                                                              }
                                                            )}
                                                          </div>
                                                        )
                                                      })
                                                      :
                                                      <div
                                                        key={key}
                                                        className="form-group-custom-control mt-2"
                                                      >

                                                        {modifiers[key].map(
                                                          (item: any, index: any) => {
                                                            if (
                                                              item.modifier_type ==
                                                              "single"
                                                            ) {
                                                              return (
                                                                <div
                                                                  onClick={() =>
                                                                    this.addModifier(key, null, item.modifier_name, null)
                                                                  }
                                                                  className="d-flex pt-2 justify-content-between"
                                                                >
                                                                  <span>
                                                                    {item.modifier_name}
                                                                  </span>
                                                                  <div className="add-btn d-flex">
                                                                    {(item.discount_price || item.discount_price == 0) && (   //For modifiers
                                                                      item.modifier_sale_price === 0
                                                                        ? null
                                                                        : <span style={{ marginRight: 5, color: 'rgb(0, 105, 145)' }}>
                                                                          (<span style={{ textDecoration: (item.discount_price || item.discount_price == 0) ? 'line-through' : 'none', color: '#d60000', fontSize: 9 }}>
                                                                            {priceunit + item.modifier_sale_price}
                                                                          </span>
                                                                          {priceunit + Math.round(item.discount_price)}
                                                                          )
                                                                        </span>
                                                                    )}
                                                                    {item.modifier_sale_price === 0
                                                                      ? null
                                                                      : item.discount_price == null && <span>
                                                                        ( +
                                                                        {priceunit +
                                                                          item.modifier_sale_price
                                                                        }{" "}
                                                                        )
                                                                      </span>
                                                                    }
                                                                    {item.selected ? (
                                                                      <div className="added">
                                                                        <span className="btn-text-clr-added">
                                                                          Added
                                                                        </span>
                                                                      </div>
                                                                    ) : (
                                                                      <div className="notAdded">
                                                                        <span className="btn-text-clr">
                                                                          Add
                                                                        </span>
                                                                      </div>
                                                                    )}
                                                                  </div>
                                                                </div>
                                                              );
                                                            } else {
                                                              return (
                                                                <div className="multi-addon-row">
                                                                  <div
                                                                    key={index}
                                                                    className="flex-nowrap justify-content-start"
                                                                    onClick={() =>
                                                                      this.handleChangeChk(
                                                                        key,
                                                                        item.modifier_name
                                                                      )
                                                                    }
                                                                  >
                                                                    <input
                                                                      type="checkbox"
                                                                      checked={
                                                                        item.selected
                                                                      }
                                                                      className="checkbox-template"
                                                                      id="checkboxCustom1"
                                                                      name="adon"
                                                                    />
                                                                    <span
                                                                      style={{
                                                                        marginLeft:
                                                                          "20px",
                                                                      }}
                                                                    >
                                                                      {" "}
                                                                      {
                                                                        item.modifier_name
                                                                      }{" "}
                                                                      {(item.discount_price || item.discount_price == 0) && (   //For modifiers
                                                                        item.modifier_sale_price === 0
                                                                          ? null
                                                                          : <span style={{ marginRight: 5, color: 'rgb(0, 105, 145)' }}>
                                                                            (<span style={{ textDecoration: (item.discount_price || item.discount_price == 0) ? 'line-through' : 'none', color: '#d60000', fontSize: 9 }}>
                                                                              {priceunit + item.modifier_sale_price}
                                                                            </span>
                                                                            {priceunit + Math.round(item.discount_price)}
                                                                            )
                                                                          </span>
                                                                      )}
                                                                      {item.modifier_sale_price === 0
                                                                        ? null
                                                                        : item.discount_price == null && <span>
                                                                          ({priceunit + item.modifier_sale_price} ){" "}
                                                                        </span>
                                                                      }
                                                                    </span>
                                                                  </div>
                                                                  {item.selected && item.max_quantity > 1 && (
                                                                    <div
                                                                      style={{
                                                                        display: "flex",
                                                                        width: "5rem",
                                                                        justifyContent:
                                                                          "space-between",
                                                                      }}
                                                                    >
                                                                      <div className="decrement-btn">
                                                                        <a
                                                                          className="dec-icon"
                                                                          onClick={() =>
                                                                            this.decrementQuantity(
                                                                              key,
                                                                              item.modifier_name
                                                                            )
                                                                          }
                                                                        >
                                                                          -
                                                                        </a>
                                                                      </div>
                                                                      <div
                                                                        style={{
                                                                          fontWeight:
                                                                            "bold",
                                                                          fontSize: 18,
                                                                        }}
                                                                      >
                                                                        {item.quantity}
                                                                      </div>
                                                                      <div className="increment-btn">
                                                                        <a
                                                                          className="inc-icon"
                                                                          onClick={() =>
                                                                            this.incrementQuantity(
                                                                              key,
                                                                              item.modifier_name
                                                                            )
                                                                          }
                                                                        >
                                                                          +
                                                                        </a>
                                                                      </div>
                                                                    </div>

                                                                    /* <NumericInput
                                                                  min={1}
                                                                  value={item.quantity}
                                                                  max={100}
                                                                  type="number"
                                                                  mobile
                                                                  size={10}
                                                                  className="numericInput"
                                                                  onChange={(value) =>
                                                                    this.handleCheckQuantity(key,item.modifier_name,value)
                                                                  }
                                                                /> */
                                                                  )}
                                                                </div>
                                                              );
                                                            }
                                                          }
                                                        )}
                                                      </div>
                                                  }
                                                </div>
                                              )
                                            })
                                          }
                                          {/* For Half N HALF Pizza */}
                                          {(obj.is_half_n_half == 1 && !_.isEmpty(modifiers)) &&
                                            <>
                                              {Object.keys(modifiers).map((key, index) => {
                                                return (
                                                  (modifiers[key].is_crust && modifiers[key].is_crust == 1) &&
                                                  <div
                                                    key={key}
                                                    className={`form-group-custom-control ${index > 0 && 'mt-4'}`}
                                                  >
                                                    <div className="d-flex justify-content-between">
                                                      <h4>{key}</h4>
                                                      <span>{
                                                        (modifiers[key].min == 0 ?
                                                          <h6 className="mb-0 required-label">
                                                            Optional
                                                          </h6>
                                                          :
                                                          <h6 id={`${key}`} className="mb-0 required-label">
                                                            Required
                                                          </h6>)
                                                      }</span>
                                                    </div>
                                                    {!modifiers[key].min ? <span>
                                                      <h6 className="mb-0 required-label">
                                                        Select any {modifiers[key][0].modGroup_max_quantity}
                                                      </h6>
                                                    </span> : <span>
                                                      <h6 className="mb-0 required-label">
                                                        Select any {modifiers[key].min}
                                                      </h6>
                                                    </span>
                                                    }
                                                    {
                                                      Object.keys(modifiers[key]).map((subkey, index) => {
                                                        return (
                                                          Array.isArray(modifiers[key][subkey]) &&
                                                          <div
                                                            key={subkey}
                                                            className="form-group-custom-control mt-2"
                                                          >
                                                            {Object.keys(modifiers[key]).length > 4 && <strong>{subkey}</strong>}

                                                            {modifiers[key][subkey].map(
                                                              (item: any, index: any) => {
                                                                if (
                                                                  item.modifier_type ==
                                                                  "single"
                                                                ) {
                                                                  return (
                                                                    <div
                                                                      onClick={() =>
                                                                        this.addModifier(key, subkey, item.modifier_name, modifiers[key])
                                                                      }
                                                                      className="d-flex pt-2 justify-content-between"
                                                                    >
                                                                      <span>
                                                                        {item.modifier_name}
                                                                      </span>
                                                                      <div className="add-btn d-flex">
                                                                        {(item.discount_price || item.discount_price == 0) && (   //For modifiers
                                                                          item.modifier_sale_price === 0
                                                                            ? null
                                                                            : <span style={{ marginRight: 5, color: 'rgb(0, 105, 145)' }}>
                                                                              (<span style={{ textDecoration: (item.discount_price || item.discount_price == 0) ? 'line-through' : 'none', color: '#d60000', fontSize: 9 }}>
                                                                                {priceunit + item.modifier_sale_price}
                                                                              </span>
                                                                              {priceunit + Math.round(item.discount_price)}
                                                                              )
                                                                            </span>
                                                                        )}
                                                                        {item.modifier_sale_price === 0
                                                                          ? null
                                                                          : item.discount_price == null && <span>
                                                                            ( +
                                                                            {priceunit +
                                                                              item.modifier_sale_price
                                                                            }{" "}
                                                                            )
                                                                          </span>
                                                                        }
                                                                        {item.selected ? (
                                                                          <div className="added">
                                                                            <span className="btn-text-clr-added">
                                                                              Added
                                                                            </span>
                                                                          </div>
                                                                        ) : (
                                                                          <div className="notAdded">
                                                                            <span className="btn-text-clr">
                                                                              Add
                                                                            </span>
                                                                          </div>
                                                                        )}
                                                                      </div>
                                                                    </div>
                                                                  )
                                                                } else {
                                                                  return (
                                                                    <div className="multi-addon-row">
                                                                      <div
                                                                        key={index}
                                                                        className="flex-nowrap justify-content-start"
                                                                        onClick={() =>
                                                                          this.handleChangeChk(
                                                                            key,
                                                                            item.modifier_name
                                                                          )
                                                                        }
                                                                      >
                                                                        <input
                                                                          type="checkbox"
                                                                          checked={
                                                                            item.selected
                                                                          }
                                                                          className="checkbox-template"
                                                                          id="checkboxCustom1"
                                                                          name="adon"
                                                                        />
                                                                        <span
                                                                          style={{
                                                                            marginLeft:
                                                                              "20px",
                                                                          }}
                                                                        >
                                                                          {" "}
                                                                          {
                                                                            item.modifier_name
                                                                          }{" "}
                                                                          {(item.discount_price || item.discount_price == 0) && (   //For modifiers
                                                                            item.modifier_sale_price === 0
                                                                              ? null
                                                                              : <span style={{ marginRight: 5, color: 'rgb(0, 105, 145)' }}>
                                                                                (<span style={{ textDecoration: (item.discount_price || item.discount_price == 0) ? 'line-through' : 'none', color: '#d60000', fontSize: 9 }}>
                                                                                  {priceunit + item.modifier_sale_price}
                                                                                </span>
                                                                                {priceunit + Math.round(item.discount_price)}
                                                                                )
                                                                              </span>
                                                                          )}
                                                                          {item.modifier_sale_price === 0
                                                                            ? null
                                                                            : item.discount_price == null && <span>
                                                                              ({priceunit + item.modifier_sale_price} ){" "}
                                                                            </span>
                                                                          }
                                                                        </span>
                                                                      </div>
                                                                      {item.selected && item.max_quantity > 1 && (
                                                                        <div
                                                                          style={{
                                                                            display: "flex",
                                                                            width: "5rem",
                                                                            justifyContent:
                                                                              "space-between",
                                                                          }}
                                                                        >
                                                                          <div className="decrement-btn">
                                                                            <a
                                                                              className="dec-icon"
                                                                              onClick={() =>
                                                                                this.decrementQuantity(
                                                                                  key,
                                                                                  item.modifier_name
                                                                                )
                                                                              }
                                                                            >
                                                                              -
                                                                            </a>
                                                                          </div>
                                                                          <div
                                                                            style={{
                                                                              fontWeight:
                                                                                "bold",
                                                                              fontSize: 18,
                                                                            }}
                                                                          >
                                                                            {item.quantity}
                                                                          </div>
                                                                          <div className="increment-btn">
                                                                            <a
                                                                              className="inc-icon"
                                                                              onClick={() =>
                                                                                this.incrementQuantity(
                                                                                  key,
                                                                                  item.modifier_name
                                                                                )
                                                                              }
                                                                            >
                                                                              +
                                                                            </a>
                                                                          </div>
                                                                        </div>
                                                                      )}
                                                                    </div>
                                                                  );
                                                                }
                                                              }
                                                            )}
                                                          </div>
                                                        )
                                                      })
                                                    }
                                                  </div>
                                                )
                                              })}
                                              <div className={`form-group-custom-control mt-4`}>
                                                <div className="d-flex justify-content-between">
                                                  <h4>Flavour</h4>
                                                  <span><h6 className="mb-0 required-label">
                                                    Required
                                                  </h6></span>
                                                </div>
                                                <div className="ItemTabs">
                                                  <Tabs
                                                    id="uncontrolled-tab-example"
                                                    className="mb-1 mt-0 justify-content-center"
                                                    activeKey={this.state.choice_active_key}
                                                    onSelect={(key, e) => {
                                                      if (key == `choice${tabIndex}1stHalf`) {
                                                        this.setState({ choice_active_key: key })
                                                      } else {
                                                        let halfNHalfItemReadyFlag = this.combohalfNHalfItemReady(halfNHalfModifiers);
                                                        if (!halfNHalfItemReadyFlag) {
                                                          toast.error(`Please select a 1st flavour`, { position: toast.POSITION.BOTTOM_RIGHT, hideProgressBar: true, autoClose: 3000 });
                                                        } else {
                                                          this.setState({ choice_active_key: key })
                                                        }
                                                      }
                                                    }}
                                                  >
                                                    <Tab className="select-item" eventKey={`choice${tabIndex}1stHalf`} title="1st Half">
                                                      {!_.isEmpty(halfNHalfModifiers) &&
                                                        Object.keys(halfNHalfModifiers[0].data).map((key, index) => {
                                                          return (
                                                            (halfNHalfModifiers[0].data[key].is_flavour && halfNHalfModifiers[0].data[key].is_flavour == 1) &&
                                                            <div key={key} className={`form-group-custom-control ${index > 0 && 'mt-4'}`}>
                                                              <div className="d-flex justify-content-between">
                                                                <h4>{key} 1</h4>
                                                                <span>
                                                                  {((halfNHalfModifiers[0].data[key].is_crust && halfNHalfModifiers[0].data[key].is_crust == 1) || (halfNHalfModifiers[0].data[key].is_flavour && halfNHalfModifiers[0].data[key].is_flavour == 1)) ?
                                                                    halfNHalfModifiers[0].data[key].min == 0 ?
                                                                      <h6 className="mb-0 required-label">
                                                                        Optional
                                                                      </h6>
                                                                      :
                                                                      <h6 id={`${key}`} className="mb-0 required-label">
                                                                        Required
                                                                      </h6>
                                                                    :
                                                                    halfNHalfModifiers[0].data[key][0].modGroup_min_quantity == 0 ?
                                                                      <h6 className="mb-0 required-label">
                                                                        Optional
                                                                      </h6>
                                                                      :
                                                                      <h6 id={`${key}`} className="required-label">
                                                                        Required
                                                                      </h6>
                                                                  }
                                                                </span>
                                                              </div>
                                                              {!halfNHalfModifiers[0].data[key].min ? <span>
                                                                <h6 className="mb-0 required-label">
                                                                  Select any {halfNHalfModifiers[0].data[key][0].modGroup_max_quantity}
                                                                </h6>
                                                              </span> : <span>
                                                                <h6 className="mb-0 required-label">
                                                                  Select any {halfNHalfModifiers[0].data[key].min}
                                                                </h6>
                                                              </span>
                                                              }
                                                              {
                                                                Object.keys(halfNHalfModifiers[0].data[key]).map((subkey, index) => {
                                                                  let found = 0;
                                                                  const checkMod = (obj: any) => obj.variant_id && (obj.variant_id.id === (this.state.selectedsize.variant_id && this.state.selectedsize.variant_id.id));;
                                                                  return (
                                                                    Array.isArray(halfNHalfModifiers[0].data[key][subkey]) &&
                                                                    <div
                                                                      key={subkey}
                                                                      className="form-group-custom-control mt-2"
                                                                    >
                                                                      {halfNHalfModifiers[0].data[key][subkey].map((obj: any) => {
                                                                        if (found == 0) {
                                                                          if (obj.mod_variant_id) {
                                                                            if (obj.mod_variant_id.filter(checkMod).length > 0) {
                                                                              found += 1;
                                                                              return <h6>{subkey}</h6>
                                                                            }
                                                                          } else {
                                                                            found += 1;
                                                                            return <h6>{subkey}</h6>
                                                                          }
                                                                        } else {
                                                                          return true;
                                                                        }
                                                                      })
                                                                      }
                                                                      {halfNHalfModifiers[0].data[key][subkey].map(
                                                                        (item: any, index: any) => {
                                                                          if (
                                                                            item.modifier_type ==
                                                                            "single"
                                                                          ) {
                                                                            if (item.mod_variant_id) {
                                                                              const checkUsername = (obj: any) => obj.variant_id && (obj.variant_id.id === (this.state.selectedsize.variant_id && this.state.selectedsize.variant_id.id));;
                                                                              return (
                                                                                item.mod_variant_id.some(checkUsername) &&
                                                                                <div
                                                                                  onClick={() =>
                                                                                    this.addHalfModifier(key, subkey, item.modifier_name, halfNHalfModifiers[0].data[key], 0)
                                                                                  }
                                                                                  className="d-flex pt-2 justify-content-between"
                                                                                >
                                                                                  <span>
                                                                                    {item.modifier_name}
                                                                                  </span>
                                                                                  <div className="add-btn d-flex">
                                                                                    {(item.discount_price || item.discount_price == 0) && (   //For modifiers
                                                                                      item.modifier_sale_price === 0
                                                                                        ? null
                                                                                        : <span style={{ marginRight: 5, color: 'rgb(0, 105, 145)' }}>
                                                                                          (<span style={{ textDecoration: (item.discount_price || item.discount_price == 0) ? 'line-through' : 'none', color: '#d60000', fontSize: 9 }}>
                                                                                            {priceunit + item.modifier_sale_price}
                                                                                          </span>
                                                                                          {priceunit + Math.round(item.discount_price)}
                                                                                          )
                                                                                        </span>
                                                                                    )}
                                                                                    {item.modifier_sale_price === 0
                                                                                      ? null
                                                                                      : item.discount_price == null && <span>
                                                                                        ( +
                                                                                        {priceunit +
                                                                                          item.modifier_sale_price
                                                                                        }{" "}
                                                                                        )
                                                                                      </span>
                                                                                    }
                                                                                    {item.selected ? (
                                                                                      <div className="added">
                                                                                        <span className="btn-text-clr-added">
                                                                                          Added
                                                                                        </span>
                                                                                      </div>
                                                                                    ) : (
                                                                                      <div className="notAdded">
                                                                                        <span className="btn-text-clr">
                                                                                          Add
                                                                                        </span>
                                                                                      </div>
                                                                                    )}
                                                                                  </div>
                                                                                </div>
                                                                              );
                                                                            } else {
                                                                              return (
                                                                                <div
                                                                                  onClick={() =>
                                                                                    this.addHalfModifier(key, subkey, item.modifier_name, halfNHalfModifiers[0].data[key], 0)
                                                                                  }
                                                                                  className="d-flex pt-2 justify-content-between"
                                                                                >
                                                                                  <span>
                                                                                    {item.modifier_name}
                                                                                  </span>
                                                                                  <div className="add-btn d-flex">
                                                                                    {(item.discount_price || item.discount_price == 0) && (   //For modifiers
                                                                                      item.modifier_sale_price === 0
                                                                                        ? null
                                                                                        : <span style={{ marginRight: 5, color: 'rgb(0, 105, 145)' }}>
                                                                                          (<span style={{ textDecoration: (item.discount_price || item.discount_price == 0) ? 'line-through' : 'none', color: '#d60000', fontSize: 9 }}>
                                                                                            {priceunit + item.modifier_sale_price}
                                                                                          </span>
                                                                                          {priceunit + Math.round(item.discount_price)}
                                                                                          )
                                                                                        </span>
                                                                                    )}
                                                                                    {item.modifier_sale_price === 0
                                                                                      ? null
                                                                                      : item.discount_price == null && <span>
                                                                                        ( +
                                                                                        {priceunit +
                                                                                          item.modifier_sale_price
                                                                                        }{" "}
                                                                                        )
                                                                                      </span>
                                                                                    }
                                                                                    {item.selected ? (
                                                                                      <div className="added">
                                                                                        <span className="btn-text-clr-added">
                                                                                          Added
                                                                                        </span>
                                                                                      </div>
                                                                                    ) : (
                                                                                      <div className="notAdded">
                                                                                        <span className="btn-text-clr">
                                                                                          Add
                                                                                        </span>
                                                                                      </div>
                                                                                    )}
                                                                                  </div>
                                                                                </div>
                                                                              );
                                                                            }
                                                                          } else {
                                                                            return (
                                                                              <div className="multi-addon-row">
                                                                                <div
                                                                                  key={index}
                                                                                  className="flex-nowrap justify-content-start"
                                                                                  onClick={() =>
                                                                                    this.handleCheckBoxForHalf(subkey, item.modifier_name, 0)
                                                                                  }
                                                                                >
                                                                                  <input
                                                                                    type="checkbox"
                                                                                    checked={
                                                                                      item.selected
                                                                                    }
                                                                                    className="checkbox-template"
                                                                                    id="checkboxCustom1"
                                                                                    name="adon"
                                                                                  />
                                                                                  <span
                                                                                    style={{
                                                                                      marginLeft:
                                                                                        "20px",
                                                                                    }}
                                                                                  >
                                                                                    {" "}
                                                                                    {
                                                                                      item.modifier_name
                                                                                    }{" "}
                                                                                    {(item.discount_price || item.discount_price == 0) && (   //For modifiers
                                                                                      item.modifier_sale_price === 0
                                                                                        ? null
                                                                                        : <span style={{ marginRight: 5, color: 'rgb(0, 105, 145)' }}>
                                                                                          (<span style={{ textDecoration: (item.discount_price || item.discount_price == 0) ? 'line-through' : 'none', color: '#d60000', fontSize: 9 }}>
                                                                                            {priceunit + item.modifier_sale_price}
                                                                                          </span>
                                                                                          {priceunit + Math.round(item.discount_price)}
                                                                                          )
                                                                                        </span>
                                                                                    )}
                                                                                    {item.modifier_sale_price === 0
                                                                                      ? null
                                                                                      : item.discount_price == null && <span>
                                                                                        ({priceunit + item.modifier_sale_price} ){" "}
                                                                                      </span>
                                                                                    }
                                                                                  </span>
                                                                                </div>
                                                                                {item.selected && item.max_quantity > 1 && (
                                                                                  <div
                                                                                    style={{
                                                                                      display: "flex",
                                                                                      width: "5rem",
                                                                                      justifyContent:
                                                                                        "space-between",
                                                                                    }}
                                                                                  >
                                                                                    <div className="decrement-btn">
                                                                                      <a
                                                                                        className="dec-icon"
                                                                                        onClick={() =>
                                                                                          this.decrementQuantity(
                                                                                            key,
                                                                                            item.modifier_name
                                                                                          )
                                                                                        }
                                                                                      >
                                                                                        -
                                                                                      </a>
                                                                                    </div>
                                                                                    <div
                                                                                      style={{
                                                                                        fontWeight:
                                                                                          "bold",
                                                                                        fontSize: 18,
                                                                                      }}
                                                                                    >
                                                                                      {item.quantity}
                                                                                    </div>
                                                                                    <div className="increment-btn">
                                                                                      <a
                                                                                        className="inc-icon"
                                                                                        onClick={() =>
                                                                                          this.incrementQuantity(
                                                                                            key,
                                                                                            item.modifier_name
                                                                                          )
                                                                                        }
                                                                                      >
                                                                                        +
                                                                                      </a>
                                                                                    </div>
                                                                                  </div>
                                                                                )}
                                                                              </div>
                                                                            );
                                                                          }
                                                                        }
                                                                      )}
                                                                    </div>
                                                                  )
                                                                })
                                                              }
                                                            </div>
                                                          )
                                                        })}
                                                      {!_.isEmpty(halfNHalfModifiers) &&
                                                        Object.keys(halfNHalfModifiers[0].data).map((key, index) => {
                                                          return (
                                                            ((!halfNHalfModifiers[0].data[key].min) && (halfNHalfModifiers[0].data[key][0].is_toping && halfNHalfModifiers[0].data[key][0].is_toping == 1)) ?
                                                              <div key={key} className={`form-group-custom-control ${index > 0 && 'mt-4'}`}>
                                                                <div className="d-flex justify-content-between">
                                                                  <h4>{key}</h4>
                                                                  <span>
                                                                    {((halfNHalfModifiers[0].data[key].is_crust && halfNHalfModifiers[0].data[key].is_crust == 1) || (halfNHalfModifiers[0].data[key].is_flavour && halfNHalfModifiers[0].data[key].is_flavour == 1)) ?
                                                                      halfNHalfModifiers[0].data[key].min == 0 ?
                                                                        <h6 className="mb-0 required-label">
                                                                          Optional
                                                                        </h6>
                                                                        :
                                                                        <h6 id={`${key}`} className="mb-0 required-label">
                                                                          Required
                                                                        </h6>
                                                                      :
                                                                      halfNHalfModifiers[0].data[key][0].modGroup_min_quantity == 0 ?
                                                                        <h6 className="mb-0 required-label">
                                                                          Optional
                                                                        </h6>
                                                                        :
                                                                        <h6 id={`${key}`} className="required-label">
                                                                          Required
                                                                        </h6>
                                                                    }
                                                                  </span>
                                                                </div>
                                                                {!halfNHalfModifiers[0].data[key].min ? <span>
                                                                  <h6 className="mb-0 required-label">
                                                                    Select any {halfNHalfModifiers[0].data[key][0].modGroup_max_quantity}
                                                                  </h6>
                                                                </span> : <span>
                                                                  <h6 className="mb-0 required-label">
                                                                    Select any {halfNHalfModifiers[0].data[key].min}
                                                                  </h6>
                                                                </span>
                                                                }
                                                                {halfNHalfModifiers[0].data[key].map(
                                                                  (item: any, index: any) => {
                                                                    if (
                                                                      item.modifier_type ==
                                                                      "single"
                                                                    ) {
                                                                      return (
                                                                        <div
                                                                          onClick={() =>
                                                                            this.addHalfModifier(key, null, item.modifier_name, null, 0)
                                                                          }
                                                                          className="d-flex pt-2 justify-content-between"
                                                                        >
                                                                          <span>
                                                                            {item.modifier_name}
                                                                          </span>
                                                                          <div className="add-btn d-flex">
                                                                            {(item.discount_price || item.discount_price == 0) && (   //For modifiers
                                                                              item.modifier_sale_price === 0
                                                                                ? null
                                                                                : <span style={{ marginRight: 5, color: 'rgb(0, 105, 145)' }}>
                                                                                  (<span style={{ textDecoration: (item.discount_price || item.discount_price == 0) ? 'line-through' : 'none', color: '#d60000', fontSize: 9 }}>
                                                                                    {priceunit + item.modifier_sale_price}
                                                                                  </span>
                                                                                  {priceunit + Math.round(item.discount_price)}
                                                                                  )
                                                                                </span>
                                                                            )}
                                                                            {item.modifier_sale_price === 0
                                                                              ? null
                                                                              : item.discount_price == null && <span>
                                                                                ( +
                                                                                {priceunit +
                                                                                  item.modifier_sale_price
                                                                                }{" "}
                                                                                )
                                                                              </span>
                                                                            }
                                                                            {item.selected ? (
                                                                              <div className="added">
                                                                                <span className="btn-text-clr-added">
                                                                                  Added
                                                                                </span>
                                                                              </div>
                                                                            ) : (
                                                                              <div className="notAdded">
                                                                                <span className="btn-text-clr">
                                                                                  Add
                                                                                </span>
                                                                              </div>
                                                                            )}
                                                                          </div>
                                                                        </div>
                                                                      );
                                                                    } else {
                                                                      return (
                                                                        <div className="multi-addon-row">
                                                                          <div
                                                                            key={index}
                                                                            className="flex-nowrap justify-content-start"
                                                                            onClick={() =>
                                                                              this.handleCheckBoxForHalf(key, item.modifier_name, 0)
                                                                            }
                                                                          >
                                                                            <input
                                                                              type="checkbox"
                                                                              checked={
                                                                                item.selected
                                                                              }
                                                                              className="checkbox-template"
                                                                              id="checkboxCustom1"
                                                                              name="adon"
                                                                            />
                                                                            <span
                                                                              style={{
                                                                                marginLeft:
                                                                                  "20px",
                                                                              }}
                                                                            >
                                                                              {" "}
                                                                              {
                                                                                item.modifier_name
                                                                              }{" "}
                                                                              {(item.discount_price || item.discount_price == 0) && (   //For modifiers
                                                                                item.modifier_sale_price === 0
                                                                                  ? null
                                                                                  : <span style={{ marginRight: 5, color: 'rgb(0, 105, 145)' }}>
                                                                                    (<span style={{ textDecoration: (item.discount_price || item.discount_price == 0) ? 'line-through' : 'none', color: '#d60000', fontSize: 9 }}>
                                                                                      {priceunit + item.modifier_sale_price}
                                                                                    </span>
                                                                                    {priceunit + Math.round(item.discount_price)}
                                                                                    )
                                                                                  </span>
                                                                              )}
                                                                              {item.modifier_sale_price === 0
                                                                                ? null
                                                                                : item.discount_price == null && <span>
                                                                                  ({priceunit + item.modifier_sale_price} ){" "}
                                                                                </span>
                                                                              }
                                                                            </span>
                                                                          </div>
                                                                          {item.selected && item.max_quantity > 1 && (
                                                                            <div
                                                                              style={{
                                                                                display: "flex",
                                                                                width: "5rem",
                                                                                justifyContent:
                                                                                  "space-between",
                                                                              }}
                                                                            >
                                                                              <div className="decrement-btn">
                                                                                <a
                                                                                  className="dec-icon"
                                                                                  onClick={() =>
                                                                                    this.decrementQuantity(
                                                                                      key,
                                                                                      item.modifier_name
                                                                                    )
                                                                                  }
                                                                                >
                                                                                  -
                                                                                </a>
                                                                              </div>
                                                                              <div
                                                                                style={{
                                                                                  fontWeight:
                                                                                    "bold",
                                                                                  fontSize: 18,
                                                                                }}
                                                                              >
                                                                                {item.quantity}
                                                                              </div>
                                                                              <div className="increment-btn">
                                                                                <a
                                                                                  className="inc-icon"
                                                                                  onClick={() =>
                                                                                    this.incrementQuantity(
                                                                                      key,
                                                                                      item.modifier_name
                                                                                    )
                                                                                  }
                                                                                >
                                                                                  +
                                                                                </a>
                                                                              </div>
                                                                            </div>
                                                                          )}
                                                                        </div>
                                                                      );
                                                                    }
                                                                  }
                                                                )}
                                                              </div> : ""
                                                          )
                                                        })}
                                                    </Tab>
                                                    {obj.variant_id && obj.variant_id.id == 1 ? "" : <Tab className="select-item" eventKey={`choice${tabIndex}2ndHalf`} title="2nd Half">
                                                      {!_.isEmpty(halfNHalfModifiers) &&
                                                        Object.keys(halfNHalfModifiers[1].data).map((key, index) => {
                                                          return (
                                                            (halfNHalfModifiers[1].data[key].is_flavour && halfNHalfModifiers[1].data[key].is_flavour == 1) &&
                                                            <div key={key} className={`form-group-custom-control ${index > 0 && 'mt-4'}`}>
                                                              <div className="d-flex justify-content-between">
                                                                <h4>{key} 2</h4>
                                                                <span>
                                                                  {((halfNHalfModifiers[1].data[key].is_crust && halfNHalfModifiers[1].data[key].is_crust == 1) || (halfNHalfModifiers[1].data[key].is_flavour && halfNHalfModifiers[1].data[key].is_flavour == 1)) ?
                                                                    halfNHalfModifiers[1].data[key].min == 0 ?
                                                                      <h6 className="mb-0 required-label">
                                                                        Optional
                                                                      </h6>
                                                                      :
                                                                      <h6 id={`${key}`} className="mb-0 required-label">
                                                                        Required
                                                                      </h6>
                                                                    :
                                                                    halfNHalfModifiers[1].data[key][0].modGroup_min_quantity == 0 ?
                                                                      <h6 className="mb-0 required-label">
                                                                        Optional
                                                                      </h6>
                                                                      :
                                                                      <h6 id={`${key}`} className="required-label">
                                                                        Required
                                                                      </h6>
                                                                  }
                                                                </span>
                                                              </div>
                                                              {!halfNHalfModifiers[1].data[key].min ? <span>
                                                                <h6 className="mb-0 required-label">
                                                                  Select any {halfNHalfModifiers[1].data[key][0].modGroup_max_quantity}
                                                                </h6>
                                                              </span> : <span>
                                                                <h6 className="mb-0 required-label">
                                                                  Select any {halfNHalfModifiers[1].data[key].min}
                                                                </h6>
                                                              </span>
                                                              }
                                                              {
                                                                Object.keys(halfNHalfModifiers[1].data[key]).map((subkey, index) => {
                                                                  let found = 0;
                                                                  const checkMod = (obj: any) => obj.variant_id && (obj.variant_id.id === (this.state.selectedsize.variant_id && this.state.selectedsize.variant_id.id));;
                                                                  return (
                                                                    Array.isArray(halfNHalfModifiers[1].data[key][subkey]) &&
                                                                    <div
                                                                      key={subkey}
                                                                      className="form-group-custom-control mt-2"
                                                                    >
                                                                      {halfNHalfModifiers[1].data[key][subkey].map((obj: any) => {
                                                                        if (found == 0) {
                                                                          if (obj.mod_variant_id) {
                                                                            if (obj.mod_variant_id.filter(checkMod).length > 0) {
                                                                              found += 1;
                                                                              return <h6>{subkey}</h6>
                                                                            }
                                                                          } else {
                                                                            found += 1;
                                                                            return <h6>{subkey}</h6>
                                                                          }
                                                                        } else {
                                                                          return true;
                                                                        }
                                                                      })
                                                                      }
                                                                      {halfNHalfModifiers[1].data[key][subkey].map(
                                                                        (item: any, index: any) => {
                                                                          if (
                                                                            item.modifier_type ==
                                                                            "single"
                                                                          ) {
                                                                            if (item.mod_variant_id) {
                                                                              const checkUsername = (obj: any) => obj.variant_id && (obj.variant_id.id === (this.state.selectedsize.variant_id && this.state.selectedsize.variant_id.id));;
                                                                              return (
                                                                                item.mod_variant_id.some(checkUsername) &&
                                                                                <div
                                                                                  onClick={() =>
                                                                                    this.addHalfModifier(key, subkey, item.modifier_name, halfNHalfModifiers[1].data[key], 1)
                                                                                  }
                                                                                  className="d-flex pt-2 justify-content-between"
                                                                                >
                                                                                  <span>
                                                                                    {item.modifier_name}
                                                                                  </span>
                                                                                  <div className="add-btn d-flex">
                                                                                    {(item.discount_price || item.discount_price == 0) && (   //For modifiers
                                                                                      item.modifier_sale_price === 0
                                                                                        ? null
                                                                                        : <span style={{ marginRight: 5, color: 'rgb(0, 105, 145)' }}>
                                                                                          (<span style={{ textDecoration: (item.discount_price || item.discount_price == 0) ? 'line-through' : 'none', color: '#d60000', fontSize: 9 }}>
                                                                                            {priceunit + item.modifier_sale_price}
                                                                                          </span>
                                                                                          {priceunit + Math.round(item.discount_price)}
                                                                                          )
                                                                                        </span>
                                                                                    )}
                                                                                    {item.modifier_sale_price === 0
                                                                                      ? null
                                                                                      : item.discount_price == null && <span>
                                                                                        ( +
                                                                                        {priceunit +
                                                                                          item.modifier_sale_price
                                                                                        }{" "}
                                                                                        )
                                                                                      </span>
                                                                                    }
                                                                                    {item.selected ? (
                                                                                      <div className="added">
                                                                                        <span className="btn-text-clr-added">
                                                                                          Added
                                                                                        </span>
                                                                                      </div>
                                                                                    ) : (
                                                                                      <div className="notAdded">
                                                                                        <span className="btn-text-clr">
                                                                                          Add
                                                                                        </span>
                                                                                      </div>
                                                                                    )}
                                                                                  </div>
                                                                                </div>
                                                                              );
                                                                            } else {
                                                                              return (
                                                                                <div
                                                                                  onClick={() =>
                                                                                    this.addHalfModifier(key, subkey, item.modifier_name, halfNHalfModifiers[1].data[key], 1)
                                                                                  }
                                                                                  className="d-flex pt-2 justify-content-between"
                                                                                >
                                                                                  <span>
                                                                                    {item.modifier_name}
                                                                                  </span>
                                                                                  <div className="add-btn d-flex">
                                                                                    {(item.discount_price || item.discount_price == 0) && (   //For modifiers
                                                                                      item.modifier_sale_price === 0
                                                                                        ? null
                                                                                        : <span style={{ marginRight: 5, color: 'rgb(0, 105, 145)' }}>
                                                                                          (<span style={{ textDecoration: (item.discount_price || item.discount_price == 0) ? 'line-through' : 'none', color: '#d60000', fontSize: 9 }}>
                                                                                            {priceunit + item.modifier_sale_price}
                                                                                          </span>
                                                                                          {priceunit + Math.round(item.discount_price)}
                                                                                          )
                                                                                        </span>
                                                                                    )}
                                                                                    {item.modifier_sale_price === 0
                                                                                      ? null
                                                                                      : item.discount_price == null && <span>
                                                                                        ( +
                                                                                        {priceunit +
                                                                                          item.modifier_sale_price
                                                                                        }{" "}
                                                                                        )
                                                                                      </span>
                                                                                    }
                                                                                    {item.selected ? (
                                                                                      <div className="added">
                                                                                        <span className="btn-text-clr-added">
                                                                                          Added
                                                                                        </span>
                                                                                      </div>
                                                                                    ) : (
                                                                                      <div className="notAdded">
                                                                                        <span className="btn-text-clr">
                                                                                          Add
                                                                                        </span>
                                                                                      </div>
                                                                                    )}
                                                                                  </div>
                                                                                </div>
                                                                              );
                                                                            }
                                                                          } else {
                                                                            return (
                                                                              <div className="multi-addon-row">
                                                                                <div
                                                                                  key={index}
                                                                                  className="flex-nowrap justify-content-start"
                                                                                  onClick={() =>
                                                                                    this.handleCheckBoxForHalf(subkey, item.modifier_name, 1)
                                                                                  }
                                                                                >
                                                                                  <input
                                                                                    type="checkbox"
                                                                                    checked={
                                                                                      item.selected
                                                                                    }
                                                                                    className="checkbox-template"
                                                                                    id="checkboxCustom1"
                                                                                    name="adon"
                                                                                  />
                                                                                  <span
                                                                                    style={{
                                                                                      marginLeft:
                                                                                        "20px",
                                                                                    }}
                                                                                  >
                                                                                    {" "}
                                                                                    {
                                                                                      item.modifier_name
                                                                                    }{" "}
                                                                                    {(item.discount_price || item.discount_price == 0) && (   //For modifiers
                                                                                      item.modifier_sale_price === 0
                                                                                        ? null
                                                                                        : <span style={{ marginRight: 5, color: 'rgb(0, 105, 145)' }}>
                                                                                          (<span style={{ textDecoration: (item.discount_price || item.discount_price == 0) ? 'line-through' : 'none', color: '#d60000', fontSize: 9 }}>
                                                                                            {priceunit + item.modifier_sale_price}
                                                                                          </span>
                                                                                          {priceunit + Math.round(item.discount_price)}
                                                                                          )
                                                                                        </span>
                                                                                    )}
                                                                                    {item.modifier_sale_price === 0
                                                                                      ? null
                                                                                      : item.discount_price == null && <span>
                                                                                        ({priceunit + item.modifier_sale_price} ){" "}
                                                                                      </span>
                                                                                    }
                                                                                  </span>
                                                                                </div>
                                                                                {item.selected && item.max_quantity > 1 && (
                                                                                  <div
                                                                                    style={{
                                                                                      display: "flex",
                                                                                      width: "5rem",
                                                                                      justifyContent:
                                                                                        "space-between",
                                                                                    }}
                                                                                  >
                                                                                    <div className="decrement-btn">
                                                                                      <a
                                                                                        className="dec-icon"
                                                                                        onClick={() =>
                                                                                          this.decrementQuantity(
                                                                                            key,
                                                                                            item.modifier_name
                                                                                          )
                                                                                        }
                                                                                      >
                                                                                        -
                                                                                      </a>
                                                                                    </div>
                                                                                    <div
                                                                                      style={{
                                                                                        fontWeight:
                                                                                          "bold",
                                                                                        fontSize: 18,
                                                                                      }}
                                                                                    >
                                                                                      {item.quantity}
                                                                                    </div>
                                                                                    <div className="increment-btn">
                                                                                      <a
                                                                                        className="inc-icon"
                                                                                        onClick={() =>
                                                                                          this.incrementQuantity(
                                                                                            key,
                                                                                            item.modifier_name
                                                                                          )
                                                                                        }
                                                                                      >
                                                                                        +
                                                                                      </a>
                                                                                    </div>
                                                                                  </div>
                                                                                )}
                                                                              </div>
                                                                            );
                                                                          }
                                                                        }
                                                                      )}
                                                                    </div>
                                                                  )
                                                                })
                                                              }
                                                            </div>
                                                          )
                                                        })}
                                                      {/* {!_.isEmpty(halfNHalfModifiers) &&
                                                        Object.keys(halfNHalfModifiers[1].data).map((key, index) => {
                                                          return (
                                                            (!halfNHalfModifiers[1].data[key].is_crust && !halfNHalfModifiers[1].data[key].is_flavour) &&
                                                            <div key={key} className={`form-group-custom-control ${index > 0 && 'mt-4'}`}>
                                                              <div className="d-flex justify-content-between">
                                                                <h4>{key}</h4>
                                                                <span>
                                                                  {((halfNHalfModifiers[1].data[key].is_crust && halfNHalfModifiers[1].data[key].is_crust == 1) || (halfNHalfModifiers[1].data[key].is_flavour && halfNHalfModifiers[1].data[key].is_flavour == 1)) ?
                                                                    halfNHalfModifiers[1].data[key].min == 0 ?
                                                                      <h6 className="mb-0 required-label">
                                                                        Optional
                                                                      </h6>
                                                                      :
                                                                      <h6 id={`${key}`} className="mb-0 required-label">
                                                                        Required
                                                                      </h6>
                                                                    :
                                                                    halfNHalfModifiers[1].data[key][0].modGroup_min_quantity == 0 ?
                                                                      <h6 className="mb-0 required-label">
                                                                        Optional
                                                                      </h6>
                                                                      :
                                                                      <h6 id={`${key}`} className="required-label">
                                                                        Required
                                                                      </h6>
                                                                  }
                                                                </span>
                                                              </div>
                                                              {!halfNHalfModifiers[1].data[key].min ? <span>
                                                                <h6 className="mb-0 required-label">
                                                                  Select any {halfNHalfModifiers[1].data[key][0].modGroup_max_quantity}
                                                                </h6>
                                                              </span> : <span>
                                                                <h6 className="mb-0 required-label">
                                                                  Select any {halfNHalfModifiers[1].data[key].min}
                                                                </h6>
                                                              </span>
                                                              }
                                                              {halfNHalfModifiers[1].data[key].map(
                                                                (item: any, index: any) => {
                                                                  if (
                                                                    item.modifier_type ==
                                                                    "single"
                                                                  ) {
                                                                    return (
                                                                      <div
                                                                        onClick={() =>
                                                                          this.addHalfModifier(key, null, item.modifier_name, null, 1)
                                                                        }
                                                                        className="d-flex pt-2 justify-content-between"
                                                                      >
                                                                        <span>
                                                                          {item.modifier_name}
                                                                        </span>
                                                                        <div className="add-btn d-flex">
                                                                          {item.modifier_sale_price ==
                                                                            0 ||
                                                                            item.modifier_sale_price ==
                                                                            null ? (
                                                                            <span></span>
                                                                          ) : (
                                                                            <span>
                                                                              ( +
                                                                              {priceunit +
                                                                                item.modifier_sale_price
                                                                              }{" "}
                                                                              )
                                                                            </span>
                                                                          )}
                                                                          {item.selected ? (
                                                                            <div className="added">
                                                                              <span className="btn-text-clr-added">
                                                                                Added
                                                                              </span>
                                                                            </div>
                                                                          ) : (
                                                                            <div className="notAdded">
                                                                              <span className="btn-text-clr">
                                                                                Add
                                                                              </span>
                                                                            </div>
                                                                          )}
                                                                        </div>
                                                                      </div>
                                                                    );
                                                                  } else {
                                                                    return (
                                                                      <div className="multi-addon-row">
                                                                        <div
                                                                          key={index}
                                                                          className="flex-nowrap justify-content-start"
                                                                          onClick={() =>
                                                                            this.handleCheckBoxForHalf(key, item.modifier_name, 1)
                                                                          }
                                                                        >
                                                                          <input
                                                                            type="checkbox"
                                                                            checked={
                                                                              item.selected
                                                                            }
                                                                            className="checkbox-template"
                                                                            id="checkboxCustom1"
                                                                            name="adon"
                                                                          />
                                                                          <span
                                                                            style={{
                                                                              marginLeft:
                                                                                "20px",
                                                                            }}
                                                                          >
                                                                            {" "}
                                                                            {
                                                                              item.modifier_name
                                                                            }{" "}
                                                                            {item.modifier_sale_price ===
                                                                              0
                                                                              ? null
                                                                              : `(${priceunit + item.modifier_sale_price} )`}{" "}
                                                                          </span>
                                                                        </div>
                                                                        {item.selected && item.max_quantity > 1 && (
                                                                          <div
                                                                            style={{
                                                                              display: "flex",
                                                                              width: "5rem",
                                                                              justifyContent:
                                                                                "space-between",
                                                                            }}
                                                                          >
                                                                            <div className="decrement-btn">
                                                                              <a
                                                                                className="dec-icon"
                                                                                onClick={() =>
                                                                                  this.decrementQuantity(
                                                                                    key,
                                                                                    item.modifier_name
                                                                                  )
                                                                                }
                                                                              >
                                                                                -
                                                                              </a>
                                                                            </div>
                                                                            <div
                                                                              style={{
                                                                                fontWeight:
                                                                                  "bold",
                                                                                fontSize: 18,
                                                                              }}
                                                                            >
                                                                              {item.quantity}
                                                                            </div>
                                                                            <div className="increment-btn">
                                                                              <a
                                                                                className="inc-icon"
                                                                                onClick={() =>
                                                                                  this.incrementQuantity(
                                                                                    key,
                                                                                    item.modifier_name
                                                                                  )
                                                                                }
                                                                              >
                                                                                +
                                                                              </a>
                                                                            </div>
                                                                          </div>
                                                                        )}
                                                                      </div>
                                                                    );
                                                                  }
                                                                }
                                                              )}
                                                            </div>
                                                          )
                                                        })} */}
                                                    </Tab>
                                                    }
                                                  </Tabs>
                                                </div>
                                              </div>
                                              {!_.isEmpty(modifiers) && Object.keys(modifiers).map((key, index) => {
                                                return (
                                                  (!modifiers[key].min) && (modifiers[key][0].is_condiment && modifiers[key][0].is_condiment == 1) ?
                                                    <div key={key} className={`form-group-custom-control ${index > 0 && 'mt-4'}`}>
                                                      <div className="d-flex justify-content-between">
                                                        <h4>{key}</h4>
                                                        <span>
                                                          {
                                                            modifiers[key][0].modGroup_min_quantity == 0 ?
                                                              <h6 className="mb-0 required-label">
                                                                Optional
                                                              </h6>
                                                              :
                                                              <h6 id={`${key}`} className="required-label">
                                                                Required
                                                              </h6>
                                                          }
                                                        </span>
                                                      </div>
                                                      <span>
                                                        <h6 className="mb-0 required-label">
                                                          Select any {modifiers[key].min}
                                                        </h6>
                                                      </span>
                                                      {modifiers[key].map(
                                                        (item: any, index: any) => {
                                                          if (
                                                            item.modifier_type ==
                                                            "single"
                                                          ) {
                                                            return (
                                                              <div
                                                                onClick={() =>
                                                                  this.addModifier(key, null, item.modifier_name, null)
                                                                }
                                                                className="d-flex pt-2 justify-content-between"
                                                              >
                                                                <span>
                                                                  {item.modifier_name}
                                                                </span>
                                                                <div className="add-btn d-flex">
                                                                  {(item.discount_price || item.discount_price == 0) && (   //For modifiers
                                                                    item.modifier_sale_price === 0
                                                                      ? null
                                                                      : <span style={{ marginRight: 5, color: 'rgb(0, 105, 145)' }}>
                                                                        (<span style={{ textDecoration: (item.discount_price || item.discount_price == 0) ? 'line-through' : 'none', color: '#d60000', fontSize: 9 }}>
                                                                          {priceunit + item.modifier_sale_price}
                                                                        </span>
                                                                        {priceunit + Math.round(item.discount_price)}
                                                                        )
                                                                      </span>
                                                                  )}
                                                                  {item.modifier_sale_price === 0
                                                                    ? null
                                                                    : item.discount_price == null && <span>
                                                                      ( +
                                                                      {priceunit +
                                                                        item.modifier_sale_price
                                                                      }{" "}
                                                                      )
                                                                    </span>
                                                                  }
                                                                  {item.selected ? (
                                                                    <div className="added">
                                                                      <span className="btn-text-clr-added">
                                                                        Added
                                                                      </span>
                                                                    </div>
                                                                  ) : (
                                                                    <div className="notAdded">
                                                                      <span className="btn-text-clr">
                                                                        Add
                                                                      </span>
                                                                    </div>
                                                                  )}
                                                                </div>
                                                              </div>
                                                            );
                                                          } else {
                                                            return (
                                                              <div className="multi-addon-row">
                                                                <div
                                                                  key={index}
                                                                  className="flex-nowrap justify-content-start"
                                                                  onClick={() =>
                                                                    this.handleChangeChk(key, item.modifier_name)
                                                                  }
                                                                >
                                                                  <input
                                                                    type="checkbox"
                                                                    checked={
                                                                      item.selected
                                                                    }
                                                                    className="checkbox-template"
                                                                    id="checkboxCustom1"
                                                                    name="adon"
                                                                  />
                                                                  <span
                                                                    style={{
                                                                      marginLeft:
                                                                        "20px",
                                                                    }}
                                                                  >
                                                                    {" "}
                                                                    {
                                                                      item.modifier_name
                                                                    }{" "}
                                                                    {(item.discount_price || item.discount_price == 0) && (   //For modifiers
                                                                      item.modifier_sale_price === 0
                                                                        ? null
                                                                        : <span style={{ marginRight: 5, color: 'rgb(0, 105, 145)' }}>
                                                                          (<span style={{ textDecoration: (item.discount_price || item.discount_price == 0) ? 'line-through' : 'none', color: '#d60000', fontSize: 9 }}>
                                                                            {priceunit + item.modifier_sale_price}
                                                                          </span>
                                                                          {priceunit + Math.round(item.discount_price)}
                                                                          )
                                                                        </span>
                                                                    )}
                                                                    {item.modifier_sale_price === 0
                                                                      ? null
                                                                      : item.discount_price == null && <span>
                                                                        ({priceunit + item.modifier_sale_price} ){" "}
                                                                      </span>
                                                                    }
                                                                  </span>
                                                                </div>
                                                                {item.selected && item.max_quantity > 1 && (
                                                                  <div
                                                                    style={{
                                                                      display: "flex",
                                                                      width: "5rem",
                                                                      justifyContent:
                                                                        "space-between",
                                                                    }}
                                                                  >
                                                                    <div className="decrement-btn">
                                                                      <a
                                                                        className="dec-icon"
                                                                        onClick={() =>
                                                                          this.decrementQuantity(
                                                                            key,
                                                                            item.modifier_name
                                                                          )
                                                                        }
                                                                      >
                                                                        -
                                                                      </a>
                                                                    </div>
                                                                    <div
                                                                      style={{
                                                                        fontWeight:
                                                                          "bold",
                                                                        fontSize: 18,
                                                                      }}
                                                                    >
                                                                      {item.quantity}
                                                                    </div>
                                                                    <div className="increment-btn">
                                                                      <a
                                                                        className="inc-icon"
                                                                        onClick={() =>
                                                                          this.incrementQuantity(
                                                                            key,
                                                                            item.modifier_name
                                                                          )
                                                                        }
                                                                      >
                                                                        +
                                                                      </a>
                                                                    </div>
                                                                  </div>
                                                                )}
                                                              </div>
                                                            );
                                                          }
                                                        }
                                                      )}
                                                    </div> : ""
                                                )
                                              })}
                                            </>
                                          }
                                          {/* For Norma Item */}
                                          {(obj.is_full_pizza == 0 && obj.is_half_n_half == 0 && obj.modifiers && !_.isEmpty(modifiers)) &&
                                            Object.keys(modifiers).map((key, index) => {
                                              return (
                                                (modifiers[key].is_addon && modifiers[key].is_addon == 1) && <div
                                                  key={key}
                                                  className={`form-group-custom-control ${index > 0 && 'mt-4'}`}
                                                >
                                                  <div className="d-flex justify-content-between">
                                                    <h4>{obj.group_name}</h4>
                                                    <span>{((modifiers[key].is_addon && modifiers[key].is_addon == 1) || (modifiers[key].is_flavour && modifiers[key].is_flavour == 1)) ?
                                                      modifiers[key].min == 0 ?
                                                        <h6 className="mb-0 required-label">
                                                          Optional
                                                        </h6>
                                                        :
                                                        <h6 id={`${key}`} className="mb-0 required-label">
                                                          Required
                                                        </h6>
                                                      :
                                                      modifiers[key][0].modGroup_min_quantity == 0 ?
                                                        <h6 className="mb-0 required-label">
                                                          Optional
                                                        </h6>
                                                        :
                                                        <h6 id={`${key}`} className="required-label">
                                                          Required
                                                        </h6>
                                                    }</span>
                                                  </div>
                                                  {!modifiers[key].min ? <span>
                                                    <h6 className="mb-0 required-label">
                                                      Select any {modifiers[key][0].modGroup_max_quantity}
                                                    </h6>
                                                  </span> : <span>
                                                    <h6 className="mb-0 required-label">
                                                      Select any {modifiers[key].min}
                                                    </h6>
                                                  </span>
                                                  }
                                                  {
                                                    <div
                                                      key={key}
                                                      className="form-group-custom-control mt-2"
                                                    >

                                                      {Object.keys(modifiers[key]).map((subkey, index) => {
                                                        return (
                                                          Array.isArray(modifiers[key][subkey]) &&
                                                          <div className='voucher-custom-container mt-1` m-auto'>
                                                            {Object.keys(modifiers[key]).length > 4 && <strong>{subkey}</strong>}
                                                            {
                                                              modifiers[key][subkey].map((item: any, modindex: any) => {
                                                                if (item.modifier_type == "single") {
                                                                  return (
                                                                    <div
                                                                      onClick={() =>
                                                                        this.addModifier(key, subkey, item.modifier_name, modifiers[key])
                                                                      }
                                                                      className="d-flex pt-2 justify-content-between"
                                                                    >
                                                                      <span>
                                                                        {item.modifier_name}
                                                                      </span>
                                                                      <div className="add-btn d-flex">
                                                                        {(item.discount_price || item.discount_price == 0) && (   //For modifiers
                                                                          item.modifier_sale_price === 0
                                                                            ? null
                                                                            : <span style={{ marginRight: 5, color: 'rgb(0, 105, 145)' }}>
                                                                              (<span style={{ textDecoration: (item.discount_price || item.discount_price == 0) ? 'line-through' : 'none', color: '#d60000', fontSize: 9 }}>
                                                                                {priceunit + item.modifier_sale_price}
                                                                              </span>
                                                                              {priceunit + Math.round(item.discount_price)}
                                                                              )
                                                                            </span>
                                                                        )}
                                                                        {item.modifier_sale_price === 0
                                                                          ? null
                                                                          : item.discount_price == null && <span>
                                                                            ( +
                                                                            {priceunit +
                                                                              item.modifier_sale_price
                                                                            }{" "}
                                                                            )
                                                                          </span>
                                                                        }
                                                                        {item.selected ? (
                                                                          <div className="added">
                                                                            <span className="btn-text-clr-added">
                                                                              Added
                                                                            </span>
                                                                          </div>
                                                                        ) : (
                                                                          <div className="notAdded">
                                                                            <span className="btn-text-clr">
                                                                              Add
                                                                            </span>
                                                                          </div>
                                                                        )}
                                                                      </div>
                                                                    </div>
                                                                  )
                                                                } else {
                                                                  return (
                                                                    <div className="multi-addon-row">
                                                                      <div
                                                                        key={index}
                                                                        className="flex-nowrap justify-content-start"
                                                                        onClick={() =>
                                                                          this.handleChangeChk(
                                                                            subkey,
                                                                            item.modifier_name
                                                                          )
                                                                        }
                                                                      >
                                                                        <input
                                                                          type="checkbox"
                                                                          checked={
                                                                            item.selected
                                                                          }
                                                                          className="checkbox-template"
                                                                          id="checkboxCustom1"
                                                                          name="adon"
                                                                        />
                                                                        <span
                                                                          style={{
                                                                            marginLeft:
                                                                              "20px",
                                                                          }}
                                                                        >
                                                                          {" "}
                                                                          {
                                                                            item.modifier_name
                                                                          }{" "}
                                                                          {(item.discount_price || item.discount_price == 0) && (   //For modifiers
                                                                            item.modifier_sale_price === 0
                                                                              ? null
                                                                              : <span style={{ marginRight: 5, color: 'rgb(0, 105, 145)' }}>
                                                                                (<span style={{ textDecoration: (item.discount_price || item.discount_price == 0) ? 'line-through' : 'none', color: '#d60000', fontSize: 9 }}>
                                                                                  {priceunit + item.modifier_sale_price}
                                                                                </span>
                                                                                {priceunit + Math.round(item.discount_price)}
                                                                                )
                                                                              </span>
                                                                          )}
                                                                          {item.modifier_sale_price === 0
                                                                            ? null
                                                                            : item.discount_price == null && <span>
                                                                              ({priceunit + item.modifier_sale_price} ){" "}
                                                                            </span>
                                                                          }
                                                                        </span>
                                                                      </div>
                                                                      {item.selected && item.max_quantity > 1 && (
                                                                        <div
                                                                          style={{
                                                                            display: "flex",
                                                                            width: "5rem",
                                                                            justifyContent:
                                                                              "space-between",
                                                                          }}
                                                                        >
                                                                          <div className="decrement-btn">
                                                                            <a
                                                                              className="dec-icon"
                                                                              onClick={() =>
                                                                                this.decrementQuantity(
                                                                                  subkey,
                                                                                  item.modifier_name
                                                                                )
                                                                              }
                                                                            >
                                                                              -
                                                                            </a>
                                                                          </div>
                                                                          <div
                                                                            style={{
                                                                              fontWeight:
                                                                                "bold",
                                                                              fontSize: 18,
                                                                            }}
                                                                          >
                                                                            {item.quantity}
                                                                          </div>
                                                                          <div className="increment-btn">
                                                                            <a
                                                                              className="inc-icon"
                                                                              onClick={() =>
                                                                                this.incrementQuantity(
                                                                                  subkey,
                                                                                  item.modifier_name
                                                                                )
                                                                              }
                                                                            >
                                                                              +
                                                                            </a>
                                                                          </div>
                                                                        </div>
                                                                      )}
                                                                    </div>
                                                                  )
                                                                }
                                                              })
                                                            }
                                                          </div>
                                                        )
                                                      })}
                                                    </div>
                                                  }
                                                </div>
                                              )
                                            })
                                          }
                                          {/* For Kitchen Instruction */}
                                          <div className="col-lg-12 d-flex align-items-center mt-2" style={{ padding: "unset" }}>
                                            <div className="project-title d-flex align-items-center">
                                              <div className="form-group">
                                                <label className="form-control-label">
                                                  <h4 className="text-dark"> Kitchen Instructions</h4>
                                                </label>
                                                <textarea
                                                  defaultValue={obj.kitchen_instruction}
                                                  className="form-control special-ins"
                                                  style={{ display: "initial" }}
                                                  name="kitchen_instructions"
                                                  onChange={(e) => this.applyKitchenInstruction(e.target.value, tabIndex)}
                                                >
                                                </textarea>
                                              </div>
                                            </div>
                                          </div>
                                        </Tab>
                                      })
                                      }
                                    </Tabs>
                                  </div>
                                  }
                                  {/* Make A combo */}
                                  <>
                                    {!_.isEmpty(itemData) && !_.isEmpty(this.state.make_combos) && <h5 className="py-2">Make A Combo</h5>}
                                    {!_.isEmpty(this.state.make_combos) &&

                                      this.state.make_combos.map((options: any) => {
                                        if (options.option.type == 'single') {
                                          return (
                                            <div onClick={(e: any) => {
                                              this.hanldeRadioCheckMakeCombo(e, options.option.option_id);

                                            }} className='combo-details'>
                                              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                <div className="form-check">
                                                  <input className="form-check-input" checked={options.option.selected} type="radio" />
                                                  <label className="form-check-label">
                                                    <span>{options.option.item_name}</span>
                                                    <span>({options.option.size})</span>
                                                  </label>
                                                </div>
                                                <div>
                                                  <span>{priceunit} {options.option.mrp}</span>
                                                </div>
                                              </div>
                                            </div>
                                          )
                                        } else {
                                          return (
                                            <div className='combo-details'>
                                              <div style={{ display: 'flex', justifyContent: 'space-between', marginRight: ' 1rem', marginLeft: '1rem' }}>
                                                <div className="form-check">
                                                  <input className="form-check-input" type="checkbox" />
                                                  <label className="form-check-label">
                                                    <p className="modal-single-item">{options.option.item_name}</p>
                                                  </label>
                                                </div>
                                                <p className='product-modal-more-optional'>+{priceunit + options.option.mrp}</p>
                                              </div>
                                            </div>
                                          )
                                        }
                                        // })
                                      })
                                    }
                                  </>
                                  {/* For Full Pizza */}
                                  {(!_.isEmpty(itemData) && itemData.is_full_pizza == 1 && !_.isEmpty(itemData.modifiers)) &&
                                    Object.keys(modifiers).map((key, index) => {
                                      return (
                                        <div
                                          key={key}
                                          className={`form-group-custom-control ${index > 0 && 'mt-4'}`}
                                        >
                                          <div className="d-flex justify-content-between">
                                            <h4>{key}</h4>
                                            <span>{((modifiers[key].is_crust && modifiers[key].is_crust == 1)) ?
                                              modifiers[key].min == 0 ?
                                                <h6 className="mb-0 required-label">
                                                  Optional
                                                </h6>
                                                :
                                                <h6 id={`${key}`} className="mb-0 required-label">
                                                  Required
                                                </h6>
                                              :
                                              modifiers[key][0].modGroup_min_quantity == 0 ?
                                                <h6 className="mb-0 required-label">
                                                  Optional
                                                </h6>
                                                :
                                                <h6 id={`${key}`} className="required-label">
                                                  Required
                                                </h6>
                                            }</span>
                                          </div>
                                          {!modifiers[key].min ? <span>
                                            <h6 className="mb-0 required-label">
                                              Select any {modifiers[key][0].modGroup_max_quantity}
                                            </h6>
                                          </span> : <span>
                                            <h6 className="mb-0 required-label">
                                              Select any {modifiers[key].min}
                                            </h6>
                                          </span>
                                          }
                                          {
                                            (modifiers[key].is_crust && modifiers[key].is_crust == 1) ?
                                              Object.keys(modifiers[key]).map((subkey, index) => {
                                                let found = 0;
                                                const checkMod = (obj: any) => obj.variant_id && (obj.variant_id.id === (this.state.selectedsize.variant_id && this.state.selectedsize.variant_id.id));
                                                return (
                                                  Array.isArray(modifiers[key][subkey]) &&
                                                  <div
                                                    key={subkey}
                                                    className="form-group-custom-control mt-2"
                                                  >
                                                    {modifiers[key][subkey].map((obj: any) => {
                                                      if (found == 0) {
                                                        if (obj.mod_variant_id) {
                                                          if (obj.mod_variant_id.filter(checkMod).length > 0) {
                                                            found += 1;
                                                            return <h6>{subkey}</h6>
                                                          }
                                                        } else {
                                                          found += 1;
                                                          return <h6>{subkey}</h6>
                                                        }
                                                      } else {
                                                        return true;
                                                      }
                                                    })
                                                    }
                                                    {modifiers[key][subkey].map(
                                                      (item: any, index: any) => {
                                                        if (
                                                          item.modifier_type ==
                                                          "single"
                                                        ) {
                                                          if (item.mod_variant_id) {
                                                            const checkUsername = (obj: any) => obj.variant_id && (obj.variant_id.id === (this.state.selectedsize.variant_id && this.state.selectedsize.variant_id.id));;
                                                            return (
                                                              item.mod_variant_id.some(checkUsername) &&
                                                              <div
                                                                onClick={() =>
                                                                  this.addModifier(key, subkey, item.modifier_name, modifiers[key])
                                                                }
                                                                className="d-flex pt-2 justify-content-between"
                                                              >
                                                                <span>
                                                                  {item.modifier_name}
                                                                </span>
                                                                <div className="add-btn d-flex">
                                                                  {(item.discount_price || item.discount_price == 0) && (   //For modifiers
                                                                    item.modifier_sale_price === 0
                                                                      ? null
                                                                      : <span style={{ marginRight: 5, color: 'rgb(0, 105, 145)' }}>
                                                                        (<span style={{ textDecoration: (item.discount_price || item.discount_price == 0) ? 'line-through' : 'none', color: '#d60000', fontSize: 9 }}>
                                                                          {priceunit + item.modifier_sale_price}
                                                                        </span>
                                                                        {priceunit + Math.round(item.discount_price)}
                                                                        )
                                                                      </span>
                                                                  )}
                                                                  {item.modifier_sale_price === 0
                                                                    ? null
                                                                    : item.discount_price == null && <span>
                                                                      ( +
                                                                      {priceunit +
                                                                        item.modifier_sale_price
                                                                      }{" "}
                                                                      )
                                                                    </span>
                                                                  }
                                                                  {item.selected ? (
                                                                    <div className="added">
                                                                      <span className="btn-text-clr-added">
                                                                        Added
                                                                      </span>
                                                                    </div>
                                                                  ) : (
                                                                    <div className="notAdded">
                                                                      <span className="btn-text-clr">
                                                                        Add
                                                                      </span>
                                                                    </div>
                                                                  )}
                                                                </div>
                                                              </div>
                                                            );
                                                          } else {
                                                            return (
                                                              <div
                                                                onClick={() =>
                                                                  this.addModifier(key, subkey, item.modifier_name, modifiers[key])
                                                                }
                                                                className="d-flex pt-2 justify-content-between"
                                                              >
                                                                <span>
                                                                  {item.modifier_name}
                                                                </span>
                                                                <div className="add-btn d-flex">
                                                                  {(item.discount_price || item.discount_price == 0) && (   //For modifiers
                                                                    item.modifier_sale_price === 0
                                                                      ? null
                                                                      : <span style={{ marginRight: 5, color: 'rgb(0, 105, 145)' }}>
                                                                        (<span style={{ textDecoration: (item.discount_price || item.discount_price == 0) ? 'line-through' : 'none', color: '#d60000', fontSize: 9 }}>
                                                                          {priceunit + item.modifier_sale_price}
                                                                        </span>
                                                                        {priceunit + Math.round(item.discount_price)}
                                                                        )
                                                                      </span>
                                                                  )}
                                                                  {item.modifier_sale_price === 0
                                                                    ? null
                                                                    : item.discount_price == null && <span>
                                                                      ( +
                                                                      {priceunit +
                                                                        item.modifier_sale_price
                                                                      }{" "}
                                                                      )
                                                                    </span>
                                                                  }
                                                                  {item.selected ? (
                                                                    <div className="added">
                                                                      <span className="btn-text-clr-added">
                                                                        Added
                                                                      </span>
                                                                    </div>
                                                                  ) : (
                                                                    <div className="notAdded">
                                                                      <span className="btn-text-clr">
                                                                        Add
                                                                      </span>
                                                                    </div>
                                                                  )}
                                                                </div>
                                                              </div>
                                                            );
                                                          }
                                                        } else {
                                                          return (
                                                            <div className="multi-addon-row">
                                                              <div
                                                                key={index}
                                                                className="flex-nowrap justify-content-start"
                                                                onClick={() =>
                                                                  this.handleChangeChk(
                                                                    key,
                                                                    item.modifier_name
                                                                  )
                                                                }
                                                              >
                                                                <input
                                                                  type="checkbox"
                                                                  checked={
                                                                    item.selected
                                                                  }
                                                                  className="checkbox-template"
                                                                  id="checkboxCustom1"
                                                                  name="adon"
                                                                />
                                                                <span
                                                                  style={{
                                                                    marginLeft:
                                                                      "20px",
                                                                  }}
                                                                >
                                                                  {" "}
                                                                  {
                                                                    item.modifier_name
                                                                  }{" "}
                                                                  {(item.discount_price || item.discount_price == 0) && (   //For modifiers
                                                                    item.modifier_sale_price === 0
                                                                      ? null
                                                                      : <span style={{ marginRight: 5, color: 'rgb(0, 105, 145)' }}>
                                                                        (<span style={{ textDecoration: (item.discount_price || item.discount_price == 0) ? 'line-through' : 'none', color: '#d60000', fontSize: 9 }}>
                                                                          {priceunit + item.modifier_sale_price}
                                                                        </span>
                                                                        {priceunit + Math.round(item.discount_price)}
                                                                        )
                                                                      </span>
                                                                  )}
                                                                  {item.modifier_sale_price === 0
                                                                    ? null
                                                                    : item.discount_price == null && <span>
                                                                      ({priceunit + item.modifier_sale_price} ){" "}
                                                                    </span>
                                                                  }
                                                                </span>
                                                              </div>
                                                              {item.selected && item.max_quantity > 1 && (
                                                                <div
                                                                  style={{
                                                                    display: "flex",
                                                                    width: "5rem",
                                                                    justifyContent:
                                                                      "space-between",
                                                                  }}
                                                                >
                                                                  <div className="decrement-btn">
                                                                    <a
                                                                      className="dec-icon"
                                                                      onClick={() =>
                                                                        this.decrementQuantity(
                                                                          key,
                                                                          item.modifier_name
                                                                        )
                                                                      }
                                                                    >
                                                                      -
                                                                    </a>
                                                                  </div>
                                                                  <div
                                                                    style={{
                                                                      fontWeight:
                                                                        "bold",
                                                                      fontSize: 18,
                                                                    }}
                                                                  >
                                                                    {item.quantity}
                                                                  </div>
                                                                  <div className="increment-btn">
                                                                    <a
                                                                      className="inc-icon"
                                                                      onClick={() =>
                                                                        this.incrementQuantity(
                                                                          key,
                                                                          item.modifier_name
                                                                        )
                                                                      }
                                                                    >
                                                                      +
                                                                    </a>
                                                                  </div>
                                                                </div>

                                                                /* <NumericInput
                                                              min={1}
                                                              value={item.quantity}
                                                              max={100}
                                                              type="number"
                                                              mobile
                                                              size={10}
                                                              className="numericInput"
                                                              onChange={(value) =>
                                                                this.handleCheckQuantity(key,item.modifier_name,value)
                                                              }
                                                            /> */
                                                              )}
                                                            </div>
                                                          );
                                                        }
                                                      }
                                                    )}
                                                  </div>
                                                )
                                              })
                                              :
                                              <div
                                                key={key}
                                                className="form-group-custom-control mt-2"
                                              >

                                                {modifiers[key].map(
                                                  (item: any, index: any) => {
                                                    if (
                                                      item.modifier_type ==
                                                      "single"
                                                    ) {
                                                      return (
                                                        <div
                                                          onClick={() =>
                                                            this.addModifier(key, null, item.modifier_name, null)
                                                          }
                                                          className="d-flex pt-2 justify-content-between"
                                                        >
                                                          <span>
                                                            {item.modifier_name}
                                                          </span>
                                                          <div className="add-btn d-flex">
                                                            {(item.discount_price || item.discount_price == 0) && (   //For modifiers
                                                              item.modifier_sale_price === 0
                                                                ? null
                                                                : <span style={{ marginRight: 5, color: 'rgb(0, 105, 145)' }}>
                                                                  (<span style={{ textDecoration: (item.discount_price || item.discount_price == 0) ? 'line-through' : 'none', color: '#d60000', fontSize: 9 }}>
                                                                    {priceunit + item.modifier_sale_price}
                                                                  </span>
                                                                  {priceunit + Math.round(item.discount_price)}
                                                                  )
                                                                </span>
                                                            )}
                                                            {item.modifier_sale_price === 0
                                                              ? null
                                                              : item.discount_price == null && <span>
                                                                ( +
                                                                {priceunit +
                                                                  item.modifier_sale_price
                                                                }{" "}
                                                                )
                                                              </span>
                                                            }
                                                            {item.selected ? (
                                                              <div className="added">
                                                                <span className="btn-text-clr-added">
                                                                  Added
                                                                </span>
                                                              </div>
                                                            ) : (
                                                              <div className="notAdded">
                                                                <span className="btn-text-clr">
                                                                  Add
                                                                </span>
                                                              </div>
                                                            )}
                                                          </div>
                                                        </div>
                                                      );
                                                    } else {
                                                      return (
                                                        <div className="multi-addon-row">
                                                          <div
                                                            key={index}
                                                            className="flex-nowrap justify-content-start"
                                                            onClick={() =>
                                                              this.handleChangeChk(
                                                                key,
                                                                item.modifier_name
                                                              )
                                                            }
                                                          >
                                                            <input
                                                              type="checkbox"
                                                              checked={
                                                                item.selected
                                                              }
                                                              className="checkbox-template"
                                                              id="checkboxCustom1"
                                                              name="adon"
                                                            />
                                                            <span
                                                              style={{
                                                                marginLeft:
                                                                  "20px",
                                                              }}
                                                            >
                                                              {" "}
                                                              {
                                                                item.modifier_name
                                                              }{" "}
                                                              {(item.discount_price || item.discount_price == 0) && (   //For modifiers
                                                                item.modifier_sale_price === 0
                                                                  ? null
                                                                  : <span style={{ marginRight: 5, color: 'rgb(0, 105, 145)' }}>
                                                                    (<span style={{ textDecoration: (item.discount_price || item.discount_price == 0) ? 'line-through' : 'none', color: '#d60000', fontSize: 9 }}>
                                                                      {priceunit + item.modifier_sale_price}
                                                                    </span>
                                                                    {priceunit + Math.round(item.discount_price)}
                                                                    )
                                                                  </span>
                                                              )}
                                                              {item.modifier_sale_price === 0
                                                                ? null
                                                                : item.discount_price == null && <span>
                                                                  ({priceunit + item.modifier_sale_price} ){" "}
                                                                </span>
                                                              }
                                                            </span>
                                                          </div>
                                                          {item.selected && item.max_quantity > 1 && (
                                                            <div
                                                              style={{
                                                                display: "flex",
                                                                width: "5rem",
                                                                justifyContent:
                                                                  "space-between",
                                                              }}
                                                            >
                                                              <div className="decrement-btn">
                                                                <a
                                                                  className="dec-icon"
                                                                  onClick={() =>
                                                                    this.decrementQuantity(
                                                                      key,
                                                                      item.modifier_name
                                                                    )
                                                                  }
                                                                >
                                                                  -
                                                                </a>
                                                              </div>
                                                              <div
                                                                style={{
                                                                  fontWeight:
                                                                    "bold",
                                                                  fontSize: 18,
                                                                }}
                                                              >
                                                                {item.quantity}
                                                              </div>
                                                              <div className="increment-btn">
                                                                <a
                                                                  className="inc-icon"
                                                                  onClick={() =>
                                                                    this.incrementQuantity(
                                                                      key,
                                                                      item.modifier_name
                                                                    )
                                                                  }
                                                                >
                                                                  +
                                                                </a>
                                                              </div>
                                                            </div>
                                                          )}
                                                        </div>
                                                      );
                                                    }
                                                  }
                                                )}
                                              </div>
                                          }
                                        </div>
                                      )
                                    })
                                  }
                                  {/* For Half N HALF Pizza */}
                                  {(!_.isEmpty(itemData) && itemData.is_half_n_half == 1 && !_.isEmpty(itemData.modifiers)) &&
                                    <>
                                      {Object.keys(modifiers).map((key, index) => {
                                        return (
                                          (modifiers[key].is_crust && modifiers[key].is_crust == 1) &&
                                          <div
                                            key={key}
                                            className={`form-group-custom-control ${index > 0 && 'mt-4'}`}
                                          >
                                            <div className="d-flex justify-content-between">
                                              <h4>{key}</h4>
                                              <span>{
                                                (modifiers[key].min == 0 ?
                                                  <h6 className="mb-0 required-label">
                                                    Optional
                                                  </h6>
                                                  :
                                                  <h6 id={`${key}`} className="mb-0 required-label">
                                                    Required
                                                  </h6>)
                                              }</span>
                                            </div>
                                            {!modifiers[key].min ? <span>
                                              <h6 className="mb-0 required-label">
                                                Select any {modifiers[key][0].modGroup_max_quantity}
                                              </h6>
                                            </span> : <span>
                                              <h6 className="mb-0 required-label">
                                                Select any {modifiers[key].min}
                                              </h6>
                                            </span>
                                            }
                                            {
                                              Object.keys(modifiers[key]).map((subkey, index) => {
                                                let found = 0;
                                                const checkMod = (obj: any) => obj.variant_id && (obj.variant_id.id === (this.state.selectedsize.variant_id && this.state.selectedsize.variant_id.id));;
                                                return (
                                                  Array.isArray(modifiers[key][subkey]) &&
                                                  <div
                                                    key={subkey}
                                                    className="form-group-custom-control mt-2"
                                                  >
                                                    {modifiers[key][subkey].map((obj: any) => {
                                                      if (found == 0) {
                                                        if (obj.mod_variant_id) {
                                                          if (obj.mod_variant_id.filter(checkMod).length > 0) {
                                                            found += 1;
                                                            return <h6>{subkey}</h6>
                                                          }
                                                        } else {
                                                          found += 1;
                                                          return <h6>{subkey}</h6>
                                                        }
                                                      } else {
                                                        return true;
                                                      }
                                                    })
                                                    }

                                                    {modifiers[key][subkey].map(
                                                      (item: any, index: any) => {
                                                        if (
                                                          item.modifier_type ==
                                                          "single"
                                                        ) {
                                                          if (item.mod_variant_id) {
                                                            const checkUsername = (obj: any) => obj.variant_id && (obj.variant_id.id === (this.state.selectedsize.variant_id && this.state.selectedsize.variant_id.id));;
                                                            return (
                                                              item.mod_variant_id.some(checkUsername) &&
                                                              <div
                                                                onClick={() =>
                                                                  this.addModifier(key, subkey, item.modifier_name, modifiers[key])
                                                                }
                                                                className="d-flex pt-2 justify-content-between"
                                                              >
                                                                <span>
                                                                  {item.modifier_name}
                                                                </span>
                                                                <div className="add-btn d-flex">
                                                                  {(item.discount_price || item.discount_price == 0) && (   //For modifiers
                                                                    item.modifier_sale_price === 0
                                                                      ? null
                                                                      : <span style={{ marginRight: 5, color: 'rgb(0, 105, 145)' }}>
                                                                        (<span style={{ textDecoration: (item.discount_price || item.discount_price == 0) ? 'line-through' : 'none', color: '#d60000', fontSize: 9 }}>
                                                                          {priceunit + item.modifier_sale_price}
                                                                        </span>
                                                                        {priceunit + Math.round(item.discount_price)}
                                                                        )
                                                                      </span>
                                                                  )}
                                                                  {item.modifier_sale_price === 0
                                                                    ? null
                                                                    : item.discount_price == null && <span>
                                                                      ( +
                                                                      {priceunit +
                                                                        item.modifier_sale_price
                                                                      }{" "}
                                                                      )
                                                                    </span>
                                                                  }
                                                                  {item.selected ? (
                                                                    <div className="added">
                                                                      <span className="btn-text-clr-added">
                                                                        Added
                                                                      </span>
                                                                    </div>
                                                                  ) : (
                                                                    <div className="notAdded">
                                                                      <span className="btn-text-clr">
                                                                        Add
                                                                      </span>
                                                                    </div>
                                                                  )}
                                                                </div>
                                                              </div>
                                                            );
                                                          } else {
                                                            return (
                                                              <div
                                                                onClick={() =>
                                                                  this.addModifier(key, subkey, item.modifier_name, modifiers[key])
                                                                }
                                                                className="d-flex pt-2 justify-content-between"
                                                              >
                                                                <span>
                                                                  {item.modifier_name}
                                                                </span>
                                                                <div className="add-btn d-flex">
                                                                  {(item.discount_price || item.discount_price == 0) && (   //For modifiers
                                                                    item.modifier_sale_price === 0
                                                                      ? null
                                                                      : <span style={{ marginRight: 5, color: 'rgb(0, 105, 145)' }}>
                                                                        (<span style={{ textDecoration: (item.discount_price || item.discount_price == 0) ? 'line-through' : 'none', color: '#d60000', fontSize: 9 }}>
                                                                          {priceunit + item.modifier_sale_price}
                                                                        </span>
                                                                        {priceunit + Math.round(item.discount_price)}
                                                                        )
                                                                      </span>
                                                                  )}
                                                                  {item.modifier_sale_price === 0
                                                                    ? null
                                                                    : item.discount_price == null && <span>
                                                                      ( +
                                                                      {priceunit +
                                                                        item.modifier_sale_price
                                                                      }{" "}
                                                                      )
                                                                    </span>
                                                                  }
                                                                  {item.selected ? (
                                                                    <div className="added">
                                                                      <span className="btn-text-clr-added">
                                                                        Added
                                                                      </span>
                                                                    </div>
                                                                  ) : (
                                                                    <div className="notAdded">
                                                                      <span className="btn-text-clr">
                                                                        Add
                                                                      </span>
                                                                    </div>
                                                                  )}
                                                                </div>
                                                              </div>
                                                            );
                                                          }
                                                        } else {
                                                          return (
                                                            <div className="multi-addon-row">
                                                              <div
                                                                key={index}
                                                                className="flex-nowrap justify-content-start"
                                                                onClick={() =>
                                                                  this.handleChangeChk(
                                                                    key,
                                                                    item.modifier_name
                                                                  )
                                                                }
                                                              >
                                                                <input
                                                                  type="checkbox"
                                                                  checked={
                                                                    item.selected
                                                                  }
                                                                  className="checkbox-template"
                                                                  id="checkboxCustom1"
                                                                  name="adon"
                                                                />
                                                                <span
                                                                  style={{
                                                                    marginLeft:
                                                                      "20px",
                                                                  }}
                                                                >
                                                                  {" "}
                                                                  {
                                                                    item.modifier_name
                                                                  }{" "}
                                                                  {(item.discount_price || item.discount_price == 0) && (   //For modifiers
                                                                    item.modifier_sale_price === 0
                                                                      ? null
                                                                      : <span style={{ marginRight: 5, color: 'rgb(0, 105, 145)' }}>
                                                                        (<span style={{ textDecoration: (item.discount_price || item.discount_price == 0) ? 'line-through' : 'none', color: '#d60000', fontSize: 9 }}>
                                                                          {priceunit + item.modifier_sale_price}
                                                                        </span>
                                                                        {priceunit + Math.round(item.discount_price)}
                                                                        )
                                                                      </span>
                                                                  )}
                                                                  {item.modifier_sale_price === 0
                                                                    ? null
                                                                    : item.discount_price == null && <span>
                                                                      ({priceunit + item.modifier_sale_price} ){" "}
                                                                    </span>
                                                                  }
                                                                </span>
                                                              </div>
                                                              {item.selected && item.max_quantity > 1 && (
                                                                <div
                                                                  style={{
                                                                    display: "flex",
                                                                    width: "5rem",
                                                                    justifyContent:
                                                                      "space-between",
                                                                  }}
                                                                >
                                                                  <div className="decrement-btn">
                                                                    <a
                                                                      className="dec-icon"
                                                                      onClick={() =>
                                                                        this.decrementQuantity(
                                                                          key,
                                                                          item.modifier_name
                                                                        )
                                                                      }
                                                                    >
                                                                      -
                                                                    </a>
                                                                  </div>
                                                                  <div
                                                                    style={{
                                                                      fontWeight:
                                                                        "bold",
                                                                      fontSize: 18,
                                                                    }}
                                                                  >
                                                                    {item.quantity}
                                                                  </div>
                                                                  <div className="increment-btn">
                                                                    <a
                                                                      className="inc-icon"
                                                                      onClick={() =>
                                                                        this.incrementQuantity(
                                                                          key,
                                                                          item.modifier_name
                                                                        )
                                                                      }
                                                                    >
                                                                      +
                                                                    </a>
                                                                  </div>
                                                                </div>
                                                              )}
                                                            </div>
                                                          );
                                                        }
                                                      }
                                                    )}
                                                  </div>
                                                )
                                              })
                                            }
                                          </div>
                                        )
                                      })}
                                      <div className={`form-group-custom-control mt-4`}>
                                        <div className="d-flex justify-content-between">
                                          <h4>Flavour</h4>
                                          <span><h6 className="mb-0 required-label">
                                            Required
                                          </h6></span>
                                        </div>
                                        <div className="ItemTabs">
                                          <Tabs
                                            id="uncontrolled-tab-example"
                                            className="mb-1 mt-0 justify-content-center"
                                            activeKey={this.state.active_key}
                                            onSelect={(key, e) => {
                                              if (key == "1stHalf") {
                                                this.setState({ active_key: key })
                                              } else {
                                                let halfNHalfItemReadyFlag = this.halfNHalfItemReady();
                                                if (!halfNHalfItemReadyFlag) {
                                                  toast.error(`Please select a 1st flavour`, { position: toast.POSITION.BOTTOM_RIGHT, hideProgressBar: true, autoClose: 3000 });
                                                } else {
                                                  this.setState({ active_key: key })
                                                }
                                              }
                                            }}
                                          >
                                            <Tab className="select-item" eventKey="1stHalf" title="1st Half">
                                              {!_.isEmpty(halfNHalfModifiers) &&
                                                Object.keys(halfNHalfModifiers[0].data).map((key, index) => {
                                                  return (
                                                    (halfNHalfModifiers[0].data[key].is_flavour && halfNHalfModifiers[0].data[key].is_flavour == 1) &&
                                                    <div key={key} className={`form-group-custom-control ${index > 0 && 'mt-4'}`}>
                                                      <div className="d-flex justify-content-between">
                                                        <h4>{key} 1</h4>
                                                        <span>
                                                          {((halfNHalfModifiers[0].data[key].is_crust && halfNHalfModifiers[0].data[key].is_crust == 1) || (halfNHalfModifiers[0].data[key].is_flavour && halfNHalfModifiers[0].data[key].is_flavour == 1)) ?
                                                            halfNHalfModifiers[0].data[key].min == 0 ?
                                                              <h6 className="mb-0 required-label">
                                                                Optional
                                                              </h6>
                                                              :
                                                              <h6 id={`${key}`} className="mb-0 required-label">
                                                                Required
                                                              </h6>
                                                            :
                                                            halfNHalfModifiers[0].data[key][0].modGroup_min_quantity == 0 ?
                                                              <h6 className="mb-0 required-label">
                                                                Optional
                                                              </h6>
                                                              :
                                                              <h6 id={`${key}`} className="required-label">
                                                                Required
                                                              </h6>
                                                          }
                                                        </span>
                                                      </div>
                                                      {!halfNHalfModifiers[0].data[key].min ? <span>
                                                        <h6 className="mb-0 required-label">
                                                          Select any {halfNHalfModifiers[0].data[key][0].modGroup_max_quantity}
                                                        </h6>
                                                      </span> : <span>
                                                        <h6 className="mb-0 required-label">
                                                          Select any {halfNHalfModifiers[0].data[key].min}
                                                        </h6>
                                                      </span>
                                                      }
                                                      {
                                                        Object.keys(halfNHalfModifiers[0].data[key]).map((subkey, index) => {
                                                          let found = 0;
                                                          const checkMod = (obj: any) => obj.variant_id && (obj.variant_id.id === (this.state.selectedsize.variant_id && this.state.selectedsize.variant_id.id));;
                                                          return (
                                                            Array.isArray(halfNHalfModifiers[0].data[key][subkey]) &&
                                                            <div
                                                              key={subkey}
                                                              className="form-group-custom-control mt-2"
                                                            >
                                                              {halfNHalfModifiers[0].data[key][subkey].map((obj: any) => {
                                                                if (found == 0) {
                                                                  if (obj.mod_variant_id) {
                                                                    if (obj.mod_variant_id.filter(checkMod).length > 0) {
                                                                      found += 1;
                                                                      return <h6>{subkey}</h6>
                                                                    }
                                                                  } else {
                                                                    found += 1;
                                                                    return <h6>{subkey}</h6>
                                                                  }
                                                                } else {
                                                                  return true;
                                                                }
                                                              })
                                                              }
                                                              {halfNHalfModifiers[0].data[key][subkey].map(
                                                                (item: any, index: any) => {
                                                                  if (
                                                                    item.modifier_type ==
                                                                    "single"
                                                                  ) {
                                                                    if (item.mod_variant_id) {
                                                                      const checkUsername = (obj: any) => obj.variant_id && (obj.variant_id.id === (this.state.selectedsize.variant_id && this.state.selectedsize.variant_id.id));;
                                                                      return (
                                                                        item.mod_variant_id.some(checkUsername) &&
                                                                        <div
                                                                          onClick={() =>
                                                                            this.addHalfModifier(key, subkey, item.modifier_name, halfNHalfModifiers[0].data[key], 0)
                                                                          }
                                                                          className="d-flex pt-2 justify-content-between"
                                                                        >
                                                                          <span>
                                                                            {item.modifier_name}
                                                                          </span>
                                                                          <div className="add-btn d-flex">
                                                                            {(item.discount_price || item.discount_price == 0) && (   //For modifiers
                                                                              item.modifier_sale_price === 0
                                                                                ? null
                                                                                : <span style={{ marginRight: 5, color: 'rgb(0, 105, 145)' }}>
                                                                                  (<span style={{ textDecoration: (item.discount_price || item.discount_price == 0) ? 'line-through' : 'none', color: '#d60000', fontSize: 9 }}>
                                                                                    {priceunit + item.modifier_sale_price}
                                                                                  </span>
                                                                                  {priceunit + Math.round(item.discount_price)}
                                                                                  )
                                                                                </span>
                                                                            )}
                                                                            {item.modifier_sale_price === 0
                                                                              ? null
                                                                              : item.discount_price == null && <span>
                                                                                ( +
                                                                                {priceunit +
                                                                                  item.modifier_sale_price
                                                                                }{" "}
                                                                                )
                                                                              </span>
                                                                            }
                                                                            {item.selected ? (
                                                                              <div className="added">
                                                                                <span className="btn-text-clr-added">
                                                                                  Added
                                                                                </span>
                                                                              </div>
                                                                            ) : (
                                                                              <div className="notAdded">
                                                                                <span className="btn-text-clr">
                                                                                  Add
                                                                                </span>
                                                                              </div>
                                                                            )}
                                                                          </div>
                                                                        </div>
                                                                      );
                                                                    } else {
                                                                      return (
                                                                        <div
                                                                          onClick={() =>
                                                                            this.addHalfModifier(key, subkey, item.modifier_name, halfNHalfModifiers[0].data[key], 0)
                                                                          }
                                                                          className="d-flex pt-2 justify-content-between"
                                                                        >
                                                                          <span>
                                                                            {item.modifier_name}
                                                                          </span>
                                                                          <div className="add-btn d-flex">
                                                                            {(item.discount_price || item.discount_price == 0) && (   //For modifiers
                                                                              item.modifier_sale_price === 0
                                                                                ? null
                                                                                : <span style={{ marginRight: 5, color: 'rgb(0, 105, 145)' }}>
                                                                                  (<span style={{ textDecoration: (item.discount_price || item.discount_price == 0) ? 'line-through' : 'none', color: '#d60000', fontSize: 9 }}>
                                                                                    {priceunit + item.modifier_sale_price}
                                                                                  </span>
                                                                                  {priceunit + Math.round(item.discount_price)}
                                                                                  )
                                                                                </span>
                                                                            )}
                                                                            {item.modifier_sale_price === 0
                                                                              ? null
                                                                              : item.discount_price == null && <span>
                                                                                ( +
                                                                                {priceunit +
                                                                                  item.modifier_sale_price
                                                                                }{" "}
                                                                                )
                                                                              </span>
                                                                            }
                                                                            {item.selected ? (
                                                                              <div className="added">
                                                                                <span className="btn-text-clr-added">
                                                                                  Added
                                                                                </span>
                                                                              </div>
                                                                            ) : (
                                                                              <div className="notAdded">
                                                                                <span className="btn-text-clr">
                                                                                  Add
                                                                                </span>
                                                                              </div>
                                                                            )}
                                                                          </div>
                                                                        </div>
                                                                      );
                                                                    }
                                                                  } else {
                                                                    return (
                                                                      <div className="multi-addon-row">
                                                                        <div
                                                                          key={index}
                                                                          className="flex-nowrap justify-content-start"
                                                                          onClick={() =>
                                                                            this.handleCheckBoxForHalf(subkey, item.modifier_name, 0)
                                                                          }
                                                                        >
                                                                          <input
                                                                            type="checkbox"
                                                                            checked={
                                                                              item.selected
                                                                            }
                                                                            className="checkbox-template"
                                                                            id="checkboxCustom1"
                                                                            name="adon"
                                                                          />
                                                                          <span
                                                                            style={{
                                                                              marginLeft:
                                                                                "20px",
                                                                            }}
                                                                          >
                                                                            {" "}
                                                                            {
                                                                              item.modifier_name
                                                                            }{" "}
                                                                            {(item.discount_price || item.discount_price == 0) && (   //For modifiers
                                                                              item.modifier_sale_price === 0
                                                                                ? null
                                                                                : <span style={{ marginRight: 5, color: 'rgb(0, 105, 145)' }}>
                                                                                  (<span style={{ textDecoration: (item.discount_price || item.discount_price == 0) ? 'line-through' : 'none', color: '#d60000', fontSize: 9 }}>
                                                                                    {priceunit + item.modifier_sale_price}
                                                                                  </span>
                                                                                  {priceunit + Math.round(item.discount_price)}
                                                                                  )
                                                                                </span>
                                                                            )}
                                                                            {item.modifier_sale_price === 0
                                                                              ? null
                                                                              : item.discount_price == null && <span>
                                                                                ({priceunit + item.modifier_sale_price} ){" "}
                                                                              </span>
                                                                            }
                                                                          </span>
                                                                        </div>
                                                                        {item.selected && item.max_quantity > 1 && (
                                                                          <div
                                                                            style={{
                                                                              display: "flex",
                                                                              width: "5rem",
                                                                              justifyContent:
                                                                                "space-between",
                                                                            }}
                                                                          >
                                                                            <div className="decrement-btn">
                                                                              <a
                                                                                className="dec-icon"
                                                                                onClick={() =>
                                                                                  this.decrementQuantity(
                                                                                    key,
                                                                                    item.modifier_name
                                                                                  )
                                                                                }
                                                                              >
                                                                                -
                                                                              </a>
                                                                            </div>
                                                                            <div
                                                                              style={{
                                                                                fontWeight:
                                                                                  "bold",
                                                                                fontSize: 18,
                                                                              }}
                                                                            >
                                                                              {item.quantity}
                                                                            </div>
                                                                            <div className="increment-btn">
                                                                              <a
                                                                                className="inc-icon"
                                                                                onClick={() =>
                                                                                  this.incrementQuantity(
                                                                                    key,
                                                                                    item.modifier_name
                                                                                  )
                                                                                }
                                                                              >
                                                                                +
                                                                              </a>
                                                                            </div>
                                                                          </div>
                                                                        )}
                                                                      </div>
                                                                    );
                                                                  }
                                                                }
                                                              )}
                                                            </div>
                                                          )
                                                        })
                                                      }
                                                    </div>
                                                  )
                                                })}
                                              {!_.isEmpty(halfNHalfModifiers) &&
                                                Object.keys(halfNHalfModifiers[0].data).map((key, index) => {
                                                  return (
                                                    ((!halfNHalfModifiers[0].data[key].min) && (halfNHalfModifiers[0].data[key][0].is_toping && halfNHalfModifiers[0].data[key][0].is_toping == 1)) ?
                                                      <div key={key} className={`form-group-custom-control ${index > 0 && 'mt-4'}`}>
                                                        <div className="d-flex justify-content-between">
                                                          <h4>{key}</h4>
                                                          <span>
                                                            {((halfNHalfModifiers[0].data[key].is_crust && halfNHalfModifiers[0].data[key].is_crust == 1) || (halfNHalfModifiers[0].data[key].is_flavour && halfNHalfModifiers[0].data[key].is_flavour == 1)) ?
                                                              halfNHalfModifiers[0].data[key].min == 0 ?
                                                                <h6 className="mb-0 required-label">
                                                                  Optional
                                                                </h6>
                                                                :
                                                                <h6 id={`${key}`} className="mb-0 required-label">
                                                                  Required
                                                                </h6>
                                                              :
                                                              halfNHalfModifiers[0].data[key][0].modGroup_min_quantity == 0 ?
                                                                <h6 className="mb-0 required-label">
                                                                  Optional
                                                                </h6>
                                                                :
                                                                <h6 id={`${key}`} className="required-label">
                                                                  Required
                                                                </h6>
                                                            }
                                                          </span>
                                                        </div>
                                                        {!halfNHalfModifiers[0].data[key].min ? <span>
                                                          <h6 className="mb-0 required-label">
                                                            Select any {halfNHalfModifiers[0].data[key][0].modGroup_max_quantity}
                                                          </h6>
                                                        </span> : <span>
                                                          <h6 className="mb-0 required-label">
                                                            Select any {halfNHalfModifiers[0].data[key].min}
                                                          </h6>
                                                        </span>
                                                        }
                                                        {halfNHalfModifiers[0].data[key].map(
                                                          (item: any, index: any) => {
                                                            if (
                                                              item.modifier_type ==
                                                              "single"
                                                            ) {
                                                              return (
                                                                <div
                                                                  onClick={() =>
                                                                    this.addHalfModifier(key, null, item.modifier_name, null, 0)
                                                                  }
                                                                  className="d-flex pt-2 justify-content-between"
                                                                >
                                                                  <span>
                                                                    {item.modifier_name}
                                                                  </span>
                                                                  <div className="add-btn d-flex">
                                                                    {(item.discount_price || item.discount_price == 0) && (   //For modifiers
                                                                      item.modifier_sale_price === 0
                                                                        ? null
                                                                        : <span style={{ marginRight: 5, color: 'rgb(0, 105, 145)' }}>
                                                                          (<span style={{ textDecoration: (item.discount_price || item.discount_price == 0) ? 'line-through' : 'none', color: '#d60000', fontSize: 9 }}>
                                                                            {priceunit + item.modifier_sale_price}
                                                                          </span>
                                                                          {priceunit + Math.round(item.discount_price)}
                                                                          )
                                                                        </span>
                                                                    )}
                                                                    {item.modifier_sale_price === 0
                                                                      ? null
                                                                      : item.discount_price == null && <span>
                                                                        ( +
                                                                        {priceunit +
                                                                          item.modifier_sale_price
                                                                        }{" "}
                                                                        )
                                                                      </span>
                                                                    }
                                                                    {item.selected ? (
                                                                      <div className="added">
                                                                        <span className="btn-text-clr-added">
                                                                          Added
                                                                        </span>
                                                                      </div>
                                                                    ) : (
                                                                      <div className="notAdded">
                                                                        <span className="btn-text-clr">
                                                                          Add
                                                                        </span>
                                                                      </div>
                                                                    )}
                                                                  </div>
                                                                </div>
                                                              );
                                                            } else {
                                                              return (
                                                                <div className="multi-addon-row">
                                                                  <div
                                                                    key={index}
                                                                    className="flex-nowrap justify-content-start"
                                                                    onClick={() =>
                                                                      this.handleCheckBoxForHalf(key, item.modifier_name, 0)
                                                                    }
                                                                  >
                                                                    <input
                                                                      type="checkbox"
                                                                      checked={
                                                                        item.selected
                                                                      }
                                                                      className="checkbox-template"
                                                                      id="checkboxCustom1"
                                                                      name="adon"
                                                                    />
                                                                    <span
                                                                      style={{
                                                                        marginLeft:
                                                                          "20px",
                                                                      }}
                                                                    >
                                                                      {" "}
                                                                      {
                                                                        item.modifier_name
                                                                      }{" "}
                                                                      {(item.discount_price || item.discount_price == 0) && (   //For modifiers
                                                                        item.modifier_sale_price === 0
                                                                          ? null
                                                                          : <span style={{ marginRight: 5, color: 'rgb(0, 105, 145)' }}>
                                                                            (<span style={{ textDecoration: (item.discount_price || item.discount_price == 0) ? 'line-through' : 'none', color: '#d60000', fontSize: 9 }}>
                                                                              {priceunit + item.modifier_sale_price}
                                                                            </span>
                                                                            {priceunit + Math.round(item.discount_price)}
                                                                            )
                                                                          </span>
                                                                      )}
                                                                      {item.modifier_sale_price === 0
                                                                        ? null
                                                                        : item.discount_price == null && <span>
                                                                          ({priceunit + item.modifier_sale_price} ){" "}
                                                                        </span>
                                                                      }
                                                                    </span>
                                                                  </div>
                                                                  {item.selected && item.max_quantity > 1 && (
                                                                    <div
                                                                      style={{
                                                                        display: "flex",
                                                                        width: "5rem",
                                                                        justifyContent:
                                                                          "space-between",
                                                                      }}
                                                                    >
                                                                      <div className="decrement-btn">
                                                                        <a
                                                                          className="dec-icon"
                                                                          onClick={() =>
                                                                            this.decrementQuantity(
                                                                              key,
                                                                              item.modifier_name
                                                                            )
                                                                          }
                                                                        >
                                                                          -
                                                                        </a>
                                                                      </div>
                                                                      <div
                                                                        style={{
                                                                          fontWeight:
                                                                            "bold",
                                                                          fontSize: 18,
                                                                        }}
                                                                      >
                                                                        {item.quantity}
                                                                      </div>
                                                                      <div className="increment-btn">
                                                                        <a
                                                                          className="inc-icon"
                                                                          onClick={() =>
                                                                            this.incrementQuantity(
                                                                              key,
                                                                              item.modifier_name
                                                                            )
                                                                          }
                                                                        >
                                                                          +
                                                                        </a>
                                                                      </div>
                                                                    </div>
                                                                  )}
                                                                </div>
                                                              );
                                                            }
                                                          }
                                                        )}
                                                      </div> : ""
                                                  )
                                                })}
                                            </Tab>
                                            <Tab className="select-item" eventKey="2ndHalf" title="2nd Half">
                                              {!_.isEmpty(halfNHalfModifiers) &&
                                                Object.keys(halfNHalfModifiers[1].data).map((key, index) => {
                                                  return (
                                                    (halfNHalfModifiers[1].data[key].is_flavour && halfNHalfModifiers[1].data[key].is_flavour == 1) &&
                                                    <div key={key} className={`form-group-custom-control ${index > 0 && 'mt-4'}`}>
                                                      <div className="d-flex justify-content-between">
                                                        <h4>{key} 2</h4>
                                                        <span>
                                                          {((halfNHalfModifiers[1].data[key].is_crust && halfNHalfModifiers[1].data[key].is_crust == 1) || (halfNHalfModifiers[1].data[key].is_flavour && halfNHalfModifiers[1].data[key].is_flavour == 1)) ?
                                                            halfNHalfModifiers[1].data[key].min == 0 ?
                                                              <h6 className="mb-0 required-label">
                                                                Optional
                                                              </h6>
                                                              :
                                                              <h6 id={`${key}`} className="mb-0 required-label">
                                                                Required
                                                              </h6>
                                                            :
                                                            halfNHalfModifiers[1].data[key][0].modGroup_min_quantity == 0 ?
                                                              <h6 className="mb-0 required-label">
                                                                Optional
                                                              </h6>
                                                              :
                                                              <h6 id={`${key}`} className="required-label">
                                                                Required
                                                              </h6>
                                                          }
                                                        </span>
                                                      </div>
                                                      {!halfNHalfModifiers[1].data[key].min ? <span>
                                                        <h6 className="mb-0 required-label">
                                                          Select any {halfNHalfModifiers[1].data[key][0].modGroup_max_quantity}
                                                        </h6>
                                                      </span> : <span>
                                                        <h6 className="mb-0 required-label">
                                                          Select any {halfNHalfModifiers[1].data[key].min}
                                                        </h6>
                                                      </span>
                                                      }
                                                      {
                                                        Object.keys(halfNHalfModifiers[1].data[key]).map((subkey, index) => {
                                                          let found = 0;
                                                          const checkMod = (obj: any) => obj.variant_id && (obj.variant_id.id === (this.state.selectedsize.variant_id && this.state.selectedsize.variant_id.id));;
                                                          return (
                                                            Array.isArray(halfNHalfModifiers[1].data[key][subkey]) &&
                                                            <div
                                                              key={subkey}
                                                              className="form-group-custom-control mt-2"
                                                            >
                                                              {halfNHalfModifiers[1].data[key][subkey].map((obj: any) => {
                                                                if (found == 0) {
                                                                  if (obj.mod_variant_id) {
                                                                    if (obj.mod_variant_id.filter(checkMod).length > 0) {
                                                                      found += 1;
                                                                      return <h6>{subkey}</h6>
                                                                    }
                                                                  } else {
                                                                    found += 1;
                                                                    return <h6>{subkey}</h6>
                                                                  }
                                                                } else {
                                                                  return true;
                                                                }
                                                              })
                                                              }
                                                              {halfNHalfModifiers[1].data[key][subkey].map(
                                                                (item: any, index: any) => {
                                                                  if (
                                                                    item.modifier_type ==
                                                                    "single"
                                                                  ) {
                                                                    if (item.mod_variant_id) {
                                                                      const checkUsername = (obj: any) => obj.variant_id && (obj.variant_id.id === (this.state.selectedsize.variant_id && this.state.selectedsize.variant_id.id));;
                                                                      return (
                                                                        item.mod_variant_id.some(checkUsername) &&
                                                                        <div
                                                                          onClick={() =>
                                                                            this.addHalfModifier(key, subkey, item.modifier_name, halfNHalfModifiers[1].data[key], 1)
                                                                          }
                                                                          className="d-flex pt-2 justify-content-between"
                                                                        >
                                                                          <span>
                                                                            {item.modifier_name}
                                                                          </span>
                                                                          <div className="add-btn d-flex">
                                                                            {(item.discount_price || item.discount_price == 0) && (   //For modifiers
                                                                              item.modifier_sale_price === 0
                                                                                ? null
                                                                                : <span style={{ marginRight: 5, color: 'rgb(0, 105, 145)' }}>
                                                                                  (<span style={{ textDecoration: (item.discount_price || item.discount_price == 0) ? 'line-through' : 'none', color: '#d60000', fontSize: 9 }}>
                                                                                    {priceunit + item.modifier_sale_price}
                                                                                  </span>
                                                                                  {priceunit + Math.round(item.discount_price)}
                                                                                  )
                                                                                </span>
                                                                            )}
                                                                            {item.modifier_sale_price === 0
                                                                              ? null
                                                                              : item.discount_price == null && <span>
                                                                                ( +
                                                                                {priceunit +
                                                                                  item.modifier_sale_price
                                                                                }{" "}
                                                                                )
                                                                              </span>
                                                                            }
                                                                            {item.selected ? (
                                                                              <div className="added">
                                                                                <span className="btn-text-clr-added">
                                                                                  Added
                                                                                </span>
                                                                              </div>
                                                                            ) : (
                                                                              <div className="notAdded">
                                                                                <span className="btn-text-clr">
                                                                                  Add
                                                                                </span>
                                                                              </div>
                                                                            )}
                                                                          </div>
                                                                        </div>
                                                                      );
                                                                    } else {
                                                                      return (
                                                                        <div
                                                                          onClick={() =>
                                                                            this.addHalfModifier(key, subkey, item.modifier_name, halfNHalfModifiers[1].data[key], 1)
                                                                          }
                                                                          className="d-flex pt-2 justify-content-between"
                                                                        >
                                                                          <span>
                                                                            {item.modifier_name}
                                                                          </span>
                                                                          <div className="add-btn d-flex">
                                                                            {(item.discount_price || item.discount_price == 0) && (   //For modifiers
                                                                              item.modifier_sale_price === 0
                                                                                ? null
                                                                                : <span style={{ marginRight: 5, color: 'rgb(0, 105, 145)' }}>
                                                                                  (<span style={{ textDecoration: (item.discount_price || item.discount_price == 0) ? 'line-through' : 'none', color: '#d60000', fontSize: 9 }}>
                                                                                    {priceunit + item.modifier_sale_price}
                                                                                  </span>
                                                                                  {priceunit + Math.round(item.discount_price)}
                                                                                  )
                                                                                </span>
                                                                            )}
                                                                            {item.modifier_sale_price === 0
                                                                              ? null
                                                                              : item.discount_price == null && <span>
                                                                                ( +
                                                                                {priceunit +
                                                                                  item.modifier_sale_price
                                                                                }{" "}
                                                                                )
                                                                              </span>
                                                                            }
                                                                            {item.selected ? (
                                                                              <div className="added">
                                                                                <span className="btn-text-clr-added">
                                                                                  Added
                                                                                </span>
                                                                              </div>
                                                                            ) : (
                                                                              <div className="notAdded">
                                                                                <span className="btn-text-clr">
                                                                                  Add
                                                                                </span>
                                                                              </div>
                                                                            )}
                                                                          </div>
                                                                        </div>
                                                                      );
                                                                    }
                                                                  } else {
                                                                    return (
                                                                      <div className="multi-addon-row">
                                                                        <div
                                                                          key={index}
                                                                          className="flex-nowrap justify-content-start"
                                                                          onClick={() =>
                                                                            this.handleCheckBoxForHalf(subkey, item.modifier_name, 1)
                                                                          }
                                                                        >
                                                                          <input
                                                                            type="checkbox"
                                                                            checked={
                                                                              item.selected
                                                                            }
                                                                            className="checkbox-template"
                                                                            id="checkboxCustom1"
                                                                            name="adon"
                                                                          />
                                                                          <span
                                                                            style={{
                                                                              marginLeft:
                                                                                "20px",
                                                                            }}
                                                                          >
                                                                            {" "}
                                                                            {
                                                                              item.modifier_name
                                                                            }{" "}
                                                                            {(item.discount_price || item.discount_price == 0) && (   //For modifiers
                                                                              item.modifier_sale_price === 0
                                                                                ? null
                                                                                : <span style={{ marginRight: 5, color: 'rgb(0, 105, 145)' }}>
                                                                                  (<span style={{ textDecoration: (item.discount_price || item.discount_price == 0) ? 'line-through' : 'none', color: '#d60000', fontSize: 9 }}>
                                                                                    {priceunit + item.modifier_sale_price}
                                                                                  </span>
                                                                                  {priceunit + Math.round(item.discount_price)}
                                                                                  )
                                                                                </span>
                                                                            )}
                                                                            {item.modifier_sale_price === 0
                                                                              ? null
                                                                              : item.discount_price == null && <span>
                                                                                ({priceunit + item.modifier_sale_price} ){" "}
                                                                              </span>
                                                                            }
                                                                          </span>
                                                                        </div>
                                                                        {item.selected && item.max_quantity > 1 && (
                                                                          <div
                                                                            style={{
                                                                              display: "flex",
                                                                              width: "5rem",
                                                                              justifyContent:
                                                                                "space-between",
                                                                            }}
                                                                          >
                                                                            <div className="decrement-btn">
                                                                              <a
                                                                                className="dec-icon"
                                                                                onClick={() =>
                                                                                  this.decrementQuantity(
                                                                                    key,
                                                                                    item.modifier_name
                                                                                  )
                                                                                }
                                                                              >
                                                                                -
                                                                              </a>
                                                                            </div>
                                                                            <div
                                                                              style={{
                                                                                fontWeight:
                                                                                  "bold",
                                                                                fontSize: 18,
                                                                              }}
                                                                            >
                                                                              {item.quantity}
                                                                            </div>
                                                                            <div className="increment-btn">
                                                                              <a
                                                                                className="inc-icon"
                                                                                onClick={() =>
                                                                                  this.incrementQuantity(
                                                                                    key,
                                                                                    item.modifier_name
                                                                                  )
                                                                                }
                                                                              >
                                                                                +
                                                                              </a>
                                                                            </div>
                                                                          </div>
                                                                        )}
                                                                      </div>
                                                                    );
                                                                  }
                                                                }
                                                              )}
                                                            </div>
                                                          )
                                                        })
                                                      }
                                                    </div>
                                                  )
                                                })}
                                              {/* {!_.isEmpty(halfNHalfModifiers) &&
                                                Object.keys(halfNHalfModifiers[1].data).map((key, index) => {
                                                  return (
                                                    (!halfNHalfModifiers[1].data[key].is_crust && !halfNHalfModifiers[1].data[key].is_flavour) &&
                                                    <div key={key} className={`form-group-custom-control ${index > 0 && 'mt-4'}`}>
                                                      <div className="d-flex justify-content-between">
                                                        <h4>{key}</h4>
                                                        <span>
                                                          {((halfNHalfModifiers[1].data[key].is_crust && halfNHalfModifiers[1].data[key].is_crust == 1) || (halfNHalfModifiers[1].data[key].is_flavour && halfNHalfModifiers[1].data[key].is_flavour == 1)) ?
                                                            halfNHalfModifiers[1].data[key].min == 0 ?
                                                              <h6 className="mb-0 required-label">
                                                                Optional
                                                              </h6>
                                                              :
                                                              <h6 id={`${key}`} className="mb-0 required-label">
                                                                Required
                                                              </h6>
                                                            :
                                                            halfNHalfModifiers[1].data[key][0].modGroup_min_quantity == 0 ?
                                                              <h6 className="mb-0 required-label">
                                                                Optional
                                                              </h6>
                                                              :
                                                              <h6 id={`${key}`} className="required-label">
                                                                Required
                                                              </h6>
                                                          }
                                                        </span>
                                                      </div>
                                                      {!halfNHalfModifiers[1].data[key].min ? <span>
                                                        <h6 className="mb-0 required-label">
                                                          Select any {halfNHalfModifiers[1].data[key][0].modGroup_max_quantity}
                                                        </h6>
                                                      </span> : <span>
                                                        <h6 className="mb-0 required-label">
                                                          Select any {halfNHalfModifiers[1].data[key].min}
                                                        </h6>
                                                      </span>
                                                      }
                                                      {halfNHalfModifiers[1].data[key].map(
                                                        (item: any, index: any) => {
                                                          if (
                                                            item.modifier_type ==
                                                            "single"
                                                          ) {
                                                            return (
                                                              <div
                                                                onClick={() =>
                                                                  this.addHalfModifier(key, null, item.modifier_name, null, 1)
                                                                }
                                                                className="d-flex pt-2 justify-content-between"
                                                              >
                                                                <span>
                                                                  {item.modifier_name}
                                                                </span>
                                                                <div className="add-btn d-flex">
                                                                  {item.modifier_sale_price ==
                                                                    0 ||
                                                                    item.modifier_sale_price ==
                                                                    null ? (
                                                                    <span></span>
                                                                  ) : (
                                                                    <span>
                                                                      ( +
                                                                      {priceunit +
                                                                        item.modifier_sale_price
                                                                      }{" "}
                                                                      )
                                                                    </span>
                                                                  )}
                                                                  {item.selected ? (
                                                                    <div className="added">
                                                                      <span className="btn-text-clr-added">
                                                                        Added
                                                                      </span>
                                                                    </div>
                                                                  ) : (
                                                                    <div className="notAdded">
                                                                      <span className="btn-text-clr">
                                                                        Add
                                                                      </span>
                                                                    </div>
                                                                  )}
                                                                </div>
                                                              </div>
                                                            );
                                                          } else {
                                                            return (
                                                              <div className="multi-addon-row">
                                                                <div
                                                                  key={index}
                                                                  className="flex-nowrap justify-content-start"
                                                                  onClick={() =>
                                                                    this.handleCheckBoxForHalf(key, item.modifier_name, 1)
                                                                  }
                                                                >
                                                                  <input
                                                                    type="checkbox"
                                                                    checked={
                                                                      item.selected
                                                                    }
                                                                    className="checkbox-template"
                                                                    id="checkboxCustom1"
                                                                    name="adon"
                                                                  />
                                                                  <span
                                                                    style={{
                                                                      marginLeft:
                                                                        "20px",
                                                                    }}
                                                                  >
                                                                    {" "}
                                                                    {
                                                                      item.modifier_name
                                                                    }{" "}
                                                                    {item.modifier_sale_price ===
                                                                      0
                                                                      ? null
                                                                      : `(${priceunit + item.modifier_sale_price} )`}{" "}
                                                                  </span>
                                                                </div>
                                                                {item.selected && item.max_quantity > 1 && (
                                                                  <div
                                                                    style={{
                                                                      display: "flex",
                                                                      width: "5rem",
                                                                      justifyContent:
                                                                        "space-between",
                                                                    }}
                                                                  >
                                                                    <div className="decrement-btn">
                                                                      <a
                                                                        className="dec-icon"
                                                                        onClick={() =>
                                                                          this.decrementQuantity(
                                                                            key,
                                                                            item.modifier_name
                                                                          )
                                                                        }
                                                                      >
                                                                        -
                                                                      </a>
                                                                    </div>
                                                                    <div
                                                                      style={{
                                                                        fontWeight:
                                                                          "bold",
                                                                        fontSize: 18,
                                                                      }}
                                                                    >
                                                                      {item.quantity}
                                                                    </div>
                                                                    <div className="increment-btn">
                                                                      <a
                                                                        className="inc-icon"
                                                                        onClick={() =>
                                                                          this.incrementQuantity(
                                                                            key,
                                                                            item.modifier_name
                                                                          )
                                                                        }
                                                                      >
                                                                        +
                                                                      </a>
                                                                    </div>
                                                                  </div>
                                                                )}
                                                              </div>
                                                            );
                                                          }
                                                        }
                                                      )}
                                                    </div>
                                                  )
                                                })} */}
                                            </Tab>
                                          </Tabs>
                                        </div>
                                      </div>
                                      {!_.isEmpty(modifiers) && Object.keys(modifiers).map((key, index) => {
                                        return (
                                          (!modifiers[key].min) && (modifiers[key][0].is_condiment && modifiers[key][0].is_condiment == 1) ?
                                            <div key={key} className={`form-group-custom-control ${index > 0 && 'mt-4'}`}>
                                              <div className="d-flex justify-content-between">
                                                <h4>{key}</h4>
                                                <span>
                                                  {
                                                    modifiers[key][0].modGroup_min_quantity == 0 ?
                                                      <h6 className="mb-0 required-label">
                                                        Optional
                                                      </h6>
                                                      :
                                                      <h6 id={`${key}`} className="required-label">
                                                        Required
                                                      </h6>
                                                  }
                                                </span>
                                              </div>
                                              <span>
                                                <h6 className="mb-0 required-label">
                                                  Select any {modifiers[key].min}
                                                </h6>
                                              </span>
                                              {modifiers[key].map(
                                                (item: any, index: any) => {
                                                  if (
                                                    item.modifier_type ==
                                                    "single"
                                                  ) {
                                                    return (
                                                      <div
                                                        onClick={() =>
                                                          this.addModifier(key, null, item.modifier_name, null)
                                                        }
                                                        className="d-flex pt-2 justify-content-between"
                                                      >
                                                        <span>
                                                          {item.modifier_name}
                                                        </span>
                                                        <div className="add-btn d-flex">
                                                          {(item.discount_price || item.discount_price == 0) && (   //For modifiers
                                                            item.modifier_sale_price === 0
                                                              ? null
                                                              : <span style={{ marginRight: 5, color: 'rgb(0, 105, 145)' }}>
                                                                (<span style={{ textDecoration: (item.discount_price || item.discount_price == 0) ? 'line-through' : 'none', color: '#d60000', fontSize: 9 }}>
                                                                  {priceunit + item.modifier_sale_price}
                                                                </span>
                                                                {priceunit + Math.round(item.discount_price)}
                                                                )
                                                              </span>
                                                          )}
                                                          {item.modifier_sale_price === 0
                                                            ? null
                                                            : item.discount_price == null && <span>
                                                              ( +
                                                              {priceunit +
                                                                item.modifier_sale_price
                                                              }{" "}
                                                              )
                                                            </span>
                                                          }
                                                          {item.selected ? (
                                                            <div className="added">
                                                              <span className="btn-text-clr-added">
                                                                Added
                                                              </span>
                                                            </div>
                                                          ) : (
                                                            <div className="notAdded">
                                                              <span className="btn-text-clr">
                                                                Add
                                                              </span>
                                                            </div>
                                                          )}
                                                        </div>
                                                      </div>
                                                    );
                                                  } else {
                                                    return (
                                                      <div className="multi-addon-row">
                                                        <div
                                                          key={index}
                                                          className="flex-nowrap justify-content-start"
                                                          onClick={() =>
                                                            this.handleChangeChk(key, item.modifier_name)
                                                          }
                                                        >
                                                          <input
                                                            type="checkbox"
                                                            checked={
                                                              item.selected
                                                            }
                                                            className="checkbox-template"
                                                            id="checkboxCustom1"
                                                            name="adon"
                                                          />
                                                          <span
                                                            style={{
                                                              marginLeft:
                                                                "20px",
                                                            }}
                                                          >
                                                            {" "}
                                                            {
                                                              item.modifier_name
                                                            }{" "}
                                                            {(item.discount_price || item.discount_price == 0) && (   //For modifiers
                                                              item.modifier_sale_price === 0
                                                                ? null
                                                                : <span style={{ marginRight: 5, color: 'rgb(0, 105, 145)' }}>
                                                                  (<span style={{ textDecoration: (item.discount_price || item.discount_price == 0) ? 'line-through' : 'none', color: '#d60000', fontSize: 9 }}>
                                                                    {priceunit + item.modifier_sale_price}
                                                                  </span>
                                                                  {priceunit + Math.round(item.discount_price)}
                                                                  )
                                                                </span>
                                                            )}
                                                            {item.modifier_sale_price === 0
                                                              ? null
                                                              : item.discount_price == null && <span>
                                                                ({priceunit + item.modifier_sale_price} ){" "}
                                                              </span>
                                                            }
                                                          </span>
                                                        </div>
                                                        {item.selected && item.max_quantity > 1 && (
                                                          <div
                                                            style={{
                                                              display: "flex",
                                                              width: "5rem",
                                                              justifyContent:
                                                                "space-between",
                                                            }}
                                                          >
                                                            <div className="decrement-btn">
                                                              <a
                                                                className="dec-icon"
                                                                onClick={() =>
                                                                  this.decrementQuantity(
                                                                    key,
                                                                    item.modifier_name
                                                                  )
                                                                }
                                                              >
                                                                -
                                                              </a>
                                                            </div>
                                                            <div
                                                              style={{
                                                                fontWeight:
                                                                  "bold",
                                                                fontSize: 18,
                                                              }}
                                                            >
                                                              {item.quantity}
                                                            </div>
                                                            <div className="increment-btn">
                                                              <a
                                                                className="inc-icon"
                                                                onClick={() =>
                                                                  this.incrementQuantity(
                                                                    key,
                                                                    item.modifier_name
                                                                  )
                                                                }
                                                              >
                                                                +
                                                              </a>
                                                            </div>
                                                          </div>
                                                        )}
                                                      </div>
                                                    );
                                                  }
                                                }
                                              )}
                                            </div> : ""
                                        )
                                      })}
                                    </>
                                  }
                                  {/* For Norma Item */}
                                  {(!_.isEmpty(itemData) && itemData.is_full_pizza == 0 && itemData.is_half_n_half == 0 && !_.isEmpty(itemData.modifiers)) &&
                                    Object.keys(modifiers).map((key, index) => {
                                      return (
                                        <div
                                          key={key}
                                          className={`form-group-custom-control ${index > 0 && 'mt-4'}`}
                                        >
                                          <div className="d-flex justify-content-between">
                                            <h4>{key}</h4>
                                            <span>{((modifiers[key].is_crust && modifiers[key].is_crust == 1)) ?
                                              modifiers[key].min == 0 ?
                                                <h6 className="mb-0 required-label">
                                                  Optional
                                                </h6>
                                                :
                                                <h6 id={`${key}`} className="mb-0 required-label">
                                                  Required
                                                </h6>
                                              :
                                              modifiers[key][0].modGroup_min_quantity == 0 ?
                                                <h6 className="mb-0 required-label">
                                                  Optional
                                                </h6>
                                                :
                                                <h6 id={`${key}`} className="required-label">
                                                  Required
                                                </h6>
                                            }</span>
                                          </div>
                                          {!modifiers[key].min ? <span>
                                            <h6 className="mb-0 required-label">
                                              Select any {modifiers[key][0].modGroup_max_quantity}
                                            </h6>
                                          </span> : <span>
                                            <h6 className="mb-0 required-label">
                                              Select any {modifiers[key].min}
                                            </h6>
                                          </span>
                                          }
                                          {
                                            <div
                                              key={key}
                                              className="form-group-custom-control mt-2"
                                            >

                                              {modifiers[key].map(
                                                (item: any, index: any) => {
                                                  if (
                                                    item.modifier_type ==
                                                    "single"
                                                  ) {
                                                    return (
                                                      <div
                                                        onClick={() =>
                                                          this.addModifier(key, null, item.modifier_name, null)
                                                        }
                                                        className="d-flex pt-2 justify-content-between"
                                                      >
                                                        <span>
                                                          {item.modifier_name}
                                                        </span>
                                                        <div className="add-btn d-flex">
                                                          {(item.discount_price || item.discount_price == 0) && (   //For modifiers
                                                            item.modifier_sale_price === 0
                                                              ? null
                                                              : <span style={{ marginRight: 5, color: 'rgb(0, 105, 145)' }}>
                                                                (<span style={{ textDecoration: (item.discount_price || item.discount_price == 0) ? 'line-through' : 'none', color: '#d60000', fontSize: 9 }}>
                                                                  {priceunit + item.modifier_sale_price}
                                                                </span>
                                                                {priceunit + Math.round(item.discount_price)}
                                                                )
                                                              </span>
                                                          )}
                                                          {item.modifier_sale_price === 0
                                                            ? null
                                                            : item.discount_price == null && <span>
                                                              ( +
                                                              {priceunit +
                                                                item.modifier_sale_price
                                                              }{" "}
                                                              )
                                                            </span>
                                                          }
                                                          {item.selected ? (
                                                            <div className="added">
                                                              <span className="btn-text-clr-added">
                                                                Added
                                                              </span>
                                                            </div>
                                                          ) : (
                                                            <div className="notAdded">
                                                              <span className="btn-text-clr">
                                                                Add
                                                              </span>
                                                            </div>
                                                          )}
                                                        </div>
                                                      </div>
                                                    );
                                                  } else {
                                                    return (
                                                      <div className="multi-addon-row">
                                                        <div
                                                          key={index}
                                                          className="flex-nowrap justify-content-start"
                                                          onClick={() =>
                                                            this.handleChangeChk(
                                                              key,
                                                              item.modifier_name
                                                            )
                                                          }
                                                        >
                                                          <input
                                                            type="checkbox"
                                                            checked={
                                                              item.selected
                                                            }
                                                            className="checkbox-template"
                                                            id="checkboxCustom1"
                                                            name="adon"
                                                          />
                                                          <span
                                                            style={{
                                                              marginLeft:
                                                                "20px",
                                                            }}
                                                          >
                                                            {" "}
                                                            {
                                                              item.modifier_name
                                                            }{" "}
                                                            {(item.discount_price || item.discount_price == 0) && (   //For modifiers
                                                              item.modifier_sale_price === 0
                                                                ? null
                                                                : <span style={{ marginRight: 5, color: 'rgb(0, 105, 145)' }}>
                                                                  (<span style={{ textDecoration: (item.discount_price || item.discount_price == 0) ? 'line-through' : 'none', color: '#d60000', fontSize: 9 }}>
                                                                    {priceunit + item.modifier_sale_price}
                                                                  </span>
                                                                  {priceunit + Math.round(item.discount_price)}
                                                                  )
                                                                </span>
                                                            )}
                                                            {item.modifier_sale_price === 0
                                                              ? null
                                                              : item.discount_price == null && <span>
                                                                ({priceunit + item.modifier_sale_price} ){" "}
                                                              </span>
                                                            }
                                                          </span>
                                                        </div>
                                                        {item.selected && item.max_quantity > 1 && (
                                                          <div
                                                            style={{
                                                              display: "flex",
                                                              width: "5rem",
                                                              justifyContent:
                                                                "space-between",
                                                            }}
                                                          >
                                                            <div className="decrement-btn">
                                                              <a
                                                                className="dec-icon"
                                                                onClick={() =>
                                                                  this.decrementQuantity(
                                                                    key,
                                                                    item.modifier_name
                                                                  )
                                                                }
                                                              >
                                                                -
                                                              </a>
                                                            </div>
                                                            <div
                                                              style={{
                                                                fontWeight:
                                                                  "bold",
                                                                fontSize: 18,
                                                              }}
                                                            >
                                                              {item.quantity}
                                                            </div>
                                                            <div className="increment-btn">
                                                              <a
                                                                className="inc-icon"
                                                                onClick={() =>
                                                                  this.incrementQuantity(
                                                                    key,
                                                                    item.modifier_name
                                                                  )
                                                                }
                                                              >
                                                                +
                                                              </a>
                                                            </div>
                                                          </div>
                                                        )}
                                                      </div>
                                                    );
                                                  }
                                                }
                                              )}
                                            </div>
                                          }
                                        </div>
                                      )
                                    })
                                  }
                                </div>
                              </div>
                              {(!_.isEmpty(itemData) && itemData.menu_item_id && itemData.menu_item_id > 0) &&
                                <div className="col-lg-12 d-flex align-items-center" style={{ padding: "unset" }}>
                                  <div className="project-title d-flex align-items-center">
                                    <div className="form-group">
                                      <label className="form-control-label">
                                        <h4 className="text-dark"> Kitchen Instructions</h4>
                                      </label>
                                      <input
                                        id="kitchen_inst"
                                        type="text"
                                        name="kitchen_inst"
                                        required
                                        value={this.state.kitchen_inst}
                                        data-msg="Please enter Kitchen Instructions"
                                        className="input-material"
                                        onChange={(e) => { this.setState({ kitchen_inst: e.target.value }) }}
                                      />
                                    </div>
                                  </div>
                                </div>
                              }
                            </div>
                            {/* ))} */}
                            <div className="item d-flex justify-content-between">
                              <div className="info d-flex">
                                <div className="title">
                                  <button
                                    className="btn btn-xs btn-danger"
                                    onClick={() => this.handleAdd(itemData)}
                                    disabled={this.state.isCartButtonDisable}
                                  >
                                    Add to cart
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>

                    <div className="col-lg-4 listScroll">{this.cartItem(cart)}</div>
                  </div>
                </div>
                :
                <div className="container-fluid">
                  <div className="error-msg">
                    <img src="assets/img/danger.png" alt="warning icon" />
                    {_.isEmpty(customer) && _.isEmpty(selectedStore) &&
                      <span className="error-text">Select customer and store to continue with menu section</span>
                    }
                    {!_.isEmpty(customer) && _.isEmpty(selectedStore) &&
                      <span className="error-text">Select store to continue order taking process</span>
                    }
                    {_.isEmpty(customer) && !_.isEmpty(selectedStore) &&
                      <span className="error-text">Select customer to continue order taking process</span>
                    }
                  </div>
                </div>
              }
            </section>
          </div>
        </div>
        <Modal
          open={open}
          showCloseIcon={false}
          onClose={this.onCloseModal}
          center
        >
          <div style={{ paddingTop: "5%", paddingBottom: "2%" }}>
            <p>
              Congratulations! Your order has been succesfully placed.
              <h6>Your Order tracking ID is {order_id}</h6>
            </p>

            <div style={{ textAlign: "center" }}>
              <button
                onClick={this.GotoHome}
                className="btn btn-sm btn-primary center"
                style={{
                  backgroundColor: "#C00A27",
                  borderColor: "#C00A27",
                  color: "white",
                }}
              >
                OKAY
              </button>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}
const mapStateToProps = (state: any) => {
  return {
    groups: state.menu.allGroupsData,
    itemsbygroupid: state.menu.menuItemsData,
    itemData: state.menu.itemData,
    counter: state.menu.counter,
    cart: state.cart.cartData,
    stores: state.customer.storesList,
    selectedStore: state.customer.selectedStore,
    tax: state.cart.taxData,
    deliveryfee: state.menu.deliveryfee,
    delivery_address: state.menu.delivery_address,
    special_inst: state.menu.special_inst,
    geo_code_address: state.menu.geocoded_address,
    houseNo: state.menu.houseNo,
    Block: state.menu.Block,
    Area: state.menu.Area,
    City: state.menu.City,
    Additional_address_info: state.menu.Additional_address_info,
    callduration: state.customer.clockCount,
    taxDataCash: state.menu.taxDataCash,
    taxDataCard: state.menu.taxDataCard,
    backupStores: state.menu.backupStoresList,
    allStoresList: state.customer.allStoresList,
    coupanCode: state.menu.coupanCode,
    coupon: state.menu.coupon,
    deliveryTime: state.menu.deliveryTime,
    taxAmount: state.menu.taxAmount,
    discountAmount: state.menu.discountAmount,
    open: state.customer.successFlag,
    order_id: state.customer.order_id,
    CartTotal: state.menu.CartTotal,
    orderPeriod: state.menu.orderPeriod,
    storesOption: state.menu.storesoptions,
    isLoading: state.menu.isLoading,
    groupsData: state.menu.groupsData,
    customer: state.customer.customerData,
    cities_portal: state.trade_zones_areas.cities,
    tradeZones_portal: state.trade_zones_areas.tradeZones,
    tradeAreas_portal: state.trade_zones_areas.tradeAreas,
    cityName: state.trade_zones_areas.cityName,
    tradeZoneName: state.trade_zones_areas.tradeZoneName,
    tradeAreaName: state.trade_zones_areas.tradeAreaName,
    tradeZoneValue: state.trade_zones_areas.tradeZoneValue,
    tradeAreaValue: state.trade_zones_areas.tradeAreaValue,
    streetName: state.menu.streetName,
    streetNo: state.menu.streetNo,
  };
};
const mapDispatchToProps = (dispatch: any) => {
  return {
    logoutUser: () => {
      dispatch(logoutUser());
    },
    handleMenuInput: (event: any) => {
      dispatch(handleMenuInput(event));
    },
    allGroupsList: (store_id: any, channel: any) => {
      dispatch(allGroupsList(store_id, channel));
    },
    ordersCounter: () => {
      dispatch(ordersCounter());
    },
    menuItemsListByGroupsID: (groupId: any) => {
      dispatch(menuItemsListByGroupsID(groupId));
    },
    getComboDetail: (store_id: any, combo_id: any) => {
      dispatch(getComboDetail(store_id, combo_id));
    },
    getItemDetail: (store_id: any, menu_item_id: any) => {
      dispatch(getItemDetail(store_id, menu_item_id));
    },
    storesList: () => {
      dispatch(getStores());
    },
    saveSelectStoreId: (store: any, channel: any) => {
      dispatch(saveSelectStoreId(store, channel));
    },
    getCart: () => {
      dispatch(getCart());
    },
    saveCart: (cart: any[]) => {
      dispatch(saveCart(cart));
    },
    clearCart: () => {
      dispatch(clearCart());
    },
    saveStore: (store: any) => {
      dispatch(saveStore(store));
    },
    saveBackupStores: (backupStores: any) => {
      dispatch(saveBackupStores(backupStores));
    },
    getTax: (state_id: any) => {
      dispatch(getTaxValue(state_id));
    },
    saveOrder: (data: any, store: any, tax: any, callObj: any, disableBtn?: any) => {
      dispatch(saveOrder(data, store, tax, callObj, disableBtn));
    },
    applyCoupon: function (coupon: any) {
      dispatch(applyCoupon(coupon));
    },
    resetCoupon: () => {
      dispatch(resetCoupon())
    },
    saveDeliveryFeeForCoupon: (fee: Number) => {
      dispatch(saveDeliveryFeeForCoupon(fee))
    },
    removeCoupon: () => {
      dispatch(removeCoupon())
    },
    consumeCoupon: function (coupon: any) {
      dispatch(consumeCoupon(coupon))
    },
    saveTaxDetails: function (state_name: any, store_id: any) {
      dispatch(saveTaxDetails(state_name, store_id))
    },
    handleAddressInput: (event: any) => { dispatch(handleAddressInput(event)) },
    handleGeoCodeAddress: (event: any) => { dispatch(handleGeoCodeAddress(event)) },
    findLocalStores: function (lat: any, long: any) {
      dispatch(findLocalStores(lat, long));
    },
    stopTimer: function () {
      dispatch(stopTimer())
    },
    findPickupStores: function (lat: any, long: any, value: any) {
      dispatch(findPickupStores(lat, long, value));
    },
    SaveTaxValue: function (taxAmount: any) {
      dispatch(SaveTaxValue(taxAmount));
    },
    SaveDiscountValue: function (discountAmount: any) {
      dispatch(SaveDiscountValue(discountAmount));
    },
    SaveCartTotal: function (cart_total: any) {
      dispatch(SaveCartTotal(cart_total));
    },
    saveOrderPeriod: (period: any) => {
      dispatch(saveOrderPeriod(period));
    },
    storesOptionList: () => {
      dispatch(storesListForMultiSelect())
    },
    UpdateMenuItems: function (data: any) {
      dispatch(UpdateMenuItems(data))
    },
    getCities: function () {
      dispatch(getCitiesWeb());
    },
    getTradeZones: function (id: any) {
      dispatch(getTradeZonesWeb(id))
    },
    getTradeAreas: function (id: any) {
      dispatch(getTradeAreas(id))
    },
    setTradeZoneName: function (name: String, value: any) {
      dispatch(setTradeZoneName(name, value))
    },
    setTradeAreaName: function (name: String, value: any) {
      dispatch(setTradeAreaName(name, value))
    },
    setCityName: function (name: String) {
      dispatch(setCityName(name))
    },
    setHouseNo: function (address: String) {
      dispatch(handleHouseNo(address))
    },
    findLocalStoreWithoutMap: function (store: any) {
      dispatch(findLocalStoreWithoutMap(store))
    },
    customerDetail: (data: any) => {
      dispatch(customerDetail(data));
    },
    getTimezone: function (id: number) {
      return dispatch(getTimezone(id))
    },
    getTimezoneForLater: function (id: number) {
      return dispatch(getTimezoneForLater(id))
    },
    resetItemSize: () => {
      dispatch(resetItemSize());
    },
    setStreetName: function (address: String) {
      dispatch(handleStreetName(address))
    },
    setStreetNo: function (address: String) {
      dispatch(handleStreetNo(address))
    },
    handleAddInfo: (value: any) => {
      dispatch(handleAddInfo(value))
    },

  }
};
export default connect(mapStateToProps, mapDispatchToProps)(Menu);
