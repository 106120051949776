import Api from '../../components/Api';
import { CITIES_WEB, TRADE_ZONES_WEB, TRADE_AREAS_WEB, SET_CITY_NAME, SET_TRADE_ZONE_NAME, SET_TRADE_AREA_NAME } from './trade_zones_areas_Type';
export const getCitiesWeb = () => {
  return function (dispatch: any) {
    Api.get(`/customer/cities_web`)
      .then((response) => {
        if (response.data.success) {
          dispatch({
            type: CITIES_WEB,
            payload: response.data.successResponse
          })
        }
      }).catch(err => {
        if (err.response) {

        } else {
          alert(err.message)
        }
      });
  }
}


export const getTradeZonesWeb = (id: number) => {
  return function (dispatch: any) {
    let token: any = localStorage.getItem('token');
    Api.get(`/call-portal/tradezones/${id}`, {
      headers: {
        'Authorization': 'Bearer ' + token,
      }
    })
      .then((response) => {
        if (response.data.success) {
          dispatch({
            type: TRADE_ZONES_WEB,
            payload: response.data.successResponse
          })
        }
      }).catch(err => {
        if (err.response) {

        } else {
          alert(err.message)
        }
      });
  }
}

export const getTradeAreas = (id: number) => {
  return function (dispatch: any) {
    let token: any = localStorage.getItem('token');
    Api.get(`/call-portal/tradeareas/${id}`, {
      headers: {
        'Authorization': 'Bearer ' + token,
      }
    })
      .then((response) => {
        if (response.data.success) {
          let address: any = (localStorage.getItem("addressObj")) ? localStorage.getItem("addressObj") : null
          let data = address != null ? JSON.parse(address) : null
          if (data) {
            let selectedArea: any = response.data.successResponse && response.data.successResponse.filter((area: any) => {
              return area.id == data.area_id
            })
            localStorage.setItem("selectedArea", JSON.stringify(selectedArea))
          }
          dispatch({
            type: TRADE_AREAS_WEB,
            payload: response.data.successResponse
          })
        }
      }).catch(err => {
        if (err.response) {
          dispatch({
            type: TRADE_AREAS_WEB,
            payload: []
          })
        } else {
          alert(err.message)
        }
      });
  }
}

export const setCityName = (name: String,value?: any) => {
  return async (dispatch: any) => {
    dispatch({
      type: SET_CITY_NAME,
      payload: name,
      value: value
    })
  }
}

export const setTradeZoneName = (name: String, value: any) => {
  return async (dispatch: any) => {
    dispatch({
      type: SET_TRADE_ZONE_NAME,
      payload: name,
      value: value
    })
  }
}

export const setPromiseTimeToZero = () => {
  return async (dispatch: any) => {
    dispatch({
      type: 'SAVE_DELIVERY_TIME',
      promiseTime: 0,
    });
  }
}

export const setTradeAreaName = (name: String, value: any) => {
  return async (dispatch: any) => {
    dispatch({
      type: SET_TRADE_AREA_NAME,
      payload: name,
      value: value
    })
  }
}

